import { DotsVerticalIcon } from '@radix-ui/react-icons';
import { Checkbox, IconButton, Table, Text } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { CommonMenu } from 'components/common/menu';
import { CommonTooltip } from 'components/common/tooltip';
import { CheckedContext } from 'contexts/layout/checked.context';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { LOCAL_TIMEZONE } from 'enums/env';
import { IMenuAction } from 'interfaces/i-menus';
import { ITableAction } from 'interfaces/tables/action';
import { ITableColumn } from 'interfaces/tables/columns';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMongoBase } from 'lib_ts/interfaces/mongo/_base';
import { MouseEventHandler, useContext, useMemo } from 'react';

// creates menu actions from table actions and the provided data model
export const convertTableToMenu = (
  action: ITableAction,
  model: any
): IMenuAction => {
  const output: IMenuAction = {
    ...action,
    invisible: action.invisibleFn?.(model),
    onClick: () => {
      if (action.disableFn && action.disableFn(model)) {
        return;
      }

      action.onClick(model);
    },
  };

  return output;
};

interface IBodyCell {
  menuID: string;
  col: ITableColumn;
  item: any;
}

interface ICheckCell extends IBodyCell {
  index: number;
  afterCheckOne?: (changedID: string, checked: boolean) => void;
  onShiftClick?: MouseEventHandler;
}

export const CheckboxCell = (props: ICheckCell) => {
  const { getChecked, setChecked, setLastCheckedIndex } =
    useContext(CheckedContext);

  const { col, index, onShiftClick, afterCheckOne } = props;

  const _item = props.item as IMongoBase;

  const checked = getChecked(_item._id);

  return (
    <Table.Cell
      className={StringHelper.classNames([col.classNameFn?.(_item)])}
      data-testid="col-checkbox"
      data-checked={checked}
      align={col.align ?? 'center'}
    >
      <Checkbox
        className="cursor-pointer"
        style={{ marginTop: '2px' }}
        checked={checked}
        color={col.colorFn?.(_item) ?? col.color}
        onClick={(event) => {
          if (!_item._id) {
            console.error('checkbox item has no _id value!', _item);
            return;
          }

          if (event.shiftKey && onShiftClick) {
            // Allow the parent table to handle shift-click/bulk selection
            onShiftClick(event);
          } else {
            // Handle single checkbox toggle
            const nextValue = !checked;
            setChecked([_item._id], nextValue);
            afterCheckOne?.(_item._id, nextValue);
          }

          // set the last checked index as a reference for shift-click selection
          setLastCheckedIndex(index);

          // prevent this from triggering select row logic (i.e. row.onClick)
          event.stopPropagation();
        }}
      />
    </Table.Cell>
  );
};

export const ActionsCell = (props: IBodyCell) => {
  const { col, item } = props;

  const classes = useMemo(
    () => StringHelper.classNames([col.classNameFn?.(item)]),
    [col.classNameFn, item]
  );

  const safeActions = useMemo(() => {
    if (!col.actions) {
      return [];
    }

    return col.actions.map((a) => convertTableToMenu(a, item));
  }, [col.actions, item]);

  return (
    <Table.Cell
      className={classes}
      data-testid="col-actions"
      align={col.align ?? 'center'}
    >
      <CommonMenu
        title="Actions"
        trigger={
          <IconButton
            id={props.menuID}
            color={col.colorFn?.(item) ?? col.color ?? RADIX.COLOR.NEUTRAL}
            className="btn-floating"
            size={RADIX.BUTTON.SIZE.TABLE}
            variant={RADIX.BUTTON.VARIANT.TABLE}
          >
            <DotsVerticalIcon />
          </IconButton>
        }
        actions={safeActions}
        skipSort
      />
    </Table.Cell>
  );
};

export const BasicCell = (props: IBodyCell) => {
  const { col, item } = props;

  const classes = useMemo(
    () => StringHelper.classNames([col.classNameFn?.(item)]),
    [col.classNameFn, item]
  );

  const content = useMemo(() => {
    try {
      if (col.formatFn) {
        return col.formatFn(item);
      }

      const anyValue = item[col.key];

      switch (col.dataType) {
        case 'date': {
          const value = anyValue ? parseISO(anyValue) : '';
          if (value) {
            return format(value, 'yyyy-MM-dd', {
              timeZone: LOCAL_TIMEZONE,
            });
          }
          break;
        }

        case 'datetime': {
          const value = anyValue ? parseISO(anyValue) : '';
          if (value) {
            return format(value, 'yyyy-MM-dd @ HH:mm z', {
              timeZone: LOCAL_TIMEZONE,
            });
          }
          break;
        }

        default: {
          break;
        }
      }

      return anyValue ?? '--';
    } catch (e) {
      console.error(e);
      return '--';
    }
  }, [item, col]);

  const tooltip = useMemo(() => col.tooltipFn?.(item), [col.tooltipFn, item]);

  return (
    <Table.Cell
      className={classes}
      data-type={props.col.dataType}
      align={props.col.align}
    >
      <CommonTooltip
        disabled={!tooltip}
        text_md={tooltip}
        trigger={
          <Text color={props.col.colorFn?.(props.item) ?? props.col.color}>
            {content}
          </Text>
        }
      />
    </Table.Cell>
  );
};
