import { Box, Code, Heading } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { CommonTextareaInput } from 'components/common/form/textarea';
import { IMachineModelsContext } from 'contexts/admin/machine-models.context';
import { IAuthContext } from 'contexts/auth.context';
import { IFullDialog } from 'interfaces/i-dialogs';
import { ModelHelper } from 'lib_ts/classes/model.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { ModelStatus } from 'lib_ts/enums/machine-models.enums';
import { BallType } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  IMachineModel,
  IMachineModelEditor,
} from 'lib_ts/interfaces/modelling/i-machine-model';
import React from 'react';

const COMPONENT_NAME = 'MachineModelEditorDialog';

interface IProps {
  /** undefined means we're creating, we do a lookup with id to ensure we never mutate original */
  id?: string;

  authCx: IAuthContext;
  machineModelsCx: IMachineModelsContext;
  onClose: () => void;
}

interface IState extends IMachineModelEditor {
  model: Partial<IMachineModel>;
  tags: string;
}

export class MachineModelEditorDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const found = props.machineModelsCx.models.find((m) => m._id === props.id);

    if (props.id && !found) {
      NotifyHelper.error({
        message_md: `Failed to find any model with _id ${props.id}!`,
      });
    }

    const model: Partial<IMachineModel> = found ?? {
      name: '',
    };

    /** make a copy to manipulate via forms */
    this.state = {
      model: {
        ...model,
      },
      ball_type: ModelHelper.getBallType(model),
      status: ModelHelper.getStatus(model),
      calibration_only: !!model.calibration_only,
      tags: ModelHelper.getNonSpecialTags(model.tags)
        .map((t) => t.trim())
        .join(','),
    };

    this.renderContent = this.renderContent.bind(this);
    this.updateMetadata = this.updateMetadata.bind(this);
  }

  private updateMetadata(model: Partial<IMachineModel>) {
    this.setState({
      model: {
        ...this.state.model,
        ...model,
      },
    });
  }

  render() {
    const mergeProps: IFullDialog = {
      identifier: COMPONENT_NAME,
      width: RADIX.DIALOG.WIDTH.MD,
      title: 'Update Model',
      loading: this.props.machineModelsCx.loading,
      content: this.renderContent(),
      buttons: [
        {
          label: 'Update',
          color: RADIX.COLOR.INFO,
          onClick: () => {
            if (!this.state.model.name) {
              NotifyHelper.error({
                message_md: 'Please enter a name and try again.',
              });
              return;
            }

            if (!this.state.status) {
              NotifyHelper.error({
                message_md: 'Please select a status and try again.',
              });
              return;
            }

            if (!this.state.ball_type) {
              NotifyHelper.error({
                message_md: 'Please select a ball type and try again.',
              });
              return;
            }

            if (
              this.props.machineModelsCx.models.findIndex(
                (t) =>
                  t.name === this.state.model.name &&
                  t._id !== this.state.model._id
              ) !== -1
            ) {
              NotifyHelper.error({
                message_md: 'Please enter a unique name and try again.',
              });
              return;
            }

            const model: Partial<IMachineModel> = {
              _id: this.state.model._id,
              name: this.state.model.name,

              status: this.state.status,
              ball_type: this.state.ball_type,

              supports_breaks: this.state.model.supports_breaks,
              supports_spins: this.state.model.supports_spins,

              calibration_only: this.state.calibration_only,
              tags: this.state.tags.split(','),

              notes: this.state.model.notes,
            };

            this.props.machineModelsCx
              .updateModel(model)
              .then(() => this.props.onClose());
          },
        },
      ],
      onClose: () => this.props.onClose(),
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog {...mergeProps} />
      </ErrorBoundary>
    );
  }

  private renderContent() {
    const isSuperAdmin = this.props.authCx.current.role === UserRole.admin;

    return (
      <CommonFormGrid columns={2}>
        <Box gridColumn="span 2">
          <CommonTextInput
            id="machine-model-name"
            label="Model Name"
            labelIcon={<SuperAdminIcon />}
            placeholder="Type a unique value"
            value={this.state.model.name}
            disabled={!isSuperAdmin}
            onChange={(v) => this.updateMetadata({ name: v })}
          />
        </Box>
        <Box gridColumn="span 2">
          <Heading size={RADIX.HEADING.SIZE.MD}>Type</Heading>
          <Code>{this.state.model.type}</Code>
        </Box>
        <Box gridColumn="span 2">
          <Heading size={RADIX.HEADING.SIZE.MD}>S3 Path</Heading>
          <Code>{this.state.model.path}</Code>
        </Box>
        <CommonSelectInput
          id="machine-model-supports-breaks"
          label="Supports Breaks"
          labelIcon={<SuperAdminIcon />}
          name="supports-breaks"
          disabled={!isSuperAdmin}
          options={[
            { label: 'common.yes', value: 'true' },
            { label: 'common.no', value: 'false' },
          ]}
          value={this.state.model.supports_breaks?.toString()}
          onBooleanChange={(v) => this.updateMetadata({ supports_breaks: v })}
          optional
        />
        <CommonSelectInput
          id="machine-model-supports-spins"
          label="Supports Spins"
          labelIcon={<SuperAdminIcon />}
          name="supports-spins"
          disabled={!isSuperAdmin}
          options={[
            { label: 'common.yes', value: 'true' },
            { label: 'common.no', value: 'false' },
          ]}
          value={this.state.model.supports_spins?.toString()}
          onBooleanChange={(v) => this.updateMetadata({ supports_spins: v })}
          optional
        />
        <CommonSelectInput
          id="machine-model-status"
          label="Status"
          labelIcon={<SuperAdminIcon />}
          name="status"
          disabled={!isSuperAdmin}
          options={Object.values(ModelStatus).map((o) => ({
            label: o,
            value: o,
          }))}
          value={this.state.status}
          onChange={(v) => this.setState({ status: v as ModelStatus })}
          optional
        />
        <CommonSelectInput
          id="machine-model-ball"
          label="Ball Type"
          labelIcon={<SuperAdminIcon />}
          name="ball_type"
          disabled={!isSuperAdmin}
          options={Object.values(BallType).map((o) => ({
            label: o,
            value: o,
          }))}
          value={this.state.ball_type}
          onChange={(v) => this.setState({ ball_type: v as BallType })}
          optional
        />
        <CommonSelectInput
          id="machine-model-calibration"
          label="Calibration Model"
          labelIcon={<SuperAdminIcon />}
          name="calibration_only"
          disabled={!isSuperAdmin}
          options={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          value={this.state.calibration_only.toString()}
          onBooleanChange={(v) =>
            this.setState({
              calibration_only: v,
            })
          }
          hint_md="If enabled, this model can only be used for machine calibration."
        />
        <CommonTextInput
          id="machine-model-tags"
          label="Tags"
          placeholder="(comma-delimited)"
          value={this.state.tags}
          onChange={(v) => this.setState({ tags: v ?? '' })}
        />
        <Box gridColumn="span 2">
          <CommonTextareaInput
            id="machine-model-notes"
            label="Notes"
            value={this.state.model.notes}
            onChange={(v) => this.updateMetadata({ notes: v })}
            rows={5}
          />
        </Box>
      </CommonFormGrid>
    );
  }
}
