import { BS_LIMITS } from 'classes/helpers/pitch-design.helper';
import { IConversionResult } from 'interfaces/i-mlb-browse';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { HawkeyeHelper } from 'lib_ts/classes/hawkeye.helper';
import { FT_TO_INCHES, clamp } from 'lib_ts/classes/math.utilities';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { MS_LIMITS } from 'lib_ts/enums/machine.enums';
import {
  AVG_EXCLUDED_OUTCOMES,
  MlbOutcomeCode,
  STRIKE_OUTCOMES,
} from 'lib_ts/enums/mlb-stats-api/guid-metadata-types.enum';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import {
  IMlbPitch,
  IMlbPitchExt,
} from 'lib_ts/interfaces/mlb-stats-api/i-pitch';
import {
  MlbLastMeasuredData,
  MlbPosition,
  MlbReleaseData,
  MlbReleasePosition,
  MlbSpinVector,
  MlbTrajectoryData,
  MlbVelocity,
} from 'lib_ts/interfaces/mlb-stats-api/raw-results/game-guids-pitch-segment';
import {
  DEFAULT_PLATE,
  IBallState,
  IBuildPitchChars,
} from 'lib_ts/interfaces/pitches';
import { IPosition, ISpin, IVelocity } from 'lib_ts/interfaces/pitches/i-base';
import { Vector3 } from 'three';

/** for components to interact with events */
export class MlbStatsHelper {
  static validSpinVector(value: Partial<MlbSpinVector> | undefined): boolean {
    if (!value) {
      return false;
    }

    if ([value.x, value.y, value.z].includes(undefined)) {
      return false;
    }

    return true;
  }

  static validVelocity(value: Partial<MlbVelocity> | undefined): boolean {
    if (!value) {
      return false;
    }

    if ([value.x, value.y, value.z].includes(undefined)) {
      return false;
    }

    return true;
  }

  static validTrajectory(
    value: Partial<MlbTrajectoryData> | undefined
  ): boolean {
    if (!value) {
      return false;
    }

    if (
      [
        value.horizontalBreak,
        value.verticalBreak,
        value.verticalBreakInduced,
      ].includes(undefined)
    ) {
      return false;
    }

    return true;
  }

  // checks to ensure a pitch can be built from this play
  static getErrors(value: Partial<IMlbPitch> | undefined): string[] {
    if (!value) {
      return ['Play is undefined'];
    }

    const errors: string[] = [];

    const spin = value.releaseData?.spinVector;
    if (!this.validSpinVector(spin)) {
      errors.push('Invalid spin vector');
    }

    const velo = value.lastMeasuredData?.velocity;
    if (!this.validVelocity(velo)) {
      errors.push('Invalid velocity');
    }

    const traj = value.trajectoryData;
    if (!this.validTrajectory(traj)) {
      errors.push('Invalid trajectory');
    }

    return errors;
  }

  // mutates the original values
  static averageReleasesByPitcher(
    pitches: IMlbPitchExt[],
    values: IConversionResult[]
  ): void {
    const guidsDict = ArrayHelper.groupBy(pitches, 'pitcherPk');

    Object.keys(guidsDict).forEach((pitcherPk) => {
      const guids = guidsDict[pitcherPk].map((g) => g.guid);
      const results = values.filter((r) => guids.includes(r.guid));

      const avg = MiscHelper.getMeanObject(
        results
          .map((r) => {
            if (!r.chars?.bs) {
              return;
            }

            const p: IPosition = {
              px: r.chars.bs.px,
              py: r.chars.bs.py,
              pz: r.chars.bs.pz,
            };
            return p;
          })
          .filter((p) => !!p) as IPosition[]
      ) as IPosition;

      results.forEach((r) => {
        if (!r.chars?.bs) {
          return;
        }

        r.chars.bs.px = avg.px;
        r.chars.bs.py = avg.py;
        r.chars.bs.pz = avg.pz;
      });
    });
  }

  static convertToChars = (
    pitch: IMlbPitchExt,
    priority: BuildPriority,
    useDefaultPlate: boolean
  ): IConversionResult => {
    const warnings: string[] = [];

    try {
      const measured = pitch.lastMeasuredData;
      if (!measured) {
        throw new Error(
          `Failed to find lastMeasuredData of guid ${pitch.guid}`
        );
      }

      const release = pitch.releaseData;
      if (!release) {
        throw new Error(`Failed to find releaseData of guid ${pitch.guid}`);
      }

      const traj = pitch.trajectoryData;
      if (!traj) {
        throw new Error(`Failed to find trajectoryData of guid ${pitch.guid}`);
      }

      const safeSpinGlobal = getSafeSpin(release);

      const spinAxisGlobal = BallHelper.safeNormalize(
        new Vector3(safeSpinGlobal.wx, safeSpinGlobal.wy, safeSpinGlobal.wz)
      );

      const heMatrix = HawkeyeHelper.getMatrix({
        Xx: release.seamOrientation.xx,
        Xy: release.seamOrientation.xy,
        Xz: release.seamOrientation.xz,
        Yx: release.seamOrientation.yx,
        Yy: release.seamOrientation.yy,
        Yz: release.seamOrientation.yz,
        Zx: release.seamOrientation.zx,
        Zy: release.seamOrientation.zy,
        Zz: release.seamOrientation.zz,
      });

      if (!heMatrix) {
        throw new Error(
          `Failed to obtain Hawkeye matrix from pitchSegment of guid ${pitch.guid}`
        );
      }

      const seams = HawkeyeHelper.getSeamsFromMatrix(heMatrix, spinAxisGlobal);

      const safeVelocity = getSafeVelocity(measured);

      const safeSpinLocal = BallHelper.getLocalSpin(
        safeSpinGlobal,
        safeVelocity
      );

      const quat = BallHelper.getQuatFromSeams({
        latitude_deg: seams.latitude_deg,
        longitude_deg: seams.longitude_deg,
        spin: safeSpinLocal,
      });

      const safePos = getSafePosition(release);

      const safeBS: IBallState = {
        // orientation
        qw: quat.qw,
        qx: quat.qx,
        qy: quat.qy,
        qz: quat.qz,

        // position
        px: safePos.px,
        py: safePos.py,
        pz: safePos.pz,

        // spin
        wx: safeSpinLocal.wx,
        wy: safeSpinLocal.wy,
        wz: safeSpinLocal.wz,
        wnet: BallHelper.getNetSpin(safeSpinLocal),

        // velo
        vx: safeVelocity.vx,
        vy: safeVelocity.vy,
        vz: safeVelocity.vz,
        vnet: BallHelper.getSpeed(safeVelocity),
      };

      const output: Partial<IBuildPitchChars> = {
        // we will need to match the build pitch back up with the original guid to fill in details for the pitcher
        mongo_id: pitch.guid,
        plate: useDefaultPlate
          ? DEFAULT_PLATE
          : {
              plate_x: measured.position.x,
              plate_z: measured.position.z,
            },
        bs: safeBS,
        breaks: {
          xInches: -traj.horizontalBreak * FT_TO_INCHES,
          zInches: traj.verticalBreakInduced * FT_TO_INCHES,
        },
        seams: seams,
        traj: undefined,
        ms: undefined,
        priority: priority,
      };

      return {
        guid: pitch.guid,
        chars: output,
        warnings,
      };
    } catch (e) {
      console.error(e);
      return {
        guid: '',
        chars: undefined,
        warnings,
      };
    }

    function getSafeVelocity(measured: MlbLastMeasuredData) {
      const LIMITS = BS_LIMITS.SPEED_MPH;

      const output: IVelocity = {
        vx: measured.velocity.x,
        vy: -clamp(Math.abs(measured.velocity.y), LIMITS.MIN, LIMITS.MAX),
        vz: measured.velocity.z,
      };

      if (Math.abs(output.vy) < Math.abs(measured.velocity.y)) {
        warnings.push(`Speed Y decreased to ${LIMITS.MAX} MPH`);
      } else if (Math.abs(output.vy) > Math.abs(measured.velocity.y)) {
        warnings.push(`Speed Y increased to ${LIMITS.MIN} MPH`);
      }

      return output;
    }

    function getSafeSpin(release: MlbReleaseData) {
      const LIMITS = BS_LIMITS.SPIN_RPM;

      const output: ISpin = {
        wx: clamp(release.spinVector.x, LIMITS.MIN, LIMITS.MAX),
        wy: clamp(release.spinVector.y, LIMITS.MIN, LIMITS.MAX),
        wz: clamp(release.spinVector.z, LIMITS.MIN, LIMITS.MAX),
      };

      if (output.wx < release.spinVector.x) {
        warnings.push(`Spin X decreased to ${LIMITS.MAX} RPM`);
      } else if (output.wx > release.spinVector.x) {
        warnings.push(`Spin X increased to ${LIMITS.MIN} RPM`);
      }

      if (output.wy < release.spinVector.y) {
        warnings.push(`Spin Y decreased to ${LIMITS.MAX} RPM`);
      } else if (output.wy > release.spinVector.y) {
        warnings.push(`Spin Y increased to ${LIMITS.MIN} RPM`);
      }

      if (output.wz < release.spinVector.z) {
        warnings.push(`Spin Z decreased to ${LIMITS.MAX} RPM`);
      } else if (output.wz > release.spinVector.z) {
        warnings.push(`Spin Z increased to ${LIMITS.MIN} RPM`);
      }

      return output;
    }

    function getSafePosition(release: MlbReleaseData) {
      const LIMITS = MS_LIMITS.POSITION;

      const output: IPosition = {
        px: clamp(release.releasePosition.x, LIMITS.X.MIN, LIMITS.X.MAX),
        py: release.releasePosition.y,
        pz: clamp(release.releasePosition.z, LIMITS.Z.MIN, LIMITS.Z.MAX),
      };

      if (output.px < release.releasePosition.x) {
        warnings.push(`Release X decreased to ${LIMITS.X.MAX}'`);
      } else if (output.px > release.releasePosition.x) {
        warnings.push(`Release X increased to ${LIMITS.X.MIN}'`);
      }

      if (output.pz < release.releasePosition.z) {
        warnings.push(`Release Z decreased to ${LIMITS.Z.MAX}'`);
      } else if (output.pz > release.releasePosition.z) {
        warnings.push(`Release Z increased to ${LIMITS.Z.MIN}'`);
      }

      return output;
    }
  };

  static averagePitches = (pitches: IMlbPitchExt[]) => {
    try {
      const dict: {
        [pitcherPk: string]: { [pitchType: string]: IMlbPitchExt[] };
      } = {};

      const safeGuids = pitches.filter(
        (g) =>
          // skip pitches with batters getting hit or intentional balls, etc...
          !AVG_EXCLUDED_OUTCOMES.includes(g.outcome ?? MlbOutcomeCode.NonPitch)
      );

      safeGuids.forEach((g) => {
        const pitcherPk = g.pitcherPk.toString();
        if (!pitcherPk) {
          return;
        }

        const pitchType = g.type;
        if (!pitchType) {
          return;
        }

        if (!dict[pitcherPk]) {
          dict[pitcherPk] = {};
        }

        if (!dict[pitcherPk][pitchType]) {
          dict[pitcherPk][pitchType] = [];
        }

        dict[pitcherPk][pitchType].push(g);
      });

      const output = Object.values(dict).flatMap((pitcher) =>
        Object.values(pitcher).flatMap((pitchType) => getAvgGuid(pitchType))
      );

      return output.filter((m) => !!m) as IMlbPitchExt[];
    } catch (e) {
      console.error(e);
    }

    function getAvgGuid(subset: IMlbPitchExt[]) {
      if (subset.length === 0) {
        return;
      }

      const measured = MiscHelper.getMeanObject(
        subset.map((g) => g.lastMeasuredData).filter((m) => !!m)
      ) as MlbLastMeasuredData;

      if (!measured) {
        return;
      }

      const releaseData = MiscHelper.getMeanObject(
        subset.map((g) => g.releaseData).filter((m) => !!m)
      ) as MlbReleaseData;

      if (!releaseData) {
        return;
      }

      const trajectoryData = MiscHelper.getMeanObject(
        subset.map((g) => g.trajectoryData).filter((m) => !!m)
      ) as MlbTrajectoryData;

      if (!trajectoryData) {
        return;
      }

      // if a strike exists in subset, choose that, otherwise use the first pitch
      const firstPitch =
        subset.find((s) =>
          STRIKE_OUTCOMES.includes(s.outcome as MlbOutcomeCode)
        ) ?? subset[0];

      const firstSeam = firstPitch?.releaseData.seamOrientation;

      if (!firstSeam) {
        return;
      }

      const releasePosition = MiscHelper.getMeanObject(
        subset
          .filter((g) => g.releaseData.releasePosition)
          .map((g) => g.releaseData.releasePosition)
      ) as MlbReleasePosition;

      if (!releasePosition) {
        return;
      }

      const spinVector = MiscHelper.getMeanObject(
        subset
          .filter((g) => g.releaseData.spinVector)
          .map((g) => g.releaseData.spinVector)
      ) as MlbSpinVector;

      if (!spinVector) {
        return;
      }

      const position = MiscHelper.getMeanObject(
        subset
          .filter((g) => g.lastMeasuredData.position)
          .map((g) => g.lastMeasuredData.position)
      ) as MlbPosition;

      if (!position) {
        return;
      }

      const velocity = MiscHelper.getMeanObject(
        subset
          .filter((g) => g.lastMeasuredData.velocity)
          .map((g) => g.lastMeasuredData.velocity)
      ) as MlbVelocity;

      if (!velocity) {
        return;
      }

      const output: IMlbPitchExt = {
        ...firstPitch,

        lastMeasuredData: {
          velocity: velocity,
          position: position,
          time: measured.time,
        },

        releaseData: {
          extension: releaseData.extension,
          angle: releaseData.angle,
          spinAxis: releaseData.spinAxis,
          spinRate: releaseData.spinRate,
          releaseSpeed: releaseData.releaseSpeed,
          direction: releaseData.direction,
          releasePosition: releasePosition,
          spinVector: spinVector,
          seamOrientation: firstSeam,
        },

        trajectoryData: trajectoryData,

        _id: `avg-from-${firstPitch._id}`,
        _created: new Date().toISOString(),
        _changed: new Date().toISOString(),
      };

      return output;
    }
  };
}
