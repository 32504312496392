import { DownloadIcon } from '@radix-ui/react-icons';
import { Badge, Blockquote, Flex } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { DialogButton } from 'components/common/dialogs/button';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonTableHoC } from 'components/common/table';
import { AuthContext } from 'contexts/auth.context';
import format from 'date-fns-tz/format';
import parseISO from 'date-fns/parseISO';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { ITableColumn } from 'interfaces/tables/columns';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { LanguageCode } from 'lib_ts/enums/translation';
import { IError } from 'lib_ts/interfaces/common/i-error';
import { IErrorType } from 'lib_ts/interfaces/common/i-error-type';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IMachineDetails } from 'lib_ts/interfaces/i-machine-details';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AdminErrorTypesService } from 'services/admin/error-types.service';
import { AdminMachinesService } from 'services/admin/machines.service';

const RECORDS_PER_REQUEST = 10;

interface IProps {
  details: IMachineDetails;
}

export const ErrorsTab = (props: IProps) => {
  const [loading, setLoading] = useState(false);

  const { current } = useContext(AuthContext);

  const [filter, setFilter] = useState<{ created: string[]; sender: string[] }>(
    { created: [], sender: [] }
  );

  const [types, setTypes] = useState<IErrorType[]>([]);

  useEffect(() => {
    AdminErrorTypesService.getInstance()
      .getAll()
      .then((result) => setTypes(result));
  }, []);

  const [data, setData] = useState<IError[]>([]);

  const columns = useMemo<ITableColumn[]>(() => {
    return [
      {
        label: 'Created',
        key: '_created',
        formatFn: (m: IError) =>
          format(parseISO(m._created), LOCAL_DATETIME_FORMAT, {
            timeZone: LOCAL_TIMEZONE,
          }),
      },
      {
        label: 'Sender',
        key: 'sender',
        formatFn: (m: IError) =>
          m.sender ? (
            <Badge color={RADIX.COLOR.WARNING}>
              {m.sender.replace(' ', ' > ')}
            </Badge>
          ) : undefined,
      },
      {
        label: 'ID',
        key: 'errorID',
        formatFn: (m: IError) =>
          m.errorID ? <Badge>{m.errorID}</Badge> : undefined,
      },
      {
        label: 'Message',
        key: '_message',
        disableSort: true,
        formatFn: (m: IError) => {
          const message = (() => {
            const type = types.find((t) => t.errorID === m.errorID);
            if (!type) {
              return (m as any).message;
            }

            switch (current.language) {
              case LanguageCode.Korean: {
                return type.user_message_korean || type.user_message;
              }

              case LanguageCode.Japanese: {
                return type.user_message_japanese || type.user_message;
              }

              case LanguageCode.English:
              default: {
                return type.user_message;
              }
            }
          })();

          return (
            <Blockquote
              className="font-mono"
              size={RADIX.TEXT.SIZE.SM}
              wrap="pretty"
              m="2"
            >
              {message}
            </Blockquote>
          );
        },
      },
    ];
  }, [types]);

  const [noMoreData, setNoMoreData] = useState(false);

  const loadData = useCallback(
    async (notify: boolean) => {
      if (loading) {
        return;
      }

      try {
        setLoading(true);

        const latest =
          await AdminMachinesService.getInstance().getLatestMachineErrors({
            machineID: props.details.machineID,
            skip: data.length,
            limit: RECORDS_PER_REQUEST,
          });

        if (latest.length < RECORDS_PER_REQUEST) {
          setNoMoreData(true);
        }

        const next = [...data, ...latest];

        setData(next);

        if (notify) {
          const anyFilters =
            filter.sender.length > 0 || filter.created.length > 0;

          const single = latest.length === 1;

          NotifyHelper.success({
            message_md: anyFilters
              ? `Fetched ${latest.length} more ${
                  single ? 'error' : 'errors'
                }, showing filtered results only.`
              : `Fetched ${latest.length} more ${
                  single ? 'error' : 'errors'
                }, showing all results.`,
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [loading, data]
  );

  useEffect(() => {
    loadData(false);
  }, []);

  const options = useMemo<{
    created: IOption[];
    sender: IOption[];
  }>(() => {
    return {
      created: ArrayHelper.unique(
        data.map((m) =>
          format(parseISO(m._created), 'yyyy-MM-dd', {
            timeZone: LOCAL_TIMEZONE,
          })
        )
      ).map((m) => {
        const o: IOption = {
          label: m,
          value: m,
        };
        return o;
      }),
      sender: ArrayHelper.unique(data.map((m) => m.sender ?? '')).map((m) => {
        const o: IOption = {
          label: m,
          value: m,
        };
        return o;
      }),
    };
  }, [data]);

  const filtered = useMemo(() => {
    return data
      .filter(
        (m) =>
          filter.created.length === 0 ||
          filter.created.includes(
            format(parseISO(m._created), 'yyyy-MM-dd', {
              timeZone: LOCAL_TIMEZONE,
            })
          )
      )
      .filter((m) => {
        if (filter.sender.length === 0) {
          return true;
        }

        if (!m.sender) {
          return false;
        }

        return filter.sender.includes(m.sender);
      });
  }, [data, filter]);

  return (
    <ErrorBoundary componentName="MachineDetailsErrorsTab">
      <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
        <CommonFormGrid columns={2}>
          <CommonSearchInput
            id="errors-created"
            placeholder="common.created"
            options={options.created}
            values={filter.created}
            onChange={(v) =>
              setFilter({
                ...filter,
                created: v,
              })
            }
            multiple
          />
          <CommonSearchInput
            id="errors-sender"
            placeholder="Sender"
            options={options.sender}
            values={filter.sender}
            onChange={(v) =>
              setFilter({
                ...filter,
                sender: v,
              })
            }
            multiple
          />
        </CommonFormGrid>

        <CommonTableHoC
          id="MachineErrorsLog"
          displayColumns={columns}
          displayData={filtered}
        />

        {!noMoreData && (
          <DialogButton
            icon={<DownloadIcon />}
            label="Load More"
            className="btn-block"
            onClick={() => loadData(true)}
          />
        )}
      </Flex>
    </ErrorBoundary>
  );
};
