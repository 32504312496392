import {
  MagnifyingGlassIcon,
  PlayIcon,
  StopIcon,
  UpdateIcon,
} from '@radix-ui/react-icons';
import { Badge, Container, Flex, Heading, Table } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTableButton } from 'components/common/table/button';
import { SectionBox } from 'components/sections/feature-demo/section-box';
import { MachineContext } from 'contexts/machine.context';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

interface IProps {}

interface IState {}

const DEFAULT_STATE: IState = {};

const BTN_CLASS = 'width-120px';

export class ProcessesDemo extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;
  }

  render() {
    return (
      <ErrorBoundary componentName="ProcessesDemo">
        <MachineContext.Consumer>
          {(machineCx) => (
            <SectionBox>
              <Flex gap={RADIX.FLEX.GAP.MD}>
                <Heading size={RADIX.HEADING.SIZE.MD}>Processes</Heading>
                <Badge color={RADIX.COLOR.WARNING}>
                  {machineCx.machine.machineID}
                </Badge>
              </Flex>

              <Container size="1" align="left">
                <Table.Root>
                  <Table.Body>
                    <Table.Row>
                      <Table.RowHeaderCell>Restart</Table.RowHeaderCell>
                      <Table.Cell align="center">
                        <CommonTableButton
                          icon={<UpdateIcon />}
                          label="common.restart"
                          color={RADIX.COLOR.WARNING}
                          tooltip="This will restart the Arc processes on the machine."
                          className={BTN_CLASS}
                          onClick={() => machineCx.restartArc('demo page')}
                        />
                      </Table.Cell>
                      <Table.Cell align="center">
                        <CommonTableButton
                          icon={<UpdateIcon />}
                          label="common.restart-os"
                          color={RADIX.COLOR.DANGER}
                          tooltip="This will restart the entire operating system on the machine."
                          className={BTN_CLASS}
                          onClick={() => machineCx.restartArc('demo page')}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.RowHeaderCell>All Processes</Table.RowHeaderCell>
                      <Table.Cell align="center">
                        <CommonTableButton
                          icon={<MagnifyingGlassIcon />}
                          label="Query"
                          color={RADIX.COLOR.INFO}
                          className={BTN_CLASS}
                          onClick={() =>
                            machineCx.sendProcessQuery('demo page')
                          }
                        />
                      </Table.Cell>
                      <Table.Cell>{/* placeholder */}</Table.Cell>
                    </Table.Row>
                    {machineCx.process_statuses.map((process) => (
                      <Table.Row key={`process-stack-${process.name}`}>
                        <Table.RowHeaderCell>
                          {process.name}
                        </Table.RowHeaderCell>

                        <Table.Cell align="center">
                          <CommonTableButton
                            icon={process.alive ? <UpdateIcon /> : <PlayIcon />}
                            label={process.alive ? 'Restart' : 'Start'}
                            color={
                              process.alive
                                ? RADIX.COLOR.WARNING
                                : RADIX.COLOR.SUCCESS
                            }
                            className={BTN_CLASS}
                            onClick={() =>
                              machineCx.sendProcessReset(
                                'demo page',
                                process.name
                              )
                            }
                          />
                        </Table.Cell>
                        <Table.Cell align="center">
                          <CommonTableButton
                            icon={<StopIcon />}
                            label="Stop"
                            color={RADIX.COLOR.DANGER}
                            disabled={!process.alive}
                            className={BTN_CLASS}
                            onClick={() =>
                              machineCx.sendProcessKill(
                                'demo page',
                                process.name
                              )
                            }
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table.Root>
              </Container>
            </SectionBox>
          )}
        </MachineContext.Consumer>
      </ErrorBoundary>
    );
  }
}
