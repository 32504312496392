import { Button } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { MachineUnavailableButton } from 'components/machine/buttons/unavailable';
import { MachineContext } from 'contexts/machine.context';
import { t } from 'i18next';
import { RadixBtnSize } from 'lib_ts/enums/radix-ui';
import { CalibrateProc } from 'lib_ts/interfaces/i-machine-msg';
import React from 'react';

const COMPONENT_NAME = 'ProjectorToggleButton';

interface IProps {
  disabled?: boolean;

  mode: 'outline' | 'rulers';

  /** applied to the button tag */
  className?: string;

  size?: RadixBtnSize;
}

interface IState {}

export class MachineProjectorToggleButton extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    /** responses are listened to by calibration listener under main component */
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <MachineContext.Consumer>
          {(machineCx) => {
            if (!machineCx.connected || machineCx.busy) {
              return (
                <MachineUnavailableButton
                  size={this.props.size}
                  className={this.props.className}
                />
              );
            }

            const label =
              this.props.mode === 'outline'
                ? t('common.toggle-outline')
                : this.props.mode === 'rulers'
                ? t('common.toggle-rulers')
                : 'UNKNOWN';

            return (
              <Button
                size={this.props.size}
                disabled={machineCx.calibrating || !!this.props.disabled}
                className={this.props.className}
                onClick={() => {
                  switch (this.props.mode) {
                    case 'outline': {
                      machineCx.calibrate(
                        [CalibrateProc.ProjectorOutline],
                        COMPONENT_NAME
                      );
                      break;
                    }
                    case 'rulers': {
                      machineCx.calibrate(
                        [CalibrateProc.ProjectorRulers],
                        COMPONENT_NAME
                      );
                      break;
                    }
                    default: {
                      break;
                    }
                  }
                }}
              >
                {label}
              </Button>
            );
          }}
        </MachineContext.Consumer>
      </ErrorBoundary>
    );
  }
}
