import { SectionHeader } from 'components/sections/header';
import { SectionsContext } from 'contexts/sections.context';
import { SectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import { useContext } from 'react';

export const AnalyticsHeader = ({ actions }: { actions?: IMenuAction[] }) => {
  const sectionsCx = useContext(SectionsContext);

  return (
    <SectionHeader
      header={t('main.analytics')}
      subsections={
        sectionsCx.userSections?.find((s) => s.value === SectionName.Analytics)
          ?.subsections
      }
      actions={actions}
    />
  );
};
