import { Card, Flex, Heading } from '@radix-ui/themes';
import { CommonDetails } from 'components/common/details';
import { ErrorBoundary } from 'components/common/error-boundary';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import { IMachineState } from 'lib_ts/interfaces/i-machine-state';
import { IPitch } from 'lib_ts/interfaces/pitches';
import React from 'react';

const MsSummary = (props: {
  header: string;
  value: any | undefined;
  key?: string;
}) => {
  if (!props.value) {
    return <></>;
  }

  return (
    <Flex
      key={props.key}
      direction="column"
      p={RADIX.FLEX.PAD.SM}
      gap={RADIX.FLEX.GAP.SM}
    >
      <Heading size={RADIX.HEADING.SIZE.SM}>{t(props.header)}</Heading>

      <Card>
        <pre>{JSON.stringify(props.value, null, 2)}</pre>
      </Card>
    </Flex>
  );
};

interface IProps {
  pitch: IPitch;
  machine: IMachine;
}

interface IState {
  activeForMachine?: IMachineState;
}

export class MSInspectorView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      activeForMachine: getMSFromMSDict(props.pitch, props.machine).ms,
    };
  }

  private renderNode() {
    if (!this.props.pitch.msDict) {
      return;
    }

    const msDict = this.props.pitch.msDict;

    return (
      <>
        {Object.keys(msDict).map((machineID, iMachine) => {
          const modelNodes: React.ReactNode[] = [];

          /** find msDict entries and append to entries */
          if (ArrayHelper.isArray(msDict[machineID])) {
            const entry = msDict[machineID] as IMachineState[];
            /** this is a dictionary of ms */
            entry.forEach((ms, i) => {
              modelNodes.push(
                <MsSummary
                  key={`m-${iMachine}-ms-${i}`}
                  header={`${machineID} | ${ms.model_key || '(none)'}`}
                  value={ms}
                />
              );
            });
          } else {
            /** this is a ms */
            const ms = msDict[machineID] as IMachineState;
            modelNodes.push(
              <MsSummary
                key={`m-${iMachine}-ms-${0}`}
                header={`${machineID} | ${ms.model_key || '(none)'}`}
                value={ms}
              />
            );
          }

          return (
            <React.Fragment key={`m-${iMachine}`}>{modelNodes}</React.Fragment>
          );
        })}
      </>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName="MSInspectorView">
        <Flex
          direction="column"
          gap={RADIX.FLEX.GAP.SM}
          height={RADIX.DIALOG.HEIGHT.MD}
          overflowX="hidden"
          overflowY="auto"
        >
          <MsSummary
            header="common.active"
            value={this.state.activeForMachine}
          />

          <MsSummary header="common.pitch" value={this.props.pitch} />

          <CommonDetails summary="From Dictionary">
            {this.renderNode()}
          </CommonDetails>
        </Flex>
      </ErrorBoundary>
    );
  }
}
