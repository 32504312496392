import { ChevronUpIcon } from '@radix-ui/react-icons';
import {
  Box,
  Button,
  Card,
  ChevronDownIcon,
  Code,
  Flex,
  Heading,
} from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTableHoC } from 'components/common/table';
import env from 'config';
import {
  IMachineCalibrationContext,
  MachineCalibrationContext,
} from 'contexts/machine-calibration.context';
import { IMachineContext, MachineContext } from 'contexts/machine.context';
import { addYears, parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { CalibrationStep } from 'enums/machine.enums';
import { ACTIONS_KEY } from 'enums/tables';
import { t } from 'i18next';
import { ITableColumn } from 'interfaces/tables/columns';
import { MetricInterval } from 'lib_ts/enums/machine-models.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IRealMachineMetric } from 'lib_ts/interfaces/modelling/i-real-machine-metric';
import React, { useContext } from 'react';
import { AdminMachineModelsService } from 'services/admin/machine-models.service';

const COMPONENT_NAME = 'RecentMetrics';

interface IProps {
  calibrationCx: IMachineCalibrationContext;
  machineCx: IMachineContext;
}

interface IState {
  expanded: boolean;

  metrics?: IRealMachineMetric[];
}

export const RecentMetricsHoC = () => {
  const props: IProps = {
    machineCx: useContext(MachineContext),
    calibrationCx: useContext(MachineCalibrationContext),
  };

  return <RecentMetrics {...props} />;
};

class RecentMetrics extends React.Component<IProps, IState> {
  private init = false;

  private readonly BASE_COLUMNS: ITableColumn[] = [
    {
      label: 'common.created',
      key: '_created',
      formatFn: (m: IRealMachineMetric) =>
        format(parseISO(m._created), LOCAL_DATETIME_FORMAT, {
          timeZone: LOCAL_TIMEZONE,
        }),
    },
    {
      label: 'ID',
      key: '_id',
      formatFn: (m: IRealMachineMetric) => <Code color="pink">{m._id}</Code>,
    },
    {
      label: 'common.ball-type',
      key: '_ballType',
      formatFn: (m: IRealMachineMetric) => {
        return m.dataFilter.ball_type ?? '(none)';
      },
      sortRowsFn: (
        a: IRealMachineMetric,
        b: IRealMachineMetric,
        dir: number
      ) => {
        const aBall = a.dataFilter.ball_type ?? '';
        const bBall = b.dataFilter.ball_type ?? '';
        return aBall.localeCompare(bBall) * dir;
      },
    },
    {
      label: 'common.actions',
      key: ACTIONS_KEY,
      formatFn: (m: IRealMachineMetric) => {
        return (
          <Button
            size={RADIX.BUTTON.SIZE.XS}
            onClick={() => {
              this.props.calibrationCx.setRealMachineMetric(m);
              this.props.calibrationCx.setStep(CalibrationStep.ReviewMetric);
            }}
          >
            {t('common.review')}
          </Button>
        );
      },
    },
  ];

  constructor(props: IProps) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.renderBody = this.renderBody.bind(this);
  }

  componentDidMount(): void {
    if (this.init) {
      return;
    }

    this.init = true;

    AdminMachineModelsService.getInstance()
      .getMetricsForMachine({
        machine_id: this.props.machineCx.machine._id,
        job_interval:
          // for testing purposes
          env.identifier === 'local'
            ? MetricInterval.Daily
            : MetricInterval.Calibration,
        start_date:
          // for testing purposes
          env.identifier === 'local' ? addYears(new Date(), -5) : undefined,
      })
      .then((result) => {
        if (result) {
          /** newest first */
          result.sort((a, b) => b._created.localeCompare(a._created));

          /** only keep 5 */
          this.setState({ metrics: result.filter((_, i) => i < 5) });
        }
      })
      .catch((reason) => console.error(reason));
  }

  private renderBody() {
    return (
      <CommonTableHoC
        id="RecentMetricsList"
        displayColumns={this.BASE_COLUMNS}
        displayData={this.state.metrics ?? []}
        loading={!this.state.metrics}
        squareBorder
      />
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Card style={{ padding: 0 }}>
          <Flex
            p="4"
            gap={RADIX.FLEX.GAP.MD}
            justify="between"
            className="cursor-pointer"
            onClick={() => this.setState({ expanded: !this.state.expanded })}
          >
            <Box>
              <Heading m="0" size={RADIX.HEADING.SIZE.MD}>
                {t('common.recent-metrics')}
              </Heading>
            </Box>
            <Box>
              {this.state.expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </Box>
          </Flex>

          {this.state.expanded && this.renderBody()}
        </Card>
      </ErrorBoundary>
    );
  }
}
