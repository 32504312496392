import { Flex, Text } from '@radix-ui/themes';
import { HELP_URLS } from 'classes/helpers/url.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSwitchInput } from 'components/common/form/switch';
import { CommonLink } from 'components/common/link';
import { SettingForm } from 'components/common/settings-dialog/form';
import { SettingRow } from 'components/common/settings-dialog/row';
import { MachineContext } from 'contexts/machine.context';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext } from 'react';

const COMPONENT_NAME = 'MachineFiringTab';

export const MachineFiringTab = () => {
  const { machine, update } = useContext(MachineContext);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <SettingForm>
        <SettingRow
          header="settings.rapid-mode"
          description={
            <Text>
              {t(
                machine.enable_rapid_mode
                  ? 'settings.rapid-mode-disable-msg'
                  : 'settings.rapid-mode-enable-msg'
              )}
            </Text>
          }
          input={
            <Flex justify="end">
              <CommonSwitchInput
                id="firing-rapid-mode"
                defaultChecked={machine.enable_rapid_mode}
                onCheckedChange={(v) => {
                  update({
                    _id: machine._id,
                    enable_rapid_mode: v,
                  });
                }}
              />
            </Flex>
          }
        />
        <SettingRow
          separatorBefore
          header="settings.rapsodo-validation"
          description={
            <Text>
              {t(
                machine.enable_raspodo_validation
                  ? 'settings.rapsodo-validation-disable-msg'
                  : 'settings.rapsodo-validation-enable-msg'
              )}
            </Text>
          }
          input={
            <Flex justify="end">
              <CommonSwitchInput
                id="firing-rapsodo-validation"
                defaultChecked={machine.enable_raspodo_validation}
                onCheckedChange={(v) => {
                  update({
                    _id: machine._id,
                    enable_raspodo_validation: v,
                  });
                }}
              />
            </Flex>
          }
        />
        <SettingRow
          separatorBefore
          header="settings.continuous-training"
          description={
            <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
              <Text>
                {t(
                  machine.enable_continuous_training
                    ? 'settings.continuous-training-disable-msg'
                    : 'settings.continuous-training-enable-msg'
                )}
              </Text>
              <CommonLink
                url={
                  t('common.intercom-url') +
                  HELP_URLS.SETTINGS_CONTINUOUS_TRAINING
                }
              >
                {t('common.read-more')}
              </CommonLink>
            </Flex>
          }
          input={
            <Flex justify="end">
              <CommonSwitchInput
                id="firing-continuous-training"
                defaultChecked={machine.enable_continuous_training}
                onCheckedChange={(v) => {
                  update({
                    _id: machine._id,
                    enable_continuous_training: v,
                  });
                }}
              />
            </Flex>
          }
        />
      </SettingForm>
    </ErrorBoundary>
  );
};
