import { Box, Flex, Grid, Heading, Separator, Text } from '@radix-ui/themes';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

interface IProps {
  header: string;
  description: React.ReactNode;
  input: React.ReactNode;

  separatorBefore?: boolean;
  separatorAfter?: boolean;
}

export const SettingRow = (props: IProps) => (
  <>
    {props.separatorBefore && <Separator size="4" />}
    <Grid
      columns={{ initial: '1', md: '3' }}
      gap={{ initial: RADIX.FLEX.GAP.XS, md: RADIX.FLEX.GAP.MD }}
    >
      <Flex
        gridColumn={{ initial: '1', md: 'span 2' }}
        direction="column"
        gap={RADIX.FLEX.GAP.XS}
      >
        <Heading size={RADIX.HEADING.SIZE.SM}>{t(props.header)}</Heading>
        <Text color={RADIX.COLOR.SECONDARY}>{props.description}</Text>
      </Flex>
      <Box className="valign-center">{props.input}</Box>
    </Grid>
    {props.separatorAfter && <Separator size="4" />}
  </>
);
