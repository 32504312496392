import { IServerResponse } from 'lib_ts/interfaces/common/i-server-response';
import { IAppNotificationLog } from 'lib_ts/interfaces/i-app-notification-log';
import { BaseRESTService } from 'services/_base-rest.service';

export class AppNotificationLogsService extends BaseRESTService {
  private static instance: AppNotificationLogsService;
  static getInstance(): AppNotificationLogsService {
    if (!AppNotificationLogsService.instance) {
      AppNotificationLogsService.instance = new AppNotificationLogsService();
    }

    return AppNotificationLogsService.instance;
  }

  private constructor() {
    super({
      controller: 'app-logs',
    });
  }

  async insert(data: Partial<IAppNotificationLog>): Promise<IServerResponse> {
    return await this.post(
      {
        uri: '',
      },
      data
    );
  }
}
