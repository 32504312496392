import { Button } from '@radix-ui/themes';
import { IntercomEvents } from 'enums/intercom.enums';
import { t } from 'i18next';
import { RADIX, RadixBtnSize } from 'lib_ts/enums/radix-ui';

export const ContactSupportButton = (props: {
  size?: RadixBtnSize;
  className?: string;
}) => {
  return (
    <Button
      size={props.size}
      color={RADIX.COLOR.DANGER}
      className={props.className}
      onClick={() => {
        document.dispatchEvent(
          new CustomEvent(IntercomEvents.ShowNewMessage, {
            detail: undefined,
          })
        );
      }}
    >
      {t('common.contact-support')}
    </Button>
  );
};
