export enum LanguageName {
  English = 'English',
  Japanese = '日本語',
  Korean = '한국',
}

export enum LanguageCode {
  English = 'en',
  Japanese = 'ja',
  Korean = 'ko',
}

export const LANGUAGE_OPTIONS: {
  label: LanguageName;
  value: LanguageCode;
}[] = [
  {
    label: LanguageName.English,
    value: LanguageCode.English,
  },
  {
    label: LanguageName.Japanese,
    value: LanguageCode.Japanese,
  },
  {
    label: LanguageName.Korean,
    value: LanguageCode.Korean,
  },
];
