import { SKIP_OVERALL_CHECKS } from 'lib_ts/classes/machine-performance.helper';
import { PassResult } from 'lib_ts/enums/machine.enums';
import { IRealModelPerformance } from 'lib_ts/interfaces/modelling/i-real-machine-metric';

export class ModelPerformanceHelper {
  /** true => model passes, false => fails */
  static checkOverall = (
    value: IRealModelPerformance,
    force?: boolean
  ): boolean => {
    if (!force && SKIP_OVERALL_CHECKS) {
      return true;
    }

    return [
      this.checkSpeedY(value) === PassResult.Pass,
      this.checkSpinOverall(value),
    ].every((v) => v === true);
  };

  static checkSpeedY = (value: IRealModelPerformance): PassResult => {
    const MEAN_AE = 1.6;
    const MAX_AE = 3.5;

    if (
      value.mean_absolute_err.vy > MEAN_AE &&
      value.max_absolute_err.vy > MAX_AE
    ) {
      return PassResult.Both;
    }

    if (value.mean_absolute_err.vy > MEAN_AE) {
      return PassResult.Mean;
    }

    if (value.max_absolute_err.vy > MAX_AE) {
      return PassResult.Max;
    }

    return PassResult.Pass;
  };

  static checkSpinOverall = (value: IRealModelPerformance): boolean => {
    return [
      this.checkSpinDimension(
        value.mean_absolute_err.wx,
        value.max_absolute_err.wx
      ),
      this.checkSpinDimension(
        value.mean_absolute_err.wy,
        value.max_absolute_err.wy
      ),
      this.checkSpinDimension(
        value.mean_absolute_err.wz,
        value.max_absolute_err.wz
      ),
    ].every((v) => v === PassResult.Pass);
  };

  static checkSpinDimension = (mean: number, max: number): PassResult => {
    const MEAN_AE = 500;
    const MAX_AE = 950;

    if (mean > MEAN_AE && max > MAX_AE) {
      return PassResult.Both;
    }

    if (mean > MEAN_AE) {
      return PassResult.Mean;
    }

    if (max > MAX_AE) {
      return PassResult.Max;
    }

    return PassResult.Pass;
  };
}
