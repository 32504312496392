import { Skeleton, Table } from '@radix-ui/themes';
import _ from 'lodash';
import { useState } from 'react';

export const TableBodyLoading = (props: { rows: number; columns: number }) => {
  const [rows] = useState(_.range(0, props.rows));
  const [columns] = useState(_.range(0, props.columns));

  return (
    <Table.Body>
      {rows.map((r, iR) => (
        <Table.Row key={`skeleton-${iR}`}>
          {columns.map((c, iC) => (
            <Table.Cell key={`skeleton-${iR}-${iC}`}>
              <Skeleton />
            </Table.Cell>
          ))}
        </Table.Row>
      ))}
    </Table.Body>
  );
};
