import { Skeleton } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { SidebarHelper } from 'classes/helpers/sidebar.helper';
import { HELP_URLS } from 'classes/helpers/url.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CopyFolderDialog } from 'components/common/pitch-lists/copy-folder';
import { ManageListDialogHoC } from 'components/common/pitch-lists/manage-list';
import { ReassignListsDialog } from 'components/common/pitch-lists/reassign-lists';
import { DeleteFolderDialog } from 'components/main/sidebar/pitch-lists/dialogs/delete-folder';
import { RenameFolderDialog } from 'components/main/sidebar/pitch-lists/dialogs/rename-folder';
import { FullMenu } from 'components/main/sidebar/pitch-lists/full-menu';
import { MiniMenu } from 'components/main/sidebar/pitch-lists/mini-menu';
import { AuthContext, getLeagueTeams } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { DropContainer } from 'enums/dnd.enums';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import { ISidebarFolder } from 'interfaces/i-sidebar';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ICopyToFrom, IPitchList } from 'lib_ts/interfaces/pitches';
import { useCallback, useContext, useMemo, useState } from 'react';
import './index.scss';

const COMPONENT_NAME = 'SidebarPitchLists';

export const SidebarPitchLists = () => {
  const { app } = useContext(CookiesContext);
  const { lists, loading, refreshLists } = useContext(PitchListsContext);
  const { current, restrictedGameStatus } = useContext(AuthContext);

  const [renameFolder, setRenameFolder] = useState<
    ISidebarFolder | undefined
  >();

  const [dialogRenameFolder, setDialogRenameFolder] = useState<
    number | undefined
  >();

  const [manageLists, setManageLists] = useState<IPitchList[] | undefined>();
  const [fromFolder, setFromFolder] = useState<ICopyToFrom | undefined>();

  const [dialogCopyFolder, setDialogCopyFolder] = useState<
    number | undefined
  >();
  const [dialogReassignFolder, setDialogReassignFolder] = useState<
    number | undefined
  >();
  const [dialogDeleteFolder, setDialogDeleteFolder] = useState<
    number | undefined
  >();
  const [dialogCreateList, setDialogCreateList] = useState<
    number | undefined
  >();

  const folders = useMemo(() => {
    const output = SidebarHelper.makeRootFolders(lists);

    if (!current.placeholder_folders) {
      return output;
    }

    const personalRoot = output.find(
      (f) => f.depth === 0 && f._parent_def === 'team-users'
    );

    if (personalRoot) {
      // only add placeholders to the personal root
      const teamNames = getLeagueTeams(current.league);
      teamNames.forEach((team) => {
        const pathComponents = [team.value];

        const existingTeam = personalRoot.folders.find((f) =>
          ArrayHelper.equals(f.pathComponents, pathComponents)
        );

        const tFolder: ISidebarFolder = existingTeam ?? {
          depth: 1,
          _parent_def: 'team-users',
          _parent_id: current.userID,
          _parent_field: 'lists',

          pathComponents: pathComponents,
          pathDisplay: pathComponents.join('/'),
          pathEnd: pathComponents.slice(-1)[0],

          files: [],
          folders: [],
          placeholder: true,
        };

        if (!existingTeam) {
          // avoid duplicate entries, but continue processing in case the subfolders need to be created
          personalRoot.folders.push(tFolder);
        }

        ['Starters', 'Relievers'].forEach((sub) => {
          const pathComponents = [team.value, sub];

          if (
            tFolder.folders.findIndex((f) =>
              ArrayHelper.equals(pathComponents, f.pathComponents)
            ) !== -1
          ) {
            // avoid duplicate entries
            return;
          }

          const sFolder: ISidebarFolder = {
            depth: 2,
            _parent_def: 'team-users',
            _parent_id: current.userID,
            _parent_field: 'lists',

            pathComponents: pathComponents,
            pathDisplay: pathComponents.join('/'),
            pathEnd: pathComponents.slice(-1)[0],

            files: [],
            folders: [],
            placeholder: true,
          };
          tFolder.folders.push(sFolder);
        });

        return tFolder;
      });
    }

    return output;
  }, [lists, current]);

  const getFolderActions = useCallback(
    (config: {
      folder: ISidebarFolder;
      container: DropContainer;
    }): IMenuAction[] => {
      /** only allow admins to change machine or team lists (i.e. reassign or delete) */
      const canMutate =
        config.container === DropContainer.PersonalFolder ||
        [UserRole.admin, UserRole.team_admin].includes(current.role);

      const folderType = (() => {
        switch (config.container) {
          case DropContainer.TeamFolder: {
            return 'team folders';
          }

          case DropContainer.MachineFolder: {
            return 'machine folders';
          }

          case DropContainer.PersonalFolder: {
            return 'personal folders';
          }

          default: {
            return 'folders';
          }
        }
      })();

      return [
        {
          label: t('common.rename-x', { x: t('pl.folder') }),
          invisible: restrictedGameStatus(),
          onClick: () => {
            setRenameFolder(config.folder);
            setDialogRenameFolder(Date.now());
          },
        },
        {
          label: t('common.duplicate-x', { x: t('pl.folder') }),
          onClick: () => {
            if (!config.folder._parent_def) {
              NotifyHelper.warning({
                message_md: `Invalid parent definition found for container type ${config.container} with ID ${config.folder._parent_id}.`,
              });
              return;
            }

            const folderContents = SidebarHelper.getFilesRecursively(
              config.folder
            );
            if (folderContents.length === 0) {
              NotifyHelper.warning({
                message_md: `No pitch lists found in folder "${config.folder.pathDisplay}".`,
              });
              return;
            }

            setManageLists(folderContents.map((f) => f.object));
            setFromFolder({
              _parent_def: config.folder._parent_def,
              _parent_id: config.folder._parent_id,
              folder: config.folder.pathDisplay,
            });
            setDialogCopyFolder(Date.now());
          },
        },
        {
          label: t('common.reassign-x', { x: t('pl.folder') }),
          color: RADIX.COLOR.WARNING,
          onClick: () => {
            if (!canMutate) {
              NotifyHelper.warning({
                message_md: `Only admins can reassign ${folderType}. Please contact your team's admin or support for more assistance.`,
                buttons: [
                  {
                    label: t('common.read-more'),
                    onClick: () =>
                      window.open(
                        t('common.intercom-url') + HELP_URLS.PITCH_REASSIGN
                      ),
                  },
                ],
              });
              return;
            }

            if (!config.folder._parent_def) {
              NotifyHelper.warning({
                message_md: `Invalid parent definition found for container type ${config.container} with ID ${config.folder._parent_id}.`,
              });
              return;
            }

            const folderContents = SidebarHelper.getFilesRecursively(
              config.folder
            );
            if (folderContents.length === 0) {
              NotifyHelper.warning({
                message_md: `No pitch lists found in folder "${config.folder.pathDisplay}".`,
              });
              return;
            }

            setManageLists(folderContents.map((f) => f.object));
            setFromFolder({
              _parent_def: config.folder._parent_def,
              _parent_id: config.folder._parent_id,
              folder: config.folder.pathDisplay,
            });
            setDialogReassignFolder(Date.now());
          },
        },
        {
          label: t('common.delete-x', { x: t('pl.folder') }),
          color: RADIX.COLOR.DANGER,
          onClick: () => {
            if (!canMutate) {
              NotifyHelper.warning({
                message_md: `Only admins can delete ${folderType}. Please contact your team's admin or support for more assistance.`,
                buttons: [
                  {
                    label: t('common.read-more'),
                    onClick: () =>
                      window.open(
                        t('common.intercom-url', {
                          x: HELP_URLS.PITCH_REASSIGN,
                        }).toString()
                      ),
                  },
                ],
              });
              return;
            }

            const folderContents = SidebarHelper.getFilesRecursively(
              config.folder
            );
            if (folderContents.length === 0) {
              NotifyHelper.warning({
                message_md: `No pitch lists found in folder "${config.folder.pathDisplay}".`,
              });
              return;
            }

            setManageLists(folderContents.map((f) => f.object));
            setDialogDeleteFolder(Date.now());
          },
        },
      ];
    },
    [current]
  );

  const getMainActions = (group: string): IMenuAction[] => {
    return [
      {
        label: 'common.create-pitch-list',
        group: group,
        onClick: () => setDialogCreateList(Date.now()),
      },
      {
        label: 'common.refresh',
        group: group,
        onClick: () => refreshLists(),
      },
    ];
  };

  if (loading && lists.length === 0) {
    return <Skeleton />;
  }

  return (
    <ErrorBoundary
      componentName={COMPONENT_NAME}
      data-testid="SidebarPitchLists"
    >
      {app.sidebar_state === 'mini' ? (
        <MiniMenu actions={getMainActions('common.actions')} />
      ) : (
        <FullMenu
          folders={folders}
          mainActions={getMainActions('_1')}
          getFolderActions={getFolderActions}
        />
      )}

      {dialogRenameFolder && renameFolder && (
        <RenameFolderDialog
          key={dialogRenameFolder}
          folder={renameFolder}
          onClose={() => setDialogRenameFolder(undefined)}
        />
      )}

      {dialogReassignFolder && fromFolder && (
        <ReassignListsDialog
          key={dialogReassignFolder}
          identifier="SidebarPitchListsReassignListsDialog"
          authCx={useContext(AuthContext)}
          listsCx={useContext(PitchListsContext)}
          refPayload={{
            filterLists: manageLists,
            filter: {
              _parent_id: fromFolder._parent_id,
              _parent_def: fromFolder._parent_def,
            },
            update: {
              processed: new Date(),
              process_notes: `reassigned from sidebar by user ${current.email}`,
            },
          }}
          onClose={() => setDialogReassignFolder(undefined)}
        />
      )}

      {dialogCreateList && (
        <ManageListDialogHoC
          key={dialogCreateList}
          identifier="SidebarPitchListsCreateListDialog"
          mode="create"
          onCreated={() => setDialogCreateList(undefined)}
          onClose={() => setDialogCreateList(undefined)}
        />
      )}

      {dialogCopyFolder && fromFolder && manageLists && (
        <CopyFolderDialog
          key={dialogCopyFolder}
          identifier="SidebarPitchListsCopyFolderDialog"
          authCx={useContext(AuthContext)}
          listsCx={useContext(PitchListsContext)}
          lists={manageLists}
          from={fromFolder}
          onClose={() => setDialogCopyFolder(undefined)}
        />
      )}

      {dialogDeleteFolder && manageLists && (
        <DeleteFolderDialog key={dialogDeleteFolder} lists={manageLists} />
      )}
    </ErrorBoundary>
  );
};
