import { Slider } from '@radix-ui/themes';
import { CommonInputHint } from 'components/common/form/hint';
import { CommonInputLabel } from 'components/common/form/label';
import { CommonInputWrapper } from 'components/common/form/wrapper';
import { ISliderInput } from 'interfaces/forms/slider';

export const CommonSliderInput = (props: ISliderInput) => (
  <CommonInputWrapper {...props}>
    <CommonInputLabel {...props} />

    <Slider
      color={props.inputColor}
      min={props.min}
      max={props.max}
      step={props.step}
      value={[props.value]}
      onValueChange={(v) => {
        props.onChange?.(v[0]);
      }}
    />

    <CommonInputHint {...props} />
  </CommonInputWrapper>
);
