import axios from 'axios';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import env from 'config';
import { ENV_PROD_JP } from 'environments/i-prod-jp';
import { ENV_PROD_KR } from 'environments/i-prod-kr';
import { IGeoInfo } from 'interfaces/i-geo-info';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { createContext, FC, ReactNode, useEffect, useState } from 'react';

let ipCheckAttempted = false;

interface IProps {
  children: ReactNode;
}

export interface IGlobalContext {
  dialogs: string[];

  readonly countDialog: (config: { id: string; open: boolean }) => void;
}

const DEFAULT: IGlobalContext = {
  dialogs: [],
  countDialog: () => console.debug('not init'),
};

export const GlobalContext = createContext(DEFAULT);

/** for tracking misc details across entire app */
export const GlobalProvider: FC<IProps> = (props) => {
  const [_dialogs, _setDialogs] = useState(DEFAULT.dialogs);

  const state: IGlobalContext = {
    dialogs: _dialogs,

    countDialog: (config) => {
      if (config.open) {
        // add the id but only keep at most 1
        _setDialogs(ArrayHelper.unique([..._dialogs, config.id]));
        return;
      }

      const found = _dialogs.filter((id) => id === config.id).length > 0;
      if (!found) {
        return;
      }

      // remove all instances of the id
      _setDialogs(_dialogs.filter((id) => id !== config.id));
    },
  };

  if (env.region_redirect) {
    useEffect(() => {
      if (ipCheckAttempted) {
        return;
      }

      ipCheckAttempted = true;

      axios
        .get('https://ipapi.co/json/')
        .then((response) => {
          const info: IGeoInfo = response.data;

          const showRedirectToast = (redirect: string) => {
            NotifyHelper.warning({
              message_md: [
                `Are you visiting from ${info.country_name}?`,
                'Please use the following URL if you have issues connecting to your machine:',
                `[${redirect}](${redirect})`,
              ].join('\n\n'),
              delay_ms: 0,
              buttons: [
                {
                  label: 'common.dismiss',
                  onClick: () => {
                    // nothing
                  },
                  dismissAfterClick: true,
                },
              ],
            });
          };

          switch (info.country_code) {
            case 'JP':
              if (!window.origin.startsWith('jp')) {
                showRedirectToast(ENV_PROD_JP.self_url);
              }
              break;

            case 'KR':
              if (!window.origin.startsWith('kr')) {
                showRedirectToast(ENV_PROD_KR.self_url);
              }
              break;

            default: {
              break;
            }
          }
        })
        .catch((error) => console.error(error));
    }, []);
  }

  return (
    <GlobalContext.Provider value={state}>
      {props.children}
    </GlobalContext.Provider>
  );
};
