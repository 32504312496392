import { Share1Icon, StarFilledIcon, StarIcon } from '@radix-ui/react-icons';
import { Code, DataList, Heading } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTabs } from 'components/common/tabs';
import { CommonTooltip } from 'components/common/tooltip';
import { ErrorsTab } from 'components/sections/video-library/video-editor/errors-tab';
import { VideoFrameTab } from 'components/sections/video-library/video-editor/frame-tab';
import { SummaryTab } from 'components/sections/video-library/video-editor/summary-tab';
import { AuthContext, IAuthContext } from 'contexts/auth.context';
import {
  DirtyContext,
  DirtyProvider,
  IDirtyContext,
} from 'contexts/dirty.context';
import { IMachineContext, MachineContext } from 'contexts/machine.context';
import {
  IVideosContext,
  STATIC_PREFIX,
  VideosContext,
} from 'contexts/videos/videos.context';
import { t } from 'i18next';
import { IFullDialog } from 'interfaces/i-dialogs';
import { VideoHelper } from 'lib_ts/classes/video.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { PitcherHand } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import React, { useContext } from 'react';

const COMPONENT_NAME = 'VideoEditorDialog';

enum TabKey {
  Summary = 'Summary',
  Details = 'Details',
  Preview = 'Preview',
  Errors = 'Errors',
  Misc = 'Misc',
}

interface IBaseProps {
  video_id: string;
  onClose: () => void;
}

interface IProps extends IBaseProps {
  dirtyCx: IDirtyContext;
  authCx: IAuthContext;
  machineCx: IMachineContext;
  /** merge saved results to context so the rest of the UI updates automatically */
  videosCx: IVideosContext;
}

interface IState {
  activeTab: TabKey;
}

export const VideoEditorDialogHoC = (props: IBaseProps) => {
  const authCx = useContext(AuthContext);
  const machineCx = useContext(MachineContext);
  const videosCx = useContext(VideosContext);

  return (
    <DirtyProvider>
      <DirtyContext.Consumer>
        {(dirtyCx) => (
          <VideoEditorDialog
            {...props}
            dirtyCx={dirtyCx}
            authCx={authCx}
            machineCx={machineCx}
            videosCx={videosCx}
          />
        )}
      </DirtyContext.Consumer>
    </DirtyProvider>
  );
};

class VideoEditorDialog extends React.Component<IProps, IState> {
  private summaryTab?: SummaryTab;
  private detailsTab?: VideoFrameTab;
  private previewTab?: VideoFrameTab;

  constructor(props: IProps) {
    super(props);

    this.state = {
      activeTab: TabKey.Summary,
    };

    this.handleSave = this.handleSave.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.video_id !== this.props.video_id) {
      this.setState({ activeTab: TabKey.Summary });
    }
  }

  private handleSave() {
    switch (this.state.activeTab) {
      case TabKey.Summary: {
        this.summaryTab?.handleSave();
        break;
      }
      case TabKey.Details: {
        this.detailsTab?.handleSave();
        break;
      }
      case TabKey.Preview: {
        this.previewTab?.handleSave();
        break;
      }
      default: {
        break;
      }
    }
  }

  private renderContent() {
    const video_id = this.props.video_id;
    const videosCx = this.props.videosCx;
    const video = videosCx.getVideo(video_id);
    const errors = video ? VideoHelper.getErrors(video) : [];

    return (
      <CommonTabs
        value={this.state.activeTab}
        onValueChange={(value) => {
          this.props.dirtyCx.checkDirty({
            accept: () => this.setState({ activeTab: value as TabKey }),
          });
        }}
        tabs={[
          {
            value: TabKey.Summary,
            label: 'common.summary',
            content: (
              <SummaryTab
                ref={(elem) => (this.summaryTab = elem as SummaryTab)}
                teamOptions={this.props.authCx.getLeagueTeamOptions()}
                dirtyCx={this.props.dirtyCx}
                video_id={video_id}
                videosCx={videosCx}
              />
            ),
          },
          {
            value: TabKey.Details,
            label: 'common.details',
            content:
              this.state.activeTab === TabKey.Details ? (
                <VideoFrameTab
                  ref={(elem) => (this.detailsTab = elem as VideoFrameTab)}
                  dirtyCx={this.props.dirtyCx}
                  machineCx={this.props.machineCx}
                  video_id={video_id}
                  videosCx={videosCx}
                />
              ) : undefined,
          },
          {
            value: TabKey.Preview,
            label: 'common.preview',
            content:
              // forces the tab to render on demand
              this.state.activeTab === TabKey.Preview ? (
                <VideoFrameTab
                  ref={(elem) => (this.previewTab = elem as VideoFrameTab)}
                  dirtyCx={this.props.dirtyCx}
                  machineCx={this.props.machineCx}
                  video_id={video_id}
                  videosCx={videosCx}
                  preview
                />
              ) : undefined,
          },
          {
            value: TabKey.Errors,
            label: 'common.errors',
            invisible: errors.length === 0,
            content: <ErrorsTab errors={errors} />,
          },
          {
            value: TabKey.Misc,
            label: 'Misc',
            labelIcon: <SuperAdminIcon />,
            invisible: this.props.authCx.current.role !== UserRole.admin,
            content: video && (
              <DataList.Root>
                <DataList.Item>
                  <DataList.Label>ID</DataList.Label>
                  <DataList.Value>
                    <Code>{video._id}</Code>
                  </DataList.Value>
                </DataList.Item>
                <DataList.Item>
                  <DataList.Label>S3 Path</DataList.Label>
                  <DataList.Value>
                    <Code>{video.video_path}</Code>
                  </DataList.Value>
                </DataList.Item>
                <DataList.Item>
                  <DataList.Label>Filename</DataList.Label>
                  <DataList.Value>{video.VideoFileName}</DataList.Value>
                </DataList.Item>
              </DataList.Root>
            ),
          },
        ]}
      />
    );
  }

  render() {
    const video = this.props.videosCx.getVideo(this.props.video_id);

    if (!video) {
      return;
    }

    const isLHP = video.ReleaseSide > 0;
    const isDefault =
      video._id ===
      (isLHP
        ? this.props.machineCx.machine.default_video_lhp
        : this.props.machineCx.machine.default_video_rhp);

    const mergeProps: IFullDialog = {
      identifier: COMPONENT_NAME,
      width: RADIX.DIALOG.WIDTH.XL,
      loading: this.props.videosCx.loading,
      title: (
        <Heading size={RADIX.HEADING.SIZE.MODAL_TITLE} truncate>
          {t('common.x-editor', { x: t('videos.video') })}{' '}
          {video.original_id && (
            <CommonTooltip
              trigger={<Share1Icon />}
              text_md="videos.shared-video-tooltip"
            />
          )}
        </Heading>
      ),
      content: this.renderContent(),
      buttons: [
        {
          icon: isDefault ? <StarFilledIcon /> : <StarIcon />,
          label: 'common.set-as-default',
          invisible: video.video_path.startsWith(STATIC_PREFIX),
          tooltip: isDefault
            ? `This is already the default video for ${
                isLHP ? PitcherHand.LHP : PitcherHand.RHP
              } on ${this.props.machineCx.machine.machineID}.`
            : `Click to set the default video for ${
                isLHP ? PitcherHand.LHP : PitcherHand.RHP
              } on ${this.props.machineCx.machine.machineID}.`,
          disabled: isDefault || this.props.videosCx.loading,
          onClick: () => {
            if (isDefault) {
              return;
            }

            const errors = VideoHelper.getErrors(video);

            if (errors.length > 0) {
              NotifyHelper.warning({
                message_md: t('videos.default-video-issues-msg'),
              });
              return;
            }

            const payload: Partial<IMachine> = {
              _id: this.props.machineCx.machine._id,
            };

            if (isLHP) {
              payload.default_video_lhp = video._id;
            } else {
              payload.default_video_rhp = video._id;
            }

            this.props.machineCx.update(payload).then(() => {
              NotifyHelper.info({
                message_md: t('videos.updated-default-video-for-x', {
                  x: this.props.machineCx.machine.machineID,
                }),
              });
            });
          },
        },
        {
          invisible: ![TabKey.Summary, TabKey.Details, TabKey.Preview].includes(
            this.state.activeTab
          ),
          label: 'common.save',
          disabled: this.props.videosCx.loading,
          onClick: this.handleSave,
        },
      ],
      onClose: () => {
        this.props.dirtyCx.checkDirty({
          accept: this.props.onClose,
        });
      },
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog {...mergeProps} />
      </ErrorBoundary>
    );
  }
}
