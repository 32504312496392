import { DownloadIcon } from '@radix-ui/react-icons';
import {
  Box,
  Button,
  ChevronDownIcon,
  Flex,
  Popover,
  Skeleton,
} from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { CommonSearchInput } from 'components/common/form/search';
import { AdvancedFilters } from 'components/sections/analytics/hitter-session/advanced-filters';
import useAnalyticsStore from 'components/sections/analytics/store/use-analytics-store';
import { lightFormat } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { HitOutcomeCode } from 'lib_ts/enums/hitters.enums';
import { PitchType } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ISessionHit } from 'lib_ts/interfaces/i-session-hit';
import { useMemo, useState } from 'react';

const getUniquePitchTypes = (hits: ISessionHit[]) =>
  ArrayHelper.unique(
    hits.map((m) => m.pitch.type).filter(Boolean)
  ).sort() as PitchType[];

const getUniqueOutcomes = (hits: ISessionHit[]) =>
  ArrayHelper.unique(
    hits.map((m) => m.hit.outcome).filter(Boolean)
  ).sort() as HitOutcomeCode[];

export const HitterSessionToolbar = () => {
  const {
    hitterSession,
    hitterSessionSummary,
    pitchTypeFilter,
    outcomeFilter,
    setPitchTypeFilter,
    setOutcomeFilter,
    loading,
  } = useAnalyticsStore();

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const pitchTypes = useMemo(
    () => getUniquePitchTypes(hitterSession),
    [hitterSession]
  );

  const outcomes = useMemo(
    () => getUniqueOutcomes(hitterSession),
    [hitterSession]
  );

  // Should this be exporting the filtered rows instead of the whole table?
  const exportReport = () => {
    let fileName = 'Hitting Report';

    if (hitterSessionSummary) {
      const { hitterName, startDate } = hitterSessionSummary;
      fileName = `${fileName} - ${hitterName} (${lightFormat(
        parseISO(startDate),
        'yyyy-MM-dd'
      )})`;
    }

    StringHelper.saveCsv(hitterSession, {
      prefix: fileName,
    });
  };

  return (
    <Flex gap={RADIX.FLEX.GAP.SM} wrap="wrap">
      <Flex gap={RADIX.FLEX.GAP.SM} flexGrow="1">
        <Skeleton loading={loading}>
          <Box flexGrow="1">
            <CommonSearchInput
              id="pitch-type-filter"
              placeholder={t('common.pitch-type') as string}
              options={pitchTypes.map((pitchType) => ({
                label: pitchType,
                value: pitchType,
              }))}
              values={pitchTypeFilter}
              onChange={(pitchTypes) =>
                setPitchTypeFilter(pitchTypes as PitchType[])
              }
              multiple
              strict
            />
          </Box>
        </Skeleton>
        <Skeleton loading={loading}>
          <Box flexGrow="1">
            <CommonSearchInput
              id="outcome-filter"
              placeholder={t('common.results') as string}
              options={outcomes.map((outcome) => ({
                label: outcome,
                value: outcome,
              }))}
              values={outcomeFilter}
              onChange={(outcomes) =>
                setOutcomeFilter(outcomes as HitOutcomeCode[])
              }
              multiple
            />
          </Box>
        </Skeleton>
      </Flex>
      <Flex gap={RADIX.FLEX.GAP.SM}>
        <Popover.Root
          open={showAdvancedFilters}
          onOpenChange={setShowAdvancedFilters}
        >
          <Skeleton loading={loading}>
            <Popover.Trigger>
              <Button
                size={RADIX.BUTTON.SIZE.SM}
                color={RADIX.COLOR.NEUTRAL}
                variant={RADIX.BUTTON.VARIANT.SECONDARY}
              >
                {t('common.advanced-filters')}
                <ChevronDownIcon />
              </Button>
            </Popover.Trigger>
          </Skeleton>
          <Popover.Content
            align="end"
            style={{
              padding: 'var(--space-4)',
            }}
          >
            <AdvancedFilters
              onApply={() => {
                setShowAdvancedFilters(false);
              }}
              onReset={() => {
                setShowAdvancedFilters(false);
              }}
            />
          </Popover.Content>
        </Popover.Root>
        <Skeleton loading={loading}>
          <Button
            size={RADIX.BUTTON.SIZE.SM}
            color={RADIX.COLOR.ACCENT}
            onClick={exportReport}
          >
            <DownloadIcon />
            {t('common.export-report')}
          </Button>
        </Skeleton>
      </Flex>
    </Flex>
  );
};
