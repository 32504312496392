import { ResetIcon } from '@radix-ui/react-icons';
import { Avatar, Badge, Box, Grid } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { StringHelper } from 'classes/helpers/string.helper';
import { CommonCallout } from 'components/common/callouts';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonTableHoC } from 'components/common/table';
import { CommonTableButton } from 'components/common/table/button';
import { SectionHeader } from 'components/sections/header';
import {
  IQuickSessionContext,
  QuickSessionContext,
  QuickSessionStep,
} from 'contexts/quick-session.context';
import { t } from 'i18next';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ITableSortable } from 'interfaces/tables/sorting';
import {
  PITCHER_HANDS,
  PITCHER_RELEASES,
  PitcherHand,
  PitcherRelease,
  PLAYER_LEVELS,
  PlayerLevel,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitchList } from 'lib_ts/interfaces/pitches';
import React, { useContext } from 'react';

const COMPONENT_NAME = 'QuickSessionSelectCard';

interface IProps {
  quickCx: IQuickSessionContext;
}

interface IStateFilters {
  filterNames: string[];
  filtersKey: number;

  filterRelease?: PitcherRelease;
  filterLevel?: PlayerLevel;
  filterHand?: PitcherHand;
}

const DEFAULT_FILTERS: IStateFilters = {
  filterNames: [],
  filtersKey: Date.now(),
};

interface IState extends IStateFilters {}

export const SelectCardHoC = () => {
  const quickCx = useContext(QuickSessionContext);

  return <SelectCard quickCx={quickCx} />;
};

class SelectCard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      ...DEFAULT_FILTERS,
    };

    this.renderToolbar = this.renderToolbar.bind(this);
  }

  private readonly BASE_COLUMNS: ITableColumn[] = [
    {
      label: '',
      key: '_avatar',
      thClassNameFn: () => 'width-60px',
      align: 'center',
      formatFn: (m: IPitchList) => (
        <Avatar
          mt="2"
          mb="2"
          src={this.props.quickCx.avatarURLs[m.card?.avatar_path ?? '']?.url}
          fallback={StringHelper.getInitials(m.name)}
        />
      ),
      disableSort: true,
    },
    {
      label: 'common.pitcher',
      key: '_name',
      formatFn: (m: IPitchList) => m.card?.name ?? '???',
      sortRowsFn: (a: IPitchList, b: IPitchList, dir: number) =>
        (b.card?.name ?? '').localeCompare(a.card?.name ?? '') * dir,
    },
    {
      label: 'common.hand',
      key: '_hand',
      align: 'center',
      formatFn: (m: IPitchList) => {
        const isLeft = m.card?.hand === PitcherHand.LHP;
        return (
          <Badge color={isLeft ? RADIX.COLOR.LEFT : RADIX.COLOR.RIGHT}>
            {t(isLeft ? 'common.lhp' : 'common.rhp')}
          </Badge>
        );
      },
      sortRowsFn: (a: IPitchList, b: IPitchList, dir: number) =>
        (b.card?.hand ?? '').localeCompare(a.card?.hand ?? '') * dir,
    },
    {
      label: 'common.slot',
      key: '_slot',
      formatFn: (m: IPitchList) =>
        PitchListHelper.shortPitcherRelease(m.card?.release),
      sortRowsFn: (a: IPitchList, b: IPitchList, dir: number) =>
        (b.card?.release ?? '').localeCompare(a.card?.release ?? '') * dir,
    },
    {
      label: 'common.level',
      key: '_level',
      formatFn: (m: IPitchList) => {
        const label = PLAYER_LEVELS.find((o) => o.value === m.card?.level)
          ?.label;
        return label ? t(label) : '--';
      },
      sortRowsFn: (a: IPitchList, b: IPitchList, dir: number) => {
        const aIndex = PLAYER_LEVELS.findIndex(
          (l) => l.value === a.card?.level
        );
        const bIndex = PLAYER_LEVELS.findIndex(
          (l) => l.value === b.card?.level
        );
        return (aIndex > bIndex ? 1 : -1) * dir;
      },
    },
  ];

  render() {
    const filteredData = this.props.quickCx.cards
      .filter(
        (c) =>
          this.state.filterNames.length === 0 ||
          this.state.filterNames.includes(c.card?.name ?? '')
      )
      .filter(
        (c) =>
          !this.state.filterLevel || c.card?.level === this.state.filterLevel
      )
      .filter(
        (c) => !this.state.filterHand || c.card?.hand === this.state.filterHand
      )
      .filter(
        (c) =>
          !this.state.filterRelease ||
          c.card?.release === this.state.filterRelease
      );

    const sort: ITableSortable = {
      enableSort: true,
    };

    const select: ITableSelectable = {
      enableSelect: true,
      blockSelect: this.props.quickCx.loading,
      afterChangeSelected: (model: IPitchList | undefined) => {
        if (!model) {
          return;
        }

        NotifyHelper.success({
          message_md: `Loading pitches, please wait...`,
        });

        this.props.quickCx
          .selectCard(model._id)
          .then(() => this.props.quickCx.setStep(QuickSessionStep.SelectPitch));
      },
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <FlexTableWrapper
          gap={RADIX.FLEX.GAP.SECTION}
          header={
            <>
              <SectionHeader header={t('qs.select-a-player')} />

              {this.props.quickCx.cards.length === 0 && (
                <CommonCallout text="qs.msg-support-add-cards" />
              )}

              {this.props.quickCx.cards.length > 0 &&
                filteredData.length === 0 && (
                  <CommonCallout text="qs.msg-no-cards-found" />
                )}
            </>
          }
          table={
            <CommonTableHoC
              id={COMPONENT_NAME}
              displayColumns={this.BASE_COLUMNS}
              displayData={filteredData}
              toolbarContent={this.renderToolbar()}
              {...select}
              {...sort}
              vFlex
            />
          }
        />
      </ErrorBoundary>
    );
  }

  private renderToolbar() {
    return (
      <Grid columns="5" gap={RADIX.FLEX.GAP.SM}>
        <Box>
          <CommonSearchInput
            key={`names-${this.state.filtersKey}`}
            id="filter-names"
            placeholder="common.name"
            options={this.props.quickCx.options.names.map((o) => ({
              label: o,
              value: o,
            }))}
            values={this.state.filterNames}
            onChange={(v) => {
              this.setState({ filterNames: v });
            }}
            multiple
            optional
          />
        </Box>

        <CommonSelectInput
          key={`slot-${this.state.filtersKey}`}
          id="card-slot"
          placeholder="common.slot"
          name="filter-slot"
          options={PITCHER_RELEASES}
          value={this.state.filterRelease}
          onChange={(v) =>
            this.setState({ filterRelease: v as PitcherRelease })
          }
          optional
        />

        <CommonSelectInput
          key={`level-${this.state.filtersKey}`}
          id="card-level"
          placeholder="common.level"
          name="filter-level"
          options={PLAYER_LEVELS}
          value={this.state.filterLevel}
          onChange={(v) => this.setState({ filterLevel: v as PlayerLevel })}
          optional
          skipSort
        />

        <CommonSelectInput
          key={`hand-${this.state.filtersKey}`}
          id="card-hand"
          placeholder="common.hand"
          name="filter-hand"
          options={PITCHER_HANDS}
          value={this.state.filterHand}
          onChange={(v) => this.setState({ filterHand: v as PitcherHand })}
          optional
        />

        <CommonTableButton
          label="common.reset"
          icon={<ResetIcon />}
          variant="soft"
          onClick={() => {
            this.setState({
              filterNames: [],
              filterHand: undefined,
              filterLevel: undefined,
              filterRelease: undefined,
              filtersKey: Date.now(),
            });
          }}
        />
      </Grid>
    );
  }
}
