import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import {
  RPM_TO_RADPS,
  STRIKEZONE,
} from 'components/common/trajectory-view/helpers/constants';
import {
  IInputModel,
  IInputModelDict,
  IModelGroup,
  ITimer,
} from 'components/common/trajectory-view/helpers/interfaces';
import { format } from 'date-fns-tz';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { AimingHelper } from 'lib_ts/classes/aiming.helper';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { EllipseHelper } from 'lib_ts/classes/ellipse.helper';
import { DEG_TO_RAD } from 'lib_ts/classes/math.utilities';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { IMachineState } from 'lib_ts/interfaces/i-machine-state';
import { IBallState, ITrajectory } from 'lib_ts/interfaces/pitches';
import { IAziAltCoordinate, IPosition } from 'lib_ts/interfaces/pitches/i-base';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import { Euler, Group, Quaternion, Vector3 } from 'three';
import { v4 } from 'uuid';

/** computes next rotation state based on current ball orientation, returning undefined if results are invalid */
export const getNextOrientation = (config: {
  delta: number;
  speed: number;
  ball: Group;
  bs: IBallState;
}) => {
  const netSpin = BallHelper.getNetSpin(config.bs);

  if (!isNaN(netSpin) && netSpin >= 1e-5) {
    const axis = new Vector3(
      config.bs.wx / netSpin,
      config.bs.wy / netSpin,
      config.bs.wz / netSpin
    );

    const oDelta = new Quaternion();
    oDelta.setFromAxisAngle(
      axis,
      /** set a larger multiplier to spin the ball faster */
      config.delta * config.speed * netSpin * RPM_TO_RADPS
    );

    const oEuler = new Euler(
      config.ball.rotation.x,
      config.ball.rotation.y,
      config.ball.rotation.z,
      'XYZ'
    );

    const qCurrent = new Quaternion();
    qCurrent.setFromEuler(oEuler);

    const qNext = new Quaternion();
    qNext.multiplyQuaternions(oDelta, qCurrent);

    return new Euler().setFromQuaternion(qNext);
  } else {
    return undefined;
  }
};

/** output is using THREE coordinates (i.e. z represents distance between pitcher and home) */
export const positionAtTime = (t: number, traj: ITrajectory): IPosition => {
  return {
    px: traj.px + traj.vx * t + traj.ax * t * t * 0.5,
    py: traj.py + traj.vy * t + traj.ay * t * t * 0.5,
    pz: traj.pz - traj.vz * t + traj.az * t * t * 0.5,
  };
};

/** search for "exact" moment and position where the ball will cross the strike zone */
export const seekArrivalTime = (config: {
  pos: IPosition;
  timer: ITimer;
  traj: ITrajectory;
  delta: number;
}): { time: number; pos: IPosition } => {
  let workingPos: IPosition = { ...config.pos };

  /** compute the value and save it for iterations of the loop */
  let tLow = config.timer.time;
  let tHigh = config.timer.time + config.delta;
  let tMid = 0;

  /** repeat the following until we are "close enough" to the strike zone z position, or until we use up all 100 attempts (to prevent a runaway process) */
  let count = 0;
  while (
    count < 100 &&
    Math.abs(workingPos.pz - STRIKEZONE.Z) > STRIKEZONE.PRECISION
  ) {
    tMid = (tLow + tHigh) / 2;
    workingPos = positionAtTime(tMid, config.traj);

    if (workingPos.pz < STRIKEZONE.Z) {
      /** ball is in front of the strike zone (pitcher's POV) => increase time */
      tLow = tMid;
    } else {
      /** ball is beyond the strike zone (pitcher's POV) => decrease time */
      tHigh = tMid;
    }

    count++;
  }

  if (count > 50) {
    console.warn({
      event: `seekArrivalTime: completed w/ ${count} attempts`,
      time: tMid,
      pos: workingPos,
    });
  }

  return { time: tMid, pos: workingPos };
};

export const GROUP_TARGET: IModelGroup = {
  sort: 0,
  name: 'Target',
  // red
  color: '#FF0000',
};

export const GROUP_ACTUAL: IModelGroup = {
  sort: 1,
  name: 'Actual',
  // yellow
  color: '#ffc107',
};

export const GROUP_AVG_ROTATED: IModelGroup = {
  sort: 2,
  name: 'Avg. Rotated',
  // darker green
  color: '#1ac661',
};

export const GROUP_ROTATED: IModelGroup = {
  sort: 3,
  name: 'Rotated',
  // lighter green
  color: '#004411',
};

export const getRenderGroupDict = (config: {
  bs: IBallState;
  traj: ITrajectory;
  ms: IMachineState;
  shots: IMachineShot[];
  plate_distance: number;

  includeTarget?: boolean;
  includeAvgRotated?: boolean;
  includeEachActual?: boolean;
  includeEachRotated?: boolean;
}): Partial<IInputModelDict> => {
  const output: Partial<IInputModelDict> = {};

  if (config.includeTarget) {
    output.target = {
      guid: v4(),
      description: 'Target trajectory of the pitch',
      button_group: GROUP_TARGET,
      color: GROUP_TARGET.color,
      traj: config.traj,
      bs: config.bs,
    };
  }

  if (config.includeEachActual) {
    const eachActual: IInputModel[] = [];

    config.shots.forEach((s, i) => {
      const timestamp = format(new Date(s._created), LOCAL_DATETIME_FORMAT, {
        timeZone: LOCAL_TIMEZONE,
      });

      eachActual.push({
        guid: v4(),
        description: `Actual trajectory of training shot ${
          i + 1
        } @ ${timestamp}`,
        button_group: GROUP_ACTUAL,
        color: GROUP_ACTUAL.color,
        traj: s.traj,
        bs: s.bs ?? config.bs,
      });
    });

    output.eachActual = eachActual;
  }

  const rotatedShots = EllipseHelper.getRotatedShots({
    ms: config.ms,
    traj: config.traj,
    shots: config.shots,
    plate_distance: config.plate_distance,
  });

  const avgRotated = PitchListHelper.getAvgShot(rotatedShots);

  const aimedPitch = AimingHelper.aimWithShots({
    chars: {
      ms: config.ms,
      bs: config.bs,
      traj: config.traj,
      priority: BuildPriority.Spins,
    },
    release: config.traj,
    shots: config.shots,
    plate_distance: config.plate_distance,
    plate_location: TrajHelper.getPlateLoc(config.traj),
    source: 'trajectory-viewer',
  });

  if (config.includeAvgRotated && avgRotated.traj) {
    output.avgRotated = {
      guid: 'average',
      description: 'Average of rotated trajectories from training shots',
      button_group: GROUP_AVG_ROTATED,
      // darker green
      color: GROUP_AVG_ROTATED.color,
      traj: avgRotated.traj,
      bs: avgRotated.bs ?? config.bs,
    };
  }

  if (config.includeEachRotated && avgRotated.actual_ms) {
    const eachRotated: IInputModel[] = [];

    const refTilt = aimedPitch.ms.tilt;
    const refYaw = aimedPitch.ms.yaw;

    config.shots.forEach((s, i) => {
      const delta: IAziAltCoordinate = {
        altitude_rad: (refTilt - s.target_ms.tilt) * DEG_TO_RAD,
        azimuth_rad: (refYaw - s.target_ms.yaw) * DEG_TO_RAD,
      };

      const timestamp = format(new Date(s._created), LOCAL_DATETIME_FORMAT, {
        timeZone: LOCAL_TIMEZONE,
      });

      const rotated = TrajHelper.getRotatedTrajectory(s.traj, delta);

      eachRotated.push({
        guid: v4(),
        description: `Rotated trajectory of training shot ${
          i + 1
        } @ ${timestamp}`,
        button_group: GROUP_ROTATED,
        // lighter green
        color: GROUP_ROTATED.color,
        traj: rotated,
        bs: s.bs ?? config.bs,
      });
    });

    output.eachRotated = eachRotated;
  }

  return output;
};
