export enum CookieKey {
  app = 'app',
  machineCalibration = 'machineCalibration',
  session = 'session',
  snapshot = 'snapshot',
}

export enum SidebarState {
  mini = 'mini',
  full = 'full',
}

export enum DragDropEngine {
  click = 'click',
  touch = 'touch',
}
