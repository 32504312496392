import { IMachineContext } from 'contexts/machine.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { ITrainingContext } from 'contexts/training.context';
import { IBaseDialog } from 'interfaces/i-dialogs';
import { RadixColor } from 'lib_ts/enums/radix-ui';
import { ITrainingMsg } from 'lib_ts/interfaces/i-machine-msg';
import { IPitch } from 'lib_ts/interfaces/pitches';

export interface ITrainingDialog extends IBaseDialog {
  machineCx: IMachineContext;
  trainingCx: ITrainingContext;

  pitches: Partial<IPitch>[];
}

export interface ITrainingMsgExt extends ITrainingMsg {
  // populated by the context when it's received
  received: Date;
}

export interface ITrainingProgress {
  // e.g. Pitch 1 of 43, Shot 1 of 3
  left_text?: string;

  // e.g. est. 30 min remaining
  right_text?: string;

  /** 0-100 */
  complete: number;

  /** if provided, will show instead of the X% of completed or default paused message */
  label?: string;

  color?: RadixColor;

  paused?: boolean;
}

export const EMPTY_PROGRESS: ITrainingProgress = {
  left_text: 'Please wait...',
  complete: 0,
};

export interface ITrainingProps {
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;

  pitch: Partial<IPitch>;

  newerThan?: string;

  /** true: overrides pretty much every other state, there is a fatal error (e.g. pitch does not have a msDict entry) */
  error?: boolean;

  /** executed after sending a pitch to machine */
  sendToMachineCallback?: () => void;

  /** what to do when a user skips the current shot */
  onSkip: () => void;

  /** if enabled, whenever this view detects a count that is at least the threshold,
   * onContinue will be triggered regardless of whether the previous count was less than threshold
   * i.e. disregard whether we are crossing the threshold or not */
  forceContinue?: boolean;

  /** what to do when a user continues to the next shot */
  onContinue: () => void;

  /** what to do when a user revisits the previous shot, provide undefined if there is no previous pitch */
  onPrevious?: () => void;
}
