import { Flex, Heading } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { MachinePositionView } from 'components/machine/position-view';
import { CalloutsDemo } from 'components/sections/feature-demo/callouts';
import { ClientMessagesDemo } from 'components/sections/feature-demo/client-messages';
import { CustomIconsDemo } from 'components/sections/feature-demo/custom-icons';
import { MachineMessagesDemo } from 'components/sections/feature-demo/machine-messages';
import { ProcessesDemo } from 'components/sections/feature-demo/processes';
import { SectionBox } from 'components/sections/feature-demo/section-box';
import { ToastsDemo } from 'components/sections/feature-demo/toasts';
import { SectionHeader } from 'components/sections/header';
import { MachineContext } from 'contexts/machine.context';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext } from 'react';

export const FeatureDemo = () => {
  const machineCx = useContext(MachineContext);

  return (
    <ErrorBoundary componentName="FeatureDemo">
      <Flex direction="column" gap={RADIX.FLEX.GAP.SECTION}>
        <SectionHeader
          header={t('main.feature-demo')}
          onClick={() => {
            NotifyHelper.haveFun();
          }}
        />

        <SectionBox>
          <Heading size={RADIX.HEADING.SIZE.MD}>Machine View</Heading>

          <MachinePositionView
            machineCx={machineCx}
            onUpdate={(pos) => {
              console.info(
                `FeatureDemo: received machine position (${pos.px}, ${pos.pz})`
              );
            }}
          />
        </SectionBox>

        <MachineMessagesDemo />

        <ClientMessagesDemo machineCx={machineCx} />

        <ProcessesDemo />

        <CalloutsDemo />

        <ToastsDemo />

        <CustomIconsDemo />
      </Flex>
    </ErrorBoundary>
  );
};
