import { NotifyHelper } from 'classes/helpers/notify.helper';
import { addMinutes } from 'date-fns';
import { t } from 'i18next';
import { IServerUptime } from 'lib_ts/interfaces/common/i-build-metadata';
import { IServerResponse } from 'lib_ts/interfaces/common/i-server-response';
import { ISlackMsg } from 'lib_ts/interfaces/common/i-slack-msg';
import {
  IExpiringUrlDict,
  IUrlDict,
} from 'lib_ts/interfaces/common/i-url-dict';
import { BaseRESTService } from 'services/_base-rest.service';

export const DEFAULT_AVATAR_PATH = '/img/player-cards/generic.png';

/** recursively finds and replaces undefined values with null
 * (e.g. to prevent JSON.stringify or axios from deleting the key-value pair altogether)
 */
const preserveUndefined = (obj: any) => {
  if (obj) {
    Object.keys(obj).forEach((k) => {
      if (obj[k] === undefined) {
        obj[k] = null;
      } else if (typeof obj[k] === 'object') {
        /** recurse */
        preserveUndefined(obj[k]);
      }
    });
  }
};

interface IOrderPayload {
  /** i.e. name of database table to interact with */
  collection: string;

  /** index in sort order determines _sort value */
  ids: string[];
}

export class MainService extends BaseRESTService {
  private static instance: MainService;
  static getInstance(): MainService {
    if (!MainService.instance) {
      MainService.instance = new MainService();
    }

    return MainService.instance;
  }

  private constructor() {
    super({
      controller: 'main',
    });
  }

  async checkServer(): Promise<IServerUptime | undefined> {
    return await this.get({
      uri: 'check',
    })
      .then((result: IServerUptime) => {
        return result;
      })
      .catch((e) => {
        console.error(e);
        return undefined;
      });
  }

  async updateOrder(data: IOrderPayload): Promise<void> {
    return await this.put(
      {
        uri: 'order',
      },
      data
    )
      .then(() => NotifyHelper.success({ message_md: 'Sort order saved.' }))
      .catch(() =>
        NotifyHelper.error({
          message_md: 'There was an error while saving your sort order.',
        })
      );
  }

  /** converts the array into a CSV string (e.g. to be saved as a file) */
  async convertJSONToCSV(data: any[]): Promise<string> {
    /** replace undefined values with null to ensure they key-value pair does not get deleted by axios */
    data.forEach((d) => {
      preserveUndefined(d);
    });

    return await this.post(
      {
        uri: 'convert/json-to-csv',
      },
      data
    );
  }

  /** reliably parses the CSV on the server to get a JSON object back */
  async convertCSVToJSON(formData: FormData): Promise<any[]> {
    return await this.post(
      {
        uri: 'convert/csv-to-json',
        headers: { 'Content-Type': 'multipart/form-data' },
      },
      formData
    );
  }

  async signUrls(
    keys: string[],
    action = 'getObject'
  ): Promise<IExpiringUrlDict> {
    return await this.post(
      {
        uri: `sign-urls/${action}`,
      },
      keys
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? t('common.request-failed-msg'),
          });
          return {};
        }

        const rawDict = result.data as IUrlDict;

        const output: IExpiringUrlDict = {};

        // server generates urls that expire in 1 hour
        const expires = addMinutes(new Date(), 55);

        Object.keys(rawDict).forEach((key) => {
          output[key] = {
            url: rawDict[key],
            expires: expires,
          };
        });

        return output;
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });
        return {};
      });
  }

  async postSlack(data: ISlackMsg) {
    return await this.post(
      {
        uri: 'slack',
      },
      data
    )
      .then((result: IServerResponse) => result.success)
      .catch((reason) => {
        console.error(reason);
        return false;
      });
  }

  async restartCron(): Promise<void> {
    return await this.get({
      uri: 'restart-cron',
    });
  }
}
