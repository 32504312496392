import { DEFAULT_ORIENTATION } from '../../enums/pitches.enums';
import { IOrientation, IPosition, ISpin, IVelocity } from './i-base';

/**
 * note 1: orientation (q[wxyz]) is as ball is exiting
 * note 2: velocity values (v[x/y/z/net]) should be in MPH
 */
export interface IBallState extends IOrientation, IPosition, ISpin, IVelocity {
  vnet: number;

  /** net spin */
  wnet: number;
}

export const DEFAULT_BALL_STATE: IBallState = {
  px: 0,
  py: 55,
  pz: 4.5,

  vx: 0,
  vy: -75,
  vz: 0.9,

  vnet: 75,

  wx: -2000,
  wy: 0,
  wz: 0,

  wnet: 2000,

  ...DEFAULT_ORIENTATION,
};
