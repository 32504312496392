import { ErrorBoundary } from 'components/common/error-boundary';
import { lightFormat, parseISO } from 'date-fns';
import { MACHINE_COLORS } from 'enums/charts.enums';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { IRealMachineMetric } from 'lib_ts/interfaces/modelling/i-real-machine-metric';
import React from 'react';
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

interface IDatum {
  // Epoch time
  date: number;
  datestring: string;
  value: number;
}

const CustomTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  if (!active) {
    return null;
  }

  if (!payload) {
    return null;
  }

  if (payload.length === 0) {
    return null;
  }

  const data: IDatum = payload[0].payload;

  if (!data) {
    return null;
  }

  return (
    <div className="custom-tooltip">
      <h6>{data.datestring}</h6>
      <div>Rating: {data.value.toFixed(2)}%</div>
    </div>
  );
};

interface IProps {
  metrics: IRealMachineMetric[];
}

interface IState {}

export class MachinePerformancePlot extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  render() {
    const xData = this.props.metrics
      .map((m) => {
        const date = parseISO(m.dataFilter.end_date ?? m._created);

        if (!m.machine_performance?.mean_stdev) {
          return undefined;
        }

        const datum: IDatum = {
          date: date.valueOf(),
          datestring: lightFormat(date, 'yyyy-MM-dd'),
          value: m.machine_performance.mean_stdev.plx * 12,
        };
        return datum;
      })
      .filter((m) => m) as IDatum[];

    const zData = this.props.metrics
      .map((m) => {
        const date = parseISO(m.dataFilter.end_date ?? m._created);

        if (!m.machine_performance?.mean_stdev) {
          return undefined;
        }

        const datum: IDatum = {
          date: date.valueOf(),
          datestring: lightFormat(date, 'yyyy-MM-dd'),
          value: m.machine_performance.mean_stdev.plz * 12,
        };
        return datum;
      })
      .filter((m) => m) as IDatum[];

    return (
      <ErrorBoundary componentName="MachinePerformancePlot">
        <ResponsiveContainer width="100%" height={400}>
          <ScatterChart
            margin={{
              top: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis
              dataKey="date"
              type="number"
              name="Date"
              scale="time"
              domain={[
                MiscHelper.getMin(xData.map((d) => d.date)),
                MiscHelper.getMax(xData.map((d) => d.date)),
              ]}
              tickFormatter={(date) => lightFormat(new Date(date), 'MM-dd')}
            />

            <YAxis
              dataKey="value"
              type="number"
              name="StDev"
              unit="in"
              domain={[0, 8]}
            />

            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              content={<CustomTooltip />}
              animationDuration={0}
            />

            <Legend />

            <Scatter
              name="Mean Plate X StDev Over Time"
              data={xData.filter((d) => d.value > 0)}
              fill={MACHINE_COLORS[0]}
              animationDuration={0}
              line
            />

            <Scatter
              name="Mean Plate Z Over Time"
              data={zData.filter((d) => d.value > 0)}
              fill={MACHINE_COLORS[1]}
              animationDuration={0}
              line
            />
          </ScatterChart>
        </ResponsiveContainer>
      </ErrorBoundary>
    );
  }
}
