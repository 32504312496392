import { Flex, Heading } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CHART_COLORS } from 'enums/charts.enums';
import { PitchType } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IGatherSummary } from 'lib_ts/interfaces/modelling/i-gather-shot-data';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

type HistMode = 'target_ms_group_size';

interface IHistorgramData {
  type: string;
  groups: number;
  shots: number;
}

const getTitle = (mode: HistMode) => {
  switch (mode) {
    case 'target_ms_group_size': {
      return '# Unique Pitches by Type';
    }

    default: {
      return `${mode} by Type`;
    }
  }
};

const formatData = (
  mode: HistMode,
  groups: IGatherSummary[]
): IHistorgramData[] => {
  switch (mode) {
    case 'target_ms_group_size': {
      const groupDict: {
        [pitch_type: string]: {
          groups: number;
          shots: number;
        };
      } = {};

      groups.forEach((group) => {
        const key = group.pitch?.type ?? PitchType.None;

        if (!groupDict[key]) {
          groupDict[key] = {
            groups: 0,
            shots: 0,
          };
        }

        groupDict[key].groups += 1;
        groupDict[key].shots += group.shot_ids.length;
      });

      return Object.keys(groupDict).map((key) => {
        const d: IHistorgramData = {
          type: key,
          groups: groupDict[key].groups,
          shots: groupDict[key].shots,
        };
        return d;
      });
    }

    default: {
      return [];
    }
  }
};

const CustomTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  if (!active) {
    return null;
  }

  if (!payload) {
    return null;
  }

  if (payload.length === 0) {
    return null;
  }

  const data: IHistorgramData = payload[0].payload;

  if (!data) {
    return null;
  }

  return (
    <div className="custom-tooltip">
      Unique Pitches: {data.groups}
      <br />
      Total Shots: {data.shots}
    </div>
  );
};

interface IProps {
  mode: HistMode;
  groups: IGatherSummary[];
}

interface IState {
  title: string;
  data: IHistorgramData[];
}

export class HistogramGroup extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const data = formatData(props.mode, props.groups);

    this.state = {
      title: getTitle(props.mode),
      data: data,
    };

    this.renderGraph = this.renderGraph.bind(this);
  }

  private renderGraph() {
    return (
      <ResponsiveContainer width="100%" height={200}>
        <BarChart
          data={this.state.data}
          margin={{
            top: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid />

          <XAxis
            name="Pitch Type"
            type="category"
            dataKey="type"
            interval={0}
          />

          <YAxis name="Count" type="number" dataKey="groups" />

          <Tooltip content={<CustomTooltip />} />

          <Bar name="Count" dataKey="groups" fill={CHART_COLORS[0]} />
        </BarChart>
      </ResponsiveContainer>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName="HistogramGroup">
        <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
          <Heading size={RADIX.HEADING.SIZE.MD}>{this.state.title}</Heading>
          {this.renderGraph()}
        </Flex>
      </ErrorBoundary>
    );
  }
}
