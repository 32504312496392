import {
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  UpdateIcon,
} from '@radix-ui/react-icons';
import { Box, Flex, Grid, IconButton } from '@radix-ui/themes';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTooltip } from 'components/common/tooltip';
import { MachineProjectorToggleButton } from 'components/machine/buttons/projector-toggle';
import env from 'config';
import { IMachineContext } from 'contexts/machine.context';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

const COMPONENT_NAME = 'ProjectorSetupDialog';
const DELTA = 0.005;

interface IProps {
  machineCx: IMachineContext;
  onClose: () => void;
}

interface IState {}

export class ProjectorSetupDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.renderContent = this.renderContent.bind(this);
  }

  private renderContent() {
    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.LG} justify="between">
        <Flex justify="center">
          <Grid columns="3" gap={RADIX.FLEX.GAP.MD}>
            {/* row 1 */}
            <Box gridColumn="2">
              <IconButton
                size={RADIX.BUTTON.SIZE.LG}
                color={RADIX.COLOR.NEUTRAL}
                onClick={() =>
                  this.props.machineCx.adjustKeystone(COMPONENT_NAME, {
                    delta_v: DELTA,
                  })
                }
              >
                <ArrowUpIcon />
              </IconButton>
            </Box>
            {/* row 2 */}
            <Box gridColumn="1">
              <IconButton
                size={RADIX.BUTTON.SIZE.LG}
                color={RADIX.COLOR.NEUTRAL}
                onClick={() =>
                  this.props.machineCx.adjustKeystone(COMPONENT_NAME, {
                    delta_h: -DELTA,
                  })
                }
              >
                <ArrowLeftIcon />
              </IconButton>
            </Box>
            <Box>
              <IconButton
                size={RADIX.BUTTON.SIZE.LG}
                color={RADIX.COLOR.NEUTRAL}
                onClick={() =>
                  this.props.machineCx.adjustKeystone(COMPONENT_NAME, {
                    reset_v: true,
                    reset_h: true,
                  })
                }
              >
                <UpdateIcon />
              </IconButton>
            </Box>
            <Box>
              <IconButton
                size={RADIX.BUTTON.SIZE.LG}
                color={RADIX.COLOR.NEUTRAL}
                onClick={() =>
                  this.props.machineCx.adjustKeystone(COMPONENT_NAME, {
                    delta_h: DELTA,
                  })
                }
              >
                <ArrowRightIcon />
              </IconButton>
            </Box>
            {/* row 3 */}
            <Box gridColumn="2">
              <IconButton
                size={RADIX.BUTTON.SIZE.LG}
                color={RADIX.COLOR.NEUTRAL}
                onClick={() =>
                  this.props.machineCx.adjustKeystone(COMPONENT_NAME, {
                    delta_v: -DELTA,
                  })
                }
              >
                <ArrowDownIcon />
              </IconButton>
            </Box>
          </Grid>
        </Flex>

        <Flex justify="center" gap={RADIX.FLEX.GAP.MD}>
          <Box>
            <CommonTooltip
              trigger={
                <MachineProjectorToggleButton
                  className="btn-block"
                  mode="outline"
                />
              }
              text_md="Toggle the ruler and the outline of the machine on the projector (e.g. to verify projector alignment)."
            />
          </Box>
          <Box>
            {!env.production && (
              <CommonTooltip
                trigger={
                  <MachineProjectorToggleButton
                    className="btn-block"
                    mode="rulers"
                  />
                }
                text_md="Toggle the rulers of the machine on the projector (e.g. to verify projector alignment)."
              />
            )}
          </Box>
        </Flex>
      </Flex>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={COMPONENT_NAME}
          width={RADIX.DIALOG.WIDTH.MD}
          title="common.projector-setup"
          content={this.renderContent()}
          onClose={() => this.props.onClose()}
        />
      </ErrorBoundary>
    );
  }
}
