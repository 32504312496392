import { Avatar, Flex, Grid, Heading, Skeleton, Text } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { DataCard } from 'components/common/data-card';
import useAnalyticsStore from 'components/sections/analytics/store/use-analytics-store';
import { SectionHeader } from 'components/sections/header';
import { SectionsContext } from 'contexts/sections.context';
import format from 'date-fns-tz/format';
import parseISO from 'date-fns/parseISO';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { round } from 'lib_ts/classes/math.utilities';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext } from 'react';

const Summary = () => {
  const { hitterSessionSummary, hitterSession, loading } = useAnalyticsStore();

  if (!hitterSessionSummary || !hitterSession || loading) {
    // Skeleton loading state
    return (
      <Flex align="center" wrap="wrap" gap={RADIX.FLEX.GAP.SM}>
        <Flex gap={RADIX.FLEX.GAP.MD} align="center" flexGrow="1">
          <Skeleton>
            <Avatar color={RADIX.COLOR.NEUTRAL} fallback="" size="7" />
          </Skeleton>
          <Flex
            direction="column"
            justify="center"
            gap={RADIX.FLEX.GAP.XS}
            align="start"
          >
            <Skeleton>
              <Heading size={RADIX.HEADING.SIZE.LG}>Lorem ipsum</Heading>
            </Skeleton>
            <Skeleton>
              <Text color={RADIX.COLOR.SECONDARY}>
                Lorem ipsum dolor sit amet consectetur
              </Text>
            </Skeleton>
          </Flex>
        </Flex>
        <Flex gap={RADIX.FLEX.GAP.XS} wrap="wrap">
          <Skeleton loading>
            <DataCard title="Lorem ipsum" value="100" />
          </Skeleton>
          <Skeleton loading>
            <DataCard title="Lorem ipsum" value="100" />
          </Skeleton>
          <Skeleton loading>
            <DataCard title="Lorem ipsum" value="100" />
          </Skeleton>
          <Skeleton loading>
            <DataCard title="Lorem ipsum" value="100" />
          </Skeleton>
          <Skeleton loading>
            <DataCard title="Lorem ipsum" value="100" />
          </Skeleton>
        </Flex>
      </Flex>
    );
  }

  const {
    hitterName,
    startDate,
    shots,
    exitMPH: avgExitMPH,
    distanceFT: avgDistanceFT,
    vLaunchDEG: avgVLaunchDeg,
  } = hitterSessionSummary;

  const formattedStartDate = format(
    parseISO(startDate),
    LOCAL_DATETIME_FORMAT,
    {
      timeZone: LOCAL_TIMEZONE,
    }
  );

  const maxDistance = MiscHelper.getMax(
    hitterSession.map(({ hit: { distanceFT } }) => distanceFT || 0)
  );
  const maxEV = MiscHelper.getMax(
    hitterSession.map(({ hit: { exitMPH } }) => exitMPH || 0)
  );

  return (
    <Flex align="center" wrap="wrap" gap={RADIX.FLEX.GAP.SM}>
      <Flex
        gap={RADIX.FLEX.GAP.MD}
        align="center"
        flexGrow="1"
        width={{ initial: '100%', md: 'auto' }}
      >
        <Avatar
          color={RADIX.COLOR.NEUTRAL}
          fallback={StringHelper.getInitials(hitterName)}
          size="7"
        />
        <Flex direction="column" justify="center" gap={RADIX.FLEX.GAP.XS}>
          <Heading size={RADIX.HEADING.SIZE.LG}>
            {hitterName ?? t('hitters.anonymous')}
          </Heading>
          {/* TODO: Add HitterSide badge here */}
          <Text
            color={RADIX.COLOR.SECONDARY}
          >{`${formattedStartDate} · ${shots} ${t('common.shots')}`}</Text>
        </Flex>
      </Flex>
      <Grid
        columns={{ initial: '2', xs: '3', sm: '4', md: '5' }}
        gap={RADIX.FLEX.GAP.XS}
        width={{ initial: '100%', xs: 'auto' }}
      >
        <DataCard
          title={t('common.avg-ev')}
          value={avgExitMPH ? round(avgExitMPH, 1) : '-'}
          unit="MPH"
        />
        <DataCard
          title={t('common.max-ev')}
          value={maxEV ? round(maxEV, 1) : '-'}
          unit="MPH"
        />
        <DataCard
          title={t('common.avg-distance')}
          value={avgDistanceFT ? round(avgDistanceFT, 1) : '-'}
          unit="FT"
        />
        <DataCard
          title={t('common.max-distance')}
          value={maxDistance ? round(maxDistance, 1) : '-'}
          unit="FT"
        />
        <DataCard
          title={t('common.avg-la')}
          value={avgVLaunchDeg ? `${round(avgVLaunchDeg, 1)}°` : '-'}
        />
      </Grid>
    </Flex>
  );
};

export const HitterSessionHeader = () => {
  const sectionsCx = useContext(SectionsContext);
  const { hitterSessionSummary } = useAnalyticsStore();

  return (
    <>
      <SectionHeader
        header={null}
        breadcrumbs={[
          {
            label: 'main.hitting-reports',
            onClick: () =>
              sectionsCx.tryChangeSection({
                section: SectionName.Analytics,
                subsection: SubSectionName.HittingReports,
                trigger: 'Analytics > breadcrumbs',
              }),
          },
          ...(hitterSessionSummary
            ? [{ label: hitterSessionSummary.hitterName }]
            : []),
        ]}
      />
      <Summary />
    </>
  );
};
