import {
  IHotkeyMapping,
  IRemoteOption,
} from 'lib_ts/interfaces/i-remote-controls';

export enum RemoteDevice {
  Empty = 'Empty',
  Default = 'Default',
  AirTurn104 = 'AirTurn AT-104',
  AirTurn104_Alt = 'AirTurn AT-104 (Alt)',
}

export const DEFAULT_REMOTE_MAPPING: IHotkeyMapping = {
  fire: 'Slash',
  next: 'Period',
  previous: 'Comma',
};

export const DEFAULT_EMPTY_MAPPING: IHotkeyMapping = {
  fire: '',
  next: '',
  previous: '',
};

export const REMOTE_PRESET_OPTIONS: IRemoteOption[] = [
  {
    label: RemoteDevice.Empty,
    value: RemoteDevice.Empty,
    mapping: DEFAULT_EMPTY_MAPPING,
  },
  {
    label: 'Default',
    value: RemoteDevice.Default,
    mapping: DEFAULT_REMOTE_MAPPING,
  },
];
