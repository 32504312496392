import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { MainService } from 'services/main.service';
import slugify from 'slugify';

const ROWS_PER_FILE = 500;

type stringish = string | undefined;

export class StringHelper {
  static classNames(values: stringish[]): string {
    return ArrayHelper.unique(values.filter((v) => v?.trim()))
      .join(' ')
      .trim();
  }

  // strips any accent characters and converts to lower case
  static normalize(value?: string) {
    if (!value) {
      return '';
    }

    return (
      value
        .normalize('NFD')
        // remove all accents
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
    );
  }

  static async saveCsv(
    data: any[],
    options: {
      prefix: string;
      maxRows?: number;
    }
  ) {
    if (data.length === 0) {
      return;
    }

    const maxRows = options.maxRows ?? ROWS_PER_FILE;

    // only one file is needed
    if (data.length <= maxRows) {
      const csvString = await MainService.getInstance().convertJSONToCSV(data);
      const blob = new Blob([csvString], { type: 'text/csv' });

      MiscHelper.saveAs(blob, `${options.prefix}.csv`);
      return;
    }

    /** split rows into separate files to avoid exceeding server payload limits */
    const chunks = ArrayHelper.chunkArray(data, maxRows);

    // split the data into multiple files
    for (let i = 0; i < chunks.length; i++) {
      const csvString = await MainService.getInstance().convertJSONToCSV(
        chunks[i]
      );

      const blob = new Blob([csvString], { type: 'text/csv' });

      const start = i * maxRows + 1;
      const end = Math.min((i + 1) * maxRows, data.length);

      MiscHelper.saveAs(blob, `${options.prefix}_${start}-${end}.csv`);
    }
  }

  static getInitials(name: stringish): string {
    const split = slugify(name ?? '', { strict: true, trim: true }).split('-');

    return split
      .map((s) => (s ? s.substring(0, 1) : '?'))
      .join('')
      .toUpperCase();
  }
}
