import { FT_TO_INCHES, isNumber } from '../classes/math.utilities';
import { IPredictionAccuracy } from '../interfaces/modelling/i-eval-models';
import {
  IMachinePrecision,
  IPerformanceAccuracy,
  IRealMachinePerformance,
  IRealModelPerformance,
} from '../interfaces/modelling/i-real-machine-metric';

const getPercentage = (x: number, good: number, bad: number): number => {
  const result = 1.0 / Math.max(1.0, 1.0 + (good - x) / (good - bad));
  return Math.min(1.0, result);
};

const MACHINE_PRECISION_WEIGHTS: IMachinePrecision = {
  vy: 3.0,
  wy: 1.0,

  plx: 4.0,
  wx: 2.0,
  break_x_ft: 2.0,

  plz: 4.0,
  wz: 2.0,
  break_z_ft: 2.0,
  groups: 0,
  shots: 0,
  plx_gt6in: 0,
  plz_gt6in: 0,
};

const MACHINE_PERFORMANCE_KEYS = Object.keys(MACHINE_PRECISION_WEIGHTS);

export const GOOD_MACHINE_PRECISION: IMachinePrecision = {
  vy: 0.4,
  wy: 100.0,

  plx: 2.5 / FT_TO_INCHES,
  wx: 200.0,
  break_x_ft: (0.8 * 2.5) / FT_TO_INCHES,

  plz: 2.5 / FT_TO_INCHES,
  wz: 200.0,
  break_z_ft: (0.8 * 2.5) / FT_TO_INCHES,
  groups: 0,
  shots: 0,
  plx_gt6in: 0,
  plz_gt6in: 0,
};

export const BAD_MACHINE_PRECISION: IMachinePrecision = {
  vy: 0.7,
  wy: 200.0,

  plx: 4.0 / FT_TO_INCHES,
  wx: 350.0,
  break_x_ft: (0.8 * 5) / FT_TO_INCHES,

  plz: 4.0 / FT_TO_INCHES,
  wz: 350.0,
  break_z_ft: (0.8 * 5) / FT_TO_INCHES,
  groups: 0,
  shots: 0,
  plx_gt6in: 0,
  plz_gt6in: 0,
};

export const MODEL_PERFORMANCE_WEIGHTS: IPredictionAccuracy = {
  vy: 4.0,
  wy: 2.0,

  vx: 1.0,
  wx: 3.0,
  break_x_ft: 3.0,

  vz: 1.0,
  wz: 3.0,
  break_z_ft: 3.0,
};

const MODEL_PERFORMANCE_KEYS = Object.keys(MODEL_PERFORMANCE_WEIGHTS);

export const GOOD_MODEL_PERFORMANCE: IPredictionAccuracy = {
  vy: 0.5,
  wy: 60.0,

  vx: 0.3,
  wx: 100.0,
  break_x_ft: 2.5 / FT_TO_INCHES,

  vz: 0.3,
  wz: 100.0,
  break_z_ft: 2.5 / FT_TO_INCHES,
};

export const BAD_MODEL_PERFORMANCE: IPredictionAccuracy = {
  vy: 1.5,
  wy: 300.0,

  vx: 1.2,
  wx: 500.0,
  break_x_ft: 5 / FT_TO_INCHES,

  vz: 1.2,
  wz: 500.0,
  break_z_ft: 5 / FT_TO_INCHES,
};

export class MachineMetricHelper {
  /** result should be between 0 and 1, higher is better */
  static overallPercent = (p?: IRealModelPerformance): number => {
    const accuracy = this.getPerformancePercent(p?.mean_absolute_err);

    let numer = 0;
    let denom = 0;

    Object.entries(accuracy)
      .filter((m) => MODEL_PERFORMANCE_KEYS.includes(m[0]))
      .forEach(([key, value]) => {
        if (!isNumber(value)) {
          return;
        }

        const factor = (MODEL_PERFORMANCE_WEIGHTS as any)[key];
        numer += factor * (value as number);
        denom += factor;
      });

    if (denom <= 0.001) {
      return 0;
    }

    return numer / denom;
  };

  /** result should be between 0 and 1, higher is better */
  static overallMachinePercent = (p?: IRealMachinePerformance): number => {
    const precision = this.getMachinePercent(p?.mean_stdev);

    let numer = 0.0;
    let denom = 0.0;

    Object.entries(precision)
      .filter((m) => MACHINE_PERFORMANCE_KEYS.includes(m[0]))
      .forEach(([key, value]) => {
        if (!isNumber(value)) {
          return;
        }

        const factor = (MACHINE_PRECISION_WEIGHTS as any)[key];
        numer += factor * (value as number);
        denom += factor;
      });

    if (denom <= 0.001) {
      return 0;
    }

    return numer / denom;
  };

  static getMachinePercent = (
    input: IMachinePrecision | undefined
  ): IMachinePrecision => {
    const DEFAULT: IMachinePrecision = {
      plx: 0,
      plz: 0,
      vy: 0,
      wx: 0,
      wy: 0,
      wz: 0,
      break_x_ft: 0,
      break_z_ft: 0,
      groups: 0,
      shots: 0,
      plx_gt6in: 0,
      plz_gt6in: 0,
    };

    if (!input) {
      return DEFAULT;
    }

    const output: IMachinePrecision = {
      ...input,
      plx: getPercentage(
        input.plx,
        GOOD_MACHINE_PRECISION.plx,
        BAD_MACHINE_PRECISION.plx
      ),
      plz: getPercentage(
        input.plz,
        GOOD_MACHINE_PRECISION.plz,
        BAD_MACHINE_PRECISION.plz
      ),
      vy: getPercentage(
        input.vy,
        GOOD_MACHINE_PRECISION.vy,
        BAD_MACHINE_PRECISION.vy
      ),
      wx: getPercentage(
        input.wx,
        GOOD_MACHINE_PRECISION.wx,
        BAD_MACHINE_PRECISION.wx
      ),
      wy: getPercentage(
        input.wy,
        GOOD_MACHINE_PRECISION.wy,
        BAD_MACHINE_PRECISION.wy
      ),
      wz: getPercentage(
        input.wz,
        GOOD_MACHINE_PRECISION.wz,
        BAD_MACHINE_PRECISION.wz
      ),
      break_x_ft: getPercentage(
        input.break_x_ft,
        GOOD_MACHINE_PRECISION.break_x_ft,
        BAD_MACHINE_PRECISION.break_x_ft
      ),
      break_z_ft: getPercentage(
        input.break_z_ft,
        GOOD_MACHINE_PRECISION.break_z_ft,
        BAD_MACHINE_PRECISION.break_z_ft
      ),
    };

    return output;
  };

  /** result should be between 0 and 1, higher is better */
  static overallModelPercent = (p?: IRealModelPerformance): number => {
    const precision = this.getPerformancePercent(p?.mean_absolute_err);

    let numer = 0.0;
    let denom = 0.0;

    Object.entries(precision)
      .filter((m) => MODEL_PERFORMANCE_KEYS.includes(m[0]))
      .forEach(([key, value]) => {
        if (!isNumber(value)) {
          return;
        }

        const factor = (MODEL_PERFORMANCE_WEIGHTS as any)[key];
        numer += factor * (value as number);
        denom += factor;
      });

    if (denom <= 0.001) {
      return 0;
    }

    return numer / denom;
  };

  static getPerformancePercent = (
    input: IPerformanceAccuracy | undefined
  ): IPerformanceAccuracy => {
    const DEFAULT: IPerformanceAccuracy = {
      vx: 0,
      vy: 0,
      vz: 0,
      wx: 0,
      wy: 0,
      wz: 0,
      break_x_ft: 0,
      break_z_ft: 0,
      groups: 0,
      shots: 0,
    };

    if (!input) {
      return DEFAULT;
    }

    const output: IPerformanceAccuracy = {
      vx: getPercentage(
        input.vx,
        GOOD_MODEL_PERFORMANCE.vx,
        BAD_MODEL_PERFORMANCE.vx
      ),
      vy: getPercentage(
        input.vy,
        GOOD_MODEL_PERFORMANCE.vy,
        BAD_MODEL_PERFORMANCE.vy
      ),
      vz: getPercentage(
        input.vz,
        GOOD_MODEL_PERFORMANCE.vz,
        BAD_MODEL_PERFORMANCE.vz
      ),
      wx: getPercentage(
        input.wx,
        GOOD_MODEL_PERFORMANCE.wx,
        BAD_MODEL_PERFORMANCE.wx
      ),
      wy: getPercentage(
        input.wy,
        GOOD_MODEL_PERFORMANCE.wy,
        BAD_MODEL_PERFORMANCE.wy
      ),
      wz: getPercentage(
        input.wz,
        GOOD_MODEL_PERFORMANCE.wz,
        BAD_MODEL_PERFORMANCE.wz
      ),
      break_x_ft: getPercentage(
        input.break_x_ft,
        GOOD_MODEL_PERFORMANCE.break_x_ft,
        BAD_MODEL_PERFORMANCE.break_x_ft
      ),
      break_z_ft: getPercentage(
        input.break_z_ft,
        GOOD_MODEL_PERFORMANCE.break_z_ft,
        BAD_MODEL_PERFORMANCE.break_z_ft
      ),
      groups: input.groups,
      shots: input.shots,
    };

    return output;
  };
}
