import { Auth0Provider } from '@auth0/auth0-react';
import { MainHoC } from 'components/main';
import env from 'config';
import { AuthProvider } from 'contexts/auth.context';
import { CookiesContext, CookiesProvider } from 'contexts/cookies.context';
import { GlobalProvider } from 'contexts/global.context';
import { InboxProvider } from 'contexts/inbox';
import { LogRocketProvider } from 'contexts/log-rocket.context';
import { DragDropEngine } from 'enums/cookies.enums';
import { isAppearanceDark, safeAppearance, setAppearance } from 'index';
import { RadixAppearance } from 'lib_ts/enums/radix-ui';
import { ReactNode, useContext, useEffect, useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { withTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import './translations/i18n';

const DragAndDropHoC = (props: { children: ReactNode }) => {
  const { app } = useContext(CookiesContext);

  const options = useMemo(() => {
    const isTouch = app.drag_drop_engine === DragDropEngine.touch;

    if (isTouch) {
      return {
        backend: TouchBackend,
        // options: {
        //   delayTouchStart: 3_000,
        // },
      };
    }

    return {
      backend: HTML5Backend,
    };
  }, [app.drag_drop_engine]);

  return (
    <DndProvider key={app.drag_drop_engine} {...options}>
      {props.children}
    </DndProvider>
  );
};

const App = () => {
  if (!env.debug_messages) {
    console.debug = () => {};
  }

  if (!env.production) {
    console.warn(
      'Render events will be logged twice when running in dev mode (see React.StrictMode)'
    );
  }

  const toggleAppearanceHotkey = (event: KeyboardEvent) => {
    if (event.code === 'F8') {
      event.preventDefault();
      event.stopPropagation();

      const isDark = isAppearanceDark();

      setAppearance({
        value: isDark ? RadixAppearance.Light : RadixAppearance.Dark,
        autoReload: true,
      });
    }
  };

  useEffect(() => {
    document.body.setAttribute('class', `radix-themes ${safeAppearance()}`);
    document.addEventListener('keydown', toggleAppearanceHotkey);
  }, []);

  return (
    <BrowserRouter>
      <Auth0Provider
        domain={env.integrations.auth0.domain}
        clientId={env.integrations.auth0.clientId}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: env.integrations.auth0.audience,
        }}
      >
        <CookiesProvider>
          <AuthProvider>
            <GlobalProvider>
              <InboxProvider>
                <DragAndDropHoC>
                  <MainHoC />
                  {env.identifier !== 'local' && <LogRocketProvider />}
                </DragAndDropHoC>
              </InboxProvider>
            </GlobalProvider>
          </AuthProvider>
        </CookiesProvider>
      </Auth0Provider>
    </BrowserRouter>
  );
};

export default withTranslation()(App);
