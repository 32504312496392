import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { CommonCallout } from 'components/common/callouts';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { TableContext } from 'components/common/table/context';
import { MachineContext } from 'contexts/machine.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitch } from 'lib_ts/interfaces/pitches';
import { useContext } from 'react';
import ReactMarkdown from 'react-markdown';

const COMPONENT_NAME = 'ResetTrainingDialog';

interface IProps {
  pitches: IPitch[];
  onClose: (success: boolean) => void;
}

export const ResetTrainingDialog = (props: IProps) => {
  const isSingle = props.pitches.length === 1;

  const { machine, lastPitchID, resetMSHash } = useContext(MachineContext);
  const { archiveShots, updateHashes } = useContext(MatchingShotsContext);
  const { updateTableKey } = useContext(TableContext);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonConfirmationDialog
        identifier={COMPONENT_NAME}
        title="common.reset-training-data"
        content={
          <>
            <ReactMarkdown
              children={[
                t('pl.confirm-reset-x', {
                  x: t(
                    isSingle ? 'common.pitch' : 'common.pitches'
                  ).toLowerCase(),
                }),
                PitchListHelper.enumeratePitches(props.pitches, 5),
              ].join('\n\n')}
            />
            <CommonCallout text_md={t('pl.warning-reset-impact').toString()} />
          </>
        }
        action={{
          label: 'common.continue',
          color: RADIX.COLOR.WARNING,
          onClick: async () => {
            const hashes = ArrayHelper.unique(
              props.pitches.map(
                (p) => getMSFromMSDict(p, machine).ms?.matching_hash ?? ''
              )
            );

            if (hashes.length === 0) {
              props.onClose(false);
              return;
            }

            const success = await archiveShots({
              machineID: machine.machineID,
              ball_type: machine.ball_type,
              matching_hashes: hashes,
            });

            if (!success) {
              props.onClose(false);
              return;
            }

            // reload shots for the hashes since the archive would have reset the dictionary
            await updateHashes({
              matching_hashes: hashes,
              includeHitterPresent: false,
              includeLowConfidence: true,
            });

            updateTableKey();

            if (props.pitches.findIndex((p) => p._id === lastPitchID) !== -1) {
              resetMSHash();
            }

            props.onClose(true);
          },
        }}
      />
    </ErrorBoundary>
  );
};
