import { STRIKEZONE } from 'components/common/trajectory-view/helpers/constants';
import dirtImg from 'components/common/trajectory-view/img/dirt_dark.png';
import grassImg from 'components/common/trajectory-view/img/grass_dark.png';
import greenRect from 'components/common/trajectory-view/img/green_rect.png';
import skyImg from 'components/common/trajectory-view/img/nighttime.png';
import strike_zone from 'components/common/trajectory-view/img/strike_zone3d_bw.png';
import {
  BackSide,
  BoxGeometry,
  Color,
  Group,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  ObjectLoader,
  RepeatWrapping,
  TextureLoader,
  Event as ThreeEvent,
} from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

const loader = new GLTFLoader();

/** loads ball, adds it to scene, returns the ball (e.g. for engine to track) */
export const loadMaterialBall = (scene: Object3D<ThreeEvent>) => {
  return new Promise<Group>((resolve) => {
    loader.load(
      process.env.PUBLIC_URL + '/glb/smallest_ball.glb',
      (gltf) => {
        gltf.scene.traverse((child) => {
          if (child instanceof Mesh) {
            child.material.emissive = new Color(0.35, 0.35, 0.35);
            child.material.emissiveIntensity = 0.35;
            child.castShadow = true;
            child.receiveShadow = true;
          }
        });

        scene.add(gltf.scene);

        resolve(gltf.scene);
      },
      undefined,
      (error) => {
        console.error(error);
      }
    );
  });
};

/** loads strike zone, adds it to scene */
export const loadStrikeZone = (scene: Object3D<ThreeEvent>) => {
  return new Promise<void>((resolve) => {
    const szgeo = new BoxGeometry(1.66, 2.15, 0.01);
    const sztexture = new TextureLoader().load(strike_zone);
    const greentexture = new TextureLoader().load(greenRect);
    const szmat = [
      new MeshBasicMaterial({
        map: greentexture,
        opacity: 0.0,
        transparent: true,
      }),
      new MeshBasicMaterial({
        map: greentexture,
        opacity: 0.0,
        transparent: true,
      }),
      new MeshBasicMaterial({
        map: greentexture,
        opacity: 0.0,
        transparent: true,
      }),
      new MeshBasicMaterial({
        map: greentexture,
        opacity: 0.0,
        transparent: true,
      }),
      new MeshBasicMaterial({
        map: sztexture,
        opacity: 0.5,
        transparent: true,
      }),
      new MeshBasicMaterial({
        map: sztexture,
        opacity: 0.5,
        transparent: true,
      }),
    ];

    const szRect = new Mesh(szgeo, szmat);

    szRect.scale.y = 1;
    szRect.position.y = STRIKEZONE.Y;
    szRect.position.z = STRIKEZONE.Z;

    scene.add(szRect);

    resolve();
  });
};

export const addSceneTextures = (scene: any) => {
  try {
    const skyTexture = new TextureLoader().load(skyImg);
    const skyMaterial = new MeshBasicMaterial({
      map: skyTexture,
      side: BackSide,
    });
    // const fenceTexture = new TextureLoader().load(fenceImg);
    // fenceTexture.wrapS = RepeatWrapping;
    // fenceTexture.wrapT = RepeatWrapping;
    // fenceTexture.repeat.set(50, 1);
    // const fenceMaterial = new MeshBasicMaterial({
    //   map: fenceTexture,
    //   side: BackSide,
    // });
    const dirtTexture = new TextureLoader().load(dirtImg);
    dirtTexture.wrapS = RepeatWrapping;
    dirtTexture.wrapT = RepeatWrapping;
    dirtTexture.repeat.set(5, 5);
    const dirtMaterial = new MeshBasicMaterial({
      map: dirtTexture,
    });
    const dirtTextureBack = new TextureLoader().load(dirtImg);
    dirtTextureBack.wrapS = RepeatWrapping;
    dirtTextureBack.wrapT = RepeatWrapping;
    dirtTextureBack.repeat.set(5, 5);
    const dirtMaterialBack = new MeshBasicMaterial({
      map: dirtTextureBack,
      side: BackSide,
    });
    const dirtTexture2 = new TextureLoader().load(dirtImg);
    dirtTexture2.wrapS = RepeatWrapping;
    dirtTexture2.wrapT = RepeatWrapping;
    dirtTexture2.repeat.set(1, 10);
    const dirtMaterial2 = new MeshBasicMaterial({
      map: dirtTexture2,
    });
    const grassTexture = new TextureLoader().load(grassImg);
    grassTexture.wrapS = RepeatWrapping;
    grassTexture.wrapT = RepeatWrapping;
    grassTexture.repeat.set(40, 40);
    const grassMaterial = new MeshBasicMaterial({
      map: grassTexture,
    });
    const skydome = scene.getObjectByName('SkyDome');
    skydome.material = skyMaterial;
    // skydome.material.color = new Color(0x5959ff);
    // const fence = scene.getObjectByName('Fence');
    // fence.material = fenceMaterial;
    // fence.material.color = new Color(0x444444);
    const grass = scene.getObjectByName('Grass');
    grass.material = grassMaterial;
    const pitcherMound = scene.getObjectByName('PitcherMound');
    pitcherMound.material = dirtMaterial;
    const firstBaseMound = scene.getObjectByName('FirstBaseMound');
    firstBaseMound.material = dirtMaterialBack;
    const secondBaseMound = scene.getObjectByName('SecondBaseMound');
    secondBaseMound.material = dirtMaterialBack;
    const thirdBaseMound = scene.getObjectByName('ThirdBaseMound');
    thirdBaseMound.material = dirtMaterialBack;
    const dirtFirstToSecond = scene.getObjectByName('DirtFirstToSecond');
    dirtFirstToSecond.material = dirtMaterial;
    const dirtSecondToThird = scene.getObjectByName('DirtSecondToThird');
    dirtSecondToThird.material = dirtMaterial;
    const outfield = scene.getObjectByName('OutfieldDirt');
    outfield.material = dirtMaterial;
    const dirtNearFirst = scene.getObjectByName('DirtPatchNearFirst');
    dirtNearFirst.material = dirtMaterial;
    const dirtNearThird = scene.getObjectByName('DirtPatchNearThird');
    dirtNearThird.material = dirtMaterial;
    const battersMound = scene.getObjectByName('BattersMound');
    battersMound.material = dirtMaterial;
    const dirtToFirst = scene.getObjectByName('DirtToFirst');
    dirtToFirst.material = dirtMaterial2;
    const dirtToThird = scene.getObjectByName('DirtToThird');
    dirtToThird.material = dirtMaterial2;
    const plateSquare = scene.getObjectByName('PlateSquare');
    plateSquare.material.color = new Color(0xffffff);
    const plateTip = scene.getObjectByName('PlateTip');
    plateTip.material.color = new Color(0xffffff);
    const lathe = scene.getObjectByName('Lathe');
    // scene.remove(fence);
    scene.remove(lathe);
  } catch (e) {
    console.error(e);
    console.error('TRAJ VIEW: failed to add scene textures');
  }
};

export const loadColors = () => {
  return new Promise<Object3D<ThreeEvent> | undefined>((resolve) => {
    const loader = new ObjectLoader();
    loader.load(
      process.env.PUBLIC_URL + '/json/scene_flat_colors_5.json',
      (result) => {
        resolve(result);
      },
      undefined,
      (error) => {
        console.error(error);
        resolve(undefined);
      }
    );
  });
};
