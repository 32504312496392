import { Badge, Box, Card, Flex, Heading, Text } from '@radix-ui/themes';
import { CommonRadio } from 'components/common/radio';
import { PresetTrainingContext } from 'components/machine/dialogs/preset-training/context';
import { PresetForm } from 'components/machine/dialogs/preset-training/controls/preset-form';
import { AuthContext } from 'contexts/auth.context';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { PresetTrainingMode } from 'lib_ts/enums/training.enums';
import { useCallback, useContext } from 'react';

const tPresetMode = (mode: PresetTrainingMode) => {
  switch (mode) {
    case PresetTrainingMode.Balanced: {
      return t('tr.preset-balanced');
    }
    case PresetTrainingMode.Custom: {
      return t('tr.preset-custom');
    }
    case PresetTrainingMode.Precision: {
      return t('tr.preset-precision');
    }
    case PresetTrainingMode.Quick: {
      return t('tr.preset-quick');
    }
    default: {
      return mode;
    }
  }
};

const tPresetDescription = (mode: PresetTrainingMode) => {
  switch (mode) {
    case PresetTrainingMode.Balanced: {
      return t('tr.preset-balanced-desc');
    }
    case PresetTrainingMode.Custom: {
      return t('tr.preset-custom-desc');
    }
    case PresetTrainingMode.Precision: {
      return t('tr.preset-precision-desc');
    }
    case PresetTrainingMode.Quick: {
      return t('tr.preset-quick-desc');
    }
    default: {
      return;
    }
  }
};

export const PresetSelector = () => {
  const { updateUser } = useContext(AuthContext);
  const { active, selectPreset, options } = useContext(PresetTrainingContext);

  const selectMode = useCallback(
    (mode: PresetTrainingMode) => {
      if (mode === active.mode) {
        return;
      }

      const preset = options.find((p) => p.mode === mode);

      if (!preset) {
        return;
      }

      selectPreset(preset);

      // save the selected spec to the user record
      updateUser({ preset_training_mode: preset.mode }, true);
    },
    [updateUser, active.mode]
  );

  return (
    <Flex flexGrow="1" direction="column" gap={RADIX.FLEX.GAP.SM}>
      {options.map((preset, i) => {
        const isActive = active.mode === preset.mode;

        return (
          <Card key={`pt-preset-${i}`} onClick={() => selectMode(preset.mode)}>
            <Flex gap="1">
              {/* radio box + gutter */}
              <Box
                style={{
                  paddingTop: '2px',
                  minWidth: '19px',
                }}
              >
                <CommonRadio
                  id="precision-training-priority"
                  name="priority"
                  options={[
                    {
                      label: '',
                      value: preset.mode,
                    },
                  ]}
                  value={active.mode}
                  onChange={() => selectMode(preset.mode)}
                />
              </Box>

              {/* header and controls and form */}
              <Flex flexGrow="1" direction="column">
                {/* header */}
                <Flex gap="1">
                  <Flex
                    flexGrow="1"
                    gap="1"
                    justify="between"
                    className="cursor-pointer"
                    onClick={() => selectMode(preset.mode)}
                  >
                    <Heading
                      color={isActive ? RADIX.COLOR.ACCENT : undefined}
                      size="3"
                      truncate
                    >
                      {tPresetMode(preset.mode)}
                    </Heading>
                    {preset.minPerPitch && (
                      <Badge
                        color={
                          isActive ? RADIX.COLOR.ACCENT : RADIX.COLOR.NEUTRAL
                        }
                      >
                        {t('tr.x-min-per-pitch', {
                          x: preset.minPerPitch,
                        })}
                      </Badge>
                    )}
                  </Flex>
                </Flex>

                {/* description */}
                <Box mt="1">
                  <Text color={RADIX.COLOR.SECONDARY}>
                    {tPresetDescription(preset.mode)}
                  </Text>
                </Box>

                {/* form */}
                {preset.showControls && (
                  <Box mt="3">
                    <PresetForm disabled={!isActive} preset={preset} />
                  </Box>
                )}
              </Flex>
            </Flex>
          </Card>
        );
      })}
    </Flex>
  );
};
