import { NotifyHelper } from 'classes/helpers/notify.helper';
import { IErrorType } from 'lib_ts/interfaces/common/i-error-type';
import { IServerResponse } from 'lib_ts/interfaces/common/i-server-response';
import { BaseRESTService } from 'services/_base-rest.service';

export class AdminErrorTypesService extends BaseRESTService {
  private static instance: AdminErrorTypesService;
  static getInstance(): AdminErrorTypesService {
    if (!AdminErrorTypesService.instance) {
      AdminErrorTypesService.instance = new AdminErrorTypesService();
    }

    return AdminErrorTypesService.instance;
  }

  private constructor() {
    super({
      controller: 'admin-error-types',
    });
  }

  async getAll(): Promise<IErrorType[]> {
    return await this.get({ uri: '' });
  }

  async create(data: Partial<IErrorType>): Promise<IServerResponse> {
    return await this.post(
      {
        uri: '',
      },
      data
    );
  }

  async update(data: Partial<IErrorType>): Promise<IServerResponse> {
    if (!data._id) {
      throw new Error('Cannot put without id');
    }

    return await this.put(
      {
        uri: data._id,
      },
      data
    );
  }

  async importJSON(files: File[]): Promise<boolean> {
    /** append the files */
    const formData = new FormData();
    files.forEach((f) => formData.append('files', f));

    return await this.post(
      {
        uri: 'import/json',
        headers: { 'Content-Type': 'multipart/form-data' },
      },
      formData
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'Unknown error importing error types.',
          });
          return false;
        }

        NotifyHelper.success({
          message_md: 'Successfully imported error types',
        });

        return true;
      })
      .catch((reason) => {
        console.error(reason);

        NotifyHelper.error({
          message_md: 'Unknown error importing error types.',
        });

        return false;
      });
  }
}
