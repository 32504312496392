import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from '@radix-ui/react-icons';
import { Box, Flex } from '@radix-ui/themes';
import { TableContext } from 'components/common/table/context';
import { CommonTablePaginationButton } from 'components/common/table/pagination-button';
import { CheckedContext } from 'contexts/layout/checked.context';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext } from 'react';

export const PaginationSelector = (props: {
  pageSize: number;
  total: number;
}) => {
  const tableCx = useContext(TableContext);
  const checkedCx = useContext(CheckedContext);

  const isFirst = tableCx.selected.page === 0;
  const isLast = tableCx.selected.page === tableCx.maxPage;

  const startIndex = (tableCx.selected.page ?? 0) * props.pageSize + 1;
  const endIndex =
    startIndex + Math.min(props.pageSize, tableCx.pageData.length) - 1;

  const summary =
    tableCx.pageData.length === 0
      ? t('common.no-data')
      : t('common.table-rows-summary', {
          first: startIndex,
          last: endIndex,
          total: props.total,
        });

  const resetLastCheckedIndex = () => {
    if (checkedCx) {
      checkedCx.setLastCheckedIndex(null);
    }
  };

  return (
    <Flex gap={RADIX.FLEX.GAP.MD}>
      <Flex gap={RADIX.FLEX.GAP.SM}>
        <CommonTablePaginationButton
          disabled={isFirst}
          tooltip={t('common.first-page').toString()}
          onClick={() => {
            tableCx.setSelected({ page: 0 });
            resetLastCheckedIndex();
          }}
          icon={<DoubleArrowLeftIcon />}
        />

        <CommonTablePaginationButton
          disabled={isFirst}
          tooltip={t('common.previous-page').toString()}
          onClick={() => {
            tableCx.setSelected({ page: tableCx.selected.page - 1 });
            resetLastCheckedIndex();
          }}
          icon={<ChevronLeftIcon />}
        />
      </Flex>

      <Box className="valign-center">{summary}</Box>

      <Flex gap={RADIX.FLEX.GAP.XS}>
        <CommonTablePaginationButton
          disabled={isLast}
          tooltip={t('common.next-page').toString()}
          onClick={() => {
            tableCx.setSelected({ page: tableCx.selected.page + 1 });
            resetLastCheckedIndex();
          }}
          icon={<ChevronRightIcon />}
        />

        <CommonTablePaginationButton
          disabled={isLast}
          tooltip={t('common.last-page').toString()}
          onClick={() => {
            tableCx.setSelected({ page: tableCx.maxPage });
            resetLastCheckedIndex();
          }}
          icon={<DoubleArrowRightIcon />}
        />
      </Flex>
    </Flex>
  );
};
