import { DownloadIcon } from '@radix-ui/react-icons';
import { Box, Flex, Spinner } from '@radix-ui/themes';
import { CommonTableButton } from 'components/common/table/button';
import { VideosContext } from 'contexts/videos/videos.context';
import { lightFormat } from 'date-fns';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useCallback, useContext } from 'react';
import { VideosService } from 'services/videos.service';

export const VideoExtraActions = () => {
  const videosCx = useContext(VideosContext);

  const exportCSVs = useCallback(() => {
    const ids = videosCx.filteredVideos.map((v) => v._id);

    VideosService.getInstance()
      .exportCSV(ids)
      .then((csvString) => {
        const blob = new Blob([csvString], { type: 'text/csv' });
        MiscHelper.saveAs(
          blob,
          `video-library_all_${lightFormat(new Date(), 'yyyy-MM-dd')}.csv`
        );
      });
  }, [videosCx.filteredVideos]);

  return (
    <Flex gap={RADIX.FLEX.GAP.SM}>
      <Box flexGrow="1">{videosCx.loading && <Spinner />}</Box>

      <CommonTableButton
        icon={<DownloadIcon />}
        label="common.export-all"
        onClick={exportCSVs}
      />
    </Flex>
  );
};
