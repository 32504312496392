import { IVideo } from '../interfaces/i-video';
import { IPosition } from '../interfaces/pitches/i-base';
import { isNumber } from './math.utilities';

/** earliest in any video that we allow the release time to be */
const MIN_RELEASE_TIME_SEC = 1;

/** min frame in any video that we allow the release frame to be */
const MIN_RELEASE_FRAME = 30;

/** max difference between release time and expected release time after converting release frame to seconds using video fps */
const MAX_DELTA_RELEASE_TIME_FRAME_SEC = 0.5;

const VALIDATION_TOLERANCE = {
  /** feet */
  X: { value: 2, text: '2 ft' },
  /** feet */
  Z: { value: 1, text: '1 ft' },
};

export class VideoHelper {
  /** returns a list of errors (if any) that prevent the video from being used, empty list if there are no issues detected */
  static getErrors = (video: Partial<IVideo>): string[] => {
    const errors: string[] = [];

    VideoHelper.getCompletenessErrors(video, errors);
    VideoHelper.getValidityErrors(video as IVideo, errors);

    return errors;
  };

  private static getCompletenessErrors(
    video: Partial<IVideo>,
    errors: string[]
  ) {
    try {
      if (!video.ffmpeg) {
        errors.push('FFMPEG metadata is not defined');
      }

      if (!isNumber(video.n_frames)) {
        errors.push('Total frames is not a number');
      } else if (video.n_frames === 0) {
        errors.push('Total frames is zero');
      }

      if (!isNumber(video.ReleasePixelX)) {
        errors.push('Release Pixel X is not a number');
      }

      if (!isNumber(video.ReleasePixelY)) {
        errors.push('Release Pixel Y is not a number');
      }

      if (!isNumber(video.MoundPixelX)) {
        errors.push('Mound Pixel X is not a number');
      }

      if (!isNumber(video.MoundPixelY)) {
        errors.push('Mound Pixel Y is not a number');
      }

      const parsedRT = parseFloat(video.ReleaseTime as any);
      if (!isNumber(parsedRT)) {
        errors.push('Release Time is not a number');
      }

      if (!isNumber(video.ReleaseSide)) {
        errors.push('Release Side is not a number');
      }

      if (!isNumber(video.ReleaseHeight)) {
        errors.push('Release Height is not a number');
      }
    } catch (e) {
      errors.push(
        e instanceof Error
          ? e.message
          : 'Unknown error while checking video completeness'
      );
    }
  }

  private static getValidityErrors(video: IVideo, errors: string[]) {
    try {
      if (
        video.ReleasePixelY < 0 ||
        video.ReleasePixelY > video.ffmpeg.video.resolution.h
      ) {
        errors.push(
          `Release Pixel Y (${video.ReleasePixelY}) is not between 0 and ${video.ffmpeg.video.resolution.h}`
        );
      }

      if (
        video.ReleasePixelX < 0 ||
        video.ReleasePixelX > video.ffmpeg.video.resolution.w
      ) {
        errors.push(
          `Release Pixel X (${video.ReleasePixelX}) is not between 0 and ${video.ffmpeg.video.resolution.w}`
        );
      }

      if (
        video.MoundPixelY < 0 ||
        video.MoundPixelY > video.ffmpeg.video.resolution.h
      ) {
        errors.push(
          `Mound Pixel Y (${video.MoundPixelY}) is not between 0 and ${video.ffmpeg.video.resolution.h}`
        );
      }

      if (
        video.MoundPixelX < 0 ||
        video.MoundPixelX > video.ffmpeg.video.resolution.w
      ) {
        errors.push(
          `Mound Pixel X (${video.MoundPixelX}) is not between 0 and ${video.ffmpeg.video.resolution.w}`
        );
      }

      if (video.ReleasePixelY > video.MoundPixelY) {
        errors.push(
          `Release Pixel Y (${video.ReleasePixelY}) is greater than Mound Pixel Y (${video.MoundPixelY})`
        );
      }

      if (video.ReleaseTime < MIN_RELEASE_TIME_SEC) {
        errors.push(
          `Release Time (${video.ReleaseTime.toFixed(
            3
          )}s) is less than the minimum (${MIN_RELEASE_TIME_SEC}s)`
        );
      }

      if (
        Math.abs(
          video.ReleaseFrame / video.ffmpeg.video.fps - video.ReleaseTime
        ) > MAX_DELTA_RELEASE_TIME_FRAME_SEC
      ) {
        errors.push(
          `Release Time (${video.ReleaseTime.toFixed(
            3
          )}s) is inconsistent with Release Frame (${
            video.ReleaseFrame
          }) and FPS (${video.ffmpeg.video.fps})`
        );
      }

      if (video.ReleaseFrame > video.n_frames) {
        errors.push(
          `Release Frame (${video.ReleaseFrame}) is greater than the max value (${video.n_frames})`
        );
      }

      if (video.ReleaseFrame < MIN_RELEASE_FRAME) {
        errors.push(
          `Release Frame (${video.ReleaseFrame}) is less than the min value (${MIN_RELEASE_FRAME})`
        );
      }
    } catch (e) {
      errors.push(
        e instanceof Error
          ? e.message
          : 'Unknown error while checking video validity'
      );
    }
  }

  /** returns warnings (e.g. when there's a large discrepancy between video release and pitch release) */
  static validateSelection(config: {
    position: IPosition;
    video: IVideo;
    /** used for errors if provided */
    pitch_name?: string;
  }): string[] {
    const output: string[] = [];

    if (
      Math.abs(config.position.px - config.video.ReleaseSide) >
      VALIDATION_TOLERANCE.X.value
    ) {
      output.push(
        `Release side (${config.position.px.toFixed(1)} ft) ${
          config.pitch_name ? `for "${config.pitch_name}" ` : ''
        }is more than ${
          VALIDATION_TOLERANCE.X.text
        } off of video release side (${config.video.ReleaseSide.toFixed(
          1
        )} ft).`
      );
    }

    if (
      Math.abs(config.position.pz - config.video.ReleaseHeight) >
      VALIDATION_TOLERANCE.Z.value
    ) {
      output.push(
        `Release height (${config.position.pz.toFixed(1)} ft) ${
          config.pitch_name ? `for "${config.pitch_name}" ` : ''
        }is more than ${
          VALIDATION_TOLERANCE.Z.text
        } off of video release height (${config.video.ReleaseHeight.toFixed(
          1
        )} ft).`
      );
    }

    return output;
  }
}
