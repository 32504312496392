import { CustomIcon } from 'components/common/custom-icon';
import { CustomIconPath } from 'enums/custom.enums';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';

export const BetaIcon = () => (
  <CustomIcon
    title="BETA"
    icon={CustomIconPath.BetaBeaker}
    color={RADIX.COLOR.BETA}
  />
);

export const VoidIcon = () => <CustomIcon icon={CustomIconPath.Void} />;

export const SuperAdminIcon = () => (
  <CustomIcon
    title="Super Admin"
    icon={CustomIconPath.SuperAdmin}
    color={RADIX.COLOR.SUPER_ADMIN}
  />
);

export const AdminIcon = () => (
  <CustomIcon
    title="Team Admin"
    icon={CustomIconPath.TeamAdmin}
    color={RADIX.COLOR.ANY_ADMIN}
  />
);

export const LowerMachineIcon = () => (
  <CustomIcon
    title={t('common.lower-machine').toString()}
    icon={CustomIconPath.LowerMachine}
  />
);

export const R2FIcon = () => (
  <CustomIcon
    title={t('common.ready-status').toString()}
    icon={CustomIconPath.R2F}
  />
);

export const BaseballIcon = () => <CustomIcon icon={CustomIconPath.Baseball} />;

export const HelmetIcon = () => <CustomIcon icon={CustomIconPath.Helmet} />;
