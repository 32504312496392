import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Table,
  Text,
} from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSelectInput } from 'components/common/form/select';
import { GameStatus } from 'lib_ts/enums/mlb.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';
import { AuthService } from 'services/auth.service';

interface IProps {}

interface IState {
  /** whether the server will check game status */
  enabled?: boolean;

  /** what (if anything) the server is overriding game status results with */
  override: GameStatus;
}

export class GameStatusControls extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    this.state = {
      override: GameStatus.Unknown,
    };
  }

  componentDidMount(): void {
    if (this.init) {
      return;
    }

    this.init = true;

    Promise.all([
      AuthService.getInstance().getCheckGameStatus(),
      AuthService.getInstance().getGameStatusOverride(),
    ]).then(([enabled, override]) => {
      this.setState({
        enabled: enabled,
        override: override,
      });
    });
  }

  render() {
    return (
      <ErrorBoundary componentName="GameStatusControls">
        <Table.Root>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell width="200px" align="center">
                Actions
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell width="100px" align="center">
                Current
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Description</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              {/* actions */}
              <Table.Cell align="center">
                <Flex
                  direction="column"
                  gap={RADIX.FLEX.GAP.SM}
                  pt={RADIX.FLEX.PAD.SM}
                  pb={RADIX.FLEX.PAD.SM}
                >
                  <Box>
                    <Button
                      color={
                        this.state.enabled
                          ? RADIX.COLOR.WARNING
                          : RADIX.COLOR.SUCCESS
                      }
                      size={RADIX.BUTTON.SIZE.SM}
                      className="btn-block"
                      onClick={() =>
                        AuthService.getInstance()
                          .toggleCheckGameStatus()
                          .then((enabled) => {
                            if (enabled !== undefined) {
                              NotifyHelper.success({
                                message_md: `Check game status ${
                                  enabled ? 'enabled' : 'disabled'
                                }.`,
                              });
                              this.setState({ enabled: enabled });
                            }
                          })
                      }
                    >
                      {this.state.enabled ? 'Disable' : 'Enable'}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      size={RADIX.BUTTON.SIZE.SM}
                      color={RADIX.COLOR.NEUTRAL}
                      className="btn-block"
                      onClick={() =>
                        AuthService.getInstance()
                          .getCheckGameStatus()
                          .then((status) => {
                            NotifyHelper.success({
                              message_md: status
                                ? 'Server is checking game status.'
                                : 'Server is not checking game status.',
                            });
                          })
                      }
                    >
                      Query
                    </Button>
                  </Box>
                </Flex>
              </Table.Cell>
              {/* current */}
              <Table.Cell align="center">
                <Badge
                  color={this.state.enabled ? RADIX.COLOR.SUCCESS : undefined}
                >
                  {this.state.enabled ? 'Enabled' : 'Disabled'}
                </Badge>
              </Table.Cell>
              {/* description */}
              <Table.Cell>
                <Flex
                  p={RADIX.FLEX.PAD.SM}
                  direction="column"
                  gap={RADIX.FLEX.GAP.SM}
                >
                  <Heading size={RADIX.HEADING.SIZE.SM}>
                    Game Status Checks
                  </Heading>

                  <Box>
                    <Text wrap="pretty">
                      This controls whether the server is contacting the Batrack
                      API to determine whether teams with MLB abbreviations are
                      currently participating in any games (i.e. whether they
                      should be placed in offline mode).
                    </Text>
                  </Box>
                </Flex>
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              {/* actions */}
              <Table.Cell align="center">
                <Flex
                  direction="column"
                  gap={RADIX.FLEX.GAP.SM}
                  pt={RADIX.FLEX.PAD.SM}
                  pb={RADIX.FLEX.PAD.SM}
                >
                  <Box>
                    <CommonSelectInput
                      id="game-status-override"
                      name="override"
                      options={[
                        ...Object.values(GameStatus)
                          .filter((gs) => gs !== GameStatus.Empty)
                          .map((gs) => ({
                            label: gs,
                            value: gs,
                          })),
                      ]}
                      value={this.state.override}
                      onChange={(v) => {
                        const override = v as GameStatus;

                        AuthService.getInstance()
                          .setGameStatusOverride(override)
                          .then((success) => {
                            if (success) {
                              NotifyHelper.success({
                                message_md: override
                                  ? `Game status override set to ${override}.`
                                  : 'Game status override cleared.',
                              });
                              this.setState({ override: override });
                            }
                          });
                      }}
                    />
                  </Box>
                  <Box>
                    <Button
                      size={RADIX.BUTTON.SIZE.SM}
                      color={RADIX.COLOR.NEUTRAL}
                      className="btn-block"
                      onClick={() =>
                        AuthService.getInstance()
                          .getGameStatusOverride()
                          .then((gs) => {
                            NotifyHelper.success({
                              message_md: gs
                                ? `Game status override is currently set to ${gs}.`
                                : 'There is no game status override in effect.',
                            });
                          })
                      }
                    >
                      Query
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      size={RADIX.BUTTON.SIZE.SM}
                      title="Clear any game status override and resume regular behavior."
                      color={RADIX.COLOR.WARNING}
                      className="btn-block"
                      onClick={() =>
                        AuthService.getInstance()
                          .setGameStatusOverride(GameStatus.Empty)
                          .then((success) => {
                            if (success) {
                              NotifyHelper.success({
                                message_md: 'Game status override cleared.',
                              });
                              this.setState({ override: GameStatus.Empty });
                            }
                          })
                      }
                    >
                      Reset
                    </Button>
                  </Box>
                </Flex>
              </Table.Cell>
              {/* current */}
              <Table.Cell align="center">
                <Badge
                  color={this.state.override ? RADIX.COLOR.WARNING : undefined}
                >
                  {this.state.override ?? 'Disabled'}
                </Badge>
              </Table.Cell>
              {/* description */}
              <Table.Cell>
                <Flex
                  p={RADIX.FLEX.PAD.SM}
                  direction="column"
                  gap={RADIX.FLEX.GAP.SM}
                >
                  <Heading size={RADIX.HEADING.SIZE.SM}>
                    Game Status Override
                  </Heading>

                  <Box>
                    <Text wrap="pretty">
                      This controls whether the server should override any game
                      status results from the Batrack API with a static value
                      set here (e.g. for testing purposes).
                    </Text>
                  </Box>
                </Flex>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table.Root>
      </ErrorBoundary>
    );
  }
}
