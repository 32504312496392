import { Box } from '@radix-ui/themes';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { MachineModelsContext } from 'contexts/admin/machine-models.context';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachineModel } from 'lib_ts/interfaces/modelling/i-machine-model';
import React from 'react';

const COMPONENT_NAME = 'DeleteModelsDialog';

interface IProps {
  models: IMachineModel[];
}

interface IState {}

export class ArchiveModelsDialog extends React.Component<IProps, IState> {
  state: IState = {};

  render() {
    const isSingle = this.props.models.length === 1;

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <MachineModelsContext.Consumer>
          {(machineModelsCx) => (
            <CommonConfirmationDialog
              identifier={COMPONENT_NAME}
              maxWidth={RADIX.DIALOG.WIDTH.MD}
              title={isSingle ? 'Archive Model' : 'Archive Models'}
              content={
                <Box>
                  <p>
                    Are you sure you want to archive the following{' '}
                    {isSingle ? 'model' : 'models'}?
                  </p>
                  <ul>
                    {this.props.models.map((m, i) => (
                      <li key={`model-${i}`}>{m.name}</li>
                    ))}
                  </ul>
                </Box>
              }
              action={{
                label: 'common.archive',
                color: RADIX.COLOR.DANGER,
                invisible: this.props.models.length === 0,
                onClick: () => {
                  machineModelsCx.archiveModels(
                    this.props.models.map((u) => u._id)
                  );
                },
              }}
            />
          )}
        </MachineModelsContext.Consumer>
      </ErrorBoundary>
    );
  }
}
