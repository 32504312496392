import { Matrix3, Vector3 } from 'three';
import { IHawkeyeRotMat } from '../interfaces/csv';
import { ISeamOrientation } from '../interfaces/pitches/i-base';
import { BallHelper } from './ball.helper';
import { getRotationMatrix, isNumber, RAD_RIGHT_ANGLE } from './math.utilities';

export class HawkeyeHelper {
  static getMatrix(data: Partial<IHawkeyeRotMat>): Matrix3 | undefined {
    try {
      const isValid = [
        data.Xx,
        data.Xy,
        data.Xz,
        data.Yx,
        data.Yy,
        data.Yz,
        data.Zx,
        data.Zy,
        data.Zz,
      ].every((m) => isNumber(m));

      if (!isValid) {
        throw Error('Hawkeye rotation matrix columns are invalid');
      }

      const safeData = data as IHawkeyeRotMat;

      return new Matrix3().set(
        // row 1
        safeData.Xx,
        safeData.Xy,
        safeData.Xz,
        // row 2
        safeData.Yx,
        safeData.Yy,
        safeData.Yz,
        // row 3
        safeData.Zx,
        safeData.Zy,
        safeData.Zz
      );
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  static getSeamsFromMatrix = (
    mx: Matrix3,
    spinAxis: Vector3
  ): ISeamOrientation => {
    const TR_ROTATION = getRotationMatrix({
      x_rad: RAD_RIGHT_ANGLE,
    });

    const trMatrix = mx.multiply(TR_ROTATION);
    const invTrMatrix = trMatrix.invert();
    const point = spinAxis.applyMatrix3(invTrMatrix);

    return BallHelper.convertPointToSeams(point);
  };
}
