import { NotifyHelper } from 'classes/helpers/notify.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { ContextName, WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { IContextMsg } from 'lib_ts/interfaces/i-machine-msg';
import React from 'react';
import slugify from 'slugify';

const COMPONENT_NAME = 'ServerListener';

interface IProps {
  listenFor: ContextName[];
  callback: (msg: IContextMsg) => void;
}

interface IState {}

export class ServerListener extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.handleContext = this.handleContext.bind(this);
    this.handleCustomResponse = this.handleCustomResponse.bind(this);
  }

  componentDidMount() {
    WebSocketHelper.on(WsMsgType.S2U_Context, this.handleContext);
    WebSocketHelper.on(
      WsMsgType.Misc_CustomResponse,
      this.handleCustomResponse
    );
  }

  componentWillUnmount() {
    WebSocketHelper.remove(WsMsgType.S2U_Context, this.handleContext);
    WebSocketHelper.remove(
      WsMsgType.Misc_CustomResponse,
      this.handleCustomResponse
    );
  }

  private handleContext(event: CustomEvent) {
    const data: IContextMsg = event.detail;

    if (!this.props.listenFor.includes(data.context)) {
      return;
    }

    this.props.callback(data);
  }

  private handleCustomResponse(event: CustomEvent) {
    NotifyHelper.success({
      message_md: `Received custom response from machine!`,
      buttons: [
        {
          label: 'Save Response',
          onClick: () => {
            const filename = `custom-response_${slugify(
              new Date().toISOString(),
              { strict: true }
            )}`;

            MiscHelper.saveAs(
              new Blob([JSON.stringify(event, null, 2)]),
              `${filename}.json`
            );
          },
        },
      ],
    });
  }

  render() {
    return <></>;
  }
}
