import { NotifyHelper } from 'classes/helpers/notify.helper';
import { IServerResponse } from 'lib_ts/interfaces/common/i-server-response';
import { IMachineModel } from 'lib_ts/interfaces/modelling/i-machine-model';
import { BaseRESTService } from 'services/_base-rest.service';

export class UserMachineModelsService extends BaseRESTService {
  private static instance: UserMachineModelsService;
  static getInstance(): UserMachineModelsService {
    if (!UserMachineModelsService.instance) {
      UserMachineModelsService.instance = new UserMachineModelsService();
    }

    return UserMachineModelsService.instance;
  }

  private constructor() {
    super({
      controller: 'machine-models',
    });
  }

  async getModel(id: string): Promise<IMachineModel | undefined> {
    return await this.get({
      uri: id,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md:
              result.error ?? 'There was an error while fetching model.',
          });
          return undefined;
        }

        return result.data as IMachineModel;
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: 'There was an error while fetching model.',
        });
        return undefined;
      });
  }
}
