import { Box, Button, Heading, Strong, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { StringHelper } from 'classes/helpers/string.helper';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormButton } from 'components/common/form/button';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonTextInput } from 'components/common/form/text';
import { CommonTextareaInput } from 'components/common/form/textarea';
import { AuthContext } from 'contexts/auth.context';
import { SessionEventsContext } from 'contexts/session-events.context';
import { t } from 'i18next';
import { IBaseDialog } from 'interfaces/i-dialogs';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  ILoginEvent,
  ILoginEventData,
} from 'lib_ts/interfaces/i-session-event';
import React from 'react';
import { SessionEventsService } from 'services/session-events.service';

const COMPONENT_NAME = 'EditSessionDialog';

interface IProps extends IBaseDialog {
  session: string;
  fires: number;
  onChanged: () => void;
}

interface IState extends Partial<ILoginEvent> {
  loading: boolean;
}

export class EditSessionDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      loading: false,
    };

    this.renderContent = this.renderContent.bind(this);
    this.setData = this.setData.bind(this);
  }

  private init = false;

  componentDidMount() {
    if (this.init) {
      return;
    }

    this.init = true;

    this.setState({ loading: true }, () => {
      SessionEventsService.getLoginEventForSession(this.props.session)
        .then((login) => {
          if (!login) {
            NotifyHelper.warning({
              message_md: `Login event could not be found for session \`${this.props.session}\`.`,
            });

            return;
          }

          this.setState({
            _id: login._id,
            data: login.data ?? {},
          });
        })
        .finally(() => this.setState({ loading: false }));
    });
  }

  private setData(model: Partial<ILoginEventData>) {
    this.setState({
      data: {
        ...this.state.data,
        ...model,
      },
    });
  }

  private renderContent() {
    return (
      <CommonFormGrid columns={3}>
        <AuthContext.Consumer>
          {(authCx) => {
            /** only allow super access when current mode is basic, i.e.  */
            if (
              authCx.current.session === this.props.session &&
              authCx.current.role === UserRole.admin
            ) {
              const isSuper = authCx.current.mode === 'super';

              return (
                <>
                  <Box gridColumn="span 3">
                    <Heading
                      size={RADIX.HEADING.SIZE.MD}
                      color={RADIX.COLOR.SUPER_ADMIN}
                    >
                      Super Access
                    </Heading>
                  </Box>
                  <Box gridColumn="span 2">
                    <p>
                      Super mode allows access to video data from all teams and
                      super-only pitch lists.
                    </p>
                    <p>
                      <Text color={RADIX.COLOR.DANGER}>
                        <Strong>
                          Any changes to video metadata will impact other users!
                        </Strong>
                      </Text>
                    </p>
                  </Box>
                  <Box className="valign-top">
                    <Button
                      id="session-toggle-super"
                      className="btn-block"
                      variant={
                        isSuper
                          ? RADIX.BUTTON.VARIANT.SELECTED
                          : RADIX.BUTTON.VARIANT.NOT_SELECTED
                      }
                      color={
                        isSuper ? RADIX.COLOR.SUCCESS : RADIX.COLOR.SUPER_ADMIN
                      }
                      onClick={() => {
                        authCx.toggleSuperSession().then((success) => {
                          if (success) {
                            this.props.onClose();
                          }
                        });
                      }}
                    >
                      {`Toggle: ${isSuper ? 'OFF' : 'ON'}`}
                    </Button>
                  </Box>
                </>
              );
            }
          }}
        </AuthContext.Consumer>

        <Box gridColumn="span 2">
          <CommonFormButton
            id="edit-session-identifier"
            title={t('common.copy-to-clipboard').toString()}
            className="font-mono"
            variant="soft"
            inputColor={RADIX.COLOR.WARNING}
            label={this.props.session}
            onClick={async () => {
              await navigator.clipboard.writeText(this.props.session);

              NotifyHelper.success({
                message_md: t('common.x-copied-to-clipboard', {
                  x: t('common.identifier'),
                }),
              });
            }}
          />
        </Box>
        <Box>
          <CommonTextInput
            id="edit-session-shots"
            label="common.shots"
            value={this.props.fires.toString()}
            disabled
          />
        </Box>

        {this.state.data && (
          <>
            <Box gridColumn="span 3">
              <CommonTextInput
                id="edit-session-name"
                label="common.name"
                value={this.state.data.name}
                disabled={this.state.loading}
                onChange={(v) => this.setData({ name: v ?? '' })}
                optional
              />
            </Box>
            <Box gridColumn="span 3">
              <CommonTextareaInput
                id="edit-session-notes"
                label="common.notes"
                value={this.state.data.notes}
                disabled={this.state.loading}
                onChange={(v) => this.setData({ notes: v ?? '' })}
                optional
              />
            </Box>
          </>
        )}
      </CommonFormGrid>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <AuthContext.Consumer>
          {(authCx) => (
            <SessionEventsContext.Consumer>
              {(sessionCx) => (
                <CommonDialog
                  identifier={this.props.identifier}
                  title={t('common.edit-x', {
                    x: t('common.session'),
                  }).toString()}
                  loading={this.state.loading}
                  width={RADIX.DIALOG.WIDTH.MD}
                  content={this.renderContent()}
                  buttons={[
                    {
                      label: 'common.export-stats',
                      color: RADIX.COLOR.INFO,
                      invisible: authCx.current.role !== UserRole.admin,
                      icon: <SuperAdminIcon />,
                      onClick: async () => {
                        const data = await sessionCx.getPitchStatsData({
                          session: this.props.session,
                        });

                        if (data.length === 0) {
                          NotifyHelper.warning({
                            message_md: t('common.session-has-no-pitch-stats'),
                          });
                          return;
                        }

                        StringHelper.saveCsv(data, {
                          prefix: `session-${this.props.session}_stats`,
                        });
                      },
                    },
                    {
                      label: t('common.new-x', {
                        x: t('common.session'),
                      }).toString(),
                      color: RADIX.COLOR.SUCCESS,
                      invisible: authCx.current.session !== this.props.session,
                      onClick: () =>
                        authCx.newSession().then((success) => {
                          if (success) {
                            this.props.onClose();
                          }
                        }),
                    },
                    {
                      label: 'common.save',
                      color: RADIX.COLOR.INFO,
                      disabled: !this.state._id || !this.state.data,
                      onClick: () => {
                        this.setState({ loading: true }, () => {
                          SessionEventsService.update({
                            _id: this.state._id,
                            data: this.state.data ?? {},
                          })
                            .then(() =>
                              NotifyHelper.success({
                                message_md: t('common.x-updated-successfully', {
                                  x: t('common.session'),
                                }),
                              })
                            )
                            .finally(() => {
                              this.props.onChanged();
                              this.props.onClose();
                            });
                        });
                      },
                    },
                  ]}
                  onClose={this.props.onClose}
                />
              )}
            </SessionEventsContext.Consumer>
          )}
        </AuthContext.Consumer>
      </ErrorBoundary>
    );
  }
}
