import { Badge, Box, Flex, Heading, Spinner } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { SectionHeaderActions } from 'components/sections/header/actions';
import { Breadcrumbs } from 'components/sections/header/breadcrumbs';
import { SubSectionSelector } from 'components/sections/header/subsection-selector';
import { DefaultTFuncReturn } from 'i18next';
import { IBreadcrumb } from 'interfaces/i-breadcrumbs';
import { IMenuAction } from 'interfaces/i-menus';
import { ISubSection } from 'interfaces/i-section';
import { RADIX } from 'lib_ts/enums/radix-ui';

interface IProps {
  /** header text, won't be translated */
  header: string | DefaultTFuncReturn;

  /** optional inline tiny text after header */
  badge?: string | DefaultTFuncReturn;

  subsections?: ISubSection[];

  /** optional, will be rendered on the right side opposite the header as a standalone button */
  mainAction?: IMenuAction;

  /** optional, will be rendered on the right side opposite the header as a menu */
  actions?: IMenuAction[];

  loading?: boolean;

  breadcrumbs?: IBreadcrumb[];

  onClick?: () => void;
}

export const SectionHeader = (props: IProps) => {
  return (
    <ErrorBoundary componentName="SectionHeader">
      <Flex direction="column" gap={RADIX.FLEX.GAP.XS}>
        {props.breadcrumbs && <Breadcrumbs items={props.breadcrumbs} />}

        <Flex
          gap={RADIX.FLEX.GAP.SM}
          justify="between"
          className={props.onClick ? 'cursor-pointer' : undefined}
          onClick={props.onClick}
        >
          {/* left cluster */}
          <Flex gap={RADIX.FLEX.GAP.SM}>
            <Box className="valign-center">
              <Heading>{props.header}</Heading>
            </Box>

            {props.subsections && (
              <SubSectionSelector subsections={props.subsections} />
            )}

            {props.loading && (
              <Box className="valign-center">
                <Spinner size="3" />
              </Box>
            )}

            {props.badge && (
              <Box className="valign-center">
                <Badge color={RADIX.COLOR.NEUTRAL}>{props.badge}</Badge>
              </Box>
            )}
          </Flex>

          {/* right cluster */}
          <Flex gap={RADIX.FLEX.GAP.SM}>
            {props.mainAction && !props.mainAction.invisible && (
              <Box>
                <SectionHeaderActions actions={[props.mainAction]} />
              </Box>
            )}

            {props.actions && (
              <Box>
                <SectionHeaderActions actions={props.actions} />
              </Box>
            )}
          </Flex>
        </Flex>
      </Flex>
    </ErrorBoundary>
  );
};
