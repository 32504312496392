export enum MlbAbbreviation {
  None = 'None',
  AAS = 'AAS',
  ATL = 'ATL',
  AZ = 'AZ',
  BAL = 'BAL',
  BOS = 'BOS',
  CHC = 'CHC',
  CIN = 'CIN',
  CLE = 'CLE',
  COL = 'COL',
  CWS = 'CWS',
  DET = 'DET',
  HOU = 'HOU',
  KC = 'KC',
  LAA = 'LAA',
  LAD = 'LAD',
  MIA = 'MIA',
  MIL = 'MIL',
  MIN = 'MIN',
  NAS = 'NAS',
  NYM = 'NYM',
  NYY = 'NYY',
  OAK = 'OAK',
  PHI = 'PHI',
  PIT = 'PIT',
  SD = 'SD',
  SEA = 'SEA',
  SF = 'SF',
  STL = 'STL',
  TB = 'TB',
  TEX = 'TEX',
  TOR = 'TOR',
  WSH = 'WSH',
}
