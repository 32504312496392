export enum ErrorLevel {
  debug = 'debug',
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export const ERROR_MSGS = {
  CONTACT_SUPPORT:
    'Please contact support via the Help Center and/or try again.',
};
