import { Flex, ScrollArea } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTableBody } from 'components/common/table/body';
import { TableContext, TableProvider } from 'components/common/table/context';
import { TableFooter } from 'components/common/table/footer';
import { TableListener } from 'components/common/table/listener';
import { TableToolbar } from 'components/common/table/toolbar';
import { CheckedProvider } from 'contexts/layout/checked.context';
import { CHECKBOX_KEY, DRAGDROP_KEY } from 'enums/tables';
import { ICommonTableProps } from 'interfaces/tables/main';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext, useEffect, useMemo } from 'react';

// use this whenever the parent doesn't need to interact with the table context
export const CommonTableHoC = (props: ICommonTableProps) => {
  return (
    <TableProvider>
      <CheckedProvider data={props.displayData}>
        <CommonTable {...props} />
      </CheckedProvider>
    </TableProvider>
  );
};

// use CommonTableHoC unless the parent component(s) need to interact with the contexts; otherwise wrap this in custom table and checked providers
export const CommonTable = (props: ICommonTableProps) => {
  const tableCx = useContext(TableContext);

  const {
    collection,
    defaultSort,
    displayData,
    enablePagination,
    identifier,
    pageSizes,
    afterChangeSelected,
    mappingFn,
  } = props;

  // once, on mount
  useEffect(() => {
    if (identifier) {
      tableCx.initIdentifier(identifier);
    }

    tableCx.initColumns(columns);

    if (afterChangeSelected) {
      tableCx.initAfterChangeSelected(afterChangeSelected);
    }

    if (enablePagination && pageSizes && pageSizes.length > 0) {
      tableCx.setPageSize(pageSizes[0], true);
    }

    if (collection && mappingFn) {
      tableCx.initReorder({
        collection,
        mappingFn,
      });
    }

    if (defaultSort) {
      tableCx.setSort(defaultSort);
    }
  }, []);

  // apply changes to context whenever data changes (e.g. from filters above)
  useEffect(() => {
    tableCx.changeData(displayData);
  }, [displayData]);

  const columns = useMemo(() => {
    const output = [...props.displayColumns];

    if (props.checkboxColumnIndex !== undefined) {
      output.splice(props.checkboxColumnIndex, 0, {
        label: '#',
        key: CHECKBOX_KEY,
      });
    }

    if (props.dragType) {
      output.splice(0, 0, {
        label: ' ',
        key: DRAGDROP_KEY,
      });
    }

    return output;
  }, [props.displayColumns, props.checkboxColumnIndex, props.dragType]);

  return (
    <ErrorBoundary componentName="CommonTable">
      <Flex
        data-identifier="CommonTable"
        direction="column"
        gap={RADIX.FLEX.GAP.SM}
        style={
          props.vFlex
            ? {
                height: '100%',
                width: '100%',
                position: 'relative',
                overflow: 'hidden',
              }
            : undefined
        }
      >
        <TableToolbar
          toolbarContent={props.toolbarContent}
          extraToolbarContent={props.extraToolbarContent}
        />

        {props.disableScrolling ? (
          <CommonTableBody {...props} columns={columns} />
        ) : (
          <ScrollArea data-identifier="CommonTableScrollBox" scrollbars="both">
            <CommonTableBody {...props} columns={columns} />
          </ScrollArea>
        )}

        {(props.enablePagination ||
          props.checkboxColumnIndex !== undefined) && (
          <TableFooter
            checkedActions={props.checkedActions}
            pageSizes={props.pageSizes}
            total={props.total}
          />
        )}
      </Flex>

      <TableListener
        enableListener={props.enableListener}
        onKeyActions={props.onKeyActions}
      />
    </ErrorBoundary>
  );
};
