import { PassResult } from '../enums/machine.enums';
import { IRealMachinePerformance } from '../interfaces/modelling/i-real-machine-metric';

export const SKIP_OVERALL_CHECKS = true;

export class MachinePerformanceHelper {
  /** true => model passes, false => fails */
  static checkOverall = (value: IRealMachinePerformance): boolean => {
    if (SKIP_OVERALL_CHECKS) {
      return true;
    }

    return [
      this.checkSpeedY(value) === PassResult.Pass,
      this.checkSpinOverall(value),
      this.checkPlateOverall(value),
    ].every((v) => v === true);
  };

  static checkSpeedY = (value: IRealMachinePerformance): PassResult => {
    const MEAN_AE = 1.4;
    const MAX_AE = 2.8;

    if (value.mean_stdev.vy > MEAN_AE && value.max_stdev.vy > MAX_AE) {
      return PassResult.Both;
    }

    if (value.mean_stdev.vy > MEAN_AE) {
      return PassResult.Mean;
    }

    if (value.max_stdev.vy > MAX_AE) {
      return PassResult.Max;
    }

    return PassResult.Pass;
  };

  static checkPlateOverall = (value: IRealMachinePerformance): boolean => {
    return [
      this.checkPlateDimension(value.mean_stdev.plx, value.max_stdev.plx),
      this.checkPlateDimension(value.mean_stdev.plz, value.max_stdev.plz),
    ].every((v) => v === PassResult.Pass);
  };

  static checkPlateDimension = (mean: number, max: number): PassResult => {
    // inches
    const MEAN_AE = 5.5;
    const MAX_AE = 10;

    if (mean > MEAN_AE && max > MAX_AE) {
      return PassResult.Both;
    }
    if (mean > MEAN_AE) {
      return PassResult.Mean;
    }

    if (max > MAX_AE) {
      return PassResult.Max;
    }

    return PassResult.Pass;
  };

  static checkSpinOverall = (value: IRealMachinePerformance): boolean => {
    return [
      this.checkSpinDimension(value.mean_stdev.wx, value.max_stdev.wx),
      this.checkSpinDimension(value.mean_stdev.wy, value.max_stdev.wy),
      this.checkSpinDimension(value.mean_stdev.wz, value.max_stdev.wz),
    ].every((v) => v === PassResult.Pass);
  };

  static checkSpinDimension = (mean: number, max: number): PassResult => {
    const MEAN_AE = 650;
    const MAX_AE = 1_000_000;

    if (mean > MEAN_AE && max > MAX_AE) {
      return PassResult.Both;
    }
    if (mean > MEAN_AE) {
      return PassResult.Mean;
    }

    if (max > MAX_AE) {
      return PassResult.Max;
    }

    return PassResult.Pass;
  };
}
