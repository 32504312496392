import {
  Badge,
  Box,
  Button,
  Code,
  Flex,
  Heading,
  Table,
  Text,
} from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useState } from 'react';
import { MainService } from 'services/main.service';

export const DataControls = () => {
  const [executed, setExecuted] = useState(false);

  return (
    <ErrorBoundary componentName="DataControls">
      <Table.Root>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell width="200px" align="center">
              Actions
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Description</Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            {/* actions */}
            <Table.Cell align="center">
              <Button
                color={RADIX.COLOR.DANGER}
                size={RADIX.BUTTON.SIZE.SM}
                className="btn-block"
                disabled={executed}
                onClick={() => {
                  setExecuted(true);

                  MainService.getInstance()
                    .restartCron()
                    .then(() => {
                      NotifyHelper.success({
                        message_md:
                          'Cron jobs started successfully. Please wait up to 10 minutes for jobs to finish.',
                      });
                    })
                    .catch((e) => {
                      console.error(e);

                      setExecuted(false);

                      NotifyHelper.error({
                        message_md:
                          'There was a problem starting cron jobs. Please ask Carl for assistance or try again.',
                      });
                    });
                }}
              >
                Execute
              </Button>
            </Table.Cell>
            {/* description */}
            <Table.Cell>
              <Flex
                p={RADIX.FLEX.PAD.SM}
                direction="column"
                gap={RADIX.FLEX.GAP.SM}
              >
                <Heading size={RADIX.HEADING.SIZE.SM}>Cron Jobs</Heading>

                <Box>
                  <Text wrap="pretty">
                    Triggers <Code>cron</Code> jobs that are normally run when
                    the server starts up and/or on a nightly schedule. For
                    example, if there is no fires by machines/date data,
                    triggering this via the{' '}
                    <Badge color={RADIX.COLOR.WARNING}>STAGING</Badge>{' '}
                    environment will usually fix the issue.
                  </Text>
                </Box>
              </Flex>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table.Root>
    </ErrorBoundary>
  );
};
