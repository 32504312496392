import { NotifyHelper } from 'classes/helpers/notify.helper';
import { MIN_QUICK_TRAIN_SHOTS } from 'classes/plate-canvas';
import env from 'config';
import { CookiesContext } from 'contexts/cookies.context';
import { MachineContext } from 'contexts/machine.context';
import { lightFormat, parseISO } from 'date-fns';
import { CalibrationStep } from 'enums/machine.enums';
import { IMachineCalibrationCookie } from 'interfaces/cookies/i-machine-calibration-cookie';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IPythonEvalModelsResult } from 'lib_ts/interfaces/modelling/i-eval-models';
import { IGatherShotDataQuery } from 'lib_ts/interfaces/modelling/i-gather-shot-data';
import { IRealMachineMetric } from 'lib_ts/interfaces/modelling/i-real-machine-metric';
import { ITrainModelsRequest } from 'lib_ts/interfaces/modelling/i-train-model';
import { IPitch } from 'lib_ts/interfaces/pitches';
import { IPitchList } from 'lib_ts/interfaces/pitches/i-pitch-list';
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AdminMachineModelsService } from 'services/admin/machine-models.service';
import { PitchListsService } from 'services/pitch-lists.service';
import { PitchesService } from 'services/pitches.service';

const MIN_MODEL_BUILDER_SHOTS = MIN_QUICK_TRAIN_SHOTS;
const MAX_MODEL_BUILDER_SHOTS = 6;

export const MODEL_BUILDER_SHOTS_OPTIONS = ArrayHelper.getIntegerOptions(
  MIN_MODEL_BUILDER_SHOTS,
  MAX_MODEL_BUILDER_SHOTS
);

export const MAX_REF_LIST_SHOTS = 30;

export const REF_LIST_SHOTS_OPTIONS: IOption[] = ArrayHelper.getIntegerOptions(
  MIN_MODEL_BUILDER_SHOTS,
  MAX_REF_LIST_SHOTS
).filter((o) => {
  const value = parseInt(o.value);

  if (value <= 10) {
    // e.g. 3-10
    return true;
  }

  if (value % 5 === 0) {
    // e.g. 15, 20, 25, 30
    return true;
  }

  // e.g. 16, 17,...
  return false;
});

export const MIN_MODEL_BUILDER_GROUPS = env.production ? 7 : 1;

export const canCollectData = (
  cookie: IMachineCalibrationCookie,
  listLength: number
): boolean => {
  if (cookie.shots === undefined || cookie.shots < MIN_MODEL_BUILDER_SHOTS) {
    return false;
  }

  if (listLength < MIN_MODEL_BUILDER_GROUPS) {
    return false;
  }

  return true;
};

interface IOptionsDict {
  names: string[];
  _created: string[];
}

export interface IMachineCalibrationContext {
  loading: boolean;

  options: IOptionsDict;

  step: CalibrationStep;

  lists: IPitchList[];
  activeList?: IPitchList;
  pitches: IPitch[];

  metric?: IRealMachineMetric;

  readonly setStep: (step: CalibrationStep) => void;
  readonly setRealMachineMetric: (model: IRealMachineMetric) => void;
  readonly refreshLists: (notify?: boolean) => void;

  /** will set the first one in result as active */
  readonly trainModels: (
    payload: ITrainModelsRequest
  ) => Promise<IPythonEvalModelsResult | undefined>;

  readonly evaluateModels: (
    modelIDs: string[],
    query: Partial<IGatherShotDataQuery>
  ) => Promise<IPythonEvalModelsResult | undefined>;
}

const DEFAULT: IMachineCalibrationContext = {
  step: CalibrationStep.Setup,

  options: {
    names: [],
    _created: [],
  },

  lists: [],
  pitches: [],
  loading: false,

  setStep: () => console.debug('not init'),
  setRealMachineMetric: () => console.debug('not init'),
  refreshLists: () => console.debug('not init'),

  trainModels: async () => new Promise(() => console.debug('not init')),
  evaluateModels: async () => new Promise(() => console.debug('not init')),
};

const getOptions = (data: IPitchList[]): IOptionsDict => {
  if (data) {
    return {
      names: ArrayHelper.unique(data.map((m) => m.name)),

      _created: ArrayHelper.unique(
        data.map((m) =>
          m._created ? lightFormat(parseISO(m._created), 'yyyy-MM-dd') : ''
        )
      ),
    };
  } else {
    return DEFAULT.options;
  }
};

export const MachineCalibrationContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const MachineCalibrationProvider: FC<IProps> = (props) => {
  const [_lastFetched, _setLastFetched] = useState(new Date());

  const [_loading, _setLoading] = useState(DEFAULT.loading);

  const [_step, _setStep] = useState(DEFAULT.step);
  const [_lists, _setLists] = useState(DEFAULT.lists);
  const [_activeList, _setActiveList] = useState(DEFAULT.activeList);
  const [_metric, _setMetric] = useState<IRealMachineMetric | undefined>();
  const [_pitches, _setPitches] = useState(DEFAULT.pitches);

  const _options = useMemo(() => getOptions(_lists), [_lists]);

  const _changeActive = async (config: {
    trigger: string;
    list_id?: string;
  }) => {
    try {
      const nextActive = _lists.find((l) => l._id === config.list_id);

      if (config.list_id && !nextActive && _lists.length > 0) {
        NotifyHelper.warning({
          message_md: `You do not have access to list \`${config.list_id}\`.`,
        });
        return;
      }

      _setPitches([]);
      _setActiveList(nextActive);

      if (!nextActive) {
        return;
      }

      _setLoading(true);
      PitchesService.getInstance()
        .getListPitches(nextActive._id)
        .then((pitches) => _setPitches(pitches))
        .finally(() => _setLoading(false));
    } catch (e) {
      console.error(e);
      NotifyHelper.error({
        message_md:
          'There was an error while preparing your list. Please try again.',
      });
    }
  };

  const state: IMachineCalibrationContext = {
    loading: _loading,

    pitches: _pitches,
    options: _options,

    step: _step,
    setStep: (step) => _setStep(step),

    metric: _metric,
    setRealMachineMetric: (model) => {
      // clear _eval to avoid interference from leftovers after reviewing other metrics
      _setMetric(model);
    },

    trainModels: async (payload) => {
      try {
        _setLoading(true);

        const result =
          await AdminMachineModelsService.getInstance().trainModels(payload);

        if (result && result.results && result.results.length > 0) {
          _setLastFetched(new Date());
        }

        return result;
      } catch (e) {
        console.error(e);
        return undefined;
      } finally {
        _setLoading(false);
      }
    },

    evaluateModels: async (modelIDs, query) => {
      try {
        _setLoading(true);

        const results =
          await AdminMachineModelsService.getInstance().evalModelMetrics(
            modelIDs,
            query
          );

        if (!results || results.length === 0) {
          throw new Error('No evaluation results');
        }

        return {
          success: true,
          results: results,
        };
      } catch (e) {
        console.error(e);

        return {
          success: false,
          errors: [e instanceof Error ? e.message : 'evaluate models error'],
        };
      } finally {
        _setLoading(false);
      }
    },

    lists: _lists,
    activeList: _activeList,
    refreshLists: (notify) => {
      if (notify) {
        NotifyHelper.success({ message_md: 'Refreshing lists...' });
      }
      _setLastFetched(new Date());
    },
  };

  const { machine } = useContext(MachineContext);

  /** reload the data whenever machineID changes to get relevant machine-only lists */
  useEffect(() => {
    if (!_lastFetched) {
      return;
    }

    _setLoading(true);

    PitchListsService.getInstance()
      .getReferenceLists()
      .then((result) => {
        if (!result) {
          return;
        }

        result.sort((a, b) => {
          // sort alphabetically
          if (a.model_builder_default === b.model_builder_default) {
            return a.name.localeCompare(b.name);
          }

          // sort defaults to the top
          if (a.model_builder_default) {
            return -1;
          }

          return 1;
        });

        _setLists(result);
      })
      .catch((reason) => console.error(reason))
      .finally(() => _setLoading(false));
  }, [
    _lastFetched,
    /** anything that might result in different pitch mss should trigger a reload */
    machine.machineID,
    machine.ball_type,
  ]);

  const { machineCalibration } = useContext(CookiesContext);

  /** load pitches when cookies change */
  useEffect(() => {
    if (!machineCalibration.list_id) {
      return;
    }

    if (_lists.length === 0) {
      return;
    }

    _changeActive({
      trigger: 'lists and/or cookies changed',
      list_id: machineCalibration.list_id,
    });
  }, [_lists, machineCalibration.list_id]);

  return (
    <MachineCalibrationContext.Provider value={state}>
      {props.children}
    </MachineCalibrationContext.Provider>
  );
};
