import { Container, Flex } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { SectionHeader } from 'components/sections/header';
import { MachineCalibrationRouter } from 'components/sections/machine-calibration/router';
import { MachinesProvider } from 'contexts/admin/machines.context';
import { MachineCalibrationProvider } from 'contexts/machine-calibration.context';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';

const COMPONENT_NAME = 'MachineCalibration';

export const MachineCalibration = () => {
  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Container size="3" className="MachineCalibration">
        <Flex direction="column" gap={RADIX.FLEX.GAP.SECTION}>
          <SectionHeader header={t('main.machine-calibration')} />
          <MachinesProvider>
            <MachineCalibrationProvider>
              <MachineCalibrationRouter />
            </MachineCalibrationProvider>
          </MachinesProvider>
        </Flex>
      </Container>
    </ErrorBoundary>
  );
};
