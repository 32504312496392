import { Box, Radio } from '@radix-ui/themes';
import { CommonInputHint } from 'components/common/form/hint';
import { CommonInputLabel } from 'components/common/form/label';
import { CommonInputWrapper } from 'components/common/form/wrapper';
import { t } from 'i18next';
import { IRadioInput } from 'interfaces/forms/radio';
import React from 'react';
import { v4 } from 'uuid';

interface IState {
  identifier: string;
}

export class CommonRadio extends React.Component<IRadioInput, IState> {
  state: IState = {
    identifier: v4(),
  };

  render() {
    return (
      <CommonInputWrapper {...this.props}>
        <CommonInputLabel {...this.props} />

        {this.props.optional && (
          <Box>
            <Radio
              name={this.state.identifier}
              value=""
              disabled={this.props.disabled}
              onChange={(e) => this.props.onChange(e)}
              checked={
                this.props.value === undefined ||
                this.props.value === null ||
                this.props.value === ''
              }
            />
            &nbsp;{t(this.props.placeholder ?? 'None')}
          </Box>
        )}

        {this.props.options.map((o, i) => (
          <Box key={`${this.props.name}-${i}`}>
            <Radio
              name={this.state.identifier}
              value={o.value}
              disabled={this.props.disabled}
              onChange={(e) => this.props.onChange(e)}
              checked={this.props.value === o.value}
            />
            &nbsp;{t(o.label)}
          </Box>
        ))}

        <CommonInputHint {...this.props} />
      </CommonInputWrapper>
    );
  }
}
