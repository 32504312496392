import { Button, Flex } from '@radix-ui/themes';
import { t } from 'i18next';
import { IButton } from 'interfaces/i-buttons';

// provides presets for parameters when not specified in props
export const SettingButton = (props: IButton) => (
  <Flex justify="end">
    <Button
      title={props.tooltip}
      disabled={props.disabled}
      color={props.color}
      variant={props.variant ?? 'soft'}
      className={props.className ?? 'btn-block'}
      onClick={props.onClick}
    >
      {props.icon}
      {t(props.label ?? 'common.click-here')}
      {props.suffixIcon}
    </Button>
  </Flex>
);
