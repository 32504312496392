import {
  CheckCircledIcon,
  Cross1Icon,
  CrossCircledIcon,
  ExclamationTriangleIcon,
  InfoCircledIcon,
} from '@radix-ui/react-icons';
import * as Toast from '@radix-ui/react-toast';
import { Box, Button, Flex, Heading, IconButton } from '@radix-ui/themes';
import { IntercomArticleButton } from 'components/common/buttons/intercom-article';
import { CommonCallout } from 'components/common/callouts';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { t } from 'i18next';
import { INotification, INotificationButton } from 'interfaces/i-notification';
import { ErrorLevel } from 'lib_ts/enums/errors.enums';
import { RADIX, RadixBtnSize, RadixBtnVariant } from 'lib_ts/enums/radix-ui';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import './index.scss';

const ENABLE_TOAST_HEADERS = false;

const BTN_VARIANT: RadixBtnVariant = 'soft';
const BTN_SIZE: RadixBtnSize = RADIX.BUTTON.SIZE.XS;

interface IProps {
  config: INotification;

  // for inbox
  onDismiss?: () => void;
  // for inbox
  withoutToast?: boolean;
}

interface IState {
  show: boolean;
}

const Icon = (props: { level?: ErrorLevel }) => {
  switch (props.level) {
    case ErrorLevel.error: {
      return <CrossCircledIcon />;
    }

    case ErrorLevel.warning: {
      return <ExclamationTriangleIcon />;
    }

    case ErrorLevel.success: {
      return <CheckCircledIcon />;
    }

    case ErrorLevel.debug:
    case ErrorLevel.info:
    default: {
      return <InfoCircledIcon />;
    }
  }
};

const Header = (props: IProps) => {
  const content = (
    <span className="text-titlecase">
      {t(props.config.header ?? 'common.notification')}
    </span>
  );

  return (
    <Heading size={RADIX.HEADING.SIZE.SM} className="hover-wrapper">
      <span className="hover-show">
        {props.config.created
          ? formatDistanceToNow(props.config.created, {
              includeSeconds: true,
              addSuffix: true,
            })
          : content}
      </span>
      <span className="hover-hide">{content}</span>
    </Heading>
  );
};

export class CommonToast extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      show: true,
    };

    this.renderFooter = this.renderFooter.bind(this);
    this.renderButton = this.renderButton.bind(this);
    this.close = this.close.bind(this);
  }

  private renderButton(btn: INotificationButton) {
    return (
      <Box>
        <Button
          size={BTN_SIZE}
          variant={BTN_VARIANT}
          color={btn.color}
          onClick={() => {
            btn.onClick();

            if (btn.dismissAfterClick) {
              this.close();
            }
          }}
        >
          {t(btn.label)}
        </Button>
      </Box>
    );
  }

  private close() {
    if (this.props.config.onClose) {
      this.props.config.onClose();
    }

    // mark the notification as closed to prevent re-render
    this.props.config.closed = true;

    /** visibly hides the notification */
    this.setState({ show: false });
  }

  private renderFooter() {
    const anyActions =
      (this.props.withoutToast && this.props.onDismiss) ||
      this.props.config.buttons ||
      this.props.config.show_restart ||
      this.props.config.intercom_article_id;

    if (!anyActions) {
      return <></>;
    }

    return (
      <Flex gap={RADIX.FLEX.GAP.SM} pt="4">
        {this.props.withoutToast &&
          this.props.onDismiss &&
          this.renderButton({
            label: 'common.dismiss',
            onClick: this.props.onDismiss,
          })}

        {this.props.config.buttons?.map((b, i) => (
          <React.Fragment key={i}>{this.renderButton(b)}</React.Fragment>
        ))}

        {this.props.config.intercom_article_id && (
          <Box>
            <IntercomArticleButton
              variant={BTN_VARIANT}
              size={BTN_SIZE}
              articleID={this.props.config.intercom_article_id}
              onClick={() => this.close()}
            />
          </Box>
        )}
      </Flex>
    );
  }

  render() {
    const content = (
      <CommonCallout
        icon={<Icon level={this.props.config.level} />}
        color={this.props.config.color}
        variant="outline"
        justifyIcon="start"
        content={
          <Box className="ToastWrapper" mr="5">
            {ENABLE_TOAST_HEADERS && !this.props.config.hideHeader && (
              <Header {...this.props} />
            )}

            {/* content */}
            <ReactMarkdown
              children={
                this.props.config.message_md ?? 'Empty notification body.'
              }
            />

            {this.renderFooter()}
          </Box>
        }
      />
    );

    if (this.props.withoutToast) {
      return content;
    }

    return (
      <Toast.Root
        style={{ position: 'relative' }}
        className="ToastRoot"
        duration={this.props.config.delay_ms}
        open={this.state.show}
        onOpenChange={(open) => {
          if (!open) {
            this.close();
          }
        }}
      >
        {content}

        <Box
          p="2"
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        >
          <IconButton
            color={this.props.config.color}
            variant="soft"
            className="btn-floating"
            onClick={() => this.close()}
          >
            <Cross1Icon />
          </IconButton>
        </Box>
      </Toast.Root>
    );
  }
}
