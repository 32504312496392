import { Box, Code, Flex, Strong, Table, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { MachinesContext } from 'contexts/admin/machines.context';
import { format } from 'date-fns-tz';
import { LOCAL_DATETIME_FORMAT_MONO, LOCAL_TIMEZONE } from 'enums/env';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import React from 'react';

const COMPONENT_NAME = 'ConfirmHWChangedDialog';

interface IProps {
  machines: IMachine[];
}

interface IState {
  date: Date;
}

export class ConfirmHWChangedDialog extends React.Component<IProps, IState> {
  state: IState = {
    date: new Date(),
  };

  render() {
    const ds = format(this.state.date, LOCAL_DATETIME_FORMAT_MONO, {
      timeZone: LOCAL_DATETIME_FORMAT_MONO,
    });

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <MachinesContext.Consumer>
          {(machinesCx) => (
            <CommonConfirmationDialog
              identifier={COMPONENT_NAME}
              maxWidth={RADIX.DIALOG.WIDTH.MD}
              title="Update Hardware"
              content={
                <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
                  <Box>
                    <Text>
                      Are you sure you want to update the{' '}
                      <Strong>last hardware changed date(s)</Strong> to{' '}
                      <Code color={RADIX.COLOR.WARNING}>{ds}</Code> for the
                      following machine(s)?
                    </Text>
                  </Box>

                  <Table.Root>
                    <Table.Header>
                      <Table.Row>
                        <Table.ColumnHeaderCell>Machine</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>
                          Nickname
                        </Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>
                          Current Value
                        </Table.ColumnHeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.props.machines.map((m, i) => (
                        <Table.Row key={`machine-${i}`}>
                          <Table.Cell>{m.machineID}</Table.Cell>
                          <Table.Cell>{m.nickname ?? '(none)'}</Table.Cell>
                          <Table.Cell>
                            <Code>
                              {m.last_hardware_changed
                                ? format(
                                    new Date(m.last_hardware_changed),
                                    LOCAL_DATETIME_FORMAT_MONO,
                                    { timeZone: LOCAL_TIMEZONE }
                                  )
                                : '(none)'}
                            </Code>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table.Root>
                </Flex>
              }
              action={{
                label: 'common.update',
                onClick: async () => {
                  if (machinesCx.loading) {
                    NotifyHelper.success({
                      message_md: `Please wait while your request is processed...`,
                    });
                    return;
                  }

                  NotifyHelper.success({
                    message_md: `Please wait while your request is processed...`,
                  });

                  // get all sessions satisfying the filter
                  await Promise.all(
                    this.props.machines.map((m) =>
                      machinesCx.update({
                        _id: m._id,
                        last_hardware_changed: this.state.date.toISOString(),
                      })
                    )
                  );
                },
              }}
            />
          )}
        </MachinesContext.Consumer>
      </ErrorBoundary>
    );
  }
}
