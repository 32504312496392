import { CSVFormat } from 'enums/csv';

interface ICSVDefinition {
  /** higher value takes precedence */
  priority: number;
  format: CSVFormat;
  requiredHeaders: string[];
}

export const CSV_FORMAT_DEFS: ICSVDefinition[] = [
  {
    priority: 3,
    format: CSVFormat.CustomSpins,
    requiredHeaders: [
      'ReleaseX',
      'ReleaseY',
      'ReleaseZ',
      'ReleaseV',
      'SpinX',
      'SpinY',
      'SpinZ',
    ],
  },
  {
    priority: 2,
    format: CSVFormat.Statcast,
    requiredHeaders: [
      'release_pos_x',
      'release_pos_y',
      'release_pos_z',
      'vx0',
      'vy0',
      'vz0',
      'ax',
      'ay',
      'az',
      'release_spin_rate',
    ],
  },
  {
    priority: 1,
    format: CSVFormat.Hawkeye,
    requiredHeaders: [
      'spinX',
      'spinY',
      'spinZ',
      'x0',
      'y0',
      'z0',
      'vx0',
      'vy0',
      'vz0',
      'ax0',
      'ay0',
      'az0',
    ],
  },
  {
    priority: 0,
    format: CSVFormat.CustomBreaks,
    requiredHeaders: [
      'ReleaseX',
      'ReleaseY',
      'ReleaseZ',
      'ReleaseV',
      'HorizontalBreakIn',
      'VerticalBreakIn',
    ],
  },
].sort((a, b) => (a.priority > b.priority ? -1 : 1));
