import {
  IInputModel,
  IModelGroup,
  ISceneModel,
  POINT_OFFSETS,
} from 'components/common/trajectory-view/helpers';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { Group, Line } from 'three';

/** additional attributes for rendering artifacts */
export class SceneEngine {
  models: ISceneModel[];

  constructor() {
    this.models = [];
  }

  sortedGroups(): IModelGroup[] {
    return ArrayHelper.unique(
      this.models.map((m) => m.source.button_group)
    ).sort((a: IModelGroup, b: IModelGroup) => (a.sort < b.sort ? -1 : 1));
  }

  addBall(input: IInputModel, ball: Group) {
    const model: ISceneModel = {
      source: input,
      ball: ball,

      time: 0,

      /** needs to be updated so it cannot share a reference with input traj (from props) */
      traj_local: {
        ...input.traj,
      },

      bs_local: {
        ...input.bs,
      },

      points: POINT_OFFSETS.map(() => []),
      lines: POINT_OFFSETS.map(() => new Line()),

      arrived: false,
    };

    /** update defaults */

    model.ball.rotation.x = 0;
    model.ball.rotation.y = 0;
    model.ball.rotation.z = 0;

    model.ball.scale.x = 1.25;
    model.ball.scale.y = 1.25;
    model.ball.scale.z = 1.25;

    this.models.push(model);

    return model;
  }

  removeBall(guid: string) {
    const index = this.models.findIndex((m) => m.source.guid === guid);

    if (index === -1) {
      return;
    }

    this.models.splice(index, 1);
  }
}
