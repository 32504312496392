import { Strong, Text } from '@radix-ui/themes';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { RestartMode } from 'lib_ts/enums/admin.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';
import { AdminMachinesService } from 'services/admin/machines.service';

const COMPONENT_NAME = 'SoftRebootDialog';

interface IProps {
  machineIDs: string[];
  onClose: () => void;
}

interface IState {}

export class SoftRebootDialog extends React.Component<IProps, IState> {
  state: IState = {};

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={COMPONENT_NAME}
          title="common.restart-os"
          width={RADIX.DIALOG.WIDTH.LG}
          content={
            <Text>
              <p>
                <Strong>
                  Please ensure each machine is not in use before proceeding.
                </Strong>
              </p>
              <p>
                The operating system on the following machine(s) will be
                restarted:
              </p>
              <ul>
                {this.props.machineIDs.map((machineID) => (
                  <li key={`machine-${machineID}`}>{machineID}</li>
                ))}
              </ul>
            </Text>
          }
          buttons={[
            {
              label: 'common.restart-os',
              color: RADIX.COLOR.SUCCESS,
              onClick: () => {
                AdminMachinesService.getInstance()
                  .restart(RestartMode.os, this.props.machineIDs)
                  .then(() => this.props.onClose());
              },
            },
          ]}
          onClose={() => this.props.onClose()}
        />
      </ErrorBoundary>
    );
  }
}
