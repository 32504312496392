import { IOption } from 'lib_ts/interfaces/common/i-option';

export enum HitterSide {
  LHH = 'LHH',
  RHH = 'RHH',
}

export const HITTER_SIDES: IOption[] = [
  {
    label: 'constants.hitter-side-lhh',
    value: HitterSide.LHH,
  },
  {
    label: 'constants.hitter-side-rhh',
    value: HitterSide.RHH,
  },
];

export enum HitterSafety {
  high = 'High',
  medium = 'Medium',
  low = 'Low',
}

export const HITTER_SAFETY_BUFFERS: IOption[] = [
  {
    label: 'constants.hitter-safety-high',
    value: HitterSafety.high,
  },
  {
    label: 'constants.hitter-safety-medium',
    value: HitterSafety.medium,
  },
  {
    label: 'constants.hitter-safety-low',
    value: HitterSafety.low,
  },
];

export enum HitOutcomeCode {
  Single = '1B',
  Double = '2B',
  Triple = '3B',
  HomeRun = 'HR',
  FieldOut = 'FO',
  Unknown = '',
}
