import { CaretRightIcon } from '@radix-ui/react-icons';
import { Flex, Link, Text } from '@radix-ui/themes';
import { t } from 'i18next';
import { IBreadcrumb } from 'interfaces/i-breadcrumbs';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

export const Breadcrumbs = (props: { items: IBreadcrumb[] }) => {
  return (
    <Flex gap="1">
      {props.items.map((b, i) => (
        <React.Fragment key={`breadcrumb-${i}`}>
          {i > 0 && (
            <Text size="4">
              <CaretRightIcon />
            </Text>
          )}

          {b.onClick ? (
            <Link
              className="cursor-pointer"
              color={RADIX.COLOR.ACCENT}
              onClick={b.onClick}
            >
              {t(b.label)}
            </Link>
          ) : (
            <Text>{t(b.label)}</Text>
          )}
        </React.Fragment>
      ))}
    </Flex>
  );
};
