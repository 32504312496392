import { Flex, Switch } from '@radix-ui/themes';
import { CommonInputHint } from 'components/common/form/hint';
import { CommonInputLabel } from 'components/common/form/label';
import { CommonInputWrapper } from 'components/common/form/wrapper';
import { ISwitchInput } from 'interfaces/forms/switch';
import { RADIX } from 'lib_ts/enums/radix-ui';

export const CommonSwitchInput = (props: ISwitchInput) => (
  <CommonInputWrapper {...props}>
    <Flex gap={RADIX.FLEX.GAP.INPUT}>
      <Switch
        name={props.name}
        disabled={props.disabled}
        checked={props.checked}
        defaultChecked={props.defaultChecked}
        color={props.inputColor}
        onCheckedChange={(checked) => props.onCheckedChange?.(checked)}
      />
      <CommonInputLabel {...props} />
    </Flex>

    <CommonInputHint {...props} />
  </CommonInputWrapper>
);
