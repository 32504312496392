import env from 'config';
import { AuthContext } from 'contexts/auth.context';
import LogRocket from 'logrocket';
import { createContext, FC, useContext, useEffect, useState } from 'react';

const APP_ID = 's9iwvp/trajekt';

export interface ILogRocketContext {}

const DEFAULT: ILogRocketContext = {};

export const LogRocketContext = createContext(DEFAULT);

/** for tracking misc details across entire app */
export const LogRocketProvider: FC = () => {
  const { current } = useContext(AuthContext);

  const [init, setInit] = useState(false);

  const state: ILogRocketContext = {};

  useEffect(() => {
    if (!current.auth) {
      return;
    }

    if (init) {
      LogRocket.startNewSession();
    } else {
      LogRocket.init(APP_ID, {
        release: `${env.version} @ ${env.getBuildStamp()}`,
      });

      setInit(true);
    }

    LogRocket.identify(current.email, {
      env: env.identifier,
      session: current.session,
    });
  }, [current.auth, current.session, current.email]);

  return <LogRocketContext.Provider value={state} />;
};
