import { Flex } from '@radix-ui/themes';
import { CommonCallout } from 'components/common/callouts';
import { SectionHeader } from 'components/sections/header';
import { RADIX } from 'lib_ts/enums/radix-ui';

export const PseudoSection = (props: {
  header: string;
  content: React.ReactNode;
}) => (
  <Flex p={RADIX.FLEX.PAD.SM} direction="column" gap={RADIX.FLEX.GAP.SM}>
    <SectionHeader header={props.header} />
    <CommonCallout color="gray" content={props.content} />
  </Flex>
);
