import { Vector3 } from 'three';
import { IBallState } from '../interfaces/pitches/i-ball-state';
import { IAziAltCoordinate } from '../interfaces/pitches/i-base';
import { BallHelper } from './ball.helper';
import { getRotationMatrix } from './math.utilities';

export class BallState {
  constructor(public value: IBallState) {}

  /** @param rotation should be provided in radians */
  rotateBallState(rotation: IAziAltCoordinate): IBallState {
    const mx = getRotationMatrix({
      z_rad: rotation.azimuth_rad,
      x_rad: -rotation.altitude_rad,
    });

    const vPrime = new Vector3(
      this.value.vx,
      this.value.vy,
      this.value.vz
    ).applyMatrix3(mx);

    // Only rotate velocity as spin and orientation are local to the ball's release velocity
    const output: IBallState = {
      ...this.value,
      vx: vPrime.x,
      vy: vPrime.y,
      vz: vPrime.z,
      vnet: BallHelper.getSpeed({
        vx: vPrime.x,
        vy: vPrime.y,
        vz: vPrime.z,
      }),
    };

    return output;
  }
}
