export enum StaticVideoType {
  default_RHP = 'default RHP',
  default_LHP = 'default LHP',
  empty_carousel = 'empty_carousel',
  screensaver = 'screensaver',
  training = 'training',
  training_short = 'training_short',
}

export enum WsMsgType {
  Firmware_Commit = 'fw-commit',
  Firmware_ConfigRead = 'fw-config-read',
  Firmware_ConfigWrite = 'fw-config-write',
  Firmware_Response = 'fw-response',
  Firmware_Upgrade = 'fw-upgrade',
  M2S_MachineStatusUpdate = 'machine-status-update',
  M2U_BallStatus = 'ball-status',
  M2U_CalibrateProgress = 'calibrate-progress',
  M2U_CalibrateStereo = 'calibrate-stereo-response',
  M2U_CalibrationResponse = 'calibrationresponse',
  M2U_CameraStream = 'camera-stream',
  M2U_FireResponse = 'fireresponse',
  M2U_HealthCheck = 'health-check-response',
  M2U_MachineReset = 'machine-reset',
  M2U_MsCurrent = 'mscurrent',
  M2U_OverlaysResponse = 'overlay-ids-response',
  M2U_QueryProcesses = 'query-processes-response',
  M2U_ReadLogResponse = 'read-log-response',
  M2U_ReadyToFire = 'r2f',
  M2U_SoftReboot = 'soft-reboot-response',
  M2U_SystemRestart = 'system-restart-response',
  M2U_TrainingResponse = 'trainingresponse',
  Misc_ControlForce = 'control-force',
  Misc_ControlRequest = 'control-request',
  Misc_ControlResponse = 'control-response',
  Misc_CustomRequest = 'custom-request',
  Misc_CustomResponse = 'custom-response',
  Misc_Error = 'errormsg',
  Misc_MachineStatus = 'machine-status',
  Misc_ProjectorSoundFx = 'projector-sfx',
  Misc_UserConnection = 'user-connection',
  Process_Kill = 'kill-process',
  Process_Query = 'query-processes',
  Process_Reset = 'reset-process',
  Process_SoftReboot = 'soft-reboot',
  Process_SystemRestart = 'system-restart',
  Queue_ActivatedUser = 'activated-user',
  Queue_DeactivatedUser = 'deactivated-user',
  S2M_ClearOverlays = 'clear-overlays',
  S2M_HitterDetails = 'hitter-details',
  S2M_PitchPreview = 'pitch-preview',
  S2M_PitchRecap = 'pitch-recap',
  S2M_TwoFa = '2fa',
  S2U_Announcement = 'announcement',
  S2U_Context = 'context',
  S2U_GameStatus = 'game-status',
  U2M_CalibrateStereo = 'calibrate-stereo-request',
  U2M_CameraStream = 'camera-stream-request',
  U2M_HealthCheck = 'health-check-request',
  U2M_PreviewVideo = 'preview-video',
  U2M_ReadLog = 'read-log',
  U2M_SpecialMsTarget = 'special-mstarget',
  U2S_Calibrate = 'calibrate',
  U2S_Dropball = 'dropball',
  U2S_EjectBalls = 'eject-balls',
  U2S_Fire = 'fire',
  U2S_Keystone = 'keystone',
  U2S_MsTarget = 'mstarget',
  U2S_Overlays = 'overlay-ids',
  U2S_Ruler = 'ruler',
}

export const WS_MISC = [
  WsMsgType.Misc_ControlForce,
  WsMsgType.Misc_ControlRequest,
  WsMsgType.Misc_ControlResponse,
  WsMsgType.Misc_CustomRequest,
  WsMsgType.Misc_CustomResponse,
  WsMsgType.Misc_MachineStatus,
  WsMsgType.Misc_ProjectorSoundFx,
  WsMsgType.Misc_UserConnection,
];

export const WS_FIRMWARE = [
  WsMsgType.Firmware_Commit,
  WsMsgType.Firmware_ConfigRead,
  WsMsgType.Firmware_ConfigWrite,
  WsMsgType.Firmware_Response,
  WsMsgType.Firmware_Upgrade,
];

export const WS_SERVER_TO_USER = [
  WsMsgType.S2U_Announcement,
  WsMsgType.S2U_Context,
  WsMsgType.S2U_GameStatus,
];

export const WS_QUEUE = [
  WsMsgType.Queue_ActivatedUser,
  WsMsgType.Queue_DeactivatedUser,
];

export const WS_PROCESS = [
  WsMsgType.Process_Kill,
  WsMsgType.Process_Query,
  WsMsgType.Process_Reset,
  WsMsgType.Process_SoftReboot,
  WsMsgType.Process_SystemRestart,
];

export const WS_SERVER_TO_MACHINE = [
  WsMsgType.S2M_ClearOverlays,
  WsMsgType.S2M_HitterDetails,
  WsMsgType.S2M_PitchPreview,
  WsMsgType.S2M_PitchRecap,
  WsMsgType.S2M_TwoFa,
  ...WS_PROCESS,
  ...WS_QUEUE,
];

export const WS_MACHINE_TO_SERVER = [WsMsgType.M2U_CameraStream];

export const WS_MACHINE_TO_USER = [
  WsMsgType.M2U_BallStatus,
  WsMsgType.M2U_CalibrationResponse,
  WsMsgType.M2U_FireResponse,
  WsMsgType.M2U_MachineReset,
  WsMsgType.M2U_MsCurrent,
  WsMsgType.M2U_OverlaysResponse,
  WsMsgType.M2U_QueryProcesses,
  WsMsgType.M2U_ReadLogResponse,
  WsMsgType.M2U_ReadyToFire,
  WsMsgType.M2U_SoftReboot,
  WsMsgType.M2U_SystemRestart,
  WsMsgType.M2U_TrainingResponse,
  WsMsgType.Misc_Error,
];

export const WS_FROM_USER_TO_SERVER = [
  WsMsgType.U2S_Calibrate,
  WsMsgType.U2S_Dropball,
  WsMsgType.U2S_EjectBalls,
  WsMsgType.U2S_Fire,
  WsMsgType.U2S_Keystone,
  WsMsgType.U2S_MsTarget,
  WsMsgType.U2S_Overlays,
  WsMsgType.U2S_Ruler,
];

export const WS_FROM_USER_TO_MACHINE = [
  WsMsgType.U2M_CameraStream,
  WsMsgType.U2M_PreviewVideo,
  WsMsgType.U2M_ReadLog,
  WsMsgType.U2M_SpecialMsTarget,
];

export enum BallStatusMsgType {
  ActivatedUser = 'activated-user',
  AfterDropball = 'after-dropball',
  AfterFire = 'after-fire',
  AfterSend = 'after-send',
  Default = 'default',
  Mock = 'mock',
}

export enum MachineProcess {
  AlphaController = 'AlphaController',
  BallInserter = 'BallInserter',
  BaseDaemon = 'BaseDaemon',
  LightsController = 'LightsController',
  OutputDetector = 'OutputDetector',
  PosController = 'PosController',
  Projector = 'Projector',
  TiltController = 'TiltController',
  WebsocketInterface = 'WebsocketInterface',
  WheelSpeedController = 'WheelSpeedController',
  YawController = 'YawController',
}

export enum MachineLogLevel {
  CRITICAL = 'CRITICAL',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
  DEBUG = 'DEBUG',
}

export enum CameraSource {
  // todo: work with FW to rename this to BI_RIGHT
  BI_R = 'Ball Inserter (Right)',
  // todo: work with FW to rename this to OD_LEFT
  OD_L = 'Output Detector (Left)',
  // todo: work with FW to rename this to OD_TOP
  OD_T = 'Output Detector (Top)',

  BI_DEBUG = 'BI_DEBUG',
  STEREO_CHECK = 'STEREO_CHECK',
}

export enum ContextName {
  Videos = 'Videos',
}

export enum SfxName {
  START = 'start',
  NOT_READY = 'not_ready',
  DELAY_FIRE = 'delay_fire',
  FIRE = 'fire',
  ERROR = 'error',
  REMOTE = 'remote',
}

export enum FirmwareBranch {
  // use whatever branch the repo has checked out
  CURRENT = 'current',
  MAIN = 'main',
  BETA = 'beta',
  STAGING = 'staging',
  DEV = 'dev',
}

export enum HealthCheckType {
  FULL = 'full-health-check',
}
