import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { Badge, Box, Code, Flex, Link, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import {
  AdminIcon,
  SuperAdminIcon,
} from 'components/common/custom-icon/shorthands';
import { AnnouncementDialog } from 'components/common/dialogs/announcement';
import { ErrorBoundary } from 'components/common/error-boundary';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { ReassignListsDialog } from 'components/common/pitch-lists/reassign-lists';
import { CommonTableHoC } from 'components/common/table';
import { CommonTooltip } from 'components/common/tooltip';
import { MachineInspectionDialog } from 'components/machine/dialogs/inspection';
import { RefreshListsDialog } from 'components/sections/admin-portal/dialogs/refresh-lists';
import {
  ArchiveShotsDialog,
  ConfirmHWChangedDialog,
  ExportSessionsDialog,
  MachineDetailsDialog,
  MachineLogsDialog,
  MachinePerformanceDialog,
  ResetConnectionsDialog,
  SoftRebootDialog,
  SystemRestartDialog,
} from 'components/sections/admin-portal/machines/dialogs';
import { UpgradeFirmwareDialog } from 'components/sections/admin-portal/machines/dialogs/upgrade-firmware';
import { AdminTabNav } from 'components/sections/admin-portal/tab-nav';
import {
  IMachinesContext,
  MachinesContext,
} from 'contexts/admin/machines.context';
import { ITeamsContext, TeamsContext } from 'contexts/admin/teams.context';
import { IUsersContext, UsersContext } from 'contexts/admin/users.context';
import { AuthContext, IAuthContext } from 'contexts/auth.context';
import { GlobalContext } from 'contexts/global.context';
import { CheckedProvider } from 'contexts/layout/checked.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import format from 'date-fns-tz/format';
import parseISO from 'date-fns/parseISO';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { SubSectionName } from 'enums/route.enums';
import { ACTIONS_KEY, TABLES } from 'enums/tables';
import { t } from 'i18next';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { IMenuAction } from 'interfaces/i-menus';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ITableSortable } from 'interfaces/tables/sorting';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { MachineHelper } from 'lib_ts/classes/machine.helper';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMachineActiveModelID, getModelKey } from 'lib_ts/classes/ms.helper';
import { RestartMode } from 'lib_ts/enums/admin.enums';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IAnnouncement } from 'lib_ts/interfaces/common/i-announcement';
import { IAdminMachine, IMachine } from 'lib_ts/interfaces/i-machine';
import { IMachineUser } from 'lib_ts/interfaces/i-machine-summary';
import { IPitchListPutManyRequest } from 'lib_ts/interfaces/pitches/i-pitch-list';
import React, { useContext } from 'react';
import { AdminMachinesService } from 'services/admin/machines.service';
import { getShortenedSession } from 'services/session-events.service';
import { MachinesTableToolbar } from './table-toolbar';

const IDENTIFIER = TableIdentifier.AdminMachineList;

const MAX_ERRORS_IN_TOOLTIP = 3;

const PAGE_SIZES = TABLES.PAGE_SIZES.LG;

/** provide callback to do something when username is clicked, otherwise open a mailto link as the default behaviour */
export const renderMachineUser = (
  u: IMachineUser,
  callback?: (m: IMachineUser) => void
) => {
  const trigger = callback ? (
    <Link onClick={() => callback(u)}>{u.email}</Link>
  ) : (
    <Link href={`mailto:${u.email}`}>{u.email}</Link>
  );

  return (
    <CommonTooltip
      trigger={trigger}
      content={
        <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
          <Box>
            Session: <Code>{getShortenedSession(u.session)}</Code>
          </Box>
          <Box>
            Connected:{' '}
            {format(parseISO(u.connectDate), LOCAL_DATETIME_FORMAT, {
              timeZone: LOCAL_TIMEZONE,
            })}
          </Box>
        </Flex>
      }
    />
  );
};

interface IProps {
  authCx: IAuthContext;
  teamsCx: ITeamsContext;
  machinesCx: IMachinesContext;
  usersCx: IUsersContext;
}

interface IDialogs {
  dialogAnnouncement?: number;
  dialogArchiveShots?: number;
  dialogDetails?: number;
  dialogExportSessions?: number;
  dialogFWUpgradeDate?: number;
  dialogHWChanged?: number;
  dialogInspection?: number;
  dialogLogs?: number;
  dialogPerformance?: number;
  dialogReassign?: number;
  dialogRefreshList?: number;
  dialogResetConnections?: number;
  dialogSoftReboot?: number;
  dialogSystemRestart?: number;
}

interface IState extends IDialogs {
  /** for confirmations (e.g. reset connections, archive shots) */
  menuMachineID?: string;
  manageMachine?: IAdminMachine;
  manageMachines?: IAdminMachine[];
  reassignListsPayload: IPitchListPutManyRequest;

  announcementModel?: Partial<IAnnouncement>;
}

export const MachinesTableHoC = () => {
  const props: IProps = {
    authCx: useContext(AuthContext),
    teamsCx: useContext(TeamsContext),
    machinesCx: useContext(MachinesContext),
    usersCx: useContext(UsersContext),
  };

  return <MachinesTable {...props} />;
};

class MachinesTable extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      reassignListsPayload: { filter: {}, update: {} },
    };

    this.getCheckedMenuActions = this.getCheckedMenuActions.bind(this);
    this.renderDialogs = this.renderDialogs.bind(this);
  }

  private readonly BASE_COLUMNS: ITableColumn[] = [
    {
      label: 'common.actions',
      key: ACTIONS_KEY,
      actions: [
        {
          label: 'main.inspect-machine',
          color: RADIX.COLOR.SUCCESS,
          invisibleFn: (m: IAdminMachine) => !m.connected,
          onClick: (m: IAdminMachine) => {
            this.setState({
              menuMachineID: m.machineID,
              dialogInspection: Date.now(),
            });
          },
        },
        {
          label: 'common.view-details',
          onClick: (m: IAdminMachine) => {
            this.props.machinesCx.loadDetails(m.machineID).then(() => {
              if (this.props.machinesCx.details) {
                this.setState({
                  dialogDetails: Date.now(),
                });
              }
            });
          },
        },
        {
          label: 'admin.view-performance',
          onClick: (m: IAdminMachine) => {
            this.setState({
              dialogPerformance: Date.now(),
              manageMachine: m,
            });
          },
        },
        {
          label: 'common.edit',
          onClick: (m: IAdminMachine) => {
            this.props.machinesCx.openEditorDialog(m);
          },
        },
        {
          label: 'common.logs',
          invisibleFn: () => this.props.authCx.current.role !== UserRole.admin,
          suffixIcon: <SuperAdminIcon />,
          onClick: (m: IAdminMachine) => {
            this.setState({
              dialogLogs: Date.now(),
              manageMachine: m,
            });
          },
        },
        {
          label: 'Export Sessions',
          onClick: (m: IAdminMachine) => {
            this.setState({
              dialogExportSessions: Date.now(),
              manageMachines: [m],
            });
          },
        },
        {
          label: 'admin.refresh-lists',
          color: RADIX.COLOR.WARNING,
          onClick: (m: IAdminMachine) => {
            this.setState({
              manageMachine: m,
              dialogRefreshList: Date.now(),
            });
          },
        },
        {
          label: 'common.reassign-lists',
          onClick: (m: IAdminMachine) => {
            const payload: IPitchListPutManyRequest = {
              filter: {
                _parent_id: m._id,
                _parent_def: 'team-machines',
              },
              update: {
                processed: new Date(),
                process_notes: `reassigned from machine ${m.machineID} by admin ${this.props.authCx.current.email}`,
                _parent_def: 'team-machines',
              },
            };

            this.setState({
              dialogReassign: Date.now(),
              reassignListsPayload: payload,
            });
          },
          color: RADIX.COLOR.WARNING,
        },
        {
          label: 'admin.reset-connections',
          color: RADIX.COLOR.WARNING,
          invisibleFn: (m: IAdminMachine) => !m.connected,
          onClick: (m: IAdminMachine) => {
            this.setState({
              menuMachineID: m.machineID,
              dialogResetConnections: Date.now(),
            });
          },
        },
        {
          label: 'Check Firmware',
          color: RADIX.COLOR.WARNING,
          suffixIcon: <SuperAdminIcon />,
          invisibleFn: (m: IAdminMachine) =>
            this.props.authCx.current.role !== UserRole.admin || !m.connected,
          onClick: (m: IAdminMachine) =>
            AdminMachinesService.getInstance().checkFirmware(m.machineID),
        },
        {
          label: 'Upgrade Firmware',
          color: RADIX.COLOR.WARNING,
          suffixIcon: <SuperAdminIcon />,
          invisibleFn: (m: IAdminMachine) =>
            this.props.authCx.current.role !== UserRole.admin || !m.connected,
          onClick: (m: IAdminMachine) => {
            if (!m.connected) {
              NotifyHelper.warning({
                message_md: `Please ensure ${m.machineID} is connected and try again.`,
              });
              return;
            }

            this.setState({
              manageMachines: [m],
              dialogFWUpgradeDate: Date.now(),
            });
          },
        },
        {
          label: 'Hardware Changed',
          color: RADIX.COLOR.WARNING,
          onClick: (m: IAdminMachine) => {
            this.setState({
              manageMachines: [m],
              dialogHWChanged: Date.now(),
            });
          },
        },
        {
          label: 'common.restart',
          color: RADIX.COLOR.WARNING,
          invisibleFn: (m: IAdminMachine) => !m.connected,
          onClick: (m: IAdminMachine) =>
            AdminMachinesService.getInstance().restart(RestartMode.arc, [
              m.machineID,
            ]),
        },
        {
          label: 'common.restart-os',
          color: RADIX.COLOR.DANGER,
          suffixIcon: <SuperAdminIcon />,
          invisibleFn: (m: IAdminMachine) =>
            this.props.authCx.current.role !== UserRole.admin || !m.connected,
          onClick: (m: IAdminMachine) =>
            AdminMachinesService.getInstance().restart(RestartMode.os, [
              m.machineID,
            ]),
        },
        {
          label: 'common.reset-training-data',
          color: RADIX.COLOR.DANGER,
          onClick: (m: IAdminMachine) => {
            this.setState({
              manageMachine: m,
              dialogArchiveShots: Date.now(),
            });
          },
        },
      ],
    },
    {
      label: 'common.issues',
      key: '_issues',
      sortRowsFn: (a: IMachine, b: IMachine, dir: number) => {
        const aErr = MachineHelper.getErrors(a);
        const bErr = MachineHelper.getErrors(b);
        return dir * (aErr.length > bErr.length ? -1 : 1);
      },
      formatFn: (m: IMachine) => {
        const errors = MachineHelper.getErrors(m);
        if (errors.length === 0) {
          return '';
        }

        const tooltip = (
          <Text>
            <p>Errors:</p>

            <ol>
              {errors
                .filter((_, i) => i < MAX_ERRORS_IN_TOOLTIP)
                .map((errorMsg, i) => (
                  <li key={`machine-${m._id}-error-${i}`}>{errorMsg}</li>
                ))}
            </ol>

            {errors.length > MAX_ERRORS_IN_TOOLTIP && (
              <p>...and {errors.length - MAX_ERRORS_IN_TOOLTIP} more</p>
            )}
          </Text>
        );

        return (
          <CommonTooltip
            trigger={<ExclamationTriangleIcon />}
            content={tooltip}
          />
        );
      },
    },
    {
      label: 'common.machine',
      key: 'machineID',
      tooltipFn: (m: IMachine) => {
        if (m.super) {
          return 'Super machines can access every video';
        }
      },
      /** machineID (nickname, if applicable), * for super flag */
      formatFn: (m: IMachine) => (
        <Flex direction="column" gap={RADIX.FLEX.GAP.XS} pt="1" pb="1">
          <Box>
            <Badge>
              {m.machineID} {m.super ? '*' : undefined}
            </Badge>
          </Box>
          {m.nickname && (
            <Box>
              <Text size={RADIX.TEXT.SIZE.XS} color={RADIX.COLOR.SECONDARY}>
                {m.nickname}
              </Text>
            </Box>
          )}
        </Flex>
      ),
    },
    {
      label: 'common.sandbox',
      key: 'sandbox',
      sortRowsFn: (a: IAdminMachine, b: IAdminMachine, dir: number) =>
        (a.sandbox ? 1 : 0) > (b.sandbox ? 1 : 0) ? -dir : dir,
      formatFn: (m: IAdminMachine) => (m.sandbox ? 'Yes' : 'No'),
    },
    {
      label: 'common.plate',
      subLabel: 'ft',
      key: 'plate_distance',
      formatFn: (m: IMachine) => (m.plate_distance ?? 0).toFixed(1),
    },
    {
      label: 'common.training',
      subLabel: '#',
      key: 'training_threshold',
      align: 'right',
    },
    {
      label: 'common.rapsodo-serial',
      key: 'rapsodo_serial',
      tooltipFn: (m: IMachine) => {
        if (m.sandbox && m.machineID !== m.rapsodo_serial) {
          return 'Sandbox MachineID does not match Rapsodo Serial.';
        }
      },
      formatFn: (m: IMachine) => {
        return (
          <Code
            color={
              m.sandbox && m.machineID !== m.rapsodo_serial
                ? RADIX.COLOR.DANGER
                : undefined
            }
          >
            {m.rapsodo_serial}
          </Code>
        );
      },
      sortRowsFn: (a: IMachine, b: IMachine, dir: number) =>
        (a.rapsodo_serial ?? '').localeCompare(b.rapsodo_serial ?? '') * -dir,
    },
    {
      label: 'Firmware',
      key: 'last_firmware',
      formatFn: (a: IMachine) => {
        if (!a.last_firmware) {
          return undefined;
        }

        const isBeta = a.last_firmware.startsWith('beta');

        return (
          <Code
            title={a.last_firmware}
            className="cursor-pointer"
            onClick={() => {
              if (!a.last_firmware) {
                return;
              }

              navigator.clipboard.writeText(a.last_firmware);

              NotifyHelper.success({
                message_md: t('common.x-copied-to-clipboard', {
                  x: a.last_firmware,
                }),
              });
            }}
            color={isBeta ? RADIX.COLOR.BETA : undefined}
          >
            {a.last_firmware}
          </Code>
        );
      },
    },
    {
      label: 'Last Updated',
      key: 'last_firmware_updated',
      dataType: 'datetime',
    },
    {
      label: 'admin.active-model',
      key: '_active_model',
      disableSort: true,
      tooltipFn: (m: IMachine) => {
        const activeKey = getModelKey(m);
        const activeID = getMachineActiveModelID(m);
        const activeModel = this.props.machinesCx.machineModels.find(
          (model) => model._id === activeID
        );

        if (activeModel) {
          return [
            `Name: ${activeModel.name}`,
            `Key: ${activeKey}`,
            `ID: \`${activeID ?? 'none'}\``,
          ].join('\n\n');
        }

        return 'No active model';
      },
      formatFn: (m: IMachine) => {
        const activeID = getMachineActiveModelID(m);
        const activeModel = this.props.machinesCx.machineModels.find(
          (model) => model._id === activeID
        );

        if (activeModel) {
          return activeModel.name;
        }

        return 'None';
      },
    },
    {
      label: 'admin.last-connected',
      labelTooltip: 'The domain that this machine connected to last',
      key: 'last_domain',
      sortRowsFn: (a: IMachine, b: IMachine, dir: number) =>
        (a.last_domain ?? '').localeCompare(b.last_domain ?? '') * dir,
    },
    {
      label: 'admin.status',
      labelTooltip:
        'The current status of this machine according to this environment',
      key: '_connection_status',
      sortRowsFn: (a: IAdminMachine, b: IAdminMachine, dir: number) => {
        return (a.connected ? 1 : 0) > (b.connected ? 1 : 0) ? -dir : dir;
      },
      formatFn: (m: IAdminMachine) => {
        return m.connected ? 'Online' : 'Offline';
      },
    },
    {
      label: 'admin.status-date',
      key: '_connection_date',
      labelTooltip: 'The most recent moment when connection status changed',
      sortRowsFn: (a: IAdminMachine, b: IAdminMachine, dir: number) =>
        (() => {
          if (a.connected && !b.connected) {
            return -1;
          }

          if (!a.connected && b.connected) {
            return 1;
          }

          return 1;
        })() * dir,
      formatFn: (m: IAdminMachine) => {
        if (m.connected) {
          if (m.connect_date) {
            return `${format(parseISO(m.connect_date), LOCAL_DATETIME_FORMAT, {
              timeZone: LOCAL_TIMEZONE,
            })}`;
          }

          return '';
        } else {
          if (m.disconnect_date) {
            return `${format(
              parseISO(m.disconnect_date),
              LOCAL_DATETIME_FORMAT,
              {
                timeZone: LOCAL_TIMEZONE,
              }
            )}`;
          }

          return '';
        }
      },
    },
    {
      label: 'common.active-user',
      key: 'active',
      formatFn: (m: IAdminMachine) =>
        m.active
          ? renderMachineUser(m.active, (u) => {
              this.setState({
                dialogAnnouncement: Date.now(),
                announcementModel: {
                  filter: { email: u.email },
                  message: `RE: ${m.machineID}`,
                },
              });
            })
          : 'None',
      sortRowsFn: (a: IAdminMachine, b: IAdminMachine, dir: number) =>
        (a.active?.email ?? '').localeCompare(b.active?.email ?? '') * -dir,
    },
    {
      label: 'admin.waiting',
      key: 'waiting',
      formatFn: (m: IAdminMachine) => {
        if (m.waiting.length === 0) {
          return 'None';
        }

        return (
          <ul className="no-style">
            {m.waiting.map((user) => (
              <li key={user.session}>
                {renderMachineUser(user, (u) => {
                  this.setState({
                    dialogAnnouncement: Date.now(),
                    announcementModel: {
                      filter: { email: u.email },
                      message: `RE: ${m.machineID}`,
                    },
                  });
                })}
              </li>
            ))}
          </ul>
        );
      },
      sortRowsFn: (a: IAdminMachine, b: IAdminMachine, dir: number) =>
        (a.waiting.length > b.waiting.length ? 1 : -1) * dir,
    },
  ];

  private getCheckedMenuActions(
    checkedMachines: IAdminMachine[]
  ): IMenuAction[] {
    const connected = checkedMachines.filter((m) => m.connected);

    const actions: IMenuAction[] = [];

    actions.push(
      {
        label: 'Download Config',
        invisible: connected.length === 0,
        onClick: () => {
          connected.forEach((machine) => {
            AdminMachinesService.getInstance()
              .getMachineConfig(machine.machineID)
              .then((result) => {
                if (!result?.machine) {
                  NotifyHelper.warning({
                    message_md: `Empty value for ${machine.machineID}, nothing to download.`,
                  });
                  return;
                }

                MiscHelper.saveAs(
                  new Blob([JSON.stringify(result.machine, null, 2)]),
                  `${machine.machineID}.json`
                );
              });
          });
        },
      },
      {
        label: 'Export Sessions',
        suffixIcon: <AdminIcon />,
        invisible: ![UserRole.admin, UserRole.team_admin].includes(
          this.props.authCx.current.role
        ),
        onClick: () => {
          this.setState({
            dialogExportSessions: Date.now(),
            manageMachines: checkedMachines,
          });
        },
      },
      {
        label: 'common.restart',
        color: RADIX.COLOR.WARNING,
        invisible: connected.length === 0,
        onClick: () => {
          this.setState({
            manageMachines: connected,
            dialogSystemRestart: Date.now(),
          });
        },
      },
      {
        label: 'common.restart-os',
        suffixIcon: <SuperAdminIcon />,
        color: RADIX.COLOR.DANGER,
        invisible:
          this.props.authCx.current.role !== UserRole.admin ||
          connected.length === 0,
        onClick: () => {
          this.setState({
            manageMachines: connected,
            dialogSoftReboot: Date.now(),
          });
        },
      },
      {
        label: 'Upgrade Firmware',
        color: RADIX.COLOR.WARNING,
        invisible: connected.length === 0,
        onClick: () => {
          this.setState({
            manageMachines: connected,
            dialogFWUpgradeDate: Date.now(),
          });
        },
      },
      {
        label: 'Hardware Changed',
        color: RADIX.COLOR.WARNING,
        onClick: () => {
          this.setState({
            manageMachines: checkedMachines,
            dialogHWChanged: Date.now(),
          });
        },
      }
    );

    return actions;
  }

  private renderDialogs() {
    return (
      <>
        {this.state.dialogReassign && (
          <PitchListsContext.Consumer>
            {(listsCx) => (
              <ReassignListsDialog
                key={this.state.dialogReassign}
                identifier="MachinesTableReassignListsDialog"
                authCx={this.props.authCx}
                listsCx={listsCx}
                onClose={() => this.setState({ dialogReassign: undefined })}
                refPayload={this.state.reassignListsPayload}
              />
            )}
          </PitchListsContext.Consumer>
        )}

        {this.state.dialogPerformance && this.state.manageMachine && (
          <MachinePerformanceDialog
            key={this.state.dialogPerformance}
            machine={this.state.manageMachine}
            onClose={() => this.setState({ dialogPerformance: undefined })}
          />
        )}

        {this.state.dialogExportSessions && this.state.manageMachines && (
          <ExportSessionsDialog
            key={this.state.dialogExportSessions}
            machineIDs={this.state.manageMachines.map((m) => m.machineID)}
            onClose={() => this.setState({ dialogExportSessions: undefined })}
          />
        )}

        {this.state.dialogInspection && (
          <MachineInspectionDialog
            key={this.state.dialogInspection}
            identifier="AdminInspectDialog"
            machineID={this.state.menuMachineID}
            onClose={() => this.setState({ dialogInspection: undefined })}
          />
        )}

        {this.state.dialogDetails && (
          <MachineDetailsDialog
            key={this.state.dialogDetails}
            machinesCx={this.props.machinesCx}
            onClose={() => this.setState({ dialogDetails: undefined })}
          />
        )}

        {this.state.dialogLogs && this.state.manageMachine && (
          <MachineLogsDialog
            key={this.state.dialogLogs}
            machine={this.state.manageMachine}
            machinesCx={this.props.machinesCx}
            onClose={() => this.setState({ dialogLogs: undefined })}
          />
        )}

        {this.state.dialogSystemRestart && this.state.manageMachines && (
          <SystemRestartDialog
            key={this.state.dialogSystemRestart}
            machineIDs={ArrayHelper.unique(
              this.state.manageMachines.map((m) => m.machineID)
            )}
            onClose={() => this.setState({ dialogSystemRestart: undefined })}
          />
        )}

        {this.state.dialogSoftReboot && this.state.manageMachines && (
          <SoftRebootDialog
            key={this.state.dialogSoftReboot}
            machineIDs={ArrayHelper.unique(
              this.state.manageMachines.map((m) => m.machineID)
            )}
            onClose={() => this.setState({ dialogSoftReboot: undefined })}
          />
        )}

        {this.state.dialogResetConnections && this.state.menuMachineID && (
          <ResetConnectionsDialog
            key={this.state.dialogResetConnections}
            machineID={this.state.menuMachineID}
            onClose={() => this.setState({ dialogResetConnections: undefined })}
          />
        )}

        {this.state.dialogRefreshList && this.state.manageMachine && (
          <RefreshListsDialog
            key={this.state.dialogRefreshList}
            name={this.state.manageMachine.machineID}
            parent_def="team-machines"
            parent_id={this.state.manageMachine._id}
            onClose={() => this.setState({ dialogRefreshList: undefined })}
          />
        )}

        {this.state.dialogFWUpgradeDate && this.state.manageMachines && (
          <UpgradeFirmwareDialog
            key={this.state.dialogFWUpgradeDate}
            machineIDs={ArrayHelper.unique(
              this.state.manageMachines.map((m) => m.machineID)
            )}
            onClose={() => this.setState({ dialogFWUpgradeDate: undefined })}
          />
        )}

        {this.state.dialogAnnouncement && (
          <AnnouncementDialog
            key={this.state.dialogAnnouncement}
            identifier="MachinesTableAnnouncementDialog"
            authCx={this.props.authCx}
            teamsCx={this.props.teamsCx}
            machinesCx={this.props.machinesCx}
            usersCx={this.props.usersCx}
            defaultModel={this.state.announcementModel}
            onClose={() =>
              this.setState({
                dialogAnnouncement: undefined,
                announcementModel: undefined,
              })
            }
          />
        )}

        {this.state.dialogArchiveShots && this.state.manageMachine && (
          <ArchiveShotsDialog
            key={this.state.dialogArchiveShots}
            machine={this.state.manageMachine}
            onClose={() => this.setState({ dialogArchiveShots: undefined })}
          />
        )}
      </>
    );
  }

  render() {
    const filtered = this.props.machinesCx.filteredMachines;

    const pagination: ITablePageable = {
      identifier: IDENTIFIER,
      total: filtered.length,
      enablePagination: true,
      pageSizes: PAGE_SIZES,
    };

    const sort: ITableSortable = {
      enableSort: true,
      defaultSort: {
        key: 'machineID',
        dir: -1,
      },
    };

    const select: ITableSelectable = {
      enableSelect: true,
    };

    return (
      <ErrorBoundary componentName="MachinesTable">
        <CheckedProvider data={filtered}>
          <FlexTableWrapper
            gap={RADIX.FLEX.GAP.SECTION}
            header={<AdminTabNav active={SubSectionName.Machines} />}
            table={
              <GlobalContext.Consumer>
                {(globalCx) => (
                  <CommonTableHoC
                    id="AdminMachines"
                    data-testid="AdminMachines"
                    toolbarContent={<MachinesTableToolbar />}
                    displayColumns={this.BASE_COLUMNS}
                    displayData={filtered}
                    enableListener={globalCx.dialogs.length === 0}
                    checkboxColumnIndex={0}
                    checkedActions={this.getCheckedMenuActions}
                    {...pagination}
                    {...select}
                    {...sort}
                    vFlex
                  />
                )}
              </GlobalContext.Consumer>
            }
          />
        </CheckedProvider>

        {this.renderDialogs()}

        {this.state.dialogHWChanged && this.state.manageMachines && (
          <ConfirmHWChangedDialog
            key={this.state.dialogHWChanged}
            machines={this.state.manageMachines}
          />
        )}
      </ErrorBoundary>
    );
  }
}
