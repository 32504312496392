import { IntercomEvents } from 'enums/intercom.enums';
import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

export const IntercomListener = () => {
  const intercom = useIntercom();

  useEffect(() => {
    document.addEventListener(IntercomEvents.ShowArticle, (message) => {
      const data = (message as CustomEvent).detail as { id: number };
      intercom.showArticle(data.id);
    });
  }, []);

  useEffect(() => {
    document.addEventListener(IntercomEvents.ShowNewMessage, (message) => {
      intercom.showNewMessage();
    });
  }, []);

  return <></>;
};
