export const MLB_TEAMS = [
  'Arizona Diamondbacks',
  'Atlanta Braves',
  'Baltimore Orioles',
  'Boston Red Sox',
  'Chicago White Sox',
  'Chicago Cubs',
  'Cincinnati Reds',
  'Cleveland Guardians',
  'Colorado Rockies',
  'Detroit Tigers',
  'Houston Astros',
  'Kansas City Royals',
  'Los Angeles Angels',
  'Los Angeles Dodgers',
  'Miami Marlins',
  'Milwaukee Brewers',
  'Minnesota Twins',
  'New York Yankees',
  'New York Mets',
  'Oakland Athletics',
  'Philadelphia Phillies',
  'Pittsburgh Pirates',
  'San Diego Padres',
  'San Francisco Giants',
  'Seattle Mariners',
  'St. Louis Cardinals',
  'Tampa Bay Rays',
  'Texas Rangers',
  'Toronto Blue Jays',
  'Washington Nationals',
];

export enum GameStatus {
  Empty = '',
  Ignore = 'Ignore',
  InProgress = 'In Progress',
  NoGame = 'No Game',
  Unknown = 'Unknown',
  Unreachable = 'Unreachable',
}

/** if a team's current game status is in this list, restrictions should apply (e.g. offline mode, block writing) */
export const GAME_STATUS_BLACKLIST: GameStatus[] = [
  GameStatus.InProgress,
  GameStatus.Unreachable,
  GameStatus.Unknown,
];

export const GAME_STATUS_DISABLE_ACTION_MSG =
  'This action is disabled while your team is in a game.';

export const CURRENT_MLB_SEASON = 2024;
