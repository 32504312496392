import { ErrorBoundary } from 'components/common/error-boundary';
import { SidebarNavigationTrigger } from 'components/main/sidebar/nav-trigger';
import { SectionsContext } from 'contexts/sections.context';
import { useContext } from 'react';

const COMPONENT_NAME = 'AdminSections';

export const AdminSections = () => {
  const { active, adminSections, tryChangeSection } =
    useContext(SectionsContext);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      {adminSections
        ?.filter((s) => !s.invisible)
        .map((s, i) => {
          return (
            <SidebarNavigationTrigger
              key={`admin-section-${i}`}
              label={s.label}
              icon={<s.icon />}
              active={active.section === s.value}
              onClick={() => {
                tryChangeSection({
                  trigger: `${COMPONENT_NAME} > section change`,
                  section: s.value,
                });
              }}
            />
          );
        })}
    </ErrorBoundary>
  );
};
