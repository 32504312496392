import { Code, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { MachinesContext } from 'contexts/admin/machines.context';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';
import { AdminMachinesService } from 'services/admin/machines.service';

const COMPONENT_NAME = 'ResetConnectionsDialog';

interface IProps {
  machineID: string;
  onClose: () => void;
}

interface IState {}

export class ResetConnectionsDialog extends React.Component<IProps, IState> {
  state: IState = {};

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <MachinesContext.Consumer>
          {(machinesCx) => (
            <CommonDialog
              identifier={COMPONENT_NAME}
              width={RADIX.DIALOG.WIDTH.MD}
              title="Reset Connections"
              content={
                <Text>
                  <p>
                    All users (active and waiting) on{' '}
                    <Code>{this.props.machineID}</Code> will be disconnected if
                    you proceed.
                  </p>
                  <p>Please ensure the machine is not in use.</p>
                </Text>
              }
              buttons={[
                {
                  label: 'common.reset-connections',
                  color: RADIX.COLOR.WARNING,
                  onClick: () => {
                    AdminMachinesService.getInstance()
                      .resetConnections(this.props.machineID)
                      .then(() => {
                        NotifyHelper.info({
                          message_md: `Connections reset for ${this.props.machineID}! Refreshing table...`,
                        });
                        machinesCx.refresh();
                        this.props.onClose();
                      })
                      .catch((error) => {
                        console.error(error);
                        NotifyHelper.info({
                          message_md: `Failed to reset connections for ${this.props.machineID}, please check console.`,
                        });
                      });
                  },
                },
              ]}
              onClose={() => this.props.onClose()}
            />
          )}
        </MachinesContext.Consumer>
      </ErrorBoundary>
    );
  }
}
