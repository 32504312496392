import { Badge, Flex, Spinner, Table, Text } from '@radix-ui/themes';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { format } from 'date-fns-tz';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { t } from 'i18next';
import { IBaseDialog } from 'interfaces/i-dialogs';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IReadyMsg } from 'lib_ts/interfaces/i-machine-msg';
import React from 'react';

const TRUTHY_VALUES = [true, 'True'];

const COMPONENT_NAME = 'R2FStatusDialog';

interface IProps extends IBaseDialog {}

interface IState {
  lastR2F?: IReadyMsg;
  lastUpdate?: Date;
  lastR2FRows: IRow[];
}

const PROC_CATEGORIES: {
  name: string;
  required: string[];
  optional?: string[];
}[] = [
  {
    name: 'common.position',
    required: ['px', 'py', 'pz'],
  },
  {
    name: 'common.wheels',
    required: ['w1', 'w2', 'w3'],
  },
  {
    name: 'common.angles',
    required: ['a1', 'a2', 'a3', 'tilt', 'yaw'],
  },
  {
    name: 'common.ball',
    required: ['firing', 'ball_count'],
  },
  {
    name: 'common.orientation',
    required: ['qw', 'qx', 'qy', 'qz'],
  },
  {
    name: 'common.miscellaneous',
    required: ['video_uuid', 'training'],
    optional: ['od_busy'],
  },
];

interface IRow {
  category: string;
  ready: string[];
  notReady: string[];
}

export class R2FStatusDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      lastR2FRows: [],
    };

    this.handleReadyToFire = this.handleReadyToFire.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  componentDidMount() {
    WebSocketHelper.on(WsMsgType.M2U_ReadyToFire, this.handleReadyToFire);
  }

  componentWillUnmount() {
    /** stop listening to websocket */
    WebSocketHelper.remove(WsMsgType.M2U_ReadyToFire, this.handleReadyToFire);
  }

  /** (*) -> isReady */
  private handleReadyToFire(event: CustomEvent) {
    const msg: IReadyMsg = event.detail;

    if (!msg?.data) {
      // don't update unless the data is well-formed
      return;
    }

    const readyDict = msg.data as any;

    const rows: IRow[] = PROC_CATEGORIES.map((c) => {
      const r: IRow = {
        category: c.name,
        ready: [],
        notReady: [],
      };

      c.required.forEach((key) => {
        const ready = readyDict[key] === true;

        if (TRUTHY_VALUES.includes(ready)) {
          r.ready.push(key);
        } else {
          r.notReady.push(key);
        }
      });

      if (c.optional) {
        c.optional.forEach((key) => {
          const ready = readyDict[key];

          switch (ready) {
            case true: {
              r.ready.push(key);
              break;
            }

            case false: {
              r.notReady.push(key);
              break;
            }

            default: {
              // don't do anything, the optional key wasn't sent
              break;
            }
          }
        });
      }

      return r;
    });

    this.setState({
      lastR2F: msg,
      lastUpdate: new Date(),
      lastR2FRows: rows,
    });
  }

  private renderContent() {
    if (!this.state.lastR2F?.data) {
      return <Spinner />;
    }

    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
        <Table.Root>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>
                {t('common.category')}
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>
                {t('common.ready')}
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>
                {t('common.not-ready')}
              </Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.lastR2FRows.map((row, i) => (
              <Table.Row key={i}>
                <Table.Cell>{t(row.category)}</Table.Cell>
                <Table.Cell>
                  {row.ready.map((m, j) => (
                    <Badge key={`r-${j}`} mr="1" color={RADIX.COLOR.SUCCESS}>
                      {m}
                    </Badge>
                  ))}
                </Table.Cell>
                <Table.Cell>
                  {row.notReady.map((m, j) => (
                    <Badge key={`nr-${j}`} mr="1" color={RADIX.COLOR.DANGER}>
                      {m}
                    </Badge>
                  ))}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>

        {this.state.lastUpdate && (
          <Text>
            {format(this.state.lastUpdate, LOCAL_DATETIME_FORMAT, {
              timeZone: LOCAL_TIMEZONE,
            })}
          </Text>
        )}
      </Flex>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={this.props.identifier}
          width={RADIX.DIALOG.WIDTH.MD}
          title="common.machine-status"
          content={this.renderContent()}
          onClose={this.props.onClose}
        />
      </ErrorBoundary>
    );
  }
}
