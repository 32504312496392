import { InfoCircledIcon } from '@radix-ui/react-icons';
import { Box, Flex, Text } from '@radix-ui/themes';
import { CommonTooltip } from 'components/common/tooltip';
import { t } from 'i18next';
import { IInputLabel } from 'interfaces/forms/_base';
import { RADIX } from 'lib_ts/enums/radix-ui';

export const CommonInputLabel = (props: IInputLabel) => {
  if (!props.label) {
    return <></>;
  }

  const label = [props.label, props.labelOptional ? '(optional)' : '']
    .filter((s) => s)
    .map((s) => t(s).toString())
    .join(' ');

  return (
    <Flex gap={RADIX.FLEX.GAP.INPUT} justify="between">
      <Flex gap={RADIX.FLEX.GAP.XS}>
        <Box>
          <Text color={props.labelColor} truncate>
            {/* &nbsp; required here to reserve the vertical space if label is a whitespace char */}
            {label}&nbsp;
          </Text>
        </Box>
        {props.labelIcon && (
          <Box style={{ marginTop: '2px' }}>{props.labelIcon}</Box>
        )}
      </Flex>

      {props.iconTooltip && (
        <Box>
          <CommonTooltip
            text_md={props.iconTooltip}
            trigger={<InfoCircledIcon />}
          />
        </Box>
      )}
    </Flex>
  );
};
