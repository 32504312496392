import { IServerResponse } from 'lib_ts/interfaces/common/i-server-response';
import {
  ILoginEvent,
  IMSTargetEvent,
  ISessionEvent,
  ISessionEventDetails,
} from 'lib_ts/interfaces/i-session-event';
import { ISessionSummary } from 'lib_ts/interfaces/i-session-summary';
import { IRapsodoShot } from 'lib_ts/interfaces/training/i-rapsodo-shot';
import { BaseRESTService } from 'services/_base-rest.service';

export const MAX_USER_SESSIONS = 250;

export const getShortenedSession = (id: string): string => {
  const parts = id.split('-');
  const index = id.substring(id.lastIndexOf('.'));
  const shortSession =
    parts.length === 6
      ? `${parts[0]}-${parts[1]}*${index}`
      : `${parts[0]}*${index}`;
  return shortSession;
};

export class SessionEventsService extends BaseRESTService {
  private static instance: SessionEventsService;

  private static getInstance(): SessionEventsService {
    if (!SessionEventsService.instance) {
      SessionEventsService.instance = new SessionEventsService();
    }

    return SessionEventsService.instance;
  }

  private constructor() {
    super({
      controller: 'sessions',
    });
  }

  /** should only return sessions which contain at least 1 fire event */
  static async getUserSessions(
    userID: string,
    max: number
  ): Promise<ISessionSummary[]> {
    return await this.getInstance().get({
      uri: `last/user/${max}`,
      params: {
        userID: userID,
      } as any,
    });
  }

  /** should only return sessions which contain at least 1 fire event */
  static async getMachineSessions(
    machineIDs: string[],
    start_date: Date,
    end_date: Date,
    max: number
  ): Promise<ISessionSummary[]> {
    return await this.getInstance().get({
      uri: `last/machine/${max}`,
      params: {
        machineIDs: machineIDs.join(','),
        start_date: start_date.toISOString(),
        end_date: end_date.toISOString(),
      } as any,
    });
  }

  static async getFireCount(session: string): Promise<number> {
    return await this.getInstance().get({
      uri: 'events/fire-count',
      params: {
        session: session,
      } as any,
    });
  }

  static async postEvent(
    data: Partial<ISessionEventDetails>
  ): Promise<IServerResponse> {
    return await this.getInstance().post(
      {
        uri: 'events',
      },
      data
    );
  }

  static async getEventsForSession(
    session: string
  ): Promise<ISessionEventDetails[]> {
    return await this.getInstance().get({
      uri: 'events',
      params: {
        session: session,
      } as any,
    });
  }

  /** returns the (single) login event for the session */
  static async getLoginEventForSession(session: string): Promise<ILoginEvent> {
    return await this.getInstance().get({
      uri: 'events/login',
      params: {
        session: session,
      } as any,
    });
  }

  static async update(data: Partial<ISessionEvent>): Promise<ISessionEvent> {
    if (!data._id) {
      throw new Error('Cannot put without id');
    }
    return await this.getInstance().put(
      {
        uri: 'event',
        params: {
          event_id: data._id,
        } as any,
      },
      data
    );
  }

  static async getRapsodoShotsForSession(
    session: string
  ): Promise<IRapsodoShot[]> {
    return await this.getInstance().get({
      uri: 'rapsodo-shots',
      params: {
        session: session,
      } as any,
    });
  }

  static async getExportShotsForSession(
    session: string
  ): Promise<IMSTargetEvent[]> {
    return await this.getInstance().get({
      uri: 'export/shots',
      params: {
        session: session,
      } as any,
    });
  }
}
