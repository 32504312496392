export enum QueueID {
  RepeatAll = 'RepeatAll',
  RepeatOne = 'RepeatOne',
  ShuffleEach = 'ShuffleEach',
}

export interface IQueueDefinition {
  id: QueueID;
  label: string;
  tooltip: string;
  icon: JSX.Element;
}
