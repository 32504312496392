export const CHART_COLORS = [
  // '#BC76C5',
  'var(--pink-11)',
  // '#EE7092',
  'var(--ruby-11)',
  // '#E7B386',
  'var(--orange-11)',
  // '#D0EF72',
  'var(--teal-11)',
  // '#A4F0A6',
  'var(--grass-11)',
];

/** teals */
export const MACHINE_COLORS = [
  // '#CBE4DE',
  'var(--sky-11)',
  // '#0E8388',
  'var(--mint-11)',
  // '#83A2BC',
  'var(--violet-11)',
];

/** oranges */
export const MODEL_COLORS = [
  // '#F2CD5C',
  'var(--yellow-11)',
  // '#F2921D',
  'var(--orange-11)',
  // '#F2875C',
  'var(--tomato-11)',
];
