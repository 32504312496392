import { NotifyHelper } from 'classes/helpers/notify.helper';
import { IAimingBaseResult } from 'interfaces/i-pitch-aiming';
import { AimingHelper } from 'lib_ts/classes/aiming.helper';
import { EllipseHelper } from 'lib_ts/classes/ellipse.helper';
import { getMergedMSDict, getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { EllipseName } from 'lib_ts/enums/ellipses';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { IEllipse } from 'lib_ts/interfaces/i-ellipses';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import { IPitch, IPlateLoc } from 'lib_ts/interfaces/pitches';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import { SessionEventsService } from 'services/session-events.service';

const CHECK_ASSERTS = false;

export class AimingContextHelper {
  static detectSafetyEvent = (
    newShot: IMachineShot,
    ellipse: IEllipse,
    target: IPlateLoc
  ) => {
    const actual: IPlateLoc = TrajHelper.getPlateLoc(newShot.traj);
    const actualEllipse = EllipseHelper.getEllipseContainingPoint({
      origin: target,
      point: actual,
      ellipse: ellipse,
      isPointRotated: true,
    });

    if (!actualEllipse) {
      SessionEventsService.postEvent({
        category: 'safety',
        tags: 'ellipse',
        data: {
          event: 'shot detected outside of all ellipses',
          target: target,
          actual: actual,
          unscaledEllipse: ellipse,
        },
      });
      return;
    }

    if (actualEllipse.name === EllipseName.Conf95) {
      SessionEventsService.postEvent({
        category: 'safety',
        tags: 'ellipse',
        data: {
          event: `shot detected within ${actualEllipse.name} ellipse`,
          target: target,
          actual: actual,
          unscaledEllipse: ellipse,
          foundIn: actualEllipse.name,
          scaling: actualEllipse.factor,
        },
      });
      return;
    }
  };

  static getAdHocAimed(config: {
    source: string;
    machine: IMachine;
    pitch: IPitch;
    plate: IPlateLoc;
    usingShots: IMachineShot[];
  }): IAimingBaseResult | undefined {
    const FN_NAME = `${config.source} > getAimedPitch`;
    const PLATE_PRECISION_FT = 0.01;

    const ms = getMSFromMSDict(config.pitch, config.machine).ms;
    if (!ms) {
      console.warn(`${FN_NAME}: no ms found`);
      return;
    }

    if (!config.pitch.bs || !ms || !config.pitch.traj) {
      /** should not trigger, but will be filtered out to be safe */
      console.warn({
        event: `${FN_NAME}: cannot rotate pitch, invalid data detected`,
        pitch: config.pitch,
        invalid_bs: !config.pitch.bs,
        invalid_ms: !ms,
        invalid_traj: !config.pitch.traj,
      });

      NotifyHelper.warning({
        message_md: `Data for pitch "${config.pitch.name}" is invalid. Please see console for more info.`,
        delay_ms: 0,
      });

      return;
    }

    const aimedChars = AimingHelper.aimWithShots({
      source: FN_NAME,
      chars: {
        bs: config.pitch.bs,
        ms: ms,
        traj: config.pitch.traj,

        seams: config.pitch.seams,
        breaks: config.pitch.breaks,
        priority: config.pitch.priority ?? BuildPriority.Spins,
      },
      release: config.pitch.bs,
      plate_location: config.plate,
      plate_distance: config.machine.plate_distance,
      shots: config.usingShots,
      stepSize: undefined,
    });

    if (CHECK_ASSERTS) {
      const aimedPlate = TrajHelper.getPlateLoc(aimedChars.traj);

      console.assert(
        Math.abs(aimedChars.plate.plate_x - config.plate.plate_x) <
          PLATE_PRECISION_FT,
        `large plate_x delta (${(
          aimedChars.plate.plate_x - config.plate.plate_x
        ).toFixed(
          4
        )}) between rotated plate (${aimedChars.plate.plate_x.toFixed(
          4
        )}) and target plate (${config.plate.plate_x.toFixed(4)})`
      );

      console.assert(
        Math.abs(aimedChars.plate.plate_z - config.plate.plate_z) <
          PLATE_PRECISION_FT,
        `large plate_z delta (${(
          aimedChars.plate.plate_z - config.plate.plate_z
        ).toFixed(
          4
        )}) between rotated plate (${aimedChars.plate.plate_z.toFixed(
          4
        )}) and target plate (${config.plate.plate_z.toFixed(4)})`
      );

      console.assert(
        Math.abs(aimedPlate.plate_x - config.plate.plate_x) <
          PLATE_PRECISION_FT,
        `large plate_x delta (${(
          aimedPlate.plate_x - config.plate.plate_x
        ).toFixed(4)}) between rotated traj plate (${aimedPlate.plate_x.toFixed(
          4
        )}) and target plate (${config.plate.plate_x.toFixed(4)})`
      );

      console.assert(
        Math.abs(aimedPlate.plate_z - config.plate.plate_z) <
          PLATE_PRECISION_FT,
        `large plate_z delta (${(
          aimedPlate.plate_z - config.plate.plate_z
        ).toFixed(4)}) between rotated traj plate (${aimedPlate.plate_z.toFixed(
          4
        )}) and target plate (${config.plate.plate_z.toFixed(4)})`
      );
    }

    const output: IAimingBaseResult = {
      pitch: {
        ...config.pitch,
        bs: aimedChars.bs,
        traj: aimedChars.traj,
        msDict: getMergedMSDict(
          config.machine,
          [aimedChars.ms],
          config.pitch.msDict
        ),
      },
      ms: aimedChars.ms,
      usingShots: config.usingShots,
    };

    return output;
  }
}
