import slugify from 'slugify';

type stringish = string | null | undefined;

export class RouteHelper {
  // fragments will not get slugify treatment, to preserve the integrity of any ID values provided
  static getSlug = (
    routes: stringish[],
    fragments: stringish[] = []
  ): string => {
    const safeParts = [
      ...routes
        .filter((s) => !!s)
        .map((m) =>
          slugify(m as string, { lower: true, strict: true, trim: true })
        ),
      ...fragments,
    ].filter((s) => !!s);

    return '/' + safeParts.join('/');
  };
}
