import { ModelStatus, TagPrefix } from '../enums/machine-models.enums';
import { BallType } from '../enums/machine.enums';
import { IMachineModel } from '../interfaces/modelling/i-machine-model';

export class ModelHelper {
  static getStatus = (model: Partial<IMachineModel>): ModelStatus => {
    if (model.status) {
      return model.status;
    }

    const result = (model.tags ?? [])
      .find((t) => t.startsWith(`${TagPrefix.Status}:`))
      ?.split(':')[1] as ModelStatus;

    return result ?? ModelStatus.Unknown;
  };

  static getBallType = (model: Partial<IMachineModel>): BallType => {
    if (model.ball_type) {
      return model.ball_type;
    }

    const result = (model.tags ?? [])
      .find((t) => t.startsWith(`${TagPrefix.BallType}:`))
      ?.split(':')[1] as BallType;

    return result;
  };

  // todo: could probably deprecate this once we have some scripts to repair all the models in the db
  static getNonSpecialTags = (tags?: string[]) => {
    return (tags ?? [])
      .filter((t) => !t.startsWith(`${TagPrefix.Status}:`))
      .filter((t) => !t.startsWith(`${TagPrefix.BallType}:`));
  };
}
