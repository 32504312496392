import { PlusIcon, UpdateIcon } from '@radix-ui/react-icons';
import { Box, Grid } from '@radix-ui/themes';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTableButton } from 'components/common/table/button';
import { MachinesContext } from 'contexts/admin/machines.context';
import { TeamsContext } from 'contexts/admin/teams.context';
import { AuthContext } from 'contexts/auth.context';
import { CheckedContext } from 'contexts/layout/checked.context';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { useContext, useMemo } from 'react';

const ENABLE_FILTER_CREATED_DATE = false;

export const MachinesTableToolbar = () => {
  const { resetChecked } = useContext(CheckedContext);
  const {
    filters,
    loading,
    machines,
    options,
    openEditorDialog,
    refresh,
    setFilters,
  } = useContext(MachinesContext);

  const teamsCx = useContext(TeamsContext);

  const authCx = useContext(AuthContext);

  const machineOptions = useMemo(
    () =>
      machines.map((m) => {
        const o: IOption = {
          label: `${m.machineID} (${m.nickname ?? 'no nickname'})`,
          value: m.machineID,
        };

        return o;
      }),
    [machines]
  );

  const dateOptions = useMemo(
    () =>
      options._created.map((m) => {
        const o: IOption = {
          label: m,
          value: m,
        };

        return o;
      }),
    [options._created]
  );

  const firmwareOptions = useMemo(
    () =>
      options.firmware.map((m) => {
        const o: IOption = {
          label: m,
          value: m,
        };

        return o;
      }),
    [options.firmware]
  );

  return (
    <Grid
      columns={ENABLE_FILTER_CREATED_DATE ? '7' : '6'}
      gap={RADIX.FLEX.GAP.SM}
    >
      <Box>
        <CommonSelectInput
          id="machines-connected"
          placeholder="Connected"
          name="filterConnected"
          options={[
            { label: 'No', value: 'false' },
            { label: 'Yes', value: 'true' },
          ]}
          value={filters.isConnected?.toString()}
          onOptionalBooleanChange={(v) => {
            resetChecked();
            setFilters({ isConnected: v });
          }}
          optional
        />
      </Box>
      <Box>
        <CommonSearchInput
          id="machines-machineID"
          placeholder="common.machine"
          options={machineOptions}
          values={filters.machineIDs}
          onChange={(v) => {
            resetChecked();
            setFilters({
              machineIDs: v,
            });
          }}
          multiple
          optional
        />
      </Box>
      <Box>
        <CommonSearchInput
          id="machines-team"
          placeholder="common.team"
          options={teamsCx.options.teams}
          values={filters.teams}
          onChange={(v) => {
            resetChecked();
            setFilters({
              teams: v,
            });
          }}
          multiple
          optional
        />
      </Box>

      {ENABLE_FILTER_CREATED_DATE && (
        <Box>
          <CommonSearchInput
            id="machines-created"
            placeholder="common.date-added"
            options={dateOptions}
            values={filters.created}
            onChange={(v) => {
              resetChecked();
              setFilters({ created: v });
            }}
            multiple
            reverseSort
            optional
          />
        </Box>
      )}

      <Box>
        <CommonSearchInput
          id="machines-firmware"
          placeholder="Firmware"
          options={firmwareOptions}
          values={filters.firmware}
          onChange={(v) => {
            resetChecked();
            setFilters({ firmware: v });
          }}
          multiple
          optional
        />
      </Box>
      <Box>
        <CommonSelectInput
          id="machines-active"
          placeholder="Active User"
          name="filterActiveUser"
          options={[
            { label: 'No', value: 'false' },
            { label: 'Yes', value: 'true' },
          ]}
          value={filters.hasActive?.toString()}
          onOptionalBooleanChange={(v) => {
            resetChecked();
            setFilters({ hasActive: v });
          }}
          optional
        />
      </Box>
      <Box>
        <CommonSelectInput
          id="machines-sandbox"
          placeholder="Sandbox"
          name="filterSandbox"
          options={[
            { label: 'No', value: 'false' },
            { label: 'Yes', value: 'true' },
          ]}
          value={filters.isSandbox?.toString()}
          onOptionalBooleanChange={(v) => {
            resetChecked();
            setFilters({ isSandbox: v });
          }}
          optional
        />
      </Box>

      <CommonTableButton
        color={RADIX.COLOR.NEUTRAL}
        icon={<UpdateIcon />}
        label="common.refresh"
        disabled={loading}
        onClick={() => {
          resetChecked();
          refresh();
        }}
      />

      {authCx.current.role === UserRole.admin && (
        <CommonTableButton
          color={RADIX.COLOR.SUCCESS}
          icon={<PlusIcon />}
          label="common.create"
          disabled={loading}
          onClick={() => openEditorDialog(undefined)}
        />
      )}
    </Grid>
  );
};
