import { NotifyHelper } from 'classes/helpers/notify.helper';
import { getPitchYearOptions } from 'classes/helpers/pitch-list.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { PitchListContext } from 'contexts/pitch-lists/list.context';
import { DEFAULT_ACCEPT_BTN } from 'interfaces/i-dialogs';
import { SHUFFLE_FREQUENCY_OPTIONS } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitch, IPitchMetadata } from 'lib_ts/interfaces/pitches';
import { useState } from 'react';

const COMPONENT_NAME = 'EditPitchDialog';

interface IProps {
  pitch: IPitch;
  onClose: (result: IPitch | undefined) => void;
}

export const EditPitchDialog = (props: IProps) => {
  const [metadata, setMetadata] = useState<Partial<IPitchMetadata>>(
    props.pitch
  );

  const [yearOptions] = useState(getPitchYearOptions());

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <PitchListContext.Consumer>
        {(listCx) => (
          <CommonDialog
            identifier={COMPONENT_NAME}
            title="pl.edit-pitch-metadata"
            loading={listCx.loading}
            width={RADIX.DIALOG.WIDTH.SM}
            description={`Use the form below to update metadata for ${
              props.pitch.name?.trim() || '(no name)'
            }`}
            content={
              <CommonFormGrid columns={1}>
                <CommonTextInput
                  id="pitch-name"
                  label="pl.pitch-name"
                  name="name"
                  value={metadata.name}
                  onChange={(e) =>
                    setMetadata({
                      ...metadata,
                      name: e,
                    })
                  }
                />
                <CommonSelectInput
                  id="pitch-year"
                  name="year"
                  label="common.year"
                  options={yearOptions}
                  value={metadata.year}
                  onChange={(v) =>
                    setMetadata({
                      ...metadata,
                      year: v,
                    })
                  }
                  optional
                />
                <CommonSelectInput
                  id="pitch-frequency"
                  name="frequency"
                  label="common.frequency"
                  options={SHUFFLE_FREQUENCY_OPTIONS}
                  value={metadata.frequency?.toString()}
                  onNumericChange={(v) =>
                    setMetadata({
                      ...metadata,
                      frequency: v,
                    })
                  }
                  skipSort
                  optional
                />
              </CommonFormGrid>
            }
            buttons={[
              {
                ...DEFAULT_ACCEPT_BTN,
                onClick: async () => {
                  if (!metadata.name || metadata.name.trim().length === 0) {
                    NotifyHelper.error({
                      message_md:
                        'Please provide a non-empty name for your pitch.',
                    });
                    return;
                  }

                  const payload: Partial<IPitch> = {
                    _id: props.pitch._id,
                    name: metadata.name.trim(),
                    year: metadata.year,
                    frequency: metadata.frequency,
                  };

                  const result = await listCx.updatePitches({
                    payloads: [payload],
                  });

                  if (result) {
                    props.onClose(result[0]);
                  }
                },
              },
            ]}
            onClose={() => props.onClose(undefined)}
          />
        )}
      </PitchListContext.Consumer>
    </ErrorBoundary>
  );
};
