import { IServerResponse } from 'lib_ts/interfaces/common/i-server-response';
import { IAdminTeam } from 'lib_ts/interfaces/i-team';
import { BaseRESTService } from 'services/_base-rest.service';

export class AdminTeamsService extends BaseRESTService {
  private static instance: AdminTeamsService;
  static getInstance(): AdminTeamsService {
    if (!AdminTeamsService.instance) {
      AdminTeamsService.instance = new AdminTeamsService();
    }

    return AdminTeamsService.instance;
  }

  private constructor() {
    super({
      controller: 'admin-teams',
    });
  }

  /** get all teams, limited to user's team if not a super admin */
  async getTeams(global: boolean): Promise<IAdminTeam[]> {
    return await this.get({
      uri: '',
      params: { global: global } as any,
    });
  }

  async create(data: Partial<IAdminTeam>): Promise<IServerResponse> {
    return await this.post(
      {
        uri: '',
      },
      data
    );
  }

  async update(data: Partial<IAdminTeam>): Promise<IServerResponse> {
    if (!data._id) {
      throw new Error('Cannot put without id');
    }

    return await this.put(
      {
        uri: data._id,
      },
      data
    );
  }
}
