import { User } from '@auth0/auth0-react';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { GameStatus } from 'lib_ts/enums/mlb.enums';
import {
  ILoginPayload,
  ILoginResult,
  ILoginSuccess,
  IResetPasswordPayload,
} from 'lib_ts/interfaces/common/i-login';
import { IServerResponse } from 'lib_ts/interfaces/common/i-server-response';
import { IUser } from 'lib_ts/interfaces/i-user';
import { IReassignOptions } from 'lib_ts/interfaces/pitches/i-pitch-list';
import { BaseRESTService, IGenericResponse } from 'services/_base-rest.service';

// temp: change this back to Unknown once offline mode is rolled out + enforced
const DEFAULT_FALLBACK_STATUS = GameStatus.NoGame;

export class AuthService extends BaseRESTService {
  private static instance: AuthService;
  static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }

    return AuthService.instance;
  }

  private constructor() {
    super({
      controller: 'auth',
    });
  }

  /** consumes the reset password code if successful (i.e. reset URL will not be useful anymore) */
  async resetPassword(config: IResetPasswordPayload): Promise<IServerResponse> {
    return await this.post({ uri: 'reset-password' }, config);
  }

  async loginAuth0(token: string, user: User): Promise<ILoginResult> {
    return await this.post(
      { uri: 'login/auth0', headers: { Authorization: `Bearer ${token}` } },
      user
    );
  }

  async loginLegacy(payload: ILoginPayload): Promise<ILoginResult> {
    return await this.post({ uri: 'login/legacy' }, payload);
  }

  /**
   * JSON result: { error: boolean, message: string, success: boolean }
   * @param token
   * @returns boolean describing whether the token is valid according to server
   */
  async checkToken(token: string): Promise<boolean> {
    return await this.post({ uri: 'check-token' }, { token })
      .then((result: { error: any; message: string; success: boolean }) => {
        if (result.error) {
          NotifyHelper.warning({ message_md: result.message });
          console.error(result.error);
        }

        return result.success;
      })
      .catch((error) => {
        NotifyHelper.error({
          message_md:
            'There was a problem checking your token. Please login again.',
        });
        console.error(error);
        return false;
      });
  }

  /** changes what the server is overriding game status results to, use GameStatus.Empty to clear the override and resume regular function */
  async setGameStatusOverride(status: GameStatus): Promise<boolean> {
    return await this.put({ uri: 'game-status/override' }, { status: status })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md:
              result.error ??
              'Encountered an error while setting game status override.',
          });
          return false;
        }

        return result.success;
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md:
            'Encountered an error while setting game status override.',
        });
        return false;
      });
  }

  /** what the server is overriding game status results to (if anything) */
  async getGameStatusOverride(): Promise<GameStatus> {
    return await this.get({ uri: 'game-status/override' })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md:
              result.error ??
              'Encountered an error while getting game status override.',
          });
          return DEFAULT_FALLBACK_STATUS;
        }

        return result.data as GameStatus;
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md:
            'Encountered an error while getting game status override.',
        });
        return DEFAULT_FALLBACK_STATUS;
      });
  }

  /** changes whether the server is checking game status from true => false or vice-versa */
  async toggleCheckGameStatus(): Promise<boolean | undefined> {
    return await this.get({ uri: 'game-status/check/toggle' })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md:
              result.error ??
              'Encountered an error while toggling game status check.',
          });
          return undefined;
        }

        return result.data.status as boolean;
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: 'Encountered an error while toggling game status check.',
        });
        return undefined;
      });
  }

  /** whether the server is checking game status */
  async getCheckGameStatus(): Promise<boolean | undefined> {
    return await this.get({ uri: 'game-status/check' })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md:
              result.error ??
              'Encountered an error while getting game status check.',
          });
          return undefined;
        }

        return result.data.status as boolean;
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: 'Encountered an error while getting game status check.',
        });
        return undefined;
      });
  }

  /** what the server received from batrack on its most recent call */
  async getGameStatuses(): Promise<any[]> {
    return await this.get({ uri: 'game-status/summary' })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md:
              result.error ??
              'Encountered an error while getting game status check.',
          });
          return [];
        }

        return result.data;
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: 'Encountered an error while getting game status check.',
        });
        return [];
      });
  }

  /**
   * which teams, machines, and users should be available for this user when reassigning pitches
   */
  async reassignOptions(): Promise<IReassignOptions> {
    return await this.get({ uri: 'reassign-options' });
  }

  /** gets a new session id (and inserts a login event) without having to actually log out and in again */
  async newSession(): Promise<ILoginSuccess | undefined> {
    return await this.get({ uri: 'new-session' }).then((result) => {
      if (result.error) {
        NotifyHelper.warning({ message_md: result.message });
        return undefined;
      }

      return result as ILoginSuccess;
    });
  }

  /** simulates a login as any user on behalf of a super admin */
  async impersonate(
    payload: Partial<ILoginPayload>
  ): Promise<ILoginSuccess | undefined> {
    return await this.post({ uri: 'impersonate' }, payload).then((result) => {
      if (result.error) {
        NotifyHelper.warning({ message_md: result.message });
        return undefined;
      }

      return result as ILoginSuccess;
    });
  }

  /** allows an admin to enter/leave super access mode to load and interact with data from all teams/users */
  async super(): Promise<ILoginSuccess | undefined> {
    return await this.get({ uri: 'super' }).then((result) => {
      if (result.error) {
        NotifyHelper.warning({ message_md: result.message });
        return undefined;
      }

      return result as ILoginSuccess;
    });
  }

  async putUser(payload: Partial<IUser>): Promise<IUser> {
    return await this.put({ uri: 'update' }, payload);
  }

  async refresh(): Promise<ILoginSuccess> {
    return await this.get({ uri: 'refresh' });
  }

  async logout(): Promise<IGenericResponse> {
    return await this.get({ uri: 'logout' });
  }

  /** note that super admins will also be included */
  async getTeamAdmins(): Promise<IUser[]> {
    return await this.get({ uri: 'team-admins' });
  }
}
