import { BallType } from '../enums/machine.enums';
import {
  IOrientation,
  IPosition,
  IWheelAngle,
  IWheelSpeed,
} from './pitches/i-base';

/** required properties on machine state */
export const MS_REQ_PROPS = [
  'w1',
  'w2',
  'w3',
  'a1',
  'a2',
  'a3',
  'qw',
  'qx',
  'qy',
  'qz',
  'tilt',
  'yaw',
  'px',
  'py',
  'pz',
];

/** required properties on ball state */
export const BS_REQ_PROPS = [
  'px',
  'py',
  'pz',
  'vx',
  'vy',
  'vz',
  'wx',
  'wy',
  'wz',
  'qw',
  'qx',
  'qy',
  'qz',
];

/** required properties on trajectories */
export const TRAJ_REQ_PROPS = [
  'px',
  'py',
  'pz',
  'vx',
  'vy',
  'vz',
  'ax',
  'ay',
  'az',
  'wnet',
];

export interface IMachineState
  extends IOrientation,
    IPosition,
    IWheelSpeed,
    IWheelAngle {
  tilt: number;
  yaw: number;

  model_id: string;

  /** should match output of getModelKey() function */
  model_key: string;

  ball_type: BallType;

  training: boolean;

  /** automatically appended to ms being sent via machine context
   * based on rapid setting in machine cookie
   * */
  rapid?: boolean;

  full_hash?: string;
  matching_hash?: string;
  last_built?: string;

  precision_trained?: boolean;
}

export const DEFAULT_MACHINE_STATE: IMachineState = {
  model_id: '',
  model_key: 'Default',
  ball_type: BallType.MLB,
  w1: 0,
  w2: 0,
  w3: 0,
  a1: 0,
  a2: 0,
  a3: 0,
  px: 0,
  py: 55,
  pz: 4.03,
  qw: 1,
  qx: 0,
  qy: 0,
  qz: 0,
  tilt: 0,
  yaw: 0,
  training: false,
};

const hasTypedProperty = (
  object: any,
  property: string,
  type: 'number'
): boolean => {
  return property in object && typeof object[property] === type;
};

export const isValidMachineState = (value: object): boolean => {
  return (
    MS_REQ_PROPS.map((k) => hasTypedProperty(value, k, 'number')) // check if each key is present
      .filter((b) => !b).length === 0 // keep only falses
  );
};
