import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { Box, Flex, Text } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { CommonMenu } from 'components/common/menu';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import { RadixColor } from 'lib_ts/enums/radix-ui';

interface ISidebarNavigation {
  icon: JSX.Element;
  color?: RadixColor;

  label: string;
  title?: string;

  disabled?: boolean;
  active?: boolean;

  onClick?: () => void;

  actions?: IMenuAction[];
}

export const SidebarNavigationTrigger = (props: ISidebarNavigation) => {
  return (
    <NavigationMenu.Item style={{ position: 'relative' }}>
      <NavigationMenu.Trigger
        data-testid="SidebarSection"
        className={StringHelper.classNames([
          'NavigationMenuTrigger',
          props.active ? 'active' : 'inactive',
        ])}
        title={props.title ?? t(props.label).toString()}
        disabled={props.disabled}
        onClick={props.onClick}
      >
        <CommonMenu
          // if (visible) actions is empty, only the trigger will be rendered
          actions={props.actions ?? []}
          title={props.label}
          trigger={
            <Flex
              gap="2"
              p="2"
              align="center"
              style={{
                width: '100%',
              }}
            >
              <Text className="NavigationMenuIcon" color={props.color}>
                {props.icon}
              </Text>
              <Box className="full-only">
                <Text color={props.color} truncate>
                  {t(props.label)}
                </Text>
              </Box>
            </Flex>
          }
          skipSort
        />
      </NavigationMenu.Trigger>
    </NavigationMenu.Item>
  );
};
