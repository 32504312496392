import { Box, Flex, Text } from '@radix-ui/themes';
import { CommonSelectInput } from 'components/common/form/select';
import { TableContext } from 'components/common/table/context';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { useContext, useMemo } from 'react';

const MIN_PAGE_SIZE = 2;

interface IProps {
  pageSizeOptions: number[];
}

export const RowSelector = (props: IProps) => {
  const options = useMemo(() => {
    return props.pageSizeOptions.map((n) => {
      const o: IOption = {
        label: n.toString(),
        value: n.toString(),
      };
      return o;
    });
  }, [props.pageSizeOptions]);

  const tableCx = useContext(TableContext);

  return (
    <Flex gap={RADIX.FLEX.GAP.SM}>
      <Box className="valign-center">
        <Text>{t('common.rows-per-page')}:</Text>
      </Box>
      <Box>
        <CommonSelectInput
          id="common-table-page-size"
          name="pageSize"
          inputColor={RADIX.COLOR.NEUTRAL}
          variant="soft"
          options={options}
          value={tableCx.pageSize?.toFixed(0)}
          onNumericChange={(v) => {
            if (v < MIN_PAGE_SIZE) {
              return;
            }

            tableCx.setPageSize(v, false);
          }}
          skipSort
        />
      </Box>
    </Flex>
  );
};
