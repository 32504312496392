import { ResetIcon, UploadIcon } from '@radix-ui/react-icons';
import { Flex } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { DialogButton } from 'components/common/dialogs/button';
import { HINT_DELIMITER } from 'components/common/form/text-hint';
import { CommonTextareaInput } from 'components/common/form/textarea';
import { IMachinesContext } from 'contexts/admin/machines.context';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachine, IMachineHardwareConfig } from 'lib_ts/interfaces/i-machine';
import React from 'react';

const DEFAULT_CONFIG: IMachineHardwareConfig = {
  ball_screw_type: 'NA',
  hdpe_type: 'NA',
  inserter_guide: 'NA',
  ip_address: 'NA',
  mac_address: 'NA',
  manufacturer: 'NA',
  motor_controller: 'NA',
  position_controller: 'NA',
  spring_setup: 'NA',
  vention_box_location: 'NA',
  wheel_serial_w1: 'NA',
  wheel_serial_w2: 'NA',
  wheel_serial_w3: 'NA',
  wheel_type: 'NA',
};

interface IProps {
  machine: Partial<IMachine>;
  machinesCx: IMachinesContext;
}

interface IState {
  initialValue: IMachineHardwareConfig;
  json: string;
}

export class HardwareConfigTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    /** make a copy to manipulate via forms */
    const config = props.machine.hardware_config ?? DEFAULT_CONFIG;

    this.state = {
      initialValue: config,
      json: JSON.stringify(
        {
          // auto-populates new required keys
          ...DEFAULT_CONFIG,
          // preserves existing values
          ...config,
        },
        null,
        2
      ),
    };

    this.getErrors = this.getErrors.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
  }

  /** DEPRECATE: use errors from machine instead */
  private getErrors(): string[] | undefined {
    try {
      const value = this.state.json;
      if (!value) {
        return ['Value cannot be empty'];
      }

      const config = JSON.parse(value) as IMachineHardwareConfig;
      if (!config) {
        return ['Value cannot be parsed into JSON'];
      }

      const output: string[] = [];

      if (!config.wheel_type) {
        output.push('"wheel_type" is required');
      }

      if (!config.spring_setup) {
        output.push('"spring_setup" is required');
      }

      if (!config.inserter_guide) {
        output.push('"inserter_guide" is required');
      }

      if (!config.hdpe_type) {
        output.push('"hdpe_type" is required');
      }

      if (!config.wheel_serial_w1) {
        output.push('"wheel_serial_w1" is required');
      }

      if (!config.wheel_serial_w2) {
        output.push('"wheel_serial_w2" is required');
      }

      if (!config.wheel_serial_w3) {
        output.push('"wheel_serial_w3" is required');
      }

      if (!config.position_controller) {
        output.push('"position_controller" is required');
      }

      if (!config.motor_controller) {
        output.push('"motor_controller" is required');
      }

      if (output.length === 0) {
        return;
      }

      return output;
    } catch (e) {
      console.error(e);
      return ['Invalid JSON (see console)'];
    }
  }

  render() {
    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
        <CommonTextareaInput
          id="hw-config-update"
          label="Update Value"
          name="config"
          rows={20}
          value={this.state.json}
          disabled={this.props.machinesCx.loading}
          onChange={(v) => this.setState({ json: v ?? '' })}
          hint_md={this.getErrors()?.join(HINT_DELIMITER)}
          monospace
        />

        {this.renderButtons()}
      </Flex>
    );
  }

  private renderButtons() {
    return (
      <Flex gap={RADIX.FLEX.GAP.SM} justify="end">
        <DialogButton
          icon={<UploadIcon />}
          label="common.update"
          color={RADIX.COLOR.SUCCESS}
          disabled={this.props.machinesCx.loading}
          onClick={() => {
            const value = this.state.json;
            if (!value) {
              NotifyHelper.warning({
                message_md: 'Update value cannot be empty.',
              });
              return;
            }

            const errors = this.getErrors();
            if (errors && errors.length > 0) {
              NotifyHelper.warning({
                message_md: errors[0],
              });
              return;
            }

            this.props.machinesCx.update({
              _id: this.props.machine._id,
              hardware_config: JSON.parse(this.state.json),
            });
          }}
        />

        <DialogButton
          icon={<ResetIcon />}
          label="common.reset"
          tooltip="Reset to active config"
          color={RADIX.COLOR.WARNING}
          disabled={this.props.machinesCx.loading}
          onClick={() =>
            this.setState({
              json: JSON.stringify(this.state.initialValue, null, 2),
            })
          }
        />
      </Flex>
    );
  }
}
