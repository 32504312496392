import {
  DARK_MODE_COLOR,
  DARK_TRAINING_DOT_COLORS,
  getScaledLabelFont,
  LIGHT_MODE_COLOR,
  LIGHT_TRAINING_DOT_COLORS,
} from 'enums/canvas';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { RAD_FULL_ROTATION } from 'lib_ts/classes/math.utilities';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { IBreakConfig } from 'lib_ts/interfaces/i-break-config';
import { ITrajectoryBreak } from 'lib_ts/interfaces/pitches';

const getScaledTicksFont = (scale: number) =>
  `${Math.round(12 * scale)}px sans-serif`;

// magic numbers need to be rescaled by HEIGHT_PX divided by REF_HEIGHT_PX (e.g. 800 / 400 = 2x)
const REF_HEIGHT_PX = 400;

export class BreakCanvas {
  // fallback to empty breaks
  static getMedian(breaks: ITrajectoryBreak[]): ITrajectoryBreak {
    if (breaks.length === 0) {
      return {
        xInches: 0,
        zInches: 0,
      };
    }

    return MiscHelper.getMedianObject(breaks) as ITrajectoryBreak;
  }

  static makeSquare(isDark: boolean) {
    return new BreakCanvas({
      isDark: isDark,

      aspectRatio: 1,
      height_px: 800,

      // controls the actual bounds of the canvas
      min_in: -30,
      max_in: 30,

      // controls where the lines and tick marks stop
      min_axis_in: -22,
      max_axis_in: 22,
    });
  }

  readonly CONFIG: IBreakConfig;

  private readonly CONSTANTS_PX: {
    origin_x: number;
    origin_y: number;
  };

  constructor(config: {
    isDark: boolean;

    aspectRatio: number;
    height_px: number;

    min_in: number;
    max_in: number;

    min_axis_in: number;
    max_axis_in: number;

    step?: number;
  }) {
    const total_y_in = config.max_in - config.min_in;
    const abs_x_in = (total_y_in * config.aspectRatio) / 2;

    this.CONFIG = {
      isDark: config.isDark,
      main_color: config.isDark ? DARK_MODE_COLOR : LIGHT_MODE_COLOR,
      canvas: {
        width_px: config.height_px * config.aspectRatio,
        height_px: config.height_px,
        scale: config.height_px / REF_HEIGHT_PX,
      },
      x: {
        min_in: -abs_x_in,
        max_in: abs_x_in,

        min_axis_in: config.min_axis_in,
        max_axis_in: config.max_axis_in,

        step: config.step ?? 0.00001,
      },
      y: {
        min_in: config.min_in,
        max_in: config.max_in,

        min_axis_in: config.min_axis_in,
        max_axis_in: config.max_axis_in,

        step: config.step ?? 0.00001,
      },
    };

    /** must be after plate is defined */
    this.CONSTANTS_PX = {
      origin_x: this.inchToPx(0),
      origin_y: this.inchToPxY(0),
    };
  }

  private inchToPxY(y_ft: number) {
    /** convert y_ft into a px value that works for the canvas by translating so that y.min_ft is the same as 0 for the canvas */
    const total_ft = Math.abs(this.CONFIG.y.max_in - this.CONFIG.y.min_in);
    const y_perc = (y_ft - this.CONFIG.y.min_in) / total_ft;

    /** canvas y is inverted relative to slider */
    return this.CONFIG.canvas.height_px * (1 - y_perc);
  }

  private inchToPx(x_ft: number) {
    /** convert x_ft into a px value that works for the canvas by translating so that x.min_ft is the same as 0 for the canvas */
    const total_ft = Math.abs(this.CONFIG.x.max_in - this.CONFIG.x.min_in);
    const x_perc = (x_ft - this.CONFIG.x.min_in) / total_ft;
    return this.CONFIG.canvas.width_px * x_perc;
  }

  drawRulers(ctx: CanvasRenderingContext2D) {
    const scale = this.CONFIG.canvas.scale;

    ctx.font = getScaledTicksFont(this.CONFIG.canvas.scale);

    ctx.lineWidth = 2 * this.CONFIG.canvas.scale;
    ctx.strokeStyle = this.CONFIG.main_color;
    ctx.fillStyle = this.CONFIG.main_color;

    /** draw x ruler */
    const x_ruler_pos_y = Math.min(
      this.CONSTANTS_PX.origin_y,
      this.inchToPxY(this.CONFIG.y.min_axis_in)
    );
    ctx.moveTo(this.inchToPxY(this.CONFIG.x.min_axis_in), x_ruler_pos_y);
    ctx.lineTo(this.inchToPxY(this.CONFIG.x.max_axis_in), x_ruler_pos_y);
    ctx.stroke();

    /** draw x ruler tick marks per foot */
    ArrayHelper.getIntegerOptions(
      Math.ceil(this.CONFIG.x.min_axis_in),
      Math.floor(this.CONFIG.x.max_axis_in)
    ).forEach((o) => {
      const intValue = parseInt(o.value);

      if (intValue % 5 !== 0) {
        return;
      }

      const x = this.inchToPx(intValue);
      ctx.moveTo(x, x_ruler_pos_y);
      ctx.lineTo(x, x_ruler_pos_y - 5 * scale);
      ctx.stroke();

      if (intValue !== 0) {
        ctx.fillText(
          `${o.value}"`,
          x - (intValue > 0 ? 6 : 9) * scale,
          x_ruler_pos_y - 10 * scale
        );
      }
    });

    /** draw y ruler down the middle */
    const y_ruler_pos_x = Math.min(
      this.CONSTANTS_PX.origin_x,
      this.inchToPxY(this.CONFIG.x.min_axis_in)
    );

    ctx.moveTo(y_ruler_pos_x, this.inchToPxY(this.CONFIG.y.min_axis_in));
    ctx.lineTo(y_ruler_pos_x, this.inchToPxY(this.CONFIG.y.max_axis_in));
    ctx.stroke();

    const ticks = ArrayHelper.getIntegerOptions(
      Math.ceil(this.CONFIG.y.min_axis_in),
      Math.floor(this.CONFIG.y.max_axis_in)
    );
    ticks.forEach((o) => {
      const intValue = parseInt(o.value);

      if (intValue % 5 !== 0) {
        return;
      }

      const y = this.inchToPxY(intValue);
      ctx.moveTo(y_ruler_pos_x, y);
      ctx.lineTo(y_ruler_pos_x + 5 * scale, y);
      ctx.stroke();

      if (intValue !== 0) {
        ctx.fillText(`${intValue}"`, y_ruler_pos_x + 8 * scale, y + 4 * scale);
      }
    });
  }

  drawTarget(
    ctx: CanvasRenderingContext2D,
    loc: {
      xInches: number;
      zInches: number;
    }
  ) {
    const scaledSize = 32 * this.CONFIG.canvas.scale;

    const crosshairs = new Image(scaledSize, scaledSize);

    crosshairs.onload = () => {
      const x = this.inchToPx(loc.xInches);
      const y = this.inchToPxY(loc.zInches);
      const half = scaledSize / 2;

      // rescale the image to match target size
      ctx.drawImage(crosshairs, x - half, y - half, scaledSize, scaledSize);
    };

    crosshairs.src = '/img/crosshair.svg';
  }

  drawTrainingBreak(
    ctx: CanvasRenderingContext2D,
    loc: ITrajectoryBreak,
    config: {
      isNew: boolean;
      size: number;
      index: number;
    }
  ) {
    const scaledSize = config.size * this.CONFIG.canvas.scale;

    if (
      loc.xInches < this.CONFIG.x.min_in ||
      loc.xInches > this.CONFIG.x.max_in
    ) {
      return;
    }

    if (
      loc.zInches < this.CONFIG.y.min_in ||
      loc.zInches > this.CONFIG.y.max_in
    ) {
      return;
    }

    const dots = this.CONFIG.isDark
      ? DARK_TRAINING_DOT_COLORS
      : LIGHT_TRAINING_DOT_COLORS;

    const colors = config.isNew ? dots.new : dots.old;

    ctx.lineWidth = 2 * this.CONFIG.canvas.scale;
    ctx.fillStyle = colors.background;
    ctx.strokeStyle = colors.border;

    ctx.beginPath();
    ctx.ellipse(
      this.inchToPx(loc.xInches),
      this.inchToPxY(loc.zInches),
      scaledSize,
      scaledSize,
      0,
      0,
      RAD_FULL_ROTATION
    );

    ctx.fill();
    ctx.stroke();

    ctx.font = getScaledLabelFont(this.CONFIG.canvas.scale);
    ctx.fillStyle = colors.label;

    // inside the circle
    ctx.fillText(
      config.index.toString(),
      this.inchToPx(loc.xInches) -
        scaledSize / 2 +
        1 * this.CONFIG.canvas.scale,
      this.inchToPxY(loc.zInches) + scaledSize / 2
    );
  }

  drawBreak(
    ctx: CanvasRenderingContext2D,
    loc: ITrajectoryBreak,
    config: {
      isNew: boolean;
      size: number;
      index: number;
    }
  ) {
    const scaledSize = config.size * this.CONFIG.canvas.scale;

    if (
      loc.xInches < this.CONFIG.x.min_in ||
      loc.xInches > this.CONFIG.x.max_in
    ) {
      return;
    }

    if (
      loc.zInches < this.CONFIG.y.min_in ||
      loc.zInches > this.CONFIG.y.max_in
    ) {
      return;
    }

    // --success-9
    ctx.fillStyle = '#30A46C';

    ctx.beginPath();
    ctx.ellipse(
      this.inchToPx(loc.xInches),
      this.inchToPxY(loc.zInches),
      scaledSize,
      scaledSize,
      0,
      0,
      RAD_FULL_ROTATION
    );

    ctx.fill();
  }
}
