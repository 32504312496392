import { Matrix3, Vector3 } from 'three';

/** in feet per second */
export const GRAVITY_FT_PER_SEC = 32.174;

/** 1 miles per hour => 1.46667 feet per second */
export const MPH_TO_FTPS = 1.46667;

export const FTPS_TO_MPH = 1 / MPH_TO_FTPS;

/** 1 foot  => 12 inches */
export const FT_TO_INCHES = 12;

/** 1 meter => 39.37008 inches */
export const METERS_TO_INCHES = 39.37008;

/** 1 meter => 3.28084 feet */
export const METERS_TO_FT = 3.28084;

/** 1 meter per sec => 2.236936 miles per hour */
export const MPS_TO_MPH = 2.236936;

// 1 meter per sec => 60 sec x 60 minutes / 1000 meters per km
export const MPS_TO_KPH = 3.6;

// miles per hour => km per hour
export const MPH_TO_KPH = 1.609344;

export const RPM_TO_RAD_PER_SEC = 0.1047;

export const RAD_PER_SEC_TO_RPM = 1.0 / RPM_TO_RAD_PER_SEC;

export const DEG_TO_RAD = Math.PI / 180;

export const RAD_TO_DEG = 180 / Math.PI;

export const RAD_RIGHT_ANGLE = 0.5 * Math.PI;
export const RAD_FULL_ROTATION = 2 * Math.PI;

export const DEG_RIGHT_ANGLE = 90;

export const HR_TO_MIN = 60;
// 12 hours per 360 degrees
export const HR_TO_DEG = 360 / 12;

interface IImperialCoordinate {
  /** original value in feet */
  value_ft: number;

  /** would be -1 | 0 | 1 to indicate negative/zero/positive value */
  sign: number;
  ft: number;
  in: number;

  display: string;
}

/** returns ft and in of provided measurement in feet */
export const numberToImperial = (value_ft: number): IImperialCoordinate => {
  const sign = Math.sign(value_ft);
  const abs = Math.abs(value_ft);
  const floor = Math.floor(abs);

  const inches = Math.round(12 * (abs - floor));
  const feet = inches === 12 ? floor + 1 : floor;

  return {
    value_ft,
    sign,
    ft: feet,
    in: inches % 12,
    display: `${sign === -1 ? '-' : ''}${feet}' ${inches}"`,
  };
};

/** provide feet and/or inches measurements to pretty-print the result,
 * like 6.5 feet => 6' 6"
 * any defined and NaN parameters (i.e. not undefined) result in 'NaN input'
 */
export const imperialToText = (
  decimal_ft?: number,
  decimal_inches?: number
): string => {
  let totalFT = 0;

  if (decimal_ft !== undefined) {
    if (isNaN(decimal_ft)) {
      return 'NaN feet parameter';
    }

    totalFT += decimal_ft;
  }

  if (decimal_inches !== undefined) {
    if (isNaN(decimal_inches)) {
      return 'NaN inches parameter';
    }

    totalFT += decimal_inches / 12;
  }

  const sign = totalFT < 0 ? '-' : '';

  const absTotal = Math.abs(totalFT);
  const absFeet = Math.floor(absTotal);
  const absInches = Math.round((absTotal - absFeet) * 12);

  if (absInches > 0) {
    return `${sign}${absFeet}' ${absInches}"`;
  } else {
    return `${sign}${absFeet}'`;
  }
};

export const IDENTITY_MATRIX_2D = [
  [1, 0],
  [0, 1],
];

/**
 * get a matrix for rotation by radians in z-axis then y-axis then x-axis
 * @param rotation leave parameters undefined (or 0) to skip rotation by that axis
 * @returns
 */
export const getRotationMatrix = (rotation: {
  z_rad?: number;
  x_rad?: number;
  y_rad?: number;
}): Matrix3 => {
  // spawns a 3x3 identity matrix
  let output = new Matrix3();

  if (rotation.x_rad) {
    output = new Matrix3()
      .set(
        1,
        0,
        0,
        0,
        Math.cos(rotation.x_rad),
        -Math.sin(rotation.x_rad),
        0,
        Math.sin(rotation.x_rad),
        Math.cos(rotation.x_rad)
      )
      .multiply(output);
  }

  if (rotation.y_rad) {
    output = new Matrix3()
      .set(
        Math.cos(rotation.y_rad),
        0,
        Math.sin(rotation.y_rad),
        0,
        1,
        0,
        -Math.sin(rotation.y_rad),
        0,
        Math.cos(rotation.y_rad)
      )
      .multiply(output);
  }

  if (rotation.z_rad) {
    output = new Matrix3()
      .set(
        Math.cos(rotation.z_rad),
        -Math.sin(rotation.z_rad),
        0,
        Math.sin(rotation.z_rad),
        Math.cos(rotation.z_rad),
        0,
        0,
        0,
        1
      )
      .multiply(output);
  }

  return output;
};

// use case would be to get a rotation matrix that could take a global velocity (vx,vy,vz) = Vector3(vx,vy,vz) and localize its frame of reference
export const getLocalizeMatrix = (vector: Vector3) => {
  return getRotationMatrix({
    // No sign switch because vy is already negative
    z_rad: Math.tan(vector.x / vector.y),
    x_rad: Math.sin(vector.z / vector.length()),
  });
};

/** returns n if it is between min and max, else returns the closest boundary */
export const clamp = (n: number, min: number, max: number) => {
  if (isNaN(n)) {
    console.warn('clamp received NaN n');
    return NaN;
  }

  if (isNaN(min)) {
    console.warn('clamp received NaN min');
    return NaN;
  }

  if (isNaN(max)) {
    console.warn('clamp received NaN max');
    return NaN;
  }

  // invalid bounds, there is no solution
  if (min > max) {
    console.warn(`clamp received invalid bounds (${min}, ${max})`);
    return NaN;
  }

  return Math.min(Math.max(n, min), max);
};

/** mean aka: average, provide a denominator to change what the sum is divided by */
export const mean = (values: number[], denominator?: number) => {
  /** no values */
  if (values.length === 0) {
    return NaN;
  }

  /** one or more invalid values */
  if (!values.every((v) => !isNaN(v))) {
    return NaN;
  }

  return values.reduce((p, c) => p + c, 0) / (denominator ?? values.length);
};

/** standard deviation */
export const std = (values: number[]) => {
  const avg = mean(values);

  /** invalid average */
  if (isNaN(avg)) {
    return NaN;
  }

  return Math.sqrt(
    values.map((v) => Math.pow(v - avg, 2)).reduce((a, b) => a + b) /
      values.length
  );
};

/** round something to a specific number of decimals */
export const round = (value: number, decimals: number) => {
  /** invalid parameters */
  if (isNaN(value) || isNaN(decimals)) {
    console.log(value, decimals);
    return NaN;
  }

  /** nonsense decimals => do nothing */
  if (decimals < 0) {
    return value;
  }

  const factor = Math.pow(10, Math.round(decimals));
  return Math.round(value * factor) / factor;
};

// does not modify the original matrix, may not be row reduced
export const getEchelonForm_2x2 = (
  input: number[][]
): number[][] | undefined => {
  try {
    // Check if the matrix is 2x2
    if (input.length !== 2 || !input.every((row) => row.length === 2)) {
      throw new Error('Input matrix must be a 2x2 matrix.');
    }

    if (!input.every((row) => !row.includes(NaN))) {
      throw new Error('Input matrix cannot contain NaN values.');
    }

    // make a copy of the values from input
    const output = input.map((row) => [row[0], row[1]]);

    // apply row operations to achieve row reduced echelon form
    if (output[0][0] === 0) {
      // swap rows if the first element in the first row is zero
      [output[0], output[1]] = [output[1], output[0]];
    }

    if (output[0][0] === 0) {
      throw new Error('First index of first row cannot be zero');
    }

    // scale the first index of first row to make the first element 1
    const scale = output[0][0];
    for (let j = 0; j < 2; j++) {
      output[0][j] /= scale;
    }

    // use row operations to make the second element in the first column zero
    const factor = output[1][0];
    for (let j = 0; j < 2; j++) {
      output[1][j] -= factor * output[0][j];
    }

    return output;
  } catch (e) {
    console.error({
      event: 'getEchelonForm_2x2 failed',
      input,
      error: e,
    });
    return undefined;
  }
};

// returns undefined whenever input cannot be successfully interpreted as a number
export const safeNumber = (
  value: string | undefined | null
): number | undefined => {
  if (value === undefined) {
    return undefined;
  }

  if (value === null) {
    return undefined;
  }

  if (value === '') {
    return undefined;
  }

  const output = Number(value);
  if (isNaN(output)) {
    return undefined;
  }

  return output;
};

export const isNumber = (value: any): boolean => {
  if (value === undefined) {
    return false;
  }

  if (value === null) {
    return false;
  }

  if (isNaN(value)) {
    return false;
  }

  return typeof value === 'bigint' || typeof value === 'number';
};

export const isInteger = (value: any): boolean => {
  try {
    if (!isNumber(value)) {
      return false;
    }

    return Math.round(value) === value;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const convertUnits = (factor: number, value?: number) => {
  if (value !== undefined && !isNaN(value)) {
    return factor * value;
  }

  console.warn(
    `Non-numeric value cannot be converted, returning original value: ${value}`
  );
  return value;
};
