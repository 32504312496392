export enum SpecialMsPosition {
  // machine config determines whether this means home or park
  default = 'default',
  // px 0, at rest
  home = 'home',
  // machine config determines px, at rest
  park = 'park',
  // px unchanged, pz 0, at rest
  lowered = 'lowered',
}

// for cases where the app won't know the per-machine configuration (e.g. park location)
export interface ISpecialMstargetMsg {
  position: SpecialMsPosition;
}
