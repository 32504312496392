import { Box, Code, Flex, Heading } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonCallout } from 'components/common/callouts';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { CommonTextareaInput } from 'components/common/form/textarea';
import { MachineModelsContext } from 'contexts/admin/machine-models.context';
import { MachinesContext } from 'contexts/admin/machines.context';
import { AuthContext } from 'contexts/auth.context';
import { DEFAULT_ACCEPT_BTN } from 'interfaces/i-dialogs';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachineModel } from 'lib_ts/interfaces/modelling/i-machine-model';
import React from 'react';

const COMPONENT_NAME = 'CopyModelDialog';

interface IProps {
  model: IMachineModel;
  onClose: () => void;
}

interface IState {
  defaultName: string;
  name: string;
  machineID: string;
  notes?: string;
}

export class CopyModelDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const defaultName = `${props.model.name} (Copy)`;

    this.state = {
      machineID: props.model.machineID,
      defaultName: defaultName,
      name: defaultName,
      notes: [props.model.notes ?? '', `Copy of "${props.model.name}"`]
        .filter((t) => !!t)
        .join('\n'),
    };
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <AuthContext.Consumer>
          {(authCx) => (
            <MachinesContext.Consumer>
              {(machinesCx) => (
                <MachineModelsContext.Consumer>
                  {(modelsCx) => (
                    <CommonDialog
                      identifier={COMPONENT_NAME}
                      width={RADIX.DIALOG.WIDTH.MD}
                      title="Copy Machine Model"
                      buttons={[
                        {
                          ...DEFAULT_ACCEPT_BTN,
                          onClick: () => {
                            if (!this.state.machineID) {
                              NotifyHelper.warning({
                                message_md: 'Model name is required.',
                              });
                              return;
                            }

                            if (!this.state.name) {
                              NotifyHelper.warning({
                                message_md: 'Model name is required.',
                              });
                              return;
                            }

                            const payload: Partial<IMachineModel> = {
                              ...this.props.model,
                              _original_id: this.props.model._id,
                              machineID: this.state.machineID,
                              name: this.state.name,
                              notes: this.state.notes,
                            };

                            modelsCx.copyModel(payload).then((success) => {
                              if (success) {
                                this.props.onClose();
                              }
                            });
                          },
                        },
                      ]}
                      onClose={this.props.onClose}
                      content={
                        <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
                          <CommonCallout text="Use the following form to copy a machine model (e.g. to be used on a different machine)." />

                          <CommonFormGrid columns={1}>
                            <CommonSelectInput
                              id="create-metric-machineID"
                              label="common.machine"
                              name="machineID"
                              value={this.state.machineID}
                              options={machinesCx.machines.map((m) => ({
                                label: m.machineID,
                                value: m.machineID,
                              }))}
                              onChange={(v) => {
                                const machineID = v;
                                if (!machineID) {
                                  return;
                                }

                                this.setState({
                                  machineID: machineID,
                                  // replace original machineID with selected one
                                  name: this.state.defaultName.replace(
                                    this.props.model.machineID,
                                    v
                                  ),
                                });
                              }}
                            />
                            <CommonTextInput
                              id="create-metric-model"
                              label="Model Name"
                              labelIcon={<SuperAdminIcon />}
                              placeholder="Type a unique value"
                              value={this.state.name}
                              disabled={authCx.current.role !== UserRole.admin}
                              onChange={(v) => this.setState({ name: v ?? '' })}
                              hint_md="Changing the machine will reset the model name."
                            />
                            <CommonTextareaInput
                              id="create-metric-notes"
                              label="Notes"
                              rows={5}
                              value={this.state.notes}
                              onChange={(value) =>
                                this.setState({ notes: value })
                              }
                            />

                            <Box>
                              <Heading size={RADIX.HEADING.SIZE.SM}>
                                Type:
                              </Heading>
                              <Code>{this.props.model.type}</Code>
                            </Box>

                            <Box>
                              <Heading size={RADIX.HEADING.SIZE.SM}>
                                S3 Path:
                              </Heading>
                              <Code>{this.props.model.path}</Code>
                            </Box>
                          </CommonFormGrid>
                        </Flex>
                      }
                    />
                  )}
                </MachineModelsContext.Consumer>
              )}
            </MachinesContext.Consumer>
          )}
        </AuthContext.Consumer>
      </ErrorBoundary>
    );
  }
}
