import { NotifyHelper } from 'classes/helpers/notify.helper';
import { AuthContext } from 'contexts/auth.context';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IUser } from 'lib_ts/interfaces/i-user';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AdminUsersService } from 'services/admin/users.service';

interface IOptionsDict {
  emails: IOption[];
  _created: string[];
}

interface IFilter {
  email: string[];
  team: string[];
  created: string[];
}

export interface IUsersContext {
  users: IUser[];

  filtered: IUser[];
  filter: IFilter;
  readonly setFilter: (config: Partial<IFilter>) => void;

  loading: boolean;

  options: IOptionsDict;

  /** changes lastFetched date which auto-triggers data to be fetched */
  readonly refresh: () => void;

  readonly createUser: (payload: Partial<IUser>) => Promise<boolean>;
  readonly updateUser: (
    payload: Partial<IUser>,
    silently?: boolean
  ) => Promise<boolean>;
  readonly deleteUsers: (ids: string[]) => Promise<boolean>;

  /** mostly for tracking checkbox values from tables */
  readonly setUsers: (users: IUser[]) => void;
}

const DEFAULT: IUsersContext = {
  users: [],

  filtered: [],
  filter: {
    email: [],
    team: [],
    created: [],
  },
  setFilter: () => console.debug('not init'),

  options: {
    emails: [],
    _created: [],
  },

  loading: false,

  refresh: () => console.debug('not init'),
  createUser: async () => new Promise(() => console.debug('not init')),
  updateUser: async () => new Promise(() => console.debug('not init')),
  deleteUsers: async () => new Promise(() => console.debug('not init')),

  setUsers: () => console.debug('not init'),
};

const getOptions = (data: IUser[]): IOptionsDict => {
  if (data) {
    return {
      emails: data.map((m) => {
        const o: IOption = {
          label: m.email,
          group: m.email.split('@')[1]?.toUpperCase(),
          value: m.email,
        };

        return o;
      }),

      _created: ArrayHelper.unique(
        data.map((m) => lightFormat(parseISO(m._created), 'yyyy-MM-dd'))
      ),
    };
  } else {
    return DEFAULT.options;
  }
};

export const UsersContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const UsersProvider: FC<IProps> = (props) => {
  const [_lastFetched, _setLastFetched] = useState(new Date());

  const [_users, _setUsers] = useState(DEFAULT.users);
  const [_filtered, _setFiltered] = useState(DEFAULT.filtered);
  const [_filter, _setFilter] = useState(DEFAULT.filter);

  const [_options, _setOptions] = useState(getOptions(DEFAULT.users));
  const [_loading, _setLoading] = useState(DEFAULT.loading);

  const state: IUsersContext = {
    users: _users,

    filtered: _filtered,
    filter: _filter,
    setFilter: (config) => {
      _setFilter({ ..._filter, ...config });
    },

    options: _options,

    loading: _loading,

    refresh: () => {
      _setLastFetched(new Date());
    },

    setUsers: (users) => {
      _setUsers(users);
    },

    deleteUsers: async (ids) => {
      try {
        _setLoading(true);

        const result = await AdminUsersService.getInstance().deleteUsers(ids);

        if (!result.success) {
          throw new Error(result.error);
        }

        const newUsers = _users.filter((u) => !ids.includes(u._id));
        _setUsers(newUsers);

        NotifyHelper.success({
          message_md: `${ids.length > 1 ? 'Users' : 'User'} deleted.`,
        });

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error ? e.message : t('common.request-failed-msg'),
        });

        return false;
      } finally {
        _setLoading(false);
      }
    },

    createUser: async (payload) => {
      try {
        _setLoading(true);

        const result = await AdminUsersService.getInstance().postUser(payload);

        if (!result.success) {
          throw new Error(result.error);
        }

        const user = result.data as IUser;
        const newUsers = [..._users, user];

        _setUsers(newUsers);

        NotifyHelper.success({ message_md: `User ${user.email} created!` });

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error ? e.message : t('common.request-failed-msg'),
        });

        return false;
      } finally {
        _setLoading(false);
      }
    },

    updateUser: async (payload, silently) => {
      try {
        _setLoading(true);

        const result = await AdminUsersService.getInstance().putUser(payload);

        if (!result.success) {
          throw new Error(result.error);
        }

        const user = result.data as IUser;
        const newUsers = [..._users];

        const index = newUsers.findIndex((v) => v._id === user._id);
        if (index !== -1) {
          /** replace current context value with updated result */
          newUsers.splice(index, 1, user);
        } else {
          /** append to end */
          newUsers.push(user);
        }

        _setUsers(newUsers);

        if (!silently) {
          NotifyHelper.success({
            message_md: `User ${user.email} updated!`,
          });
        }

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error ? e.message : t('common.request-failed-msg'),
        });
        return false;
      } finally {
        _setLoading(false);
      }
    },
  };

  /** fetch the data whenever lastFetched changes */
  useEffect(() => {
    (async (): Promise<void> => {
      _setLoading(true);

      return AdminUsersService.getInstance()
        .getUsers(true)
        .then((result) => {
          _setUsers(result);
        })
        .finally(() => _setLoading(false));
    })();
  }, [_lastFetched]);

  const { current } = useContext(AuthContext);

  /** reload data to match session access */
  useEffect(() => {
    /** trigger refresh */
    _setLastFetched(new Date());
  }, [current.session]);

  useEffect(() => {
    // console.debug('updating filtered users');

    const filtered = _users
      .filter(
        (m) => _filter.email.length === 0 || _filter.email.includes(m.email)
      )
      .filter(
        (m) => _filter.team.length === 0 || _filter.team.includes(m._parent_id)
      )
      .filter(
        (m) =>
          _filter.created.length === 0 ||
          _filter.created.includes(
            lightFormat(parseISO(m._created), 'yyyy-MM-dd')
          )
      );

    _setFiltered(filtered);
  }, [_users, _filter]);

  /** update options whenever users changes */
  useEffect(() => {
    _setOptions(getOptions(_users));
  }, [_users]);

  return (
    <UsersContext.Provider value={state}>
      {props.children}
    </UsersContext.Provider>
  );
};
