import { Box, Flex, Spinner, Tabs, Text } from '@radix-ui/themes';
import { t } from 'i18next';
import { RADIX, RadixColor } from 'lib_ts/enums/radix-ui';
import { ReactNode, useState } from 'react';
import slugify from 'slugify';

interface ITabDef {
  value: string;
  label: string;
  labelIcon?: ReactNode;
  color?: RadixColor;
  content?: ReactNode;
  invisible?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

interface IProps {
  className?: string;

  color?: RadixColor;

  tabs: ITabDef[];

  // provide this if parent needs to control which tab is shown
  value: string;
  onValueChange: (value: string) => void;
}

export const CommonTabs = (props: IProps) => {
  const [key, setKey] = useState(Date.now());

  const activeTab = props.tabs.find((t) => t.value === props.value);

  return (
    <Tabs.Root
      key={key}
      className={props.className}
      value={props.value}
      onValueChange={(value) => {
        // let the parent handle it
        props.onValueChange(value);
        // trigger render
        setKey(Date.now());
      }}
    >
      <Tabs.List color={props.color}>
        {props.tabs
          .filter((tab) => !tab.invisible)
          .map((tab, i) => (
            <Tabs.Trigger
              key={`tab-${i}`}
              id={slugify(tab.value, { strict: true, lower: true })}
              value={tab.value}
              disabled={tab.disabled}
            >
              {/* double-negation necessary because Spinner's loading defaults to true */}
              <Spinner loading={!!tab.loading}>
                <Flex gap={RADIX.FLEX.GAP.XS} align="center">
                  <Box>
                    <Text color={tab.color}>{t(tab.label)}</Text>
                  </Box>
                  {tab.labelIcon && <Box mt="1">{tab.labelIcon}</Box>}
                </Flex>
              </Spinner>
            </Tabs.Trigger>
          ))}
      </Tabs.List>

      <Tabs.Content value={props.value} mt="5">
        {!activeTab || activeTab.loading ? <Spinner /> : activeTab.content}
      </Tabs.Content>
    </Tabs.Root>
  );
};
