import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonCallout } from 'components/common/callouts';
import { CommonReorderDialog } from 'components/common/dialogs/reorder';
import { TableContext } from 'components/common/table/context';
import { ITableReorder } from 'interfaces/tables/reordering';
import { useContext, useState } from 'react';
import { MainService } from 'services/main.service';

export const ReorderDialog = (props: ITableReorder) => {
  const [show, setShow] = useState(true);

  if (!show) {
    return <></>;
  }

  const tableCx = useContext(TableContext);

  if (!tableCx.canReorder) {
    return (
      <CommonCallout text="Table context has not been configured for reordering." />
    );
  }

  return (
    <CommonReorderDialog
      identifier="ReorderDialog"
      items={tableCx.sortedData.map(props.mappingFn)}
      onReorder={(options) => {
        if (options.length !== tableCx.sortedData.length) {
          NotifyHelper.error({
            message_md: `Invalid result from reorder operation, expected ${tableCx.sortedData.length} items but received ${options.length} instead.`,
          });
          return;
        }

        // directly update the sort value for each item
        tableCx.sortedData.forEach((m) => {
          m._sort = options.findIndex((o) => o.value === m._id);
        });

        const wasSorted = tableCx.sort !== undefined;

        tableCx.setSort({
          key: undefined,
        });

        if (!wasSorted) {
          /** if the table wasn't sorted before
          resetting the sort key will not re - calculate sorted data */
          tableCx.updateTableKey();
        }

        setShow(false);

        MainService.getInstance().updateOrder({
          collection: props.collection,
          ids: options.map((m) => m.value),
        });
      }}
      onClose={() => setShow(false)}
    />
  );
};
