import { MinusIcon, PauseIcon, PlusIcon } from '@radix-ui/react-icons';
import { Box, Button, Grid } from '@radix-ui/themes';
import { SPEEDS } from 'components/common/trajectory-view/helpers/constants';
import { t } from 'i18next';
import { IButton } from 'interfaces/i-buttons';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { Component } from 'react';

interface IProps {
  speed: number;

  /** value is the speed to set */
  onChange: (value: number) => void;
}

interface IState {
  /** for coming out of pause */
  lastSpeed: number;
}

export class SpeedControlView extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      lastSpeed: props.speed,
    };

    this.change = this.change.bind(this);
    this.pause = this.pause.bind(this);
    this.play = this.play.bind(this);
  }

  private change(delta: number) {
    const next = this.props.speed + delta;

    if (next > SPEEDS.MAX) {
      return;
    }

    if (next < SPEEDS.MIN) {
      return;
    }

    this.setState({ lastSpeed: next }, () => this.props.onChange(next));
  }

  private pause() {
    this.setState(
      {
        // store the current speed to resume later
        lastSpeed: this.props.speed,
      },
      () => this.props.onChange(0)
    );
  }

  private play() {
    // resume playback from saved value
    this.props.onChange(this.state.lastSpeed);
  }

  render() {
    const paused = this.props.speed === 0;

    const buttons: Partial<IButton>[] = [
      {
        tooltip: 'common.decrease-animation-speed',
        disabled: paused || this.props.speed === SPEEDS.MIN,
        icon: <MinusIcon />,
        onClick: () => this.change(-1),
      },
      {
        tooltip: paused ? 'common.resume-animation' : 'common.pause-animation',
        icon: paused ? <PauseIcon /> : undefined,
        // 0 should not show anything
        label: paused ? undefined : this.props.speed.toString(),
        onClick: paused ? this.play : this.pause,
        color: paused ? RADIX.COLOR.WARNING : undefined,
      },
      {
        tooltip: 'common.increase-animation-speed',
        disabled: paused || this.props.speed === SPEEDS.MAX,
        icon: <PlusIcon />,
        onClick: () => this.change(1),
      },
    ];

    return (
      <Grid columns="3" gap={RADIX.FLEX.GAP.XS}>
        {buttons.map((b, i) => (
          <Box key={i}>
            <Button
              title={b.tooltip ? t(b.tooltip).toString() : undefined}
              disabled={b.disabled}
              color={b.color ?? RADIX.COLOR.NEUTRAL}
              variant={b.variant ?? 'soft'}
              className="btn-block"
              onClick={b.onClick}
            >
              {b.icon}
              {b.label}
            </Button>
          </Box>
        ))}
      </Grid>
    );
  }
}
