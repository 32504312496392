import { ErrorBoundary } from 'components/common/error-boundary';
import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const COMPONENT_NAME = 'BasicVideoPlayer';

interface IProps extends videojs.PlayerOptions {
  video_url: string;
  video_mime_type: string;

  height_px: number;
  width_px: number;
}

interface IState {
  width: number;
  height: number;

  /** store the last video URL that has been set as player src */
  currentVideoURL?: string;
}

export class BasicVideoPlayer extends React.Component<IProps, IState> {
  private init = false;

  private wrapperNode?: HTMLDivElement;
  private videoNode?: HTMLVideoElement;
  private player?: videojs.Player;

  constructor(props: IProps) {
    super(props);

    this.state = {
      width: 100,
      height: 100,
    };

    this.handleResize = this.handleResize.bind(this);
    this.setupPlayer = this.setupPlayer.bind(this);
  }

  componentDidMount() {
    /** listen for subsequent resize events to re-run resizer */
    window.addEventListener('resize', this.handleResize);

    /** resize video now that wrapper has spawned */
    this.handleResize();

    if (this.init) {
      return;
    }

    this.init = true;
  }

  /** needed for changing video without remounting component */
  componentDidUpdate() {
    if (this.state.currentVideoURL !== this.props.video_url) {
      this.setupPlayer();
    }
  }

  // destroy player on unmount
  componentWillUnmount() {
    /** stop listening for resize events */
    window.removeEventListener('resize', this.handleResize);

    if (this.player) {
      this.player.dispose();
    }
  }

  private handleResize() {
    if (!this.wrapperNode) {
      return;
    }

    const aspectRatio = this.props.height_px / (this.props.width_px || 1);

    const width = this.wrapperNode.offsetWidth ?? 100;
    const height = Math.round(width * aspectRatio);

    this.setState({
      width,
      height,
    });
  }

  private setupPlayer() {
    if (this.player) {
      /** update the src */
      this.player.src({
        type: this.props.video_mime_type,
        src: this.props.video_url,
      });

      this.setState({
        currentVideoURL: this.props.video_url,
      });
      return;
    }

    /** instantiate Video.js */
    const options: videojs.PlayerOptions = {
      autoplay: false,
      controls: true,
      sources: [
        {
          src: this.props.video_url,
          type: this.props.video_mime_type,
        },
      ],
      preload: 'auto', //starts loading if browser supports it
    };

    this.player = videojs(this.videoNode as HTMLVideoElement, options, () => {
      // nothing
    });

    this.player.on('pause', () => {
      // nothing
    });

    this.setState({
      currentVideoURL: this.props.video_url,
    });
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <div ref={(elem) => (this.wrapperNode = elem as HTMLDivElement)}>
          <div
            data-vjs-player
            style={{
              width: this.state.width,
              height: this.state.height,
            }}
          >
            <video
              ref={(elem) => (this.videoNode = elem as HTMLVideoElement)}
              className="video-js"
            />
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}
