export interface ITeamPermissions {
  quick_session: boolean;
  mlb_stats_api: boolean;
}

export interface IMachinePermissions {}

export interface IUserPermissions {
  machine_lists: boolean;
  team_lists: boolean;
  quick_session: boolean;
}

export interface ICombinedPermissions
  extends ITeamPermissions,
    IMachinePermissions,
    IUserPermissions {}

export const DEFAULT_COMBINED_PERMISSIONS: ICombinedPermissions = {
  quick_session: false,
  machine_lists: false,
  team_lists: false,
  mlb_stats_api: false,
};
