import { ClipboardCopyIcon } from '@radix-ui/react-icons';
import {
  Box,
  Code,
  Flex,
  Heading,
  IconButton,
  Table,
  Text,
} from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonSearchInput } from 'components/common/form/search';
import { MachinesContext } from 'contexts/admin/machines.context';
import { t } from 'i18next';
import { generateModelKeys, getModelKey } from 'lib_ts/classes/ms.helper';
import { RADIX, RadixColor } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IMachine, IMachineModelDictionary } from 'lib_ts/interfaces/i-machine';
import { IMachineModel } from 'lib_ts/interfaces/modelling/i-machine-model';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AdminMachineModelsService } from 'services/admin/machine-models.service';

/** ensures input is always turned into a string (empty if needed) and trimmed */
const safelyTrimString = (key?: string): string => {
  return (key ?? '').trim();
};

export const ModelsConfigTab = (props: {
  machine: Partial<IMachine>;
  updateMetadata: (model: Partial<IMachine>, callback?: () => void) => void;
}) => {
  const { loading, machines } = useContext(MachinesContext);

  // load models rather than depend on models context
  const [models, setModels] = useState<IMachineModel[]>([]);

  useEffect(() => {
    AdminMachineModelsService.getInstance()
      .getAllModels()
      .then((result) => setModels(result));
  }, []);

  const activeKey = useMemo(() => getModelKey(props.machine), [props.machine]);

  const validKeys = Object.keys(generateModelKeys());

  /** for model_id:
   * undefined => deleting an existing key
   * '' (empty string) => adding a new key
   * (anything else) => updating a key
   *
   * for model_key: value will be trimmed before setting dictionary key-value pair
   */
  const _onChange = useCallback(
    (key: string, id: string | undefined) => {
      const dict: IMachineModelDictionary = { ...props.machine.model_ids };

      if (id === undefined) {
        /** delete the key (if it exists) */
        delete dict[key];
        props.updateMetadata({ model_ids: dict });
        return;
      }

      // check if key is allowed
      const safeModelKey = safelyTrimString(key);
      if (!safeModelKey) {
        NotifyHelper.error({ message_md: 'Invalid or empty model key.' });
        return;
      }

      /** add or update the key */
      dict[safeModelKey] = id;
      props.updateMetadata({ model_ids: dict });
    },
    [props.machine, props.updateMetadata]
  );

  const modelOptionsDict = useMemo(() => {
    const output: { [key: string]: IOption[] } = {};

    if (props.machine.model_ids) {
      Object.keys(props.machine.model_ids).forEach((key) => {
        const value = props.machine.model_ids?.[key];

        const sortedModels = models
          .filter((m) => {
            if (m._id === value) {
              // currently used model
              return true;
            }

            if (m.archived) {
              // exclude archived models
              return false;
            }

            if (!m.machineID) {
              // allowed for any machine
              return true;
            }

            if (m.machineID === props.machine.machineID) {
              // allowed for this machine
              return true;
            }

            return false;
          })
          .sort((a, b) => a.name.localeCompare(b.name));

        const options: IOption[] = [];

        options.push(
          ...sortedModels
            .filter((m) => m.calibration_only)
            .map((m) => {
              const o: IOption = {
                group: 'Calibration Models',
                value: m._id,
                label: m.name,
              };
              return o;
            })
        );

        options.push(
          ...sortedModels
            .filter((m) => !m.calibration_only)
            .filter((m) => !m.machineID)
            .map((m) => {
              const o: IOption = {
                group: 'Generic Models',
                value: m._id,
                label: m.name,
              };
              return o;
            })
        );

        options.push(
          ...sortedModels
            .filter((m) => !m.calibration_only)
            .filter((m) => m.machineID === props.machine.machineID)
            .map((m) => {
              const o: IOption = {
                group: 'Machine Models',
                value: m._id,
                label: m.name,
              };
              return o;
            })
        );

        output[key] = options;
      });
    }

    return output;
  }, [props.machine, models]);

  return (
    <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
      <Heading size={RADIX.HEADING.SIZE.SM}>Models</Heading>
      <Box>
        <Table.Root>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Model Key</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Model ID</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell align="center">
                Actions
              </Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {props.machine.model_ids &&
              Object.keys(props.machine.model_ids).map((key, i) => {
                const isActive = activeKey === key;
                const isValid = validKeys.includes(key);

                const value = (props.machine.model_ids ?? {})[key];
                const values = value ? [value] : [];

                const keyTooltip = (() => {
                  const labels: string[] = [];
                  labels.push(isActive ? 'Active' : 'Inactive');

                  if (!isValid) {
                    labels.push('Invalid key');
                  }

                  return labels.join(', ');
                })();

                const keyColor: RadixColor | undefined = isActive
                  ? RADIX.COLOR.SUCCESS
                  : !isValid
                  ? /** if the key is invalid, e.g. model key pattern is changed and data needs to be updated */
                    RADIX.COLOR.WARNING
                  : undefined;

                const keyNode = <Code color={keyColor}>{key}</Code>;

                return (
                  <Table.Row key={`model-key-${i}`}>
                    <Table.Cell>
                      <Flex justify="between" gap={RADIX.FLEX.GAP.SM}>
                        <Text title={keyTooltip}>{keyNode}</Text>
                      </Flex>
                    </Table.Cell>
                    <Table.Cell>
                      <CommonSearchInput
                        id="machine-editor-model"
                        name="model_id"
                        options={modelOptionsDict[key]}
                        values={values}
                        onChange={(v) => _onChange(key, v[0])}
                        disabled={loading}
                        optional
                      />
                    </Table.Cell>
                    <Table.Cell align="center">
                      <IconButton
                        title={t('common.copy-to-clipboard').toString()}
                        variant="soft"
                        onClick={() => {
                          const modelID = props.machine.model_ids?.[key];
                          if (!modelID) {
                            NotifyHelper.warning({
                              message_md: `No model selected for model key \`${key}\`.`,
                            });
                            return;
                          }

                          const model = models.find((m) => m._id === modelID);

                          if (!model) {
                            NotifyHelper.warning({
                              message_md: `No model found for model ID \`${modelID}\`.`,
                            });
                            return;
                          }

                          const value = `${model.name} | ${model._id}`;
                          navigator.clipboard.writeText(value).then(() =>
                            NotifyHelper.success({
                              message_md: t('common.x-copied-to-clipboard', {
                                x: `\`${value}\``,
                              }),
                            })
                          );
                        }}
                      >
                        <ClipboardCopyIcon />
                      </IconButton>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table.Root>
      </Box>
    </Flex>
  );
};
