import { DotsVerticalIcon } from '@radix-ui/react-icons';
import { IconButton } from '@radix-ui/themes';
import { CommonMenu } from 'components/common/menu';
import { IMenuAction } from 'interfaces/i-menus';
import { RADIX } from 'lib_ts/enums/radix-ui';

interface IProps {
  title?: string;
  actions: IMenuAction[];
}

export const CommonTableCheckedMenu = (props: IProps) => (
  <CommonMenu
    title={props.title ?? 'Actions'}
    trigger={
      <IconButton variant="ghost" color={RADIX.COLOR.NEUTRAL}>
        <DotsVerticalIcon />
      </IconButton>
    }
    actions={props.actions}
    skipSort
  />
);
