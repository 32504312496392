import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { ReactNode } from 'react';

interface IProps extends NavigationMenu.NavigationMenuProps {
  children: ReactNode;
}

export const CommonNavMenu = (props: IProps) => (
  <NavigationMenu.Root {...props} className="NavigationMenuRoot">
    <NavigationMenu.List className="NavigationMenuList">
      {props.children}
    </NavigationMenu.List>
  </NavigationMenu.Root>
);
