import {
  CheckCircledIcon,
  GlobeIcon,
  LightningBoltIcon,
} from '@radix-ui/react-icons';
import { Box, Flex, Heading, Spinner, Text } from '@radix-ui/themes';
import { ContactSupportButton } from 'components/common/buttons/contact-support';
import { CommonCallout } from 'components/common/callouts';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { MachineContext } from 'contexts/machine.context';
import { t } from 'i18next';
import { IBaseDialog } from 'interfaces/i-dialogs';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

const COMPONENT_NAME = 'MachineConnectionDialog';

interface IProps extends IBaseDialog {}

interface IState {}

const STEPS: { header: string; text: string; icon: JSX.Element }[] = [
  {
    header: 'common.check-power',
    text: 'common.check-power-message',
    icon: <LightningBoltIcon />,
  },
  {
    header: 'common.check-internet',
    text: 'common.check-internet-message',
    icon: <GlobeIcon />,
  },
];

const StepCard = (props: { icon: JSX.Element; content: React.ReactNode }) => (
  <CommonCallout color="gray" icon={props.icon} content={props.content} />
);

export class MachineConnectionDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.renderSteps = this.renderSteps.bind(this);
    this.renderStatus = this.renderStatus.bind(this);
  }

  private renderSteps() {
    return (
      <>
        {STEPS.map((s, i) => (
          <StepCard
            key={`dc-step-${i}`}
            icon={s.icon}
            content={
              <>
                <Heading size={RADIX.HEADING.SIZE.SM}>{t(s.header)}</Heading>
                <Text>{t(s.text)}</Text>
              </>
            }
          />
        ))}
      </>
    );
  }

  private renderStatus() {
    return (
      <MachineContext.Consumer>
        {(machineCx) => (
          <StepCard
            icon={
              machineCx.connected ? (
                <CheckCircledIcon color={RADIX.COLOR.SUCCESS} />
              ) : (
                <Spinner size="3" />
              )
            }
            content={
              machineCx.connected ? (
                <Text>{t('common.machine-connected-message')}</Text>
              ) : (
                <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
                  <Heading size={RADIX.HEADING.SIZE.SM}>
                    {t('common.attempting-reconnection')}
                  </Heading>

                  <Text>{t('common.attempting-reconnection-message')}</Text>

                  <Box mt="2">
                    <ContactSupportButton />
                  </Box>
                </Flex>
              )
            }
          />
        )}
      </MachineContext.Consumer>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={COMPONENT_NAME}
          title="common.machine-disconnected"
          width={RADIX.DIALOG.WIDTH.LG}
          content={
            <Flex direction="column" gap={RADIX.FLEX.GAP.LG}>
              <Box>{t('common.machine-disconnected-message')}</Box>

              {this.renderSteps()}
              {this.renderStatus()}
            </Flex>
          }
          onClose={() => this.props.onClose()}
        />
      </ErrorBoundary>
    );
  }
}
