import { NotifyHelper } from 'classes/helpers/notify.helper';
import { AuthContext } from 'contexts/auth.context';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IAdminTeam, ITeam } from 'lib_ts/interfaces/i-team';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AdminTeamsService } from 'services/admin/teams.service';

interface IOptionsDict {
  teams: IOption[];
  _created: string[];
}

export interface ITeamsContext {
  teams: IAdminTeam[];
  loading: boolean;

  options: IOptionsDict;

  /** changes lastFetched date which auto-triggers data to be fetched */
  readonly refresh: () => void;

  readonly create: (payload: Partial<IAdminTeam>) => Promise<boolean>;
  readonly update: (payload: Partial<IAdminTeam>) => Promise<boolean>;

  /** mostly for tracking checkbox values from tables */
  readonly set: (teams: IAdminTeam[]) => void;
}

const DEFAULT: ITeamsContext = {
  teams: [],

  options: {
    teams: [],
    _created: [],
  },

  loading: false,

  refresh: () => console.debug('not init'),
  create: async () => new Promise(() => console.debug('not init')),
  update: async () => new Promise(() => console.debug('not init')),

  set: () => console.debug('not init'),
};

const getOptions = (data: ITeam[]): IOptionsDict => {
  if (data) {
    return {
      teams: data.map((m) => {
        const o: IOption = {
          label: m.name,
          group: m.league,
          value: m._id,
        };

        return o;
      }),

      _created: ArrayHelper.unique(
        data.map((m) => lightFormat(parseISO(m._created), 'yyyy-MM-dd'))
      ),
    };
  } else {
    return DEFAULT.options;
  }
};

export const TeamsContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const TeamsProvider: FC<IProps> = (props) => {
  const [_lastFetched, _setLastFetched] = useState(new Date());

  const [_teams, _setTeams] = useState(DEFAULT.teams);

  const [_loading, _setLoading] = useState(DEFAULT.loading);
  const [_options, _setOptions] = useState(getOptions(DEFAULT.teams));

  const state: ITeamsContext = {
    teams: _teams,

    options: _options,

    loading: _loading,

    refresh: () => {
      _setLastFetched(new Date());
    },

    set: (teams) => {
      _setTeams(teams);
    },

    create: async (payload) => {
      try {
        _setLoading(true);
        const result = await AdminTeamsService.getInstance().create(payload);

        if (!result.success) {
          throw new Error(result.error);
        }

        const newTeam: IAdminTeam = result.data;
        _setTeams([..._teams, newTeam]);

        NotifyHelper.success({
          message_md: `Team ${newTeam.name} created!`,
        });

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error ? e.message : t('common.request-failed-msg'),
        });

        return false;
      } finally {
        _setLoading(false);
      }
    },

    update: async (payload) => {
      try {
        _setLoading(true);

        const result = await AdminTeamsService.getInstance().update(payload);

        if (!result.success) {
          throw new Error(result.error);
        }

        const team = result.data as ITeam;
        const newTeams = [..._teams];

        const index = newTeams.findIndex((v) => v._id === team._id);
        if (index !== -1) {
          /** replace current context value with updated result */
          newTeams.splice(index, 1, team);
        } else {
          /** append to end */
          newTeams.push(team);
        }

        _setTeams(newTeams);

        const warnings: string[] = [];

        // check if any domains are duplicated across different teams
        if (!!team.email_domains && ArrayHelper.isArray(team.email_domains)) {
          // other teams with domains defined
          const otherTeams = newTeams.filter(
            (t) =>
              t._id !== team._id &&
              !!t.email_domains &&
              ArrayHelper.isArray(t.email_domains)
          );

          // all unique domains from other teams
          const otherDomains = ArrayHelper.unique(
            otherTeams.map((t) => t.email_domains).flat()
          );

          team.email_domains.forEach((domain) => {
            if (otherDomains.includes(domain)) {
              const allTeamNames = [
                team.name,
                ...otherTeams
                  .filter((t) => t.email_domains.includes(domain))
                  .map((t) => t.name),
              ];

              warnings.push(
                `Domain <${domain}> is associated with multiple teams: ${allTeamNames.join(
                  ', '
                )}`
              );
            }
          });
        }

        if (warnings.length === 0) {
          NotifyHelper.success({
            message_md: `Team "${team.name}" updated!`,
          });
        } else {
          NotifyHelper.warning({
            message_md: `Team "${team.name}" updated with warnings. Check console for more info.`,
            inbox: true,
            delay_ms: 0,
          });

          console.warn({
            event: 'ADMIN: team update warnings',
            warnings,
          });
        }

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });

        return false;
      } finally {
        _setLoading(false);
      }
    },
  };

  /** fetch the data whenever lastFetched changes */
  useEffect(() => {
    (async (): Promise<void> => {
      _setLoading(true);

      return AdminTeamsService.getInstance()
        .getTeams(true)
        .then((result) => {
          _setTeams(
            result.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
          );
        })
        .finally(() => _setLoading(false));
    })();
  }, [_lastFetched]);

  const { current } = useContext(AuthContext);

  /** reload data to match session access */
  useEffect(() => {
    /** trigger refresh */
    _setLastFetched(new Date());
  }, [current.session]);

  /** update options and show warnings whenever teams changes */
  useEffect(() => {
    if (_teams) {
      _setOptions(getOptions(_teams));
    }
  }, [_teams]);

  return (
    <TeamsContext.Provider value={state}>
      {props.children}
    </TeamsContext.Provider>
  );
};
