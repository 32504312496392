import { Box, Code, Link } from '@radix-ui/themes';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { UsersContext } from 'contexts/admin/users.context';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IUser } from 'lib_ts/interfaces/i-user';
import React from 'react';

const COMPONENT_NAME = 'DeleteUsersDialog';

interface IProps {
  users: IUser[];
}

interface IState {}

export class DeleteUsersDialog extends React.Component<IProps, IState> {
  state: IState = {};

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <UsersContext.Consumer>
          {(usersCx) => (
            <CommonConfirmationDialog
              identifier={COMPONENT_NAME}
              title="common.delete"
              content={
                <Box>
                  <p>Are you sure you want to delete the following user(s)?</p>
                  <ul>
                    {this.props.users
                      .sort((a, b) => a.email.localeCompare(b.email))
                      .map((u, i) => (
                        <li key={i}>
                          <Link href={`mailto:${u.email}`}>
                            <Code>{u.email}</Code>
                          </Link>
                        </li>
                      ))}
                  </ul>
                </Box>
              }
              action={{
                label: 'common.delete',
                color: RADIX.COLOR.DANGER,
                onClick: () => {
                  usersCx.deleteUsers(this.props.users.map((u) => u._id));
                },
              }}
            />
          )}
        </UsersContext.Consumer>
      </ErrorBoundary>
    );
  }
}
