import { Card, Flex } from '@radix-ui/themes';
import { RADIX } from 'lib_ts/enums/radix-ui';

export const SectionBox = (props: { children: React.ReactNode }) => (
  <Card>
    <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
      {props.children}
    </Flex>
  </Card>
);
