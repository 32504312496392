import { Button } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { t } from 'i18next';
import { IButton } from 'interfaces/i-buttons';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useState } from 'react';

export const CommonTableButton = (props: IButton) => {
  const [className, setClassName] = useState(
    StringHelper.classNames([props.className, 'CommonTableButton'])
  );

  return (
    <Button
      size={RADIX.BUTTON.SIZE.TABLE}
      title={props.tooltip}
      disabled={props.disabled}
      className={className}
      color={props.color}
      variant={props.variant}
      onClick={props.onClick}
    >
      {props.icon}&nbsp;{props.label && t(props.label)}&nbsp;{props.suffixIcon}
    </Button>
  );
};
