import { SectionName, SubSectionName } from 'enums/route.enums';

interface IBaseSection {
  /** for translations */
  label: string;
  slug: string;
  icon?: any;
  invisible?: boolean;
}

export interface ISection extends IBaseSection {
  value: SectionName;

  subsections?: ISubSection[];
}

export interface ISubSection extends IBaseSection {
  value: SubSectionName;

  // there should only be 1 default per group of subsections
  default?: boolean;
}

export interface ISectionDef {
  section: SectionName;
  subsection?: SubSectionName;
  /** e.g. pitch list _id */
  fragments?: string[];
}

export const HOME: ISectionDef = {
  section: SectionName.Pitches,
  subsection: SubSectionName.Library,
  fragments: [],
};
