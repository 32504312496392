import { MachineModelType } from 'lib_ts/enums/machine-models.enums';

export interface IModelTypeDef {
  value: MachineModelType;
  name: string;
  title: string;
  min_groups: number;
  production_enabled: boolean;
  default: boolean;
}

export const ALLOWED_MODEL_TYPES: IModelTypeDef[] = [
  {
    value: MachineModelType.DirectBreaks,
    name: 'Direct Breaks Model',
    title:
      'This model type contains a separate engine for controlling MS using breaks directly when provided.',
    min_groups: 20,
    production_enabled: true,
    default: true,
  },
  {
    value: MachineModelType.LinearRansac,
    name: 'Linear Ransac Model',
    title:
      'This model is safer than Direct Breaks because it is smooth and invertible, but has more parameters than the linear independent model.',
    min_groups: 12,
    production_enabled: true,
    default: false,
  },
  {
    value: MachineModelType.LinearIndependent,
    name: 'Linear Independent Model',
    title: 'This model is much simpler and best for small number of pitches.',
    min_groups: 10,
    production_enabled: true,
    default: false,
  },
  {
    value: MachineModelType.TestModel,
    name: 'Test Model',
    title: 'New model type for testing purposes.',
    min_groups: 1,
    production_enabled: false,
    default: false,
  },
  {
    value: MachineModelType.DevModel,
    name: 'Dev Model',
    title: 'New model type for dev purposes.',
    min_groups: 1,
    production_enabled: false,
    default: false,
  },
];
