import { Box, Button, Flex, Heading } from '@radix-ui/themes';
import { CommonDialog } from 'components/common/dialogs';
import CONTENT_EN from 'components/common/dialogs/terms-of-service/content-en.md';
import CONTENT_JA from 'components/common/dialogs/terms-of-service/content-ja.md';
import CONTENT_KO from 'components/common/dialogs/terms-of-service/content-ko.md';
import { ErrorBoundary } from 'components/common/error-boundary';
import env from 'config';
import { AuthContext } from 'contexts/auth.context';
import { lightFormat } from 'date-fns';
import { AuthEvent } from 'enums/auth';
import { t } from 'i18next';
import { IFullDialog } from 'interfaces/i-dialogs';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { LanguageCode } from 'lib_ts/enums/translation';
import { useContext, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

const COMPONENT_NAME = 'TermsOfServiceDialog';

interface IProps {
  show: boolean;
  onClose: (changed: boolean) => void;
  logoutFn: (reason: AuthEvent, silent?: boolean) => void;
}

export const TermsOfServiceDialog = (props: IProps) => {
  const { current, logout } = useContext(AuthContext);

  const [_content, _setContent] = useState('Please wait...');
  const [_loggedOut, _setLoggedOut] = useState(false);

  const lastUpdated = new Date(env.termsRevisionDate);

  useEffect(() => {
    const c = (() => {
      switch (current.language) {
        case LanguageCode.Japanese: {
          return CONTENT_JA;
        }

        case LanguageCode.Korean: {
          return CONTENT_KO;
        }

        case LanguageCode.English:
        default: {
          return CONTENT_EN;
        }
      }
    })();

    fetch(c)
      .then((res) => res.text())
      .then((txt) => _setContent(txt));
  }, [current.language]);

  if (_loggedOut) {
    return <></>;
  }

  const DEFAULT_PROPS: IFullDialog = {
    identifier: COMPONENT_NAME,
    width: RADIX.DIALOG.WIDTH.XL,
    vFlexHeight: RADIX.DIALOG.HEIGHT.LG,
    title: 'main.terms-of-service',
    content: (
      <Flex direction="column" gap={RADIX.FLEX.GAP.SECTION} pr="2">
        <Heading size={RADIX.HEADING.SIZE.SM}>
          {t('common.updated')}: {lightFormat(lastUpdated, 'yyyy-MM-dd')}
        </Heading>
        <Box className={RADIX.VFLEX.GROW}>
          <ReactMarkdown children={_content} />
        </Box>
        <Flex gap={RADIX.FLEX.GAP.SM} justify="end">
          <Button
            color={RADIX.COLOR.SUCCESS}
            onClick={() => props.onClose(true)}
          >
            {t('common.accept')}
          </Button>

          <Button
            color={RADIX.COLOR.DANGER}
            onClick={() => {
              _setLoggedOut(true);
              logout();
            }}
          >
            {t('main.logout')}
          </Button>
        </Flex>
      </Flex>
    ),
    onClose: () => props.onClose(false),
  };

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonDialog {...DEFAULT_PROPS} />
    </ErrorBoundary>
  );
};
