import { CommonSearchInput } from 'components/common/form/search';
import { VideosContext } from 'contexts/videos/videos.context';
import { useContext, useMemo } from 'react';

interface IProps {
  onChange?: () => void;
}

export const VideosFilterPitcher = (props: IProps) => {
  const { options, filter, setFilter } = useContext(VideosContext);

  const pitchers = useMemo(() => {
    return options.PitcherFullName.map((o) => ({
      label: o,
      value: o,
    }));
  }, [options.PitcherFullName]);

  return (
    <CommonSearchInput
      id="video-pitcher"
      placeholder="common.pitcher"
      options={pitchers}
      values={filter.pitcher}
      onChange={(v) => {
        setFilter({ pitcher: v });
        props.onChange?.();
      }}
      multiple
      optional
    />
  );
};
