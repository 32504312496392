import { ErrorBoundary } from 'components/common/error-boundary';
import { SectionsContext } from 'contexts/sections.context';
import { useContext } from 'react';
import { SidebarNavigationTrigger } from './nav-trigger';

const COMPONENT_NAME = 'UserSections';

export const UserSections = () => {
  const { active, userSections, tryChangeSection } =
    useContext(SectionsContext);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      {userSections
        ?.filter((s) => !s.invisible)
        .map((s, i) => (
          <SidebarNavigationTrigger
            key={`user-section-${i}`}
            active={active.section === s.value}
            label={s.label}
            icon={<s.icon />}
            onClick={() => {
              if (s.value !== active.section) {
                tryChangeSection({
                  trigger: `${COMPONENT_NAME} > diff section`,
                  section: s.value,
                });
                return;
              }

              const defaultSubsection = s.subsections?.find((s) => s.default)
                ?.value;

              if (
                defaultSubsection &&
                defaultSubsection !== active.subsection
              ) {
                tryChangeSection({
                  trigger: `${COMPONENT_NAME} > diff subsection from default`,
                  section: s.value,
                  subsection: defaultSubsection,
                });
                return;
              }

              // no need to move
            }}
          />
        ))}
    </ErrorBoundary>
  );
};
