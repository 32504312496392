import { Box, Code, Flex, Heading } from '@radix-ui/themes';
import { CommonDetails } from 'components/common/details';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

interface IProps {
  componentName: string;
  children: React.ReactNode;

  hideIntercom?: boolean;
}
interface IState {
  hasError: boolean;
  message?: string;
}

export class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  /** state will update => next render will show the fallback UI */
  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error(`ERROR BOUNDARY: ${error.message}`, errorInfo);
    this.setState({ message: error.message });
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    return (
      <Flex p={RADIX.FLEX.PAD.LG} direction="column" gap={RADIX.FLEX.GAP.LG}>
        <Heading size={RADIX.HEADING.SIZE.SM}>
          Uh-oh, something broke in {this.props.componentName}
        </Heading>

        <Box>
          It seems something isn't working quite right, but we can fix that.
        </Box>

        {this.props.hideIntercom ? (
          <Box>
            If this is the first time you've encountered this issue, please
            contact support and we'll be in touch shortly!
          </Box>
        ) : (
          <Box>
            If this is the first time you've encountered this issue, please use
            the Intercom button to open a support ticket and we'll be in touch
            shortly!
          </Box>
        )}

        {this.state.message && (
          <CommonDetails
            summaryColor={RADIX.COLOR.DANGER}
            summary="Error Message"
            defaultOpen
          >
            <Code color={RADIX.COLOR.DANGER}>{this.state.message}</Code>
          </CommonDetails>
        )}
      </Flex>
    );
  }
}
