import { ChevronUpIcon, DotsVerticalIcon } from '@radix-ui/react-icons';
import { Box, Flex, Text } from '@radix-ui/themes';
import { SidebarHelper } from 'classes/helpers/sidebar.helper';
import { DropHandle } from 'components/common/drag-drop';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonMenu } from 'components/common/menu';
import { PitchList } from 'components/main/sidebar/pitch-lists/pitch-list';
import { MachineContext } from 'contexts/machine.context';
import { DragItem, DropContainer } from 'enums/dnd.enums';
import { IMenuAction } from 'interfaces/i-menus';
import { ISidebarFolder } from 'interfaces/i-sidebar';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext } from 'react';

interface IProps {
  folder: ISidebarFolder;
  container: DropContainer;
  accept: DragItem;

  getFolderActions: (config: {
    folder: ISidebarFolder;
    container: DropContainer;
  }) => IMenuAction[];

  defaultOpen?: boolean;
}

export const Folder = (props: IProps) => {
  const { machine } = useContext(MachineContext);

  return (
    <ErrorBoundary componentName="PitchListFolder">
      <Flex data-identifier="SidebarFolder" direction="column">
        {props.folder.files.map((file, iFile) => (
          <PitchList
            key={`folder-${props.folder.pathDisplay}-file-${iFile}`}
            file={file}
            accept={props.accept}
          />
        ))}

        {props.folder.folders.map((folder, iSub) => (
          <Box key={iSub}>
            <details
              className="SidebarPLFolderDetails"
              open={props.defaultOpen}
            >
              <summary>
                <DropHandle
                  value={folder.pathDisplay}
                  container={props.container}
                  accept={props.accept ?? DragItem.AcceptNothing}
                >
                  <Flex gap={RADIX.FLEX.GAP.SM}>
                    <Box
                      flexGrow="1"
                      className="cursor-pointer"
                      title={folder.pathDisplay}
                      pl={RADIX.BOX.PAD.SM}
                      style={{
                        position: 'relative',
                        height: '26px',
                      }}
                    >
                      <Text
                        color={RADIX.COLOR.NEUTRAL}
                        style={{
                          position: 'absolute',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          // border: '1px solid purple',
                        }}
                        as="div"
                        truncate
                      >
                        {SidebarHelper.getFolderName(folder, machine.machineID)}
                      </Text>
                    </Box>

                    <Flex
                      gap={RADIX.FLEX.GAP.XS}
                      className="SidebarPLFolderActions"
                    >
                      <ChevronUpIcon className="SidebarPLFolderChevron" />
                      <CommonMenu
                        title={'Manage Folder'}
                        actions={props.getFolderActions({
                          folder: folder,
                          container: props.container,
                        })}
                        trigger={<DotsVerticalIcon />}
                        skipSort
                      />
                    </Flex>
                  </Flex>
                </DropHandle>
              </summary>

              <Box pl="2">
                <Folder {...props} folder={folder} />
              </Box>
            </details>
          </Box>
        ))}
      </Flex>
    </ErrorBoundary>
  );
};
