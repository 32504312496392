import { Vector3 } from 'three';

/** frames per second */
export const FPS = 30.0;

/** width of tracer lines on balls */
export const LINE_WIDTH = 5;

/** ft off the ground, should be the center of the plate */
export const PLATE_HEIGHT = 2.1;

export const SPEEDS = {
  MIN: 1,
  MAX: 10,
};

/** the number after 'pitcher' indicates towards which base on the field */
export type Perspective = 'batter' | 'pitcher-1' | 'pitcher-2' | 'pitcher-3';

export const POV_OPTIONS: Perspective[] = [
  'batter',
  'pitcher-1',
  'pitcher-2',
  'pitcher-3',
];

export const STRIKEZONE = {
  /** accuracy of the search for final time of each ball */
  PRECISION: 0.001,
  /** height for the top of the strike zone */
  Y: 1.524 + 2.15 * 0.5 - 0.075,
  /** distance to the tip of home plate closest to the pitcher */
  Z: -1.415,
};

export const RPM_TO_RADPS = 0.1047;

/** todo: maybe this belongs somewhere more central */
const getOS = () => {
  const agent = window.navigator.userAgent;

  if (agent.indexOf('Win') !== -1) {
    return 'Windows';
  } else if (agent.indexOf('Mac') !== -1) {
    return 'MacOS';
  } else if (agent.indexOf('X11') !== -1) {
    return 'UNIX';
  } else if (agent.indexOf('Linux') !== -1) {
    return 'Linux';
  } else {
    return 'Unknown';
  }
};

export const POINT_OFFSETS = (() => {
  switch (getOS()) {
    case 'MacOS': {
      /** MacOS doesn't respect the thickness of the lines, so draw a bunch of them */
      return [
        [0, 0, 0],
        [0.01, 0, 0],
        [-0.01, 0, 0],
        [0, 0.01, 0],
        [0, -0.01, 0],
        [0, -0.005, 0],
        [0.015, 0, 0],
        [-0.015, 0, 0],
        [0, 0.015, 0],
        [0, -0.015, 0],
        [0.02, 0, 0],
        [-0.02, 0, 0],
        [0, 0.02, 0],
        [0, -0.02, 0],
        [0.025, 0, 0],
        [-0.025, 0, 0],
        [0, 0.025, 0],
        [0, -0.025, 0],
        [0.03, 0, 0],
        [-0.03, 0, 0],
        [0, 0.03, 0],
        [0, -0.03, 0],
        [0.035, 0, 0],
        [-0.035, 0, 0],
        [0, 0.035, 0],
        [0, -0.035, 0],
      ];
    }
    default: {
      return [[0, 0, 0]];
    }
  }
})().map((coord) => new Vector3(coord[0], coord[1], coord[2]));
