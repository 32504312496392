import { SKIP_OVERALL_CHECKS } from 'lib_ts/classes/machine-performance.helper';
import { isNumber } from 'lib_ts/classes/math.utilities';
import {
  BAD_MODEL_PERFORMANCE,
  GOOD_MODEL_PERFORMANCE,
  MODEL_PERFORMANCE_WEIGHTS,
} from 'lib_ts/classes/metric.helper';
import { PassResult } from 'lib_ts/enums/machine.enums';
import {
  IModelPrediction,
  IPredictionAccuracy,
} from 'lib_ts/interfaces/modelling/i-eval-models';

export class ModelPredictionHelper {
  /** result should be between 0 and 1, higher is better */
  static overallPercent = (p?: IModelPrediction): number => {
    const accuracy = this.getPercent(p?.mean_absolute_err);

    let numer = 0;
    let denom = 0;

    Object.entries(accuracy)
      .filter((m) => Object.keys(MODEL_PERFORMANCE_WEIGHTS).includes(m[0]))
      .forEach(([key, value]) => {
        if (!isNumber(value)) {
          return;
        }

        const factor = (MODEL_PERFORMANCE_WEIGHTS as any)[key];
        numer += factor * (value as number);
        denom += factor;
      });

    if (denom <= 0.001) {
      return 0;
    }

    return numer / denom;
  };

  static getPercent = (
    input: IPredictionAccuracy | undefined
  ): IPredictionAccuracy => {
    const DEFAULT: IPredictionAccuracy = {
      vx: 0,
      vy: 0,
      vz: 0,
      wx: 0,
      wy: 0,
      wz: 0,
      break_x_ft: 0,
      break_z_ft: 0,
    };

    if (!input) {
      return DEFAULT;
    }

    return {
      vx: getPercentage(
        input.vx,
        GOOD_MODEL_PERFORMANCE.vx,
        BAD_MODEL_PERFORMANCE.vx
      ),
      vy: getPercentage(
        input.vy,
        GOOD_MODEL_PERFORMANCE.vy,
        BAD_MODEL_PERFORMANCE.vy
      ),
      vz: getPercentage(
        input.vz,
        GOOD_MODEL_PERFORMANCE.vz,
        BAD_MODEL_PERFORMANCE.vz
      ),
      wx: getPercentage(
        input.wx,
        GOOD_MODEL_PERFORMANCE.wx,
        BAD_MODEL_PERFORMANCE.wx
      ),
      wy: getPercentage(
        input.wy,
        GOOD_MODEL_PERFORMANCE.wy,
        BAD_MODEL_PERFORMANCE.wy
      ),
      wz: getPercentage(
        input.wz,
        GOOD_MODEL_PERFORMANCE.wz,
        BAD_MODEL_PERFORMANCE.wz
      ),
      break_x_ft: getPercentage(
        input.break_x_ft,
        GOOD_MODEL_PERFORMANCE.break_x_ft,
        BAD_MODEL_PERFORMANCE.break_x_ft
      ),
      break_z_ft: getPercentage(
        input.break_z_ft,
        GOOD_MODEL_PERFORMANCE.break_z_ft,
        BAD_MODEL_PERFORMANCE.break_z_ft
      ),
    };

    function getPercentage(x: number, good: number, bad: number): number {
      const result = 1.0 / Math.max(1.0, 1.0 + (good - x) / (good - bad));
      return Math.min(1.0, result);
    }
  };

  /** true => model passes, false => fails */
  static checkOverall = (value: IModelPrediction): boolean => {
    if (SKIP_OVERALL_CHECKS) {
      return true;
    }

    return [
      this.checkSpeedY(value) === PassResult.Pass,
      this.checkSpinOverall(value),
    ].every((v) => v === true);
  };

  static checkSpeedY = (value: IModelPrediction): PassResult => {
    const MEAN_AE = 1.6;
    const MAX_AE = 3.5;

    if (
      value.mean_absolute_err.vy > MEAN_AE &&
      value.max_absolute_err.vy > MAX_AE
    ) {
      return PassResult.Both;
    }

    if (value.mean_absolute_err.vy > MEAN_AE) {
      return PassResult.Mean;
    }

    if (value.max_absolute_err.vy > MAX_AE) {
      return PassResult.Max;
    }

    return PassResult.Pass;
  };

  static checkSpinOverall = (value: IModelPrediction): boolean => {
    return [
      this.checkSpinDimension(
        value.mean_absolute_err.wx,
        value.max_absolute_err.wx
      ),
      this.checkSpinDimension(
        value.mean_absolute_err.wy,
        value.max_absolute_err.wy
      ),
      this.checkSpinDimension(
        value.mean_absolute_err.wz,
        value.max_absolute_err.wz
      ),
    ].every((v) => v === PassResult.Pass);
  };

  static checkSpinDimension = (mean: number, max: number): PassResult => {
    const MEAN_AE = 500;
    const MAX_AE = 950;

    if (mean > MEAN_AE && max > MAX_AE) {
      return PassResult.Both;
    }

    if (mean > MEAN_AE) {
      return PassResult.Mean;
    }

    if (max > MAX_AE) {
      return PassResult.Max;
    }

    return PassResult.Pass;
  };

  static checkBreakDimension = (mean: number, max: number): PassResult => {
    const MEAN_AE = 0.5;
    const MAX_AE = 1.0;

    if (mean > MEAN_AE && max > MAX_AE) {
      return PassResult.Both;
    }

    if (mean > MEAN_AE) {
      return PassResult.Mean;
    }

    if (max > MAX_AE) {
      return PassResult.Max;
    }

    return PassResult.Pass;
  };
}
