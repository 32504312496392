import { IMSTargetEventData } from 'lib_ts/interfaces/i-session-event';
import {
  ITrackmanHit,
  ITrackmanPitch,
} from 'lib_ts/interfaces/trackman/i-ball';
import { Vector3 } from 'three';
import {
  ICSVRow,
  ICustomBreaks,
  ICustomSpins,
  IHawkeye,
  IPlateSpec,
  IStatCast,
} from '../interfaces/csv';
import {
  ITrackingData,
  ITrajektData,
} from '../interfaces/csv/exports/i-session-fires';
import { DEFAULT_PLATE, IPlateLoc } from '../interfaces/pitches';
import {
  IOrientation,
  ISeamOrientation,
  ISpin,
} from '../interfaces/pitches/i-base';
import { IRapsodoShot } from '../interfaces/training/i-rapsodo-shot';
import { BallHelper } from './ball.helper';
import { HawkeyeHelper } from './hawkeye.helper';
import {
  convertUnits,
  FT_TO_INCHES,
  isNumber,
  METERS_TO_FT,
  METERS_TO_INCHES,
  MPS_TO_MPH,
  safeNumber,
} from './math.utilities';

const LIMITS = {
  PLATE: {
    HEIGHT: {
      MAX: 6,
      MIN: 0,
    },
    SIDE: {
      MAX: 2.5,
      MIN: -2.5,
    },
  },
};

const CSV_NUM_COL = [
  'SeamLat',
  'SeamLon',

  'seam_latitude',
  'seam_longitude',

  'PlateLocHeight',
  'PlateLocSide',

  /** allow any format to use hawkeye rotation matrix notation */
  'Xx',
  'Xy',
  'Xz',
  'Yx',
  'Yy',
  'Yz',
  'Zx',
  'Zy',
  'Zz',

  /** allow any format to specify breaks */
  'HorizontalBreakIn',
  'VerticalBreakIn',
];

export const CUSTOM_RELEASE_COL = [
  'ReleaseX',
  'ReleaseY',
  'ReleaseZ',
  'ReleaseV',
];

export const CUSTOM_BREAKS_NUM_COL = [
  ...CSV_NUM_COL,
  ...CUSTOM_RELEASE_COL,
  'HorizontalBreakIn',
  'VerticalBreakIn',
  'NetSpin',
];

export const CUSTOM_SPINS_NUM_COL = [
  ...CSV_NUM_COL,
  ...CUSTOM_RELEASE_COL,
  'SpinX',
  'SpinY',
  'SpinZ',
];

export const HAWKEYE_RELEASE_COL = [
  'releaseX',
  'releaseY',
  'releaseZ',
  'releaseSpeed',
];

export const HAWKEYE_NUM_COL = [
  ...CSV_NUM_COL,
  ...HAWKEYE_RELEASE_COL,
  'spinX',
  'spinY',
  'spinZ',
  'x0',
  'y0',
  'z0',
  'vx0',
  'vy0',
  'vz0',
  'ax0',
  'ay0',
  'az0',
];

export const STATCAST_RELEASE_COL = [
  'release_pos_x',
  'release_pos_y',
  'release_pos_z',
  'release_spin_rate',
];

export const STATCAST_NUM_COL = [
  ...CSV_NUM_COL,
  ...STATCAST_RELEASE_COL,
  'vx0',
  'vy0',
  'vz0',
  'ax',
  'ay',
  'az',
];

export class CSVHelper {
  static createOutput(row: ICSVRow, numericCols: string[]): any | undefined {
    try {
      const output: any = { ...row };
      const warnedColumns: string[] = [];
      Object.keys(row)
        .filter((key) => numericCols.includes(key) && output[key] !== undefined)
        .forEach((key) => {
          const maybeNum = safeNumber(output[key] as string);

          if (maybeNum !== undefined) {
            output[key] = maybeNum;
            return;
          }

          if (!warnedColumns.includes(key)) {
            console.warn(`Numeric column ${key} has NaN value, skipping...`);
            warnedColumns.push(key);
            return;
          }

          // already warned about this key, do nothing
        });

      return output;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  /** if IPlateSpec is provided via CSV, check to make sure values are usable
   * - if no IPlateSpec values are provided, return default plate
   * - if bad IPlateSpec values are provided, return an error message string
   * - if acceptable IPlateSpec values are provided, return a corresponding IPlateLoc (1:1 mapping)
   */
  static getPlateLoc(data: Partial<IPlateSpec>): IPlateLoc {
    try {
      if (data.PlateLocSide === undefined || isNaN(data.PlateLocSide)) {
        throw new Error('Plate location side is not a number.');
      }

      if (data.PlateLocHeight === undefined || isNaN(data.PlateLocHeight)) {
        throw new Error('Plate location height is not a number.');
      }

      if (data.PlateLocSide > LIMITS.PLATE.SIDE.MAX) {
        throw new Error(
          `Plate location side value (${data.PlateLocSide} ft.) is greater than the maximum value (${LIMITS.PLATE.SIDE.MAX} ft.).`
        );
      }

      if (data.PlateLocSide < LIMITS.PLATE.SIDE.MIN) {
        throw new Error(
          `Plate location side value (${data.PlateLocSide} ft.) is less than the minimum value (${LIMITS.PLATE.SIDE.MIN} ft.).`
        );
      }

      if (data.PlateLocHeight > LIMITS.PLATE.HEIGHT.MAX) {
        throw new Error(
          `Plate location height value (${data.PlateLocHeight} ft.) is greater than the maximum value (${LIMITS.PLATE.HEIGHT.MAX} ft.).`
        );
      }

      if (data.PlateLocHeight < LIMITS.PLATE.HEIGHT.MIN) {
        throw new Error(
          `Plate location height value (${data.PlateLocHeight} ft.) is less than the minimum value (${LIMITS.PLATE.HEIGHT.MIN} ft.).`
        );
      }

      return {
        plate_x: data.PlateLocSide,
        plate_z: data.PlateLocHeight,
      };
    } catch (e) {
      console.error(e);
      return DEFAULT_PLATE;
    }
  }

  static createCustomBreaks(row: ICSVRow): ICustomBreaks | undefined {
    return CSVHelper.createOutput(row, CUSTOM_BREAKS_NUM_COL);
  }

  static createCustomSpins(row: ICSVRow): ICustomSpins | undefined {
    return CSVHelper.createOutput(row, CUSTOM_SPINS_NUM_COL);
  }

  static createHawkeye(row: ICSVRow): IHawkeye | undefined {
    return CSVHelper.createOutput(row, HAWKEYE_NUM_COL);
  }

  static createStatCast(row: ICSVRow): IStatCast | undefined {
    return CSVHelper.createOutput(row, STATCAST_NUM_COL);
  }

  static hasBreakColumns(data: Partial<ICSVRow>): boolean {
    const keys = Object.keys(data);
    return ['HorizontalBreakIn', 'VerticalBreakIn'].every((m) =>
      keys.includes(m)
    );
  }

  static hasBreakDetails(data: Partial<ICSVRow>): boolean {
    return [data.HorizontalBreakIn, data.VerticalBreakIn].every((m) =>
      isNumber(m)
    );
  }

  /** same criteria as getQuaternion to detect if seam info is provided */
  static hasSeamDetails(data: Partial<ICSVRow>): boolean {
    if ([data.qw, data.qx, data.qy, data.qz].every((m) => isNumber(m))) {
      return true;
    }

    if (
      [
        data.Xx,
        data.Xy,
        data.Xz,
        data.Yx,
        data.Yy,
        data.Yz,
        data.Zx,
        data.Zy,
        data.Zz,
      ].every((m) => isNumber(m))
    ) {
      return true;
    }

    if ([data.SeamLat, data.SeamLon].every((m) => isNumber(m))) {
      return true;
    }

    if (data.SeamOrientation !== undefined) {
      return true;
    }

    return false;
  }

  static getQuatFromData(data: Partial<ICSVRow>, spin: ISpin): IOrientation {
    if ([data.qw, data.qx, data.qy, data.qz].every((m) => isNumber(m))) {
      return data as IOrientation;
    }

    const heSeams = this.getSeamsFromHawkeye(data as IHawkeye);

    if (heSeams) {
      return BallHelper.getQuatFromSeams({
        latitude_deg: heSeams.latitude_deg,
        longitude_deg: heSeams.longitude_deg,
        spin: spin,
      });
    }

    if ([data.SeamLat, data.SeamLon].every((m) => isNumber(m))) {
      return BallHelper.getQuatFromSeams({
        latitude_deg: data.SeamLat as number,
        longitude_deg: data.SeamLon as number,
        spin: spin,
      });
    }

    if (data.SeamOrientation !== undefined) {
      if (data.SeamOrientation === '2S') {
        return BallHelper.getQuatFromSeams({
          longitude_deg: 90,
          latitude_deg: 0,
          spin: spin,
        });
      }
    }

    /** default if all the above was passed over */
    return BallHelper.getQuatFromSeams({
      longitude_deg: 0,
      latitude_deg: 0,
      spin: spin,
    });
  }

  static getSeams(
    data: ICustomSpins | ICustomBreaks | IHawkeye | IStatCast
  ): ISeamOrientation | undefined {
    try {
      if (
        data.seam_latitude !== undefined &&
        data.seam_longitude !== undefined
      ) {
        return {
          latitude_deg: data.seam_latitude,
          longitude_deg: data.seam_longitude,
        };
      }

      if (data.SeamLat !== undefined && data.SeamLon !== undefined) {
        return {
          latitude_deg: data.SeamLat,
          longitude_deg: data.SeamLon,
        };
      }

      const heSeams = this.getSeamsFromHawkeye(data);

      if (heSeams) {
        return heSeams;
      }

      if (data.seamOrientation === '4S' || data.SeamOrientation === '4S') {
        return {
          latitude_deg: 0,
          longitude_deg: 0,
        };
      }

      if (data.seamOrientation === '2S' || data.SeamOrientation === '2S') {
        return {
          latitude_deg: 90,
          longitude_deg: 0,
        };
      }

      throw Error('Failed to find seams from rawData, returning undefined');
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  private static getSeamsFromHawkeye(
    data: ICustomSpins | ICustomBreaks | IHawkeye | IStatCast
  ): ISeamOrientation | undefined {
    try {
      const spin = this.getSpin(data);
      if (!spin) {
        throw Error('Failed to get spin');
      }

      const spinAxis = BallHelper.safeNormalize(
        new Vector3(spin.wx, spin.wy, spin.wz)
      );

      const heMatrix = HawkeyeHelper.getMatrix(data);
      if (!heMatrix) {
        throw Error('Failed to get Hawkeye rotation matrix');
      }

      const seams = HawkeyeHelper.getSeamsFromMatrix(heMatrix, spinAxis);

      return seams;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  static getSpin(
    data: ICustomSpins | ICustomBreaks | IHawkeye | IStatCast
  ): ISpin | undefined {
    try {
      const customData = data as ICustomSpins;

      const hasCustomSpin = [
        customData.SpinX,
        customData.SpinY,
        customData.SpinZ,
      ].every((m) => isNumber(m));

      if (hasCustomSpin) {
        return {
          wx: customData.SpinX,
          wy: customData.SpinY,
          wz: customData.SpinZ,
        };
      }

      const hawkeyeData = data as IHawkeye;

      const hasHawkeyeSpin = [
        hawkeyeData.spinX,
        hawkeyeData.spinY,
        hawkeyeData.spinZ,
      ].every((m) => isNumber(m));

      if (hasHawkeyeSpin) {
        return {
          wx: hawkeyeData.spinX,
          wy: hawkeyeData.spinY,
          wz: hawkeyeData.spinZ,
        };
      }

      throw Error('Failed to get spin');
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  static mstargetToTrajekt(targetData: IMSTargetEventData) {
    const seams = BallHelper.getBallStateSeams(targetData.bs);

    const output: Partial<ITrajektData> = {
      BallType: targetData.ms?.ball_type,
      BuildPriority: targetData.build_priority,

      InGame: 'N',
      Training: 'N',
      Valid: 'Y', // will be overwritten later as per fire event
      ErrorMsg: '', // will be overwritten later as per fire event

      PitchList: targetData.list,
      PitchTitle: targetData.pitch_title,
      PitchType: targetData.pitch_type,
      PitcherFullName: targetData.pitcher,
      VideoTitle: targetData.video_title,

      /** pitch and video details from target */
      ReleaseX: targetData.bs?.px,
      ReleaseY: targetData.bs?.py,
      ReleaseZ: targetData.bs?.pz,
      ReleaseV: targetData.bs ? -targetData.bs?.vy : undefined,

      SpinX: targetData.bs?.wx,
      SpinY: targetData.bs?.wy,
      SpinZ: targetData.bs?.wz,

      SeamLat: seams?.latitude_deg,
      SeamLon: seams?.longitude_deg,

      TargetPlateX: targetData.plate?.plate_x,
      TargetPlateZ: targetData.plate?.plate_z,
    };

    return output;
  }

  static rapsodoToTracking(data: IRapsodoShot) {
    const output: ITrackingData = {
      Validated: [
        data.PITCH_StrikePositionSideConfidence,
        data.PITCH_StrikePositionHeightConfidence,
        data.PITCH_SpinConfidence,
      ].every((v) => v > 0.9)
        ? 'Y'
        : 'N',

      PITCH_GyroDegree: data.PITCH_GyroDegree,
      PITCH_HBSpin: convertUnits(METERS_TO_INCHES, data.PITCH_HBSpin),
      PITCH_HBTrajectory: convertUnits(
        METERS_TO_INCHES,
        data.PITCH_HBTrajectory
      ),
      PITCH_HorizontalAngle: data.PITCH_HorizontalAngle,
      PITCH_PlayerID: data.PITCH_PlayerID,
      PITCH_ReleaseExtension: convertUnits(
        METERS_TO_FT,
        data.PITCH_ReleaseExtension
      ),
      PITCH_ReleaseHeight: convertUnits(METERS_TO_FT, data.PITCH_ReleaseHeight),
      PITCH_ReleaseSide: convertUnits(METERS_TO_FT, data.PITCH_ReleaseSide),
      PITCH_ReleaseTime: data.PITCH_ReleaseTime,
      PITCH_Speed: convertUnits(MPS_TO_MPH, data.PITCH_Speed),
      PITCH_SpinAxis: BallHelper.convertTimeToDegrees(data.PITCH_SpinAxis),
      PITCH_SpinEfficiency: data.PITCH_SpinEfficiency,
      PITCH_Strike: data.PITCH_Strike,
      PITCH_StrikeZoneHeight: convertUnits(
        METERS_TO_INCHES,
        data.PITCH_StrikeZoneHeight
      ),
      PITCH_StrikeZoneSide: convertUnits(
        METERS_TO_INCHES,
        data.PITCH_StrikeZoneSide
      ),
      PITCH_StrikeZoneTime: data.PITCH_StrikeZoneTime,
      PITCH_TotalSpin: data.PITCH_TotalSpin,
      PITCH_TrueSpin: data.PITCH_TrueSpin,
      PITCH_VBSpin: convertUnits(METERS_TO_INCHES, data.PITCH_VBSpin),
      PITCH_VBTrajectory: convertUnits(
        METERS_TO_INCHES,
        data.PITCH_VBTrajectory
      ),
      PITCH_VerticalAngle: data.PITCH_VerticalAngle,
      PITCH_ZoneTime: data.PITCH_ZoneTime,

      HIT_Distance: data.HIT_Distance
        ? convertUnits(METERS_TO_FT, data.HIT_Distance)
        : undefined,
      HIT_LaunchAngle: data.HIT_LaunchAngle,
      HIT_ExitSpeed: data.HIT_ExitSpeed
        ? convertUnits(MPS_TO_MPH, data.HIT_ExitSpeed)
        : undefined,
    };

    return output;
  }

  // todo: double-check which of the undefined items can actually be defined from trackman data
  static trackmanToTracking(config: {
    pitch: ITrackmanPitch | undefined;
    hit: ITrackmanHit | undefined;
  }): ITrackingData {
    const pRelease = config.pitch?.Release;
    const pLocation = config.pitch?.Location;
    const pMovement = config.pitch?.Movement;

    const output: ITrackingData = {
      // todo: replace this with real logic
      Validated: pRelease?.Speed ?? -1 > 0 ? 'Y' : 'N',

      PITCH_GyroDegree: pRelease?.SpinAxis3dLongitudinalAngle,
      PITCH_HBSpin: undefined, // convertUnits(METERS_TO_INCHES, data.PITCH_HBSpin),
      PITCH_HBTrajectory: pMovement?.Horizontal,
      PITCH_HorizontalAngle: undefined, // data.PITCH_HorizontalAngle,
      PITCH_PlayerID: undefined, // data.PITCH_PlayerID,
      PITCH_ReleaseExtension: pRelease?.Extension,
      PITCH_ReleaseHeight: pRelease?.Height,
      PITCH_ReleaseSide: pRelease?.Side,
      PITCH_ReleaseTime: undefined, // data.PITCH_ReleaseTime,
      PITCH_Speed: pRelease?.Speed,
      PITCH_SpinAxis: pRelease?.SpinAxis3dTransverseAngle, // data.PITCH_SpinAxis,
      PITCH_SpinEfficiency: pRelease?.SpinAxis3dSpinEfficiency,
      PITCH_Strike: undefined, // data.PITCH_Strike,
      PITCH_StrikeZoneHeight: convertUnits(FT_TO_INCHES, pLocation?.Height),
      PITCH_StrikeZoneSide: convertUnits(FT_TO_INCHES, pLocation?.Side),
      PITCH_StrikeZoneTime: pLocation?.Time,
      PITCH_TotalSpin: undefined, // data.PITCH_TotalSpin,
      PITCH_TrueSpin: undefined, // data.PITCH_TrueSpin,
      PITCH_VBSpin: undefined, // convertUnits(METERS_TO_INCHES, data.PITCH_VBSpin),
      PITCH_VBTrajectory: pMovement?.InducedVertical,
      PITCH_VerticalAngle: pRelease?.VerticalAngle,
      PITCH_ZoneTime: undefined, // data.PITCH_ZoneTime,

      HIT_Distance: config.hit?.LandingFlat.Distance,
      HIT_LaunchAngle: config.hit?.Launch.VerticalAngle,
      HIT_ExitSpeed: config.hit?.Launch.Speed,
    };

    return output;
  }
}
