import { Box, Code, Text } from '@radix-ui/themes';
import { CommonDetails } from 'components/common/details';
import { format } from 'date-fns';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';
import './index.scss';

export interface ILog {
  timestamp: Date;
  message: string;
}

interface IProps {
  logs: ILog[];
}

interface IState {
  started: Date;
}

export class CommonLogs extends React.Component<IProps, IState> {
  state: IState = {
    started: new Date(),
  };

  private renderEntry(log: ILog, index: number) {
    return (
      <li key={`log-${index}`}>
        <Code className="select-all">
          {format(log.timestamp, '@ HH:mm:ss')}
        </Code>
        <br />
        <Code className="select-all">{log.message}</Code>
      </li>
    );
  }

  render() {
    return (
      <CommonDetails className="Logs" summary="Logs">
        <Box className="entries">
          <Text size={RADIX.TEXT.SIZE.SM}>
            <ol>
              {this.renderEntry(
                {
                  timestamp: this.state.started,
                  message: 'Start of log...',
                },
                -1
              )}
              {this.props.logs.map((log, index) =>
                this.renderEntry(log, index)
              )}
            </ol>
          </Text>
        </Box>
      </CommonDetails>
    );
  }
}
