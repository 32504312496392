import { ErrorBoundary } from 'components/common/error-boundary';
import { IVideoPlayback } from 'lib_ts/interfaces/i-video';
import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const COMPONENT_NAME = 'CommonVideoPlayer';

interface IProps extends videojs.PlayerOptions {
  /** contains playback details (e.g. poster URL, media URL, file type, etc...) */
  playback: IVideoPlayback;

  /** have player skip to this timestamp after setup */
  startAtTime?: number;

  /** called whenever player's timeupdate triggers, if player is paused */
  onTimeUpdate?: (newTime: number) => void;
}

interface IState {
  width: number;
  height: number;

  /** store the last video URL that has been set as player src */
  currentVideoURL?: string;
}

export class CommonVideoPlayer extends React.Component<IProps, IState> {
  private init = false;

  private wrapperNode?: HTMLDivElement;
  private videoNode?: HTMLVideoElement;
  private player?: videojs.Player;

  constructor(props: IProps) {
    super(props);

    this.state = {
      width: 100,
      height: 100,
    };

    this.handleResize = this.handleResize.bind(this);
    this.handleTimeUpdate = this.handleTimeUpdate.bind(this);
    this.setupPlayer = this.setupPlayer.bind(this);
  }

  componentDidMount() {
    /** listen for subsequent resize events to re-run resizer */
    window.addEventListener('resize', this.handleResize);

    /** resize video now that wrapper has spawned */
    this.handleResize();

    if (this.init) {
      return;
    }

    this.init = true;

    videojs.hook('setup', (player) => {
      /** should be checking against undefined because 0 is a valid value */
      if (this.props.startAtTime !== undefined) {
        player.pause();
        player.currentTime(this.props.startAtTime);
      }
    });
  }

  /** needed for changing video without remounting component */
  componentDidUpdate() {
    if (this.state.currentVideoURL !== this.props.playback.video.url) {
      this.setupPlayer();
    }

    if (this.player && this.props.startAtTime) {
      if (this.player.currentTime() !== this.props.startAtTime) {
        this.player.currentTime(this.props.startAtTime);
      }
    }
  }

  // destroy player on unmount
  componentWillUnmount() {
    /** stop listening for resize events */
    window.removeEventListener('resize', this.handleResize);

    if (this.player) {
      this.player.off(this.player, 'timeupdate', this.handleTimeUpdate);
      this.player.dispose();
    }
  }

  private handleResize() {
    if (!this.wrapperNode) {
      return;
    }

    const aspectRatio =
      this.props.playback.video.cap_size_0 === undefined ||
      this.props.playback.video.cap_size_1 === undefined
        ? 10 / 16
        : this.props.playback.video.cap_size_0 /
          (this.props.playback.video.cap_size_1 || 1);

    const width = this.wrapperNode.offsetWidth ?? 100;
    const height = Math.round(width * aspectRatio);

    this.setState({
      width,
      height,
    });
  }

  private setupPlayer() {
    if (this.player) {
      /** update the src */
      this.player.src({
        type: this.props.playback.video.mime_type,
        src: this.props.playback.video.url,
      });

      this.player.poster(this.props.playback.thumb.url);
      return;
    }

    /** instantiate Video.js */
    const options: videojs.PlayerOptions = {
      autoplay: false,
      controls: true,
      sources: [
        {
          src: this.props.playback.video.url,
          type: this.props.playback.video.mime_type,
        },
      ],
      poster: this.props.playback.thumb.url,
      preload: 'auto', //starts loading if browser supports it
    };

    this.player = videojs(this.videoNode as HTMLVideoElement, options, () => {
      // nothing
    });
    this.player.on('timeupdate', this.handleTimeUpdate);

    this.player.on('pause', () => {
      // nothing
    });

    if (this.props.startAtTime !== undefined) {
      this.player.addClass('vjs-has-started');
    }

    this.setState({
      currentVideoURL: this.props.playback.video.url,
    });
  }

  private handleTimeUpdate() {
    /** only when player is ready */
    if (!this.player) {
      return;
    }

    /** only when player is paused */
    if (!this.player.paused()) {
      return;
    }

    /** only if callback is provided */
    if (!this.props.onTimeUpdate) {
      return;
    }

    this.props.onTimeUpdate(this.player.currentTime());
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <div
          ref={(elem) => (this.wrapperNode = elem as HTMLDivElement)}
          style={{
            overflow: 'hidden',
            borderRadius: 'var(--radius-4)',
          }}
        >
          <div
            data-vjs-player
            style={{
              width: this.state.width,
              height: this.state.height,
            }}
          >
            <video
              ref={(elem) => (this.videoNode = elem as HTMLVideoElement)}
              className="video-js"
            />
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}
