import { CommonCallout } from 'components/common/callouts';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTabs } from 'components/common/tabs';
import { ArchivedLogsTab } from 'components/sections/admin-portal/machines/dialogs/logs/archived-logs.tab';
import { LiveLogsTab } from 'components/sections/admin-portal/machines/dialogs/logs/live-logs.tab';
import { IMachinesContext } from 'contexts/admin/machines.context';
import { AuthContext } from 'contexts/auth.context';
import { IFullDialog } from 'interfaces/i-dialogs';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IAdminMachine } from 'lib_ts/interfaces/i-machine';
import React from 'react';

const COMPONENT_NAME = 'MachineLogsDialog';

enum TabKey {
  LiveLogs = 'LiveLogs',
  ArchivedLogs = 'ArchivedLogs',
}

interface IProps {
  machine: IAdminMachine;
  machinesCx: IMachinesContext;
  onClose: () => void;
}

interface IState {
  activeTab: TabKey;
}

export class MachineLogsDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      activeTab: TabKey.LiveLogs,
    };
  }

  render() {
    const mergeProps: IFullDialog = {
      identifier: COMPONENT_NAME,
      title: `${this.props.machine.machineID} Logs`,
      width: RADIX.DIALOG.WIDTH.XL,
      content: (
        <CommonTabs
          value={this.state.activeTab}
          onValueChange={(value) => {
            this.setState({ activeTab: value as TabKey });
          }}
          tabs={[
            {
              value: TabKey.LiveLogs,
              label: 'Live Logs',
              content: this.props.machine.connected ? (
                <AuthContext.Consumer>
                  {(authCx) => (
                    <LiveLogsTab
                      authCx={authCx}
                      machineID={this.props.machine.machineID}
                    />
                  )}
                </AuthContext.Consumer>
              ) : (
                <CommonCallout text="This is only available for connected machines." />
              ),
            },
            {
              value: TabKey.ArchivedLogs,
              label: 'Archived Logs',
              content: (
                <ArchivedLogsTab machineID={this.props.machine.machineID} />
              ),
            },
          ]}
        />
      ),

      loading: this.props.machinesCx.loading,
      onClose: () => {
        this.props.onClose();
      },
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog {...mergeProps} />
      </ErrorBoundary>
    );
  }
}
