import * as Toast from '@radix-ui/react-toast';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonToast } from 'components/common/toast';
import { ICookiesContext } from 'contexts/cookies.context';
import { IInboxContext } from 'contexts/inbox';
import { IMachineContext } from 'contexts/machine.context';
import { lightFormat } from 'date-fns';
import { INotification, INotificationButton } from 'interfaces/i-notification';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { IAnnouncement } from 'lib_ts/interfaces/common/i-announcement';
import { IUserErrorMsg } from 'lib_ts/interfaces/machine-msg/i-error';
import React from 'react';
import Confetti from 'react-confetti';
import { SessionEventsService } from 'services/session-events.service';
import { v4 } from 'uuid';
import './index.scss';

const RESTART_LABEL = 'common.restart';

const DAY_COLOR_DICT: { [mmdd: string]: string[] | undefined } = {
  '0101': ['white', 'blue'],
  '0214': ['white', 'pink', 'red'],
  '0317': ['white', 'green', 'orange'],
  '0701': ['red', 'white'],
  '0704': ['red', 'blue', 'white'],
  '1031': ['orange', 'green', 'purple', 'white'],
  '1225': ['red', 'green', 'white'],
};

const getDayColors = () => {
  const today = lightFormat(new Date(), 'MMdd');
  return DAY_COLOR_DICT[today];
};

interface IProps {
  cookiesCx: ICookiesContext;
  inboxCx: IInboxContext;
  machineCx?: IMachineContext;
}

interface IState {
  notifications: INotification[];
  fun: boolean;
  colors: string[];
}

export class NotificationListener extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    this.state = {
      notifications: [],
      fun: false,
      colors: getDayColors() ?? ['green', 'yellow'],
    };

    this.handleAnnouncement = this.handleAnnouncement.bind(this);
    this.handleDispatch = this.handleDispatch.bind(this);
    this.handleFun = this.handleFun.bind(this);
    this.handleUserError = this.handleUserError.bind(this);
  }

  componentDidMount() {
    WebSocketHelper.on(WsMsgType.Misc_Error, this.handleUserError);
    WebSocketHelper.on(WsMsgType.S2U_Announcement, this.handleAnnouncement);

    NotifyHelper.onToast(this.handleDispatch);
    NotifyHelper.onFun(this.handleFun);

    if (this.init) {
      return;
    }

    this.init = true;

    const today = lightFormat(new Date(), 'MMdd');

    if (getDayColors() && localStorage.getItem('last-fun') !== today) {
      // prevent repeated triggers
      localStorage.setItem('last-fun', today);
      NotifyHelper.haveFun();
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (prevProps.cookiesCx.session !== this.props.cookiesCx.session) {
      this.props.inboxCx.clear();
    }
  }

  componentWillUnmount() {
    NotifyHelper.removeToast(this.handleDispatch);
    WebSocketHelper.remove(WsMsgType.Misc_Error, this.handleUserError);
    WebSocketHelper.remove(WsMsgType.S2U_Announcement, this.handleAnnouncement);

    NotifyHelper.removeFun(this.handleFun);
  }

  private handleDispatch(event: CustomEvent) {
    try {
      const data: INotification = {
        ...event.detail,
        id: v4(),
      };

      if (data.debug && !this.props.cookiesCx.app.enable_debug_toasts) {
        // filter out debug toasts if disabled in cookies
        console.debug('suppressed debug toast', data);
        return;
      }

      if (data.show_restart) {
        const restartBtn: INotificationButton = {
          label: RESTART_LABEL,
          dismissAfterClick: true,
          onClick: () => this.props.machineCx?.restartArc('errormsg'),
        };

        if (!data.buttons) {
          data.buttons = [restartBtn];
        } else if (data.buttons.findIndex((b) => b.label) === -1) {
          data.buttons.push(restartBtn);
        }
      }

      if (data.inbox) {
        this.props.inboxCx.add(data);
      }

      this.state.notifications.push(data);

      this.setState({
        notifications: this.state.notifications.filter((n) => !n.closed),
      });
    } catch (e) {
      console.error('NotifyListener caught an error in handleToast', e);
    }
  }

  private handleAnnouncement(model: { detail: IAnnouncement }) {
    NotifyHelper.announce(model.detail);
  }

  // routes to this.handleDispatch
  private handleUserError(model: { detail: IUserErrorMsg }) {
    try {
      SessionEventsService.postEvent({
        category: 'machine',
        tags: 'errormsg',
        data: model.detail,
      });

      const type = model.detail.type;

      if (!type.user_message || typeof type.user_message !== 'string') {
        console.error({
          event: 'Invalid machine error-types data',
          type: type,
        });

        NotifyHelper.error({
          message_md:
            'Received machine error with invalid data. Check console.',
          delay_ms: 0,
          inbox: true,
        });
        return;
      }

      NotifyHelper.userError(type);
    } catch (e) {
      console.error('NotifyListener caught an error in handleErrorMsg', e);
    }
  }

  private handleFun() {
    if (!this.state.fun) {
      this.setState({ fun: true });
    }
  }

  render() {
    return (
      <ErrorBoundary componentName="NotifyListener">
        {/* provide it here to ensure it exists where machine context is defined */}
        <Toast.Provider swipeDirection="right" duration={60_000}>
          {this.state.notifications
            .filter((n) => !n.closed)
            .map((n) => (
              <CommonToast key={n.id} config={n} />
            ))}
          <Toast.Viewport className="ToastViewport top-right" />
        </Toast.Provider>

        {this.state.fun && (
          <Confetti
            colors={this.state.colors}
            drawShape={(ctx: CanvasRenderingContext2D) => {
              ctx.beginPath();
              ctx.fillStyle = ArrayHelper.getRandomMember(this.state.colors);
              ctx.fillRect(0, 0, 10, 10);
              ctx.closePath();
            }}
            recycle={false}
            onConfettiComplete={() => this.setState({ fun: false })}
            style={{
              // draw above dialogs
              zIndex: 2000,
            }}
          />
        )}
      </ErrorBoundary>
    );
  }
}
