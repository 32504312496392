import { Box, Flex, Heading } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSelectInput } from 'components/common/form/select';
import { MachinePerformanceBreakdown } from 'components/common/modelling/machine-performance-breakdown';
import { MachinePerformancePlot } from 'components/common/modelling/machine-performance-plot';
import MetricHeader from 'components/sections/admin-portal/machines/dialogs/performance/metric-header';
import { t } from 'i18next';
import { IPerformanceGroup } from 'interfaces/i-performance';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import React from 'react';

const COMPONENT_NAME = 'PerformanceMachineTab';

interface IProps {
  groups: IPerformanceGroup[];
  machine: IMachine;
}

interface IState {
  selectedGroup?: IPerformanceGroup;
}

export class PerformanceMachineTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedGroup: props.groups[0],
    };

    this.renderGroup = this.renderGroup.bind(this);
  }

  private renderGroup() {
    const group = this.state.selectedGroup;
    if (!group) {
      return;
    }

    const lastMetric = group.metrics.slice(-1)[0];

    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.LG}>
        <Heading size={RADIX.HEADING.SIZE.MD}>{group.type}</Heading>

        {group.metrics.length === 0 && <p>{t('common.no-data')}</p>}

        {group.metrics.length > 0 && (
          <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
            <Box>
              <MachinePerformancePlot metrics={group.metrics} />
            </Box>

            {lastMetric?.machine_performance && (
              <Box>
                <MetricHeader metric={lastMetric} />
                <MachinePerformanceBreakdown
                  timestamp={lastMetric._created}
                  performance={lastMetric.machine_performance}
                />
              </Box>
            )}
          </Flex>
        )}
      </Flex>
    );
  }

  render() {
    const ballTypes = ArrayHelper.unique(this.props.groups.map((m) => m.type));

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex direction="column" gap={RADIX.FLEX.GAP.LG}>
          <CommonSelectInput
            id="machine-performance-ball"
            name="selectedType"
            label="Ball Type"
            value={this.state.selectedGroup?.type}
            options={ballTypes.map((o) => ({
              label: o === this.props.machine.ball_type ? `${o} (Active)` : o,
              value: o,
            }))}
            onChange={(v) => {
              const group = this.props.groups.find((g) => g.type === v);
              this.setState({ selectedGroup: group });
            }}
            optional
          />

          {this.renderGroup()}
        </Flex>
      </ErrorBoundary>
    );
  }
}
