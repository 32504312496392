import { Box, Flex, Text } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonProgress } from 'components/common/progress';
import { ITrainingProgress } from 'interfaces/i-training';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

const COMPONENT_NAME = 'CommonTrainingProgress';

const COLOR_BY_PERCENT = false;

interface IProps {
  progress: ITrainingProgress;
}

interface IState {}

export class CommonTrainingProgress extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.getLabel = this.getLabel.bind(this);
    this.getColor = this.getColor.bind(this);
  }

  private getColor() {
    if (this.props.progress.paused) {
      return RADIX.COLOR.WARNING;
    }

    if (COLOR_BY_PERCENT) {
      if (this.props.progress.complete >= 100) {
        return RADIX.COLOR.SUCCESS;
      }

      if (this.props.progress.complete >= 67) {
        return RADIX.COLOR.WARNING;
      }

      if (this.props.progress.complete >= 33) {
        return RADIX.COLOR.INFO;
      }
    }

    return undefined;
  }

  private getLabel() {
    if (this.props.progress.label) {
      return this.props.progress.label;
    }

    if (this.props.progress.paused) {
      // no label but the bar is paused
      return 'Paused';
    }

    // default
    return `${this.props.progress.complete.toFixed(0)}%`;
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
          <Flex gap={RADIX.FLEX.GAP.MD} justify="between">
            <Box data-identifier="progress-left">
              <Text size={RADIX.TEXT.SIZE.SM}>
                {this.props.progress.left_text}
              </Text>
            </Box>
            <Box data-identifier="progress-right">
              <Text size={RADIX.TEXT.SIZE.SM}>
                {this.props.progress.right_text}
              </Text>
            </Box>
          </Flex>

          <CommonProgress
            value={this.props.progress.complete}
            color={this.getColor()}
            label={this.getLabel()}
            paused={this.props.progress.paused}
          />
        </Flex>
      </ErrorBoundary>
    );
  }
}
