import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { PitchListSidebar } from 'components/sections/pitch-list/sidebar';
import { IAimingContext } from 'contexts/aiming.context';
import { IAuthContext } from 'contexts/auth.context';
import { IMachineContext } from 'contexts/machine.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { IVideosContext } from 'contexts/videos/videos.context';
import { VideoHelper } from 'lib_ts/classes/video.helper';
import React from 'react';

const COMPONENT_NAME = 'MlbStatsBrowseSidebar';

interface IProps {
  authCx: IAuthContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  videosCx: IVideosContext;
  aimingCx: IAimingContext;

  handleVideoChange: (video_id: string | undefined) => void;
}

interface IState {}

export class MlbStatsBrowseSidebar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <PitchListSidebar
          machineCx={this.props.machineCx}
          matchingCx={this.props.matchingCx}
          videosCx={this.props.videosCx}
          aimingCx={this.props.aimingCx}
          onMatchesChanged={() => {
            // nothing
          }}
          onVideoChanged={(video_id) => {
            const pitch = this.props.aimingCx.pitch;
            if (!pitch) {
              return;
            }

            const changed = pitch.video_id !== video_id;
            if (!changed) {
              return;
            }

            if (video_id) {
              const video = this.props.videosCx.getVideo(video_id);

              if (!video) {
                NotifyHelper.error({
                  message_md: `Video \`${video_id}\` does not exist in context. Please try again.`,
                });
                return;
              }

              const warnings = VideoHelper.validateSelection({
                pitch_name: pitch.name,
                position: pitch.bs,
                video: video,
              });

              if (warnings.length > 0) {
                NotifyHelper.warning({
                  message_md: warnings[0],
                  inbox: true,
                });
              }
            }

            this.props.handleVideoChange(video_id);
          }}
        />
      </ErrorBoundary>
    );
  }
}
