import { Box, Flex, Heading } from '@radix-ui/themes';
import { CommonTableCheckedMenu } from 'components/common/table/checked-menu';
import { TableContext } from 'components/common/table/context';
import { PaginationSelector } from 'components/common/table/pagination-selector';
import { RowSelector } from 'components/common/table/row-selector';
import { CheckedContext } from 'contexts/layout/checked.context';
import { t } from 'i18next';
import { CheckedActionsCallback } from 'interfaces/tables/checking';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext, useMemo } from 'react';

interface IProps {
  checkedActions: CheckedActionsCallback | undefined;
  pageSizes: number[] | undefined;
  total: number | undefined;
}

export const TableFooter = (props: IProps) => {
  const tableCx = useContext(TableContext);
  const checkedCx = useContext(CheckedContext);

  const actions = useMemo(() => {
    if (!props.checkedActions) {
      return undefined;
    }

    const items = tableCx.sortedData.filter((m) => checkedCx.getChecked(m._id));
    if (items.length === 0) {
      // note: actions that are not related to checked items should not be put here!
      return undefined;
    }

    const output = props.checkedActions(items).filter((a) => !a.invisible);
    if (output.length === 0) {
      return undefined;
    }

    return output;
  }, [
    // faster than monitoring sortedData
    tableCx.tableKey,
    checkedCx.tally.key,
    props.checkedActions,
  ]);

  return (
    <Flex gap={RADIX.FLEX.GAP.LG} justify="end">
      {actions && (
        <Flex flexGrow="1" gap={RADIX.FLEX.GAP.SM}>
          <Heading size={RADIX.HEADING.SIZE.MD} mb="1">
            {checkedCx.tally.checked}&nbsp;
            {t('common.selected').toLowerCase()}
          </Heading>
          <Box ml="1" className="valign-center">
            <CommonTableCheckedMenu actions={actions} />
          </Box>
        </Flex>
      )}

      {tableCx.pageSize !== undefined &&
        props.pageSizes &&
        props.pageSizes.length > 1 && (
          <RowSelector pageSizeOptions={props.pageSizes} />
        )}

      {tableCx.pageSize !== undefined && props.total !== undefined && (
        <PaginationSelector pageSize={tableCx.pageSize} total={props.total} />
      )}
    </Flex>
  );
};
