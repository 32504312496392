import {
  IDropHandle,
  IDropValue,
} from 'components/common/drag-drop/drop-handle';
import { DragItem } from 'enums/dnd.enums';
import { FC, ReactNode } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';

interface IProps {
  id?: string;
  className?: string;
  value: any;
  type: DragItem;
  endFn?: (item: IDropValue, monitor: DragSourceMonitor<IDropValue>) => void;
  children: ReactNode;
}

const defaultEndFn = (
  item: IDropValue,
  monitor: DragSourceMonitor<IDropValue>
) => {
  const target = monitor.getDropResult<IDropHandle>();
  if (item && target) {
    console.warn({
      event: 'successfully dropped item onto target without handler',
      item,
      target,
    });
  }
};

export const DragHandle: FC<IProps> = (props) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: props.type,
      item: { value: props.value } as any,
      end: props.endFn ?? defaultEndFn,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [props.value]
  );

  const style = {
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <span
      ref={drag}
      id={props.id}
      className={`DragHandle ${props.className ?? ''}`}
      style={style}
    >
      {props.children}
    </span>
  );
};
