import { Box, Flex, Text } from '@radix-ui/themes';
import { CommonDetails } from 'components/common/details';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { DotDotDot } from 'components/common/layout/dot-dot-dot';
import { MachineContext } from 'contexts/machine.context';
import { IButton } from 'interfaces/i-buttons';
import { HealthCheckType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MainService } from 'services/main.service';

const COMPONENT_NAME = 'HealthCheckDialog';

export const HealthCheckDialog = (props: { onClose: () => void }) => {
  const { healthChecking, healthCheckResults, startHealthCheck } =
    useContext(MachineContext);

  const buttons: IButton[] = useMemo(() => {
    return [
      {
        label: `Full Check`,
        disabled: healthChecking,
        onClick: () => startHealthCheck(HealthCheckType.FULL),
      },
    ];
  }, [healthChecking]);

  const [url, setUrl] = useState<string | undefined>();

  useEffect(() => {
    if (!healthCheckResults?.s3_path) {
      setUrl(undefined);
      return;
    }

    const path = healthCheckResults.s3_path;

    MainService.getInstance()
      .signUrls([path])
      .then((result) => {
        setUrl(result[path]?.url);
      });
  }, [healthCheckResults?.s3_path]);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonDialog
        identifier={COMPONENT_NAME}
        title="common.health-check"
        width={RADIX.DIALOG.WIDTH.SM}
        content={
          <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
            <Box>
              {healthChecking ? (
                <DotDotDot label="Please wait" />
              ) : (
                <Text color={RADIX.COLOR.WARNING}>
                  The machine will perform a comprehensive health check of all
                  submodules. This test may take up to 6 minutes. During
                  testing, the machine will move, so please ensure the track is
                  clear. The machine will not accept any pitch during this
                  procedure. Once the health check is complete, a summary with a
                  Pass or Fail result will display, along with a list of any
                  submodules that did not pass, including details of any failed
                  tests. Additionally, a detailed breakdown of all test results
                  will be shown for further review.
                </Text>
              )}
            </Box>

            {url && (
              <img alt="health check image" className="plain-image" src={url} />
            )}

            {healthCheckResults && (
              <CommonDetails summary="Results" defaultOpen>
                <pre>{JSON.stringify(healthCheckResults, null, 2)}</pre>
              </CommonDetails>
            )}
          </Flex>
        }
        buttons={buttons}
        onClose={() => props.onClose()}
      />
    </ErrorBoundary>
  );
};
