import {
  IPitchStat,
  IPitchStatsFilters,
} from 'lib_ts/interfaces/data/i-pitch-stats';
import { BaseRESTService } from 'services/_base-rest.service';

export class DataService extends BaseRESTService {
  private static instance: DataService;

  private static getInstance(): DataService {
    if (!DataService.instance) {
      DataService.instance = new DataService();
    }

    return DataService.instance;
  }

  private constructor() {
    super({
      controller: 'data',
    });
  }

  static async getPitchStats(
    filters: IPitchStatsFilters
  ): Promise<IPitchStat[]> {
    return await this.getInstance().post(
      {
        uri: 'pitch-stats',
      },
      filters
    );
  }
}
