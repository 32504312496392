import { Flex } from '@radix-ui/themes';
import { ITableToolbar } from 'interfaces/tables/toolbar';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useMemo } from 'react';

interface IProps extends Partial<ITableToolbar> {}

export const TableToolbar = (props: IProps) => {
  const showToolbar = useMemo(() => {
    if (props.toolbarContent !== undefined) {
      return true;
    }

    if (props.extraToolbarContent !== undefined) {
      return true;
    }

    return false;
  }, [props.toolbarContent, props.extraToolbarContent]);

  if (!showToolbar) {
    return <></>;
  }

  return (
    <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
      {props.toolbarContent}
      {props.extraToolbarContent}
    </Flex>
  );
};
