import { Badge, Box, Flex, Heading } from '@radix-ui/themes';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import {
  PresetTrainingContext,
  PresetTrainingProvider,
} from 'components/machine/dialogs/preset-training/context';
import { PresetTrainingControls } from 'components/machine/dialogs/preset-training/controls';
import {
  AimingContext,
  AimingProvider,
  IAimingContext,
} from 'contexts/aiming.context';
import { AuthContext, IAuthContext } from 'contexts/auth.context';
import { CookiesContext, ICookiesContext } from 'contexts/cookies.context';
import { GlobalContext, IGlobalContext } from 'contexts/global.context';
import { IPitchListContext } from 'contexts/pitch-lists/list.context';
import {
  IMatchingShotsContext,
  MatchingShotsContext,
  MatchingShotsProvider,
} from 'contexts/pitch-lists/matching-shots.context';
import { t } from 'i18next';
import { ITrainingDialog } from 'interfaces/i-training';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

const COMPONENT_NAME = 'PresetTrainingDialog';

interface IProps extends ITrainingDialog {
  listCx?: IPitchListContext;
}

interface IState {
  session_start: string;
}

export class PresetTrainingDialog extends React.Component<IProps, IState> {
  controls?: PresetTrainingControls;

  constructor(props: IProps) {
    super(props);

    this.state = {
      session_start: new Date().toISOString(),
    };

    this.renderDialog = this.renderDialog.bind(this);
  }

  componentDidMount(): void {
    // todo: convert this to a functional component to clean this up
    (this.context as IGlobalContext).countDialog({
      id: this.props.identifier,
      open: true,
    });
  }

  componentWillUnmount() {
    // todo: convert this to a functional component to clean this up
    (this.context as IGlobalContext).countDialog({
      id: this.props.identifier,
      open: false,
    });
    this.props.machineCx.onEndTraining();
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <AuthContext.Consumer>
          {(authCx) => {
            if (authCx.restrictedGameStatus()) {
              return (
                <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
                  <Heading size={RADIX.HEADING.SIZE.LG}>
                    Game Status: {authCx.gameStatus}
                  </Heading>

                  <p>
                    Training is not possible at this time. Please try again
                    later or contact support.
                  </p>
                </Flex>
              );
            }

            return (
              <CookiesContext.Consumer>
                {(cookiesCx) => (
                  <MatchingShotsProvider>
                    <MatchingShotsContext.Consumer>
                      {(matchingCx) => (
                        <AimingProvider newerThan={this.state.session_start}>
                          <AimingContext.Consumer>
                            {(aimingCx) =>
                              this.renderDialog(
                                aimingCx,
                                authCx,
                                cookiesCx,
                                matchingCx
                              )
                            }
                          </AimingContext.Consumer>
                        </AimingProvider>
                      )}
                    </MatchingShotsContext.Consumer>
                  </MatchingShotsProvider>
                )}
              </CookiesContext.Consumer>
            );
          }}
        </AuthContext.Consumer>
      </ErrorBoundary>
    );
  }

  private renderDialog(
    aimingCx: IAimingContext,
    authCx: IAuthContext,
    cookiesCx: ICookiesContext,
    matchingCx: IMatchingShotsContext
  ) {
    const single = this.props.pitches.length === 1;

    return (
      <CommonDialog
        identifier={COMPONENT_NAME}
        width={RADIX.DIALOG.WIDTH.XL}
        title={
          <Flex gap={RADIX.FLEX.GAP.SM} align="center">
            <Box mt="1">
              <Heading size={RADIX.HEADING.SIZE.MODAL_TITLE} truncate>
                {single
                  ? t('common.training-pitch-x', {
                      x: aimingCx.pitch?.name ?? t('pl.unnamed-pitch'),
                    })
                  : t('common.training-pitch-n-of-total-x', {
                      n: (this.controls?.getIndex() ?? 0) + 1,
                      total: this.props.pitches.length,
                      x: aimingCx.pitch?.name ?? t('pl.unnamed-pitch'),
                    })}
              </Heading>
            </Box>

            <Box className="valign-center">
              <Badge>
                {t(
                  aimingCx.pitch?.priority === BuildPriority.Breaks
                    ? 'common.break-priority'
                    : 'common.spin-priority'
                )}
              </Badge>
            </Box>
          </Flex>
        }
        content={
          <PresetTrainingProvider>
            <PresetTrainingContext.Consumer>
              {(presetCx) => (
                <PresetTrainingControls
                  ref={(elem) =>
                    (this.controls = elem as PresetTrainingControls)
                  }
                  machineCx={this.props.machineCx}
                  trainingCx={this.props.trainingCx}
                  listCx={this.props.listCx}
                  cookiesCx={cookiesCx}
                  authCx={authCx}
                  matchingCx={matchingCx}
                  aimingCx={aimingCx}
                  presetCx={presetCx}
                  pitches={this.props.pitches}
                  onFinish={this.props.onClose}
                />
              )}
            </PresetTrainingContext.Consumer>
          </PresetTrainingProvider>
        }
        onClose={this.props.onClose}
      />
    );
  }
}

PresetTrainingDialog.contextType = GlobalContext;
