import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { SessionVisualizer } from 'components/common/sessions/dialogs/visualize-session/visualizer';
import { t } from 'i18next';
import { IBaseDialog } from 'interfaces/i-dialogs';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

const COMPONENT_NAME = 'VisualizeSessionDialog';

interface IProps extends IBaseDialog {
  session: string;
}

interface IState {}

export class VisualizeSessionDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={this.props.identifier}
          title={`${t('common.session')} ${this.props.session}`}
          content={<SessionVisualizer session={this.props.session} />}
          width={RADIX.DIALOG.WIDTH.XL}
          onClose={this.props.onClose}
        />
      </ErrorBoundary>
    );
  }
}
