import { Box, ContextMenu, Table, Text } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { ClickCatcher } from 'components/common/layout/click-catcher';
import { SEPARATOR_LABEL, setupMenuActions } from 'components/common/menu';
import { convertTableToMenu } from 'components/common/table/body-cell';
import { TableBodyLoading } from 'components/common/table/body-loading';
import { TableContext } from 'components/common/table/context';
import { CommonTableHeaderCell } from 'components/common/table/header-cell';
import { NoDataPlaceholder } from 'components/common/table/no-data-placeholder';
import { TableRow } from 'components/common/table/row';
import { CheckedContext } from 'contexts/layout/checked.context';
import { ACTIONS_KEY } from 'enums/tables';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import { ITableColumn } from 'interfaces/tables/columns';
import { ICommonTableProps } from 'interfaces/tables/main';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { CSSProperties, useContext, useMemo } from 'react';

const ENABLE_CHECKED_ACTIONS = false;
const ENABLE_CONTEXT_MENU = true;

const ROUNDED_TABLE_BORDER_CSS: CSSProperties = {
  overflow: 'hidden',
  border: '1px solid var(--gray-3)',
  borderRadius: 'var(--radius-3)',
};

const SQUARED_TABLE_BORDER_CSS: CSSProperties = {
  border: '1px solid var(--gray-3)',
  borderRadius: 0,
};

interface IProps extends ICommonTableProps {
  columns: ITableColumn[];
}

export const CommonTableBody = (props: IProps) => {
  const {
    contextMenuTarget,
    pageData,
    sortedData,
    tableKey,
    setContextMenuTarget,
  } = useContext(TableContext);

  const { tally, getChecked } = useContext(CheckedContext);

  // lifted from footer component
  const checkedActions = useMemo(() => {
    if (!ENABLE_CHECKED_ACTIONS) {
      return undefined;
    }

    if (!props.checkedActions) {
      return undefined;
    }

    const items = sortedData.filter((m) => getChecked(m._id));
    if (items.length === 0) {
      // note: actions that are not related to checked items should not be put here!
      return undefined;
    }

    const output = props.checkedActions(items).filter((a) => !a.invisible);
    if (output.length === 0) {
      return undefined;
    }

    output.forEach((o) => {
      o.group = 'common.selected';
    });

    return output;
  }, [
    // faster than monitoring sortedData
    tableKey,
    tally.key,
    props.checkedActions,
  ]);

  const rowActions = useMemo(() => {
    if (!contextMenuTarget) {
      return undefined;
    }

    const actionColumn = props.columns.find((c) => c.key === ACTIONS_KEY);

    if (!actionColumn?.actions) {
      return undefined;
    }

    return actionColumn.actions
      .map((a) => convertTableToMenu(a, contextMenuTarget))
      .filter((a) => !a.invisible);
  }, [contextMenuTarget, props.columns]);

  const contextActions = useMemo(() => {
    const output: IMenuAction[] = [];

    if (rowActions) {
      output.push(...rowActions);
    }

    if (checkedActions) {
      output.push(...checkedActions);
    }

    return setupMenuActions(
      output.filter((m) => !m.invisible),
      true
    );
  }, [rowActions, checkedActions]);

  const tableRoot = useMemo(() => {
    if (!props.loading && pageData.length === 0) {
      return (
        <NoDataPlaceholder
          vFlex={props.vFlex}
          noDataBody={props.noDataBody}
          noDataHeader={props.noDataHeader}
        />
      );
    }

    const cssRules = props.squareBorder
      ? SQUARED_TABLE_BORDER_CSS
      : ROUNDED_TABLE_BORDER_CSS;

    return (
      <Table.Root
        id={props.id}
        data-testid={props.id}
        className={props.className}
        onContextMenu={() => {
          console.debug('clear context menu target from table root');
          setContextMenuTarget(undefined);
        }}
        style={
          props.vFlex
            ? {
                ...cssRules,
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              }
            : cssRules
        }
      >
        <Table.Header>
          <Table.Row>
            {props.columns.map((col, iCol) => (
              <CommonTableHeaderCell
                key={iCol}
                col={col}
                enableSort={!!props.enableSort}
                checkAllIDs={props.displayData.map((m) => m._id)}
                afterCheckAll={props.afterCheckAll}
              />
            ))}
          </Table.Row>
        </Table.Header>

        {props.loading ? (
          <TableBodyLoading rows={6} columns={props.columns.length} />
        ) : (
          <Table.Body>
            {pageData.map((item, index) => (
              <TableRow
                key={`${tableKey}-table-${props.id}-row-${index}`}
                tableID={props.id}
                index={index}
                item={item}
                {...props}
              />
            ))}
          </Table.Body>
        )}

        {!props.loading && props.footerRow && (
          <tfoot>
            <TableRow
              key={`table-${props.id}-row-footer`}
              tableID={props.id}
              item={props.footerRow}
              columns={props.columns}
              index={-1}
            />
          </tfoot>
        )}
      </Table.Root>
    );
  }, [pageData, tableKey, props.loading, props.squareBorder]);

  if (!ENABLE_CONTEXT_MENU) {
    // right-click shouldn't trigger any menu
    return tableRoot;
  }

  return (
    <ClickCatcher>
      <ContextMenu.Root
        onOpenChange={(open) => {
          if (open) {
            // do nothing if the menu just opened
            return;
          }

          // this is for the case where the menu was closed and no new menu was opened, then reset the target so the highlight goes away
          // this should occur before any context event of right-clicking a new row goes through (which would select a new target) because of the timeout
          // this ensures that clearing things here will not interfere with selecting a new target for the new row
          setContextMenuTarget(undefined);
        }}
      >
        <ContextMenu.Trigger>{tableRoot}</ContextMenu.Trigger>

        {contextMenuTarget && contextActions.length > 0 && (
          <ContextMenu.Content
            key={contextMenuTarget._id}
            className="MenuContent scroll-hover"
          >
            {contextActions.map((a, i) =>
              a.label === SEPARATOR_LABEL ? (
                <ContextMenu.Separator
                  key={i}
                  data-group={a.group}
                  className="MenuSeparator"
                  // avoid starting a menu with a separator
                  hidden={i === 0}
                />
              ) : (
                <ContextMenu.Item
                  key={i}
                  className={StringHelper.classNames(['MenuItem', a.className])}
                  data-group={a.group}
                  title={a.tooltip ? t(a.tooltip).toString() : undefined}
                  disabled={a.disabled}
                  onClick={a.onClick}
                >
                  {a.prefixIcon && (
                    <Box
                      className="valign-center"
                      style={{
                        marginRight: '8px',
                        paddingTop: '2px',
                      }}
                    >
                      <Text
                        color={a.disabled ? RADIX.COLOR.SECONDARY : a.color}
                      >
                        {a.prefixIcon}
                      </Text>
                    </Box>
                  )}

                  <Box className="valign-center">
                    <Text
                      color={a.disabled ? RADIX.COLOR.SECONDARY : a.color}
                      title={t(a.label).toString()}
                      truncate
                    >
                      {t(a.label)}
                    </Text>
                  </Box>

                  {a.suffixIcon && (
                    <Box
                      className="valign-center"
                      style={{
                        marginLeft: '8px',
                        paddingTop: '2px',
                      }}
                    >
                      {a.suffixIcon}
                    </Box>
                  )}
                </ContextMenu.Item>
              )
            )}
          </ContextMenu.Content>
        )}
      </ContextMenu.Root>
    </ClickCatcher>
  );
};
