import {
  BarChartIcon,
  ExclamationTriangleIcon,
  EyeOpenIcon,
  GearIcon,
  KeyboardIcon,
  LightningBoltIcon,
  QuestionMarkCircledIcon,
  VideoIcon,
} from '@radix-ui/react-icons';
import { RouteHelper } from 'classes/helpers/route.helper';
import {
  BaseballIcon,
  HelmetIcon,
  SuperAdminIcon,
} from 'components/common/custom-icon/shorthands';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { ISection } from 'interfaces/i-section';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { SessionMode } from 'lib_ts/interfaces/i-auth-token';
import { ICombinedPermissions } from 'lib_ts/interfaces/i-permissions';

interface IUserSectionConfig {
  /** game status */
  restricted: boolean;
  permissions: ICombinedPermissions;
}

export class SectionsHelper {
  static isAnyAdmin(role: UserRole): boolean {
    if ([UserRole.team_admin, UserRole.admin].includes(role)) {
      return true;
    }

    return false;
  }

  static isSuperAdmin(role: UserRole): boolean {
    if (UserRole.admin === role) {
      return true;
    }

    return false;
  }

  private static BASE_SECTIONS: ISection[] = [
    {
      value: SectionName.GameInProgress,
      slug: RouteHelper.getSlug([SectionName.GameInProgress]),
      label: 'main.game-in-progress',
      icon: ExclamationTriangleIcon,
      invisible: true,
    },
    {
      value: SectionName.NotFound,
      slug: RouteHelper.getSlug([SectionName.NotFound]),
      label: 'main.not-found',
      icon: QuestionMarkCircledIcon,
      invisible: true,
    },
    {
      value: SectionName.Unavailable,
      slug: RouteHelper.getSlug([SectionName.Unavailable]),
      label: 'main.unavailable',
      icon: QuestionMarkCircledIcon,
      invisible: true,
    },
  ];

  static getAdminSections(config: {
    role: UserRole;
    restricted: boolean;
    mode: SessionMode;
  }): ISection[] {
    return [
      {
        value: SectionName.AdminPortal,
        slug: RouteHelper.getSlug([SectionName.AdminPortal]),
        label: 'main.admin-portal',
        icon: KeyboardIcon,
        invisible:
          // hide from non-admin users
          !this.isAnyAdmin(config.role) ||
          // hide from team admins during a game
          (config.role === UserRole.team_admin && config.restricted),

        subsections: [
          {
            default: true,
            value: SubSectionName.Users,
            slug: RouteHelper.getSlug([SubSectionName.Users]),
            label: 'admin.users',
          },
          {
            value: SubSectionName.Machines,
            slug: RouteHelper.getSlug([SubSectionName.Machines]),
            label: 'admin.machines',
          },
          {
            value: SubSectionName.Teams,
            slug: RouteHelper.getSlug([SubSectionName.Teams]),
            label: 'admin.teams',
            invisible: !this.isSuperAdmin(config.role),
            icon: SuperAdminIcon,
          },
          {
            value: SubSectionName.MachineModels,
            slug: RouteHelper.getSlug([SubSectionName.MachineModels]),
            label: 'admin.machine-models',
            invisible: !this.isSuperAdmin(config.role),
            icon: SuperAdminIcon,
          },
          {
            value: SubSectionName.ErrorTypes,
            slug: RouteHelper.getSlug([SubSectionName.ErrorTypes]),
            label: 'admin.error-types',
            invisible: !this.isSuperAdmin(config.role),
            icon: SuperAdminIcon,
          },
          {
            value: SubSectionName.StaticVideos,
            slug: RouteHelper.getSlug([SubSectionName.StaticVideos]),
            label: 'admin.videos',
            invisible: !this.isSuperAdmin(config.role),
            icon: SuperAdminIcon,
          },

          {
            value: SubSectionName.ApiKeys,
            slug: RouteHelper.getSlug([SubSectionName.ApiKeys]),
            label: 'admin.api-keys',
          },

          {
            value: SubSectionName.ServerControls,
            slug: RouteHelper.getSlug([SubSectionName.ServerControls]),
            label: 'admin.server-controls',
            invisible: !this.isSuperAdmin(config.role),
            icon: SuperAdminIcon,
          },
        ],
      },
      {
        value: SectionName.FeatureDemo,
        slug: RouteHelper.getSlug([SectionName.FeatureDemo]),
        label: 'main.feature-demo',
        icon: EyeOpenIcon,
        invisible: !SectionsHelper.isSuperAdmin(config.role),
      },
    ];
  }

  static getUserSections(config: IUserSectionConfig): ISection[] {
    return [
      ...this.BASE_SECTIONS,
      {
        value: SectionName.Pitches,
        slug: RouteHelper.getSlug([SectionName.Pitches]),
        label: 'main.pitches',
        icon: BaseballIcon,
        subsections: [
          {
            default: true,
            value: SubSectionName.Library,
            slug: RouteHelper.getSlug([SubSectionName.Library]),
            label: 'main.library',
          },
          {
            value: SubSectionName.Lists,
            slug: RouteHelper.getSlug([SubSectionName.Lists]),
            label: 'main.pitch-lists',
          },
          {
            value: SubSectionName.GameData,
            slug: RouteHelper.getSlug([SubSectionName.GameData]),
            label: 'main.game-data',
            invisible: !config.permissions.mlb_stats_api,
          },
          // not shown in selector
          {
            value: SubSectionName.Design,
            slug: RouteHelper.getSlug([SubSectionName.Design]),
            label: 'main.pitch-design',
            invisible: true,
          },

          {
            value: SubSectionName.List,
            slug: RouteHelper.getSlug([SubSectionName.List]),
            label: 'main.pitch-list',
            invisible: true,
          },
          {
            value: SubSectionName.Upload,
            slug: RouteHelper.getSlug([SubSectionName.Upload]),
            label: 'main.bulk-pitch-upload',
            invisible: true,
          },
        ],
      },

      {
        value: SectionName.QuickSession,
        slug: RouteHelper.getSlug([SectionName.QuickSession]),
        label: 'main.quick-session',
        icon: LightningBoltIcon,
        invisible: !config.permissions.quick_session,
      },

      {
        value: SectionName.HitterLibrary,
        slug: RouteHelper.getSlug([SectionName.HitterLibrary]),
        label: 'main.hitters',
        icon: HelmetIcon,
        invisible: config.restricted,
      },
      {
        value: SectionName.VideoLibrary,
        slug: RouteHelper.getSlug([SectionName.VideoLibrary]),
        label: 'main.videos',
        icon: VideoIcon,
        invisible: config.restricted,
      },
      {
        value: SectionName.Analytics,
        slug: RouteHelper.getSlug([SectionName.Analytics]),
        label: 'main.analytics',
        icon: BarChartIcon,
        invisible: config.restricted,
        subsections: [
          {
            default: true,
            value: SubSectionName.HittingReports,
            slug: RouteHelper.getSlug([SubSectionName.HittingReports]),
            label: 'main.hitting-reports',
          },
          {
            value: SubSectionName.SessionHistory,
            slug: RouteHelper.getSlug([SubSectionName.SessionHistory]),
            label: 'main.session-history',
          },
          // not shown in selector
          {
            value: SubSectionName.HitterSession,
            slug: RouteHelper.getSlug([SubSectionName.HitterSession]),
            label: 'main.hitter-session',
            invisible: true,
          },
        ],
      },
      {
        value: SectionName.MachineCalibration,
        slug: RouteHelper.getSlug([SectionName.MachineCalibration]),
        label: 'main.machine-calibration',
        icon: GearIcon,
        invisible: config.restricted,
      },
    ];
  }
}
