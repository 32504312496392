import { Box } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonSwitchInput } from 'components/common/form/switch';
import { CommonTextInput } from 'components/common/form/text';
import { IMachineModelsContext } from 'contexts/admin/machine-models.context';
import { IMachinesContext } from 'contexts/admin/machines.context';
import { addMonths, startOfToday } from 'date-fns';
import { IFullDialog } from 'interfaces/i-dialogs';
import { TagPrefix } from 'lib_ts/enums/machine-models.enums';
import { BallType } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IGatherShotDataQuery } from 'lib_ts/interfaces/modelling/i-gather-shot-data';
import React from 'react';

const COMPONENT_NAME = 'CreateMetricsDialog';

const DEFAULT_FILTER: Partial<IGatherShotDataQuery> = {
  start_date: addMonths(startOfToday(), -3).toISOString(),
  end_date: startOfToday().toISOString(),
};

interface IProps {
  machinesCx: IMachinesContext;
  machineModelsCx: IMachineModelsContext;
  onClose: () => void;
}

interface IState {
  ball_type?: BallType;
  machineID?: string;

  names: string[];
  dataFilter: Partial<IGatherShotDataQuery>;

  force: boolean;
}

// todo: deprecate this if it's no longer necessary
export class CreateMetricsDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      names: [],
      ball_type: DEFAULT_FILTER.ball_type,
      dataFilter: DEFAULT_FILTER,
      force: false,
    };

    this.updateMetadata = this.updateMetadata.bind(this);
  }

  private updateMetadata(filter: Partial<IGatherShotDataQuery>) {
    this.setState({
      dataFilter: {
        ...this.state.dataFilter,
        ...filter,
      },
    });
  }

  render() {
    const mergeProps: IFullDialog = {
      identifier: COMPONENT_NAME,
      title: 'Create Metrics',
      width: RADIX.DIALOG.WIDTH.XL,
      content: (
        <CommonFormGrid columns={2}>
          {/* row 1 */}
          <CommonSelectInput
            id="create-metric-ball"
            name="ball_type"
            label="common.ball-type"
            options={Object.values(BallType).map((m) => ({
              label: m,
              value: m,
            }))}
            value={this.state.ball_type}
            onChange={(v) =>
              this.setState({
                ball_type: v as BallType,
                names: [],
              })
            }
            optional
          />
          <CommonSearchInput
            id="create-metric-machineID"
            label="common.machine"
            options={this.props.machinesCx.machines.map((o) => ({
              label: `${o.machineID} (${o.nickname ?? 'no nickname'})`,
              value: o.machineID,
            }))}
            values={this.state.machineID ? [this.state.machineID] : []}
            onChange={(machineIDs) => {
              this.setState({
                machineID: machineIDs[0],
              });
            }}
          />
          {/* row 2 */}
          <Box gridColumn="span 2">
            <CommonSearchInput
              id="names"
              label="common.model"
              placeholder={
                this.state.ball_type
                  ? 'common.select-one-or-more'
                  : 'admin.select-a-ball-type-first'
              }
              options={
                this.state.ball_type
                  ? this.props.machineModelsCx.models
                      .filter(
                        (m) =>
                          m.tags &&
                          m.tags.includes(
                            `${TagPrefix.BallType}:${this.state.ball_type}`
                          )
                      )
                      .filter(
                        (m) =>
                          !this.state.machineID ||
                          m.machineID === this.state.machineID
                      )
                      .sort((a, b) =>
                        (b._created ?? '').localeCompare(a._created ?? '')
                      )
                      .map((m) => ({
                        label: m.name,
                        value: m.name,
                      }))
                  : []
              }
              values={this.state.names}
              onChange={(v) => {
                this.setState({ names: v });
              }}
              hint_md="Models are listed newest first (i.e. descending creation date)."
              multiple
            />
          </Box>
          {/* row 3 */}
          <CommonTextInput
            id="create-metric-start"
            label="common.start-date"
            name="start_date"
            value={this.state.dataFilter.start_date}
            placeholder="YYYY-MM-DD"
            onChange={(v) =>
              this.updateMetadata({
                start_date: v,
              })
            }
            hint_md={`Like \`${DEFAULT_FILTER.start_date}\``}
          />
          <CommonTextInput
            id="create-metric-end"
            label="common.end-date"
            name="end_date"
            value={this.state.dataFilter.end_date}
            placeholder="YYYY-MM-DD"
            onChange={(v) =>
              this.updateMetadata({
                end_date: v,
              })
            }
            hint_md={`Like \`${DEFAULT_FILTER.end_date}\``}
          />
          {/* row 4 */}
          <CommonTextInput
            id="create-metric-session"
            label="common.session"
            name="session"
            value={this.state.dataFilter.session}
            onChange={(v) => this.updateMetadata({ session: v })}
            hint_md="Like \`AAAAAAAA-...-BBBB.X\`"
            optional
          />
          <CommonSwitchInput
            id="create-metric-ignore-duplicates"
            label="admin.force-ignore-duplicates"
            defaultChecked={this.state.force}
            onCheckedChange={(v) => this.setState({ force: v })}
          />
        </CommonFormGrid>
      ),
      loading: this.props.machineModelsCx.loading,
      buttons: [
        {
          label: 'common.create',
          color: RADIX.COLOR.INFO,
          onClick: () => {
            if (!this.state.names || this.state.names.length === 0) {
              NotifyHelper.error({
                message_md: 'Please select at least one model and try again.',
              });
              return;
            }

            const modelIDs = this.props.machineModelsCx.models
              .filter((m) => this.state.names.includes(m.name))
              .map((m) => m._id);

            const query: Partial<IGatherShotDataQuery> = {
              ...this.state.dataFilter,
              ball_type: this.state.ball_type,
            };

            this.props.machineModelsCx
              .evalModelMetrics(modelIDs, query)
              .then((result) => {
                if (result) {
                  this.props.onClose();
                }
              });
          },
        },
      ],
      onClose: () => this.props.onClose(),
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog {...mergeProps} />
      </ErrorBoundary>
    );
  }
}
