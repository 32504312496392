import { Flex, Progress, Text } from '@radix-ui/themes';
import { RADIX, RadixColor } from 'lib_ts/enums/radix-ui';

interface IProps {
  // 0-100
  value: number;
  color?: RadixColor;
  label?: string;
  paused?: boolean;
  hidden?: boolean;
}

export const CommonProgress = (props: IProps) => (
  <Flex hidden={props.hidden} direction="column" gap={RADIX.FLEX.GAP.SM}>
    <Progress
      color={props.paused ? RADIX.COLOR.WARNING : props.color}
      value={props.value}
    />

    {(props.paused || props.label) && (
      <Text as="div" align="right" size={RADIX.TEXT.SIZE.SM}>
        {props.paused ? 'Paused' : props.label}
      </Text>
    )}
  </Flex>
);
