import { ENV_PROD } from 'environments/i-prod';
import { IEnvConfig } from 'interfaces/i-env-config';
import { LanguageCode } from 'lib_ts/enums/translation';

export const ENV_PROD_JP: IEnvConfig = {
  ...ENV_PROD,

  // todo: add japanese to this list to expose the language selector
  languages: [LanguageCode.English],

  region_redirect: false,
  self_url: 'https://jp.trajektsports.app',
  server_url: 'https://jp.trajektsports.app/node',
  ws_url: 'wss://jp.trajektsports.app/wss',
};
