import { ErrorBoundary } from 'components/common/error-boundary';
import { LandingFormWrapper } from 'components/main/landing/forms/wrapper';
import React from 'react';

interface IProps {}

interface IState {}

export class Maintenance extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <ErrorBoundary componentName="Maintenance">
        <LandingFormWrapper header="The application is current undergoing scheduled maintenance.">
          <div className="align-center">Please try again later.</div>
        </LandingFormWrapper>
      </ErrorBoundary>
    );
  }
}
