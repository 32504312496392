export type FiringMode = 'manual' | 'auto';

export enum TrainingMode {
  Quick = 'quick',
  Manual = 'manual',
}

export enum HardwareVersion {
  Arc2022 = 'Arc 2022',
  Arc2023 = 'Arc 2023',
  Arc2024 = 'Arc 2024',
  Arc2025 = 'Arc 2025',
}

export enum BallType {
  A = 'A',
  AAA = 'AAA',
  FSOLB = 'FSOLB',
  BadenDimpledSeams = 'Baden Dimpled w/ Seams',
  L10 = 'L10 Training',
  L10Pro = 'L10 Pro',
  Marv = 'MARV',
  MiLB = 'MiLB',
  MLB = 'MLB',
  NCAA = 'NCAA',
  NPB = 'NPB',
  Smash = 'Driveline Smashfactor',
  StingFreeJugs = 'Sting-Free Jugs',
  Trajekt_36 = 'Trajekt 3.6oz',
  Trajekt_50 = 'Trajekt 5.0oz',
  Wilson = 'Wilson A1712',
  Misc = 'MISC',
}

export enum FireOption {
  SkipRapsodoValidation,
  DisableTrainFromFire,
}

export const MS_LIMITS = {
  /** ft, checked during pitch upload process */
  PITCH_PLATE_DISTANCE: {
    MAX: 65,
    MIN: 45,
  },
  /** ft, checked before sending mstarget to machine */
  PLATE_DISTANCE: {
    MAX: 65,
    MIN: 50,
  },
  /** rpm */
  WHEEL_SPEED: {
    MIN: 100,
    MAX: 3_000,
  },
  /** degrees */
  ALPHAS: {
    MIN: -24,
    MAX: 24,
  },
  /** degrees */
  TILT: {
    MIN: -8,
    MAX: 8,
  },
  /** degrees */
  YAW: {
    MIN: -8,
    MAX: 8,
  },
  /** ft */
  POSITION: {
    X: {
      MIN: -3.2,
      MAX: 3.2,
    },
    Z: {
      MIN: 4.1,
      MAX: 7,
    },
  },
};

export enum PassResult {
  Pass = 'pass',
  Mean = 'mean',
  Max = 'max',
  Both = 'both',
}
