import { Button, IconButton } from '@radix-ui/themes';
import { t } from 'i18next';
import { IButton } from 'interfaces/i-buttons';
import { RADIX } from 'lib_ts/enums/radix-ui';

export const DialogButton = (props: IButton) => {
  return (
    <Button
      className={props.className}
      size={RADIX.BUTTON.SIZE.MODAL}
      color={props.color}
      variant={props.variant ?? 'soft'}
      title={props.tooltip}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.icon}
      {props.label && t(props.label)}
      {props.suffixIcon}
    </Button>
  );
};

export const DialogIconButton = (props: IButton) => {
  return (
    <IconButton
      className={props.className}
      size={RADIX.BUTTON.SIZE.MODAL}
      color={props.color}
      variant={props.variant ?? 'soft'}
      title={props.tooltip}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.icon}
      {props.suffixIcon}
    </IconButton>
  );
};
