import { Button } from '@radix-ui/themes';
import { MachineContext, MachineDialogMode } from 'contexts/machine.context';
import { t } from 'i18next';
import { RADIX, RadixBtnSize } from 'lib_ts/enums/radix-ui';
import React from 'react';

interface IProps {
  size?: RadixBtnSize;
  /** applied to the button tag */
  className?: string;
}

interface IState {}

export class MachineUnavailableButton extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    /** responses are listened to by calibration listener under main component */
  }

  render() {
    return (
      <MachineContext.Consumer>
        {(machineCx) => {
          if (!machineCx.connected) {
            return (
              <Button
                size={this.props.size}
                color={RADIX.COLOR.DANGER}
                className={this.props.className}
                onClick={() =>
                  machineCx.setDialog(MachineDialogMode.Disconnected)
                }
              >
                {t('common.disconnected')}
              </Button>
            );
          }

          if (machineCx.busy) {
            return (
              <Button
                size={this.props.size}
                color={RADIX.COLOR.WARNING}
                className={this.props.className}
                onClick={() =>
                  machineCx.setDialog(MachineDialogMode.RequestControl)
                }
              >
                {t('common.busy')}
              </Button>
            );
          }
        }}
      </MachineContext.Consumer>
    );
  }
}
