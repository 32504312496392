import { Box, Code, Flex, Heading } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { lightFormat, parseISO } from 'date-fns';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IRealMachineMetric } from 'lib_ts/interfaces/modelling/i-real-machine-metric';

const MetricHeader = (props: {
  metric: IRealMachineMetric;
  showModel?: boolean;
}) => {
  const dateRange =
    props.metric.min_shot_created && props.metric.max_shot_created
      ? `between ${lightFormat(
          parseISO(props.metric.min_shot_created),
          'yyyy-MM-dd'
        )} and ${lightFormat(
          parseISO(props.metric.max_shot_created),
          'yyyy-MM-dd'
        )}`
      : undefined;

  return (
    <Flex gap={RADIX.FLEX.GAP.SM} justify="between">
      <Heading size={RADIX.HEADING.SIZE.SM}>
        <Code
          className="cursor-pointer"
          onClick={() => {
            if (!props.metric.active_model_id) {
              return;
            }

            navigator.clipboard.writeText(props.metric.active_model_id);

            NotifyHelper.success({
              message_md: `Model ID copied to clipboard!`,
            });
          }}
        >
          {props.showModel
            ? props.metric.active_model_name ?? 'Active Model'
            : props.metric.dataFilter.machineID ?? '???'}
        </Code>

        <small className="block">
          as of {lightFormat(parseISO(props.metric._created), 'yyyy-MM-dd')}
        </small>
      </Heading>

      <Box className="align-right">
        {props.metric.shots ?? '???'} shots
        {dateRange && <small className="block">{dateRange}</small>}
      </Box>
    </Flex>
  );
};

export default MetricHeader;
