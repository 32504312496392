import { Button } from '@radix-ui/themes';
import { ContactSupportButton } from 'components/common/buttons/contact-support';
import { ErrorBoundary } from 'components/common/error-boundary';
import { MachineUnavailableButton } from 'components/machine/buttons/unavailable';
import {
  IMachineContext,
  MachineContext,
  MachineDialogMode,
} from 'contexts/machine.context';
import { CalibrateMode } from 'enums/calibration';
import { RADIX, RadixBtnSize } from 'lib_ts/enums/radix-ui';
import React from 'react';

const COMPONENT_NAME = 'MachineCalibrateButton';

interface IProps {
  disabled?: boolean;

  label?: string;

  /** applied to the button tag */
  className?: string;

  size?: RadixBtnSize;

  /** for testing via feature demo */
  overrideMode?: CalibrateMode;
}

interface IState {}

export class MachineCalibrateButton extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.getMode = this.getMode.bind(this);
    this.renderButton = this.renderButton.bind(this);
  }

  private getMode(machineCx: IMachineContext) {
    if (this.props.overrideMode) {
      return this.props.overrideMode;
    }

    if (!machineCx.connected || machineCx.busy) {
      return CalibrateMode.Unavailable;
    }

    if (!machineCx.canCalibrate) {
      return CalibrateMode.ContactSupport;
    }

    return CalibrateMode.Calibrate;
  }

  private renderButton(machineCx: IMachineContext) {
    switch (this.getMode(machineCx)) {
      case CalibrateMode.Unavailable: {
        return (
          <MachineUnavailableButton
            size={this.props.size}
            className={this.props.className}
          />
        );
      }

      case CalibrateMode.ContactSupport: {
        return (
          <ContactSupportButton
            size={this.props.size}
            className={this.props.className}
          />
        );
      }

      case CalibrateMode.Calibrate:
      default: {
        if (machineCx.calibrating) {
          return (
            <Button
              size={this.props.size}
              color={RADIX.COLOR.WARNING}
              className={this.props.className}
              disabled
            >
              Realigning...
            </Button>
          );
        }

        return (
          <Button
            size={this.props.size}
            color={RADIX.COLOR.WARNING}
            disabled={this.props.disabled}
            className={this.props.className}
            onClick={() => machineCx.setDialog(MachineDialogMode.Realign)}
          >
            Realign Machine
          </Button>
        );
      }
    }
  }

  render() {
    return (
      <MachineContext.Consumer>
        {(machineCx) => (
          <ErrorBoundary componentName={COMPONENT_NAME}>
            {this.renderButton(machineCx)}
          </ErrorBoundary>
        )}
      </MachineContext.Consumer>
    );
  }
}
