import { useIntercom } from 'react-use-intercom';

export const IntercomSnapshotUpdater = (props: { url: string }) => {
  try {
    const intercom = useIntercom();

    intercom.update({
      customAttributes: {
        'Machine Snapshots URL': props.url,
      },
    });
  } catch (e) {
    console.error(e);
  }

  return <></>;
};
