import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTabs } from 'components/common/tabs';
import { PerformanceMachineTab } from 'components/sections/admin-portal/machines/dialogs/performance/machine.tab';
import { PerformanceModelTab } from 'components/sections/admin-portal/machines/dialogs/performance/model.tab';
import { PlottingTab } from 'components/sections/admin-portal/machines/dialogs/performance/plotting.tab';
import { lightFormat, parseISO } from 'date-fns';
import { IFullDialog } from 'interfaces/i-dialogs';
import { IPerformanceGroup } from 'interfaces/i-performance';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { MetricInterval } from 'lib_ts/enums/machine-models.enums';
import { BallType } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import { IRealMachineMetric } from 'lib_ts/interfaces/modelling/i-real-machine-metric';
import React from 'react';
import { AdminMachineModelsService } from 'services/admin/machine-models.service';

const COMPONENT_NAME = 'MachinePerformanceDialog';

enum TabKey {
  Model = 'ModelPerformance',
  Machine = 'MachinePerformance',
  Plotting = 'Repeatability',
}

interface IProps {
  machine: IMachine;
  onClose: () => void;
}

interface IState {
  groups?: IPerformanceGroup[];
  activeTab: TabKey;
}

export class MachinePerformanceDialog extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    this.state = {
      activeTab: TabKey.Model,
    };

    this.fetchData = this.fetchData.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  componentDidMount(): void {
    if (this.init) {
      return;
    }

    this.init = true;
    this.fetchData();
  }

  private async fetchData() {
    const metrics =
      await AdminMachineModelsService.getInstance().getMetricsForMachine({
        machine_id: this.props.machine._id,
        job_interval: MetricInterval.Daily,
      });

    const ballTypes = ArrayHelper.unique(
      metrics.map((m) => m.dataFilter.ball_type as BallType)
    );

    const groups = ballTypes
      .map((bt) => {
        // only keep one entry per date per ball_type
        const dateDict: { [date: string]: IRealMachineMetric } = {};

        metrics
          .filter((m) => m.dataFilter.ball_type === bt)
          .forEach((m) => {
            const ds = lightFormat(
              parseISO(m.dataFilter.end_date ?? m._created),
              'yyyy-MM-dd'
            );
            dateDict[ds] = m;
          });

        const group: IPerformanceGroup = {
          type: bt,
          metrics: Object.values(dateDict).sort((a, b) => {
            const aDate = a.dataFilter.end_date ?? a._created;
            const bDate = b.dataFilter.end_date ?? b._created;

            return aDate.localeCompare(bDate);
          }),
        };

        return group;
      })
      // ensure active ball type is always first, followed by alphabetical sorting
      .sort((a, b) => {
        if (a.type === this.props.machine.ball_type) {
          return -1;
        }

        if (b.type === this.props.machine.ball_type) {
          return 1;
        }

        return a.type.localeCompare(b.type);
      });

    this.setState({
      groups: groups,
    });
  }

  private renderContent() {
    return (
      <CommonTabs
        value={this.state.activeTab}
        onValueChange={(value) => {
          this.setState({ activeTab: value as TabKey });
        }}
        tabs={[
          {
            value: TabKey.Model,
            label: 'Model',
            loading: !this.state.groups,
            content: this.state.groups ? (
              <PerformanceModelTab
                groups={this.state.groups}
                machine={this.props.machine}
              />
            ) : undefined,
          },
          {
            value: TabKey.Machine,
            label: 'Machine',
            loading: !this.state.groups,
            content: this.state.groups ? (
              <PerformanceMachineTab
                groups={this.state.groups}
                machine={this.props.machine}
              />
            ) : undefined,
          },
          {
            value: TabKey.Plotting,
            label: 'Plotting',
            content: <PlottingTab machine={this.props.machine} />,
          },
        ]}
      />
    );
  }

  render() {
    const mergeProps: IFullDialog = {
      identifier: COMPONENT_NAME,
      width: RADIX.DIALOG.WIDTH.XL,
      vFlexHeight: RADIX.DIALOG.HEIGHT.LG,
      title: `${this.props.machine.machineID} Performance`,
      content: this.renderContent(),
      onClose: () => this.props.onClose(),
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog {...mergeProps} />
      </ErrorBoundary>
    );
  }
}
