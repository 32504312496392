import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';

/** for components to interact with events */
export class WebSocketHelper {
  static on(type: WsMsgType, callback: (message: any) => void) {
    /** callback must be **exactly** what will be provided to removeEventListener */
    document.addEventListener(type, callback);
  }

  static dispatch(type: WsMsgType, data: any) {
    document.dispatchEvent(new CustomEvent(type, { detail: data }));
  }

  static remove(type: WsMsgType, callback: (message: any) => void) {
    /** callback must be **exactly** what was provided to addEventListener */
    document.removeEventListener(type, callback);
  }
}
