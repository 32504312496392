import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { IAnnouncement } from 'lib_ts/interfaces/common/i-announcement';
import { IServerResponse } from 'lib_ts/interfaces/common/i-server-response';
import { IUser } from 'lib_ts/interfaces/i-user';
import { BaseRESTService } from 'services/_base-rest.service';

export class AdminUsersService extends BaseRESTService {
  private static instance: AdminUsersService;
  static getInstance(): AdminUsersService {
    if (!AdminUsersService.instance) {
      AdminUsersService.instance = new AdminUsersService();
    }

    return AdminUsersService.instance;
  }

  private constructor() {
    super({
      controller: 'admin-users',
    });
  }

  /** get all users, limited to user's team if not a super admin */
  async getUsers(global: boolean): Promise<IUser[]> {
    return await this.get({
      uri: '',
      params: { global: global } as any,
    });
  }

  /** password (plaintext) must be provided */
  async postUser(data: Partial<IUser>): Promise<IServerResponse> {
    if (!data._parent_id || !data._parent_def || !data._parent_field) {
      throw new Error('Cannot post without parent (e.g. team) details');
    }

    if (!data.machineID) {
      throw new Error('Cannot post without machine details');
    }

    /** default value of domains to an empty array */
    if (!ArrayHelper.isArray(data.domains)) {
      data.domains = [];
    }

    return await this.post(
      {
        uri: '',
      },
      data
    );
  }

  /** if password (plaintext) is provided, server will generate a new password hash */
  async putUser(data: Partial<IUser>): Promise<IServerResponse> {
    if (!data._id) {
      throw new Error('Cannot put without id');
    }

    return await this.put(
      {
        uri: data._id,
      },
      data
    );
  }

  async deleteUsers(ids: string[]): Promise<IServerResponse> {
    return await this.post(
      {
        uri: 'delete',
      },
      ids
    );
  }

  async resetPasswordUrl(id: string): Promise<IServerResponse> {
    return await this.get({
      uri: `reset-password-url/${id}`,
    });
  }

  async postAnnouncement(data: IAnnouncement): Promise<IServerResponse> {
    return await this.post(
      {
        uri: 'announce',
      },
      data
    );
  }
}
