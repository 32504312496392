import { Box, Flex, Strong } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { SidebarHelper } from 'classes/helpers/sidebar.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonTextInput } from 'components/common/form/text';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { t } from 'i18next';
import { DEFAULT_ACCEPT_BTN } from 'interfaces/i-dialogs';
import { ISidebarFolder } from 'interfaces/i-sidebar';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

const COMPONENT_NAME = 'RenameFolderDialog';

interface IProps {
  folder: ISidebarFolder;
  onClose: () => void;
}

interface IState {
  folder: string;
}

export class RenameFolderDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = { folder: props.folder.pathDisplay };
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <PitchListsContext.Consumer>
          {(listsCx) => (
            <CommonDialog
              identifier={COMPONENT_NAME}
              width={RADIX.DIALOG.WIDTH.MD}
              title={t('common.rename-x', { x: t('common.folder') })}
              loading={listsCx.loading}
              content={
                <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
                  <Box>
                    <p>
                      <Strong>Warning:</Strong> This will affect{' '}
                      <Strong>all lists</Strong> in this folder.
                    </p>
                    <p>
                      Current Folder/Path:{' '}
                      <Strong>
                        {this.props.folder.pathDisplay || '(none)'}
                      </Strong>
                    </p>
                  </Box>

                  <CommonFormGrid columns={1}>
                    <Box>
                      <CommonTextInput
                        id="rename-folder-folder"
                        placeholder="Type in a new folder or path"
                        value={this.state.folder}
                        onChange={(v) => this.setState({ folder: v ?? '' })}
                      />
                    </Box>
                  </CommonFormGrid>
                </Flex>
              }
              buttons={[
                {
                  ...DEFAULT_ACCEPT_BTN,
                  onClick: () => {
                    const value = this.state.folder;

                    if (value.length === 0) {
                      NotifyHelper.error({
                        message_md:
                          'Please provide a non-empty name for your folder.',
                      });
                      return;
                    }

                    if (
                      ArrayHelper.equals(
                        SidebarHelper.parseFolder(value),
                        this.props.folder.pathComponents
                      )
                    ) {
                      NotifyHelper.info({
                        message_md: 'Folder name was not changed.',
                      });
                      this.props.onClose();
                      return;
                    }

                    /** change all folders for pitch lists within the same folder */
                    listsCx
                      .renameFolder({
                        _parent_id: this.props.folder._parent_id,
                        fromFolder: this.props.folder.pathDisplay,
                        toFolder: value,
                      })
                      .then((success) => {
                        if (success) {
                          this.props.onClose();
                        }
                      });
                  },
                },
              ]}
              onClose={this.props.onClose}
            />
          )}
        </PitchListsContext.Consumer>
      </ErrorBoundary>
    );
  }
}
