import { Box } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { StringHelper } from 'classes/helpers/string.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonDateInput } from 'components/common/form/date';
import { CommonFormGrid } from 'components/common/form/grid';
import { SessionEventsContext } from 'contexts/session-events.context';
import { addDays, startOfToday } from 'date-fns';
import { format } from 'date-fns-tz';
import { LOCAL_DATETIME_FORMAT_SHORT, LOCAL_TIMEZONE } from 'enums/env';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';
import { SessionEventsService } from 'services/session-events.service';
import slugify from 'slugify';

const COMPONENT_NAME = 'ExportSessionsDialog';

const MAX_SESSIONS_PER_REQUEST = 50;

interface IProps {
  machineIDs: string[];
  onClose: () => void;
}

interface IState {
  loading: boolean;
  start_date: Date;
  end_date: Date;
}

export class ExportSessionsDialog extends React.Component<IProps, IState> {
  state: IState = {
    loading: false,
    start_date: addDays(startOfToday(), -3),
    end_date: startOfToday(),
  };

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <SessionEventsContext.Consumer>
          {(sessionsCx) => (
            <CommonDialog
              identifier={COMPONENT_NAME}
              width={RADIX.DIALOG.WIDTH.MD}
              title="Export Machine Sessions"
              content={
                <CommonFormGrid columns={2}>
                  <Box gridColumn="span 2">
                    Export sessions for {this.props.machineIDs.join(', ')}.
                  </Box>

                  <CommonDateInput
                    id="export-sessions-start"
                    label="common.start-date"
                    disabled={this.state.loading}
                    defaultValue={this.state.start_date}
                    onChange={(date) => {
                      if (!date) {
                        NotifyHelper.warning({
                          message_md: t('common.check-inputs-msg'),
                        });
                        return;
                      }

                      this.setState({
                        start_date: date,
                      });
                    }}
                    showTime
                  />
                  <CommonDateInput
                    id="export-sessions-end"
                    label="common.end-date"
                    disabled={this.state.loading}
                    defaultValue={this.state.end_date}
                    onChange={(date) => {
                      if (!date) {
                        NotifyHelper.warning({
                          message_md: t('common.check-inputs-msg'),
                        });
                        return;
                      }

                      this.setState({
                        end_date: date,
                      });
                    }}
                    showTime
                  />
                </CommonFormGrid>
              }
              loading={this.state.loading}
              buttons={[
                {
                  label: 'common.export',
                  color: RADIX.COLOR.INFO,
                  onClick: async () => {
                    if (this.state.loading) {
                      NotifyHelper.success({
                        message_md: `Please wait while your request is processed...`,
                      });
                      return;
                    }

                    this.setState({ loading: true });

                    NotifyHelper.success({
                      message_md: `Please wait while your request is processed...`,
                    });

                    // get all sessions satisfying the filter
                    const sessions =
                      await SessionEventsService.getMachineSessions(
                        this.props.machineIDs,
                        this.state.start_date,
                        this.state.end_date,
                        MAX_SESSIONS_PER_REQUEST
                      );

                    const sessionsByMachine = ArrayHelper.groupBy(
                      sessions,
                      'machineID'
                    );

                    // loop over sessions per machine
                    for (const mSessions of Object.values(sessionsByMachine)) {
                      const machineID =
                        mSessions[0]?.machineID ?? 'Unknown Machine';

                      const nickname = mSessions[0]?.machine_nickname;

                      const withFires = mSessions.filter((s) => s.fires > 0);

                      if (withFires.length === 0) {
                        NotifyHelper.warning({
                          message_md: `Unable to find any sessions with at least one fire for \`${machineID}\`.`,
                        });
                        continue;
                      }

                      const allRows = (
                        await Promise.all(
                          withFires.map((m) =>
                            sessionsCx.getShotsData(m.session, true)
                          )
                        )
                      ).flatMap((m) => m);

                      if (allRows.length === 0) {
                        NotifyHelper.warning({
                          message_md: `No data to export for \`${machineID}\`.`,
                        });
                        continue;
                      }

                      await StringHelper.saveCsv(allRows, {
                        prefix: slugify(
                          [
                            machineID,
                            nickname,
                            format(
                              this.state.start_date,
                              LOCAL_DATETIME_FORMAT_SHORT,
                              { timeZone: LOCAL_TIMEZONE }
                            ),
                            format(
                              this.state.end_date,
                              LOCAL_DATETIME_FORMAT_SHORT,
                              { timeZone: LOCAL_TIMEZONE }
                            ),
                          ]
                            .map((m) => m?.trim())
                            .filter((m) => m)
                            .join('-'),
                          { strict: true }
                        ),
                      });
                    }

                    this.setState({ loading: false });
                    this.props.onClose();
                  },
                },
              ]}
              onClose={() => this.props.onClose()}
            />
          )}
        </SessionEventsContext.Consumer>
      </ErrorBoundary>
    );
  }
}
