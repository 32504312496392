import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { CommonCallout } from 'components/common/callouts';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { PitchListContext } from 'contexts/pitch-lists/list.context';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitch } from 'lib_ts/interfaces/pitches';
import { useContext } from 'react';
import ReactMarkdown from 'react-markdown';

const COMPONENT_NAME = 'DeletePitchesDialog';

interface IProps {
  pitches: IPitch[];
  onDelete: () => void;
}

export const DeletePitchesDialog = (props: IProps) => {
  const { deletePitches } = useContext(PitchListContext);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonConfirmationDialog
        identifier={COMPONENT_NAME}
        title="common.delete"
        content={
          <>
            <ReactMarkdown
              children={[
                `Are you sure you want to remove the following pitch(es) from this list?`,
                PitchListHelper.enumeratePitches(props.pitches, 5),
              ].join('\n\n')}
            />
            <CommonCallout text_md="This cannot be undone." />
          </>
        }
        action={{
          label: 'common.delete',
          color: RADIX.COLOR.DANGER,
          onClick: () => {
            deletePitches(props.pitches.map((p) => p._id)).then((success) => {
              if (success) {
                props.onDelete();
              }
            });
          },
        }}
      />
    </ErrorBoundary>
  );
};
