import { TrashIcon } from '@radix-ui/react-icons';
import { IconButton } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTableHoC } from 'components/common/table';
import { ICookiesContext } from 'contexts/cookies.context';
import { lightFormat, parseISO } from 'date-fns';
import { ACTIONS_KEY, TABLES } from 'enums/tables';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSortable } from 'interfaces/tables/sorting';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMongoBase, IMongoChild } from 'lib_ts/interfaces/mongo/_base';
import { IPitchListLink } from 'lib_ts/interfaces/pitches/i-pitch-list-link';
import React from 'react';
import { AdminPitchListsService } from 'services/admin/pitch-lists.service';

const IDENTIFIER = TableIdentifier.ManageCardAccess;

interface IProps {
  list_id: string;

  lastFetched: Date;

  cookiesCx: ICookiesContext;
}

interface IState {
  loading: boolean;

  links: IPitchListLink[];
}

const DEFAULT_STATE: IState = {
  loading: false,

  links: [],
};

const PAGE_SIZES = TABLES.PAGE_SIZES.MD;

export class ManageAccessTable extends React.Component<IProps, IState> {
  private readonly BASE_COLUMNS: ITableColumn[] = [
    {
      label: 'common.created',
      key: '_created',
      formatFn: (m: IMongoBase) =>
        m._created ? lightFormat(parseISO(m._created), 'yyyy-MM-dd') : '',
    },
    {
      label: 'common.type',
      key: '_parent_def',
      formatFn: (m: IMongoChild) => {
        switch (m._parent_def) {
          case 'teams': {
            return 'Team';
          }

          case 'team-machines': {
            return 'Machine';
          }

          case 'team-users': {
            return 'User';
          }

          default: {
            return m._parent_def;
          }
        }
      },
    },
    {
      label: 'common.name',
      key: '_parent_name',
    },
    {
      label: 'common.actions',
      key: ACTIONS_KEY,
      formatFn: (m: IPitchListLink) => {
        return (
          <IconButton
            variant="soft"
            color={RADIX.COLOR.DANGER}
            onClick={() => this.handleDelete([m._id])}
          >
            <TrashIcon />
          </IconButton>
        );
      },
    },
  ];

  constructor(props: IProps) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.state = DEFAULT_STATE;
  }

  componentDidMount(): void {
    this.fetchData();
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (prevProps.lastFetched !== this.props.lastFetched) {
      this.fetchData();
    }
  }

  private fetchData() {
    AdminPitchListsService.getInstance()
      .getLinksForLists([this.props.list_id])
      .then((results) => this.setState({ links: results }));
  }

  /** uses the current pitch from props */
  private handleDelete(link_ids: string[]) {
    this.setState({ loading: true });
    AdminPitchListsService.getInstance()
      .deleteLinks(link_ids)
      .then((success) => {
        if (success) {
          this.setState({
            links: this.state.links.filter(
              (link) => !link_ids.includes(link._id)
            ),
          });
        }
      })
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const pagination: ITablePageable = {
      identifier: IDENTIFIER,
      total: this.state.links.length,
      enablePagination: true,
      pageSizes: PAGE_SIZES,
    };

    const sort: ITableSortable = {
      enableSort: true,
      defaultSort: {
        key: '_created',
        dir: 1,
      },
    };

    return (
      <ErrorBoundary componentName="ManageAccessTable">
        <CommonTableHoC
          id="ManageCardAccess"
          displayColumns={this.BASE_COLUMNS}
          displayData={this.state.links}
          {...pagination}
          {...sort}
        />
      </ErrorBoundary>
    );
  }
}
