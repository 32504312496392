import { Skeleton } from '@radix-ui/themes';
import { PitchDesignHoC } from 'components/sections/pitch-design';
import { PitchDesignContext } from 'contexts/pitch-lists/pitch-design.context';
import { useContext } from 'react';

export const Design = () => {
  const { loading, reference, ball } = useContext(PitchDesignContext);

  // wait for design context befor rendering anything
  if (loading || !reference || !ball) {
    return <Skeleton />;
  }

  return <PitchDesignHoC reference={reference} />;
};
