import { Box, Flex, Heading, TabNav } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { SectionsContext } from 'contexts/sections.context';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext } from 'react';

export const AdminTabNav = (props: { active: SubSectionName }) => {
  const { adminSections, tryChangeSection } = useContext(SectionsContext);

  return (
    <ErrorBoundary componentName="AdminTabNav">
      <Heading mt="1" mb="0">
        {t('main.admin-portal')}
      </Heading>

      <TabNav.Root>
        {adminSections
          ?.find((s) => s.value === SectionName.AdminPortal)
          ?.subsections?.filter((m) => !m.invisible)
          .map((m, i) => (
            <TabNav.Link
              key={i}
              active={props.active === m.value}
              onClick={() =>
                tryChangeSection({
                  section: SectionName.AdminPortal,
                  subsection: m.value,
                  trigger: 'AdminTabNav',
                })
              }
            >
              <Flex gap={RADIX.FLEX.GAP.XS} align="center">
                <Box>{t(m.label)}</Box>
                {m.icon && (
                  <Box mt="1">
                    <m.icon />
                  </Box>
                )}
              </Flex>
            </TabNav.Link>
          ))}
      </TabNav.Root>
    </ErrorBoundary>
  );
};
