import { Flex } from '@radix-ui/themes';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonToast } from 'components/common/toast';
import { INotification } from 'interfaces/i-notification';
import { ErrorLevel } from 'lib_ts/enums/errors.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { createContext, FC, ReactNode, useEffect, useState } from 'react';

const AUTO_CLOSE_DELAY_MS = 3_000;

interface IProps {
  children: ReactNode;
}

export interface IInboxContext {
  inbox: INotification[];

  readonly showDialog: () => void;
  readonly add: (value: INotification) => void;
  readonly remove: (id?: string) => void;
  readonly clear: () => void;
}

const DEFAULT: IInboxContext = {
  inbox: [],
  showDialog: () => console.debug('not init'),
  add: () => console.debug('not init'),
  remove: () => console.debug('not init'),
  clear: () => console.debug('not init'),
};

export const InboxContext = createContext(DEFAULT);

export const InboxProvider: FC<IProps> = (props) => {
  const [_inbox, _setInbox] = useState(DEFAULT.inbox);
  const [_dialog, _setDialog] = useState(false);

  const state: IInboxContext = {
    inbox: _inbox,
    showDialog: () => _setDialog(true),
    add: (value) =>
      _setInbox([..._inbox.filter((n) => n.id !== value.id), value]),
    remove: (id) => _setInbox(_inbox.filter((n) => n.id !== id)),
    clear: () => _setInbox([]),
  };

  useEffect(() => {
    if (_inbox.length === 0 && _dialog) {
      setTimeout(() => {
        _setDialog(false);
      }, AUTO_CLOSE_DELAY_MS);
    }
  }, [_inbox.length, _dialog]);

  return (
    <ErrorBoundary componentName="InboxContext">
      <InboxContext.Provider value={state}>
        {props.children}

        {_dialog && (
          <CommonDialog
            identifier="InboxDialog"
            width={RADIX.DIALOG.WIDTH.LG}
            title="Inbox"
            content={
              <Flex direction="column" gap={RADIX.FLEX.GAP.LG}>
                {_inbox.length === 0 && (
                  <CommonToast
                    config={{
                      level: ErrorLevel.success,
                      color: RADIX.COLOR.SUCCESS,
                      message_md:
                        'Congratulations, your inbox is empty! This window will close itself shortly...',
                    }}
                    withoutToast
                    onDismiss={() => _setDialog(false)}
                  />
                )}

                {_inbox.map((n, i) => (
                  <CommonToast
                    key={`inbox-msg-${i}`}
                    config={n}
                    withoutToast
                    onDismiss={() => state.remove(n.id)}
                  />
                ))}
              </Flex>
            }
            buttons={[
              {
                invisible: _inbox.length === 0,
                label: 'Clear All',
                color: RADIX.COLOR.DANGER,
                onClick: () => state.clear(),
              },
            ]}
            onClose={() => _setDialog(false)}
          />
        )}
      </InboxContext.Provider>
    </ErrorBoundary>
  );
};
