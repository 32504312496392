import { Flex } from '@radix-ui/themes';
import { CommonCallout } from 'components/common/callouts';
import { ErrorBoundary } from 'components/common/error-boundary';
import { DataControls } from 'components/sections/admin-portal/server-controls/data-controls';
import { GameStatusControls } from 'components/sections/admin-portal/server-controls/game-status-controls';
import { GameStatusSummary } from 'components/sections/admin-portal/server-controls/game-status-summary';
import { AdminTabNav } from 'components/sections/admin-portal/tab-nav';
import { AuthContext } from 'contexts/auth.context';
import { SubSectionName } from 'enums/route.enums';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext } from 'react';

export const ServerControlsTab = () => {
  const { current } = useContext(AuthContext);

  if (current.role !== UserRole.admin) {
    return (
      <CommonCallout
        color={RADIX.COLOR.SUPER_ADMIN}
        text="You do not have access to this feature."
      />
    );
  }

  return (
    <ErrorBoundary componentName="GameStatusTab">
      <Flex direction="column" gap={RADIX.FLEX.GAP.SECTION}>
        <AdminTabNav active={SubSectionName.ServerControls} />
        <DataControls />
        <GameStatusControls />
        <GameStatusSummary />
      </Flex>
    </ErrorBoundary>
  );
};
