import { Button } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { MachineContext, MachineDialogMode } from 'contexts/machine.context';
import { t } from 'i18next';
import { IButton } from 'interfaces/i-buttons';
import React from 'react';

const COMPONENT_NAME = 'MachineInspectButton';

interface IProps extends IButton {}

interface IState {}

/** sends a ball-status event to the app itself to trigger the inspector */
export class MachineInspectButton extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <MachineContext.Consumer>
          {(machineCx) => (
            <Button
              title={this.props.tooltip}
              color={this.props.color}
              variant={this.props.variant}
              className={this.props.className}
              disabled={this.props.disabled || !machineCx.checkActive(true)}
              onClick={() => machineCx.setDialog(MachineDialogMode.Inspect)}
            >
              {t('main.inspect')}
            </Button>
          )}
        </MachineContext.Consumer>
      </ErrorBoundary>
    );
  }
}
