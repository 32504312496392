import * as Accordion from '@radix-ui/react-accordion';
import { Flex } from '@radix-ui/themes';
import {
  CommonAccordionItem,
  IAccordionItem,
} from 'components/common/accordion/item';
import { RADIX } from 'lib_ts/enums/radix-ui';

interface IProps extends Accordion.AccordionSingleProps {
  items: IAccordionItem[];
}

export const CommonSingleAccordion = (props: IProps) => (
  <Accordion.Root className="AccordionRoot" {...props}>
    <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
      {props.items.map((item, i) => (
        <CommonAccordionItem key={i} {...item} />
      ))}
    </Flex>
  </Accordion.Root>
);
