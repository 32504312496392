import { Box, Flex, Heading, Text } from '@radix-ui/themes';
import { CommonCallout } from 'components/common/callouts';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

interface IProps {
  errors: string[];
}

interface IState {}

export class ErrorsTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.MD} width="50%">
        <Heading size={RADIX.HEADING.SIZE.SM}>
          {this.props.errors.length === 0 ? 'No Errors' : 'Errors'}
        </Heading>

        {this.props.errors.length === 0 && (
          <Box>
            <Text>Your video is ready to be used!</Text>
          </Box>
        )}

        {this.props.errors.length > 0 &&
          this.props.errors.map((e, i) => (
            <Box key={`error-${i}`}>
              <CommonCallout text_md={e} />
            </Box>
          ))}
      </Flex>
    );
  }
}
