import { Box, Grid, Heading, Text } from '@radix-ui/themes';
import { AnnouncementDialog } from 'components/common/dialogs/announcement';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTooltip } from 'components/common/tooltip';
import { renderMachineUser } from 'components/sections/admin-portal/machines/table';
import { MachinesContext } from 'contexts/admin/machines.context';
import { TeamsContext } from 'contexts/admin/teams.context';
import { UsersContext } from 'contexts/admin/users.context';
import { AuthContext } from 'contexts/auth.context';
import format from 'date-fns-tz/format';
import parseISO from 'date-fns/parseISO';
import { LOCAL_TIMEZONE } from 'enums/env';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IAnnouncement } from 'lib_ts/interfaces/common/i-announcement';
import { IMachineDetails } from 'lib_ts/interfaces/i-machine-details';
import React from 'react';

interface IProps {
  details: IMachineDetails;
}

interface IState {
  dialogAnnouncement?: number;
  announcementModel?: Partial<IAnnouncement>;
}

export class SummaryTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.renderAnnouncementModal = this.renderAnnouncementModal.bind(this);
  }

  private renderAnnouncementModal() {
    if (this.state.dialogAnnouncement && this.state.announcementModel) {
      return (
        <AuthContext.Consumer>
          {(authCx) => (
            <TeamsContext.Consumer>
              {(teamsCx) => (
                <MachinesContext.Consumer>
                  {(machinesCx) => (
                    <UsersContext.Consumer>
                      {(usersCx) => (
                        <AnnouncementDialog
                          key={this.state.dialogAnnouncement}
                          identifier="MachineSummaryAnnouncementModal"
                          authCx={authCx}
                          teamsCx={teamsCx}
                          machinesCx={machinesCx}
                          usersCx={usersCx}
                          defaultModel={this.state.announcementModel}
                          onClose={() =>
                            this.setState({
                              dialogAnnouncement: undefined,
                              announcementModel: undefined,
                            })
                          }
                        />
                      )}
                    </UsersContext.Consumer>
                  )}
                </MachinesContext.Consumer>
              )}
            </TeamsContext.Consumer>
          )}
        </AuthContext.Consumer>
      );
    }
  }

  render() {
    const details = this.props.details;

    const localLastFiredDate = details.lastFire
      ? format(parseISO(details.lastFire._created), 'yyyy-MM-dd @ HH:mm z', {
          timeZone: LOCAL_TIMEZONE,
        })
      : undefined;

    const localConnectDate = details.connect_date
      ? format(parseISO(details.connect_date), 'yyyy-MM-dd @ HH:mm z', {
          timeZone: LOCAL_TIMEZONE,
        })
      : undefined;

    const localDisconnectDate = details.disconnect_date
      ? format(parseISO(details.disconnect_date), 'yyyy-MM-dd @ HH:mm z', {
          timeZone: LOCAL_TIMEZONE,
        })
      : undefined;

    return (
      <ErrorBoundary componentName="MachineDetailsSummaryTab">
        <Grid columns="2" gap={RADIX.FLEX.GAP.MD}>
          <Box>
            <Text size={RADIX.TEXT.SIZE.SM}>
              {details.connected &&
                localConnectDate &&
                `As of ${localConnectDate}`}

              {!details.connected &&
                localDisconnectDate &&
                `As of ${localDisconnectDate}`}
            </Text>

            <Heading size={RADIX.HEADING.SIZE.MD}>
              {details.connected ? 'Connected' : 'Disconnected'}
            </Heading>
          </Box>

          {details.connected && (
            <Box>
              <Text size={RADIX.TEXT.SIZE.SM}>Status:</Text>

              <Heading size={RADIX.HEADING.SIZE.MD}>
                {details.calibrated ? 'Calibrated' : 'Not Calibrated'}
              </Heading>
            </Box>
          )}

          {details.connected && (
            <>
              <Box>
                <Text size={RADIX.TEXT.SIZE.SM}>Active:</Text>
                <Heading size={RADIX.HEADING.SIZE.MD}>
                  {details.active
                    ? renderMachineUser(details.active, (u) => {
                        this.setState({
                          dialogAnnouncement: Date.now(),
                          announcementModel: {
                            filter: { email: u.email },
                            message: `RE: ${details.machineID}`,
                          },
                        });
                      })
                    : 'None'}
                </Heading>
              </Box>
              <Box>
                <Text size={RADIX.TEXT.SIZE.SM}>Waiting:</Text>
                <Heading size={RADIX.HEADING.SIZE.MD}>
                  {details.waiting.length === 0 ? (
                    'None'
                  ) : (
                    <CommonTooltip
                      trigger={
                        <ul className="no-style">
                          {details.waiting.map((m) => (
                            <li key={m.session}>
                              {renderMachineUser(m, (u) => {
                                this.setState({
                                  dialogAnnouncement: Date.now(),
                                  announcementModel: {
                                    filter: { email: u.email },
                                    message: `RE: ${details.machineID}`,
                                  },
                                });
                              })}
                            </li>
                          ))}
                        </ul>
                      }
                      text_md={details.waiting.join(', ')}
                    />
                  )}
                </Heading>
              </Box>
            </>
          )}

          <Box>
            <Text size={RADIX.TEXT.SIZE.SM}>Last Fire Date:</Text>
            <Heading size={RADIX.HEADING.SIZE.MD}>
              {localLastFiredDate ?? '???'}
            </Heading>
          </Box>
        </Grid>

        {this.renderAnnouncementModal()}
      </ErrorBoundary>
    );
  }
}
