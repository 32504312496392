import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { Box, Flex, Grid, Text } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonNavMenu } from 'components/common/layout/nav-menu';
import { InterfaceSection } from 'components/common/settings-dialog/main-sections/interface.section';
import {
  MachineSection,
  MachineSettingsTabKey,
} from 'components/common/settings-dialog/main-sections/machine.section';
import { RemoteControlSection } from 'components/common/settings-dialog/main-sections/remote-control.section';
import {
  UserSection,
  UserSettingsTabKey,
} from 'components/common/settings-dialog/main-sections/user.section';
import { AuthContext, IAuthContext } from 'contexts/auth.context';
import { CookiesContext, ICookiesContext } from 'contexts/cookies.context';
import { IMachineContext, MachineContext } from 'contexts/machine.context';
import {
  IPitchListsContext,
  PitchListsContext,
} from 'contexts/pitch-lists/lists.context';
import { ISectionsContext, SectionsContext } from 'contexts/sections.context';
import { t } from 'i18next';
import { IBaseDialog, IFullDialog } from 'interfaces/i-dialogs';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React, { useContext } from 'react';

const COMPONENT_NAME = 'SettingsDialog';

export interface ISettingsDialog {
  initialSection: SettingsSection;
  /** if provided, only tabs listed here will be rendered, length should be at least 1 if provided */
  sections?: SettingsSection[];

  /** if provided, the view should start on this tab */
  initialTab?: string;
}

interface ISection {
  label: string;
  value: SettingsSection;
}

interface IBaseProps extends IBaseDialog, ISettingsDialog {}

interface IProps extends IBaseProps {
  authCx: IAuthContext;
  cookiesCx: ICookiesContext;
  machineCx: IMachineContext;
  listsCx: IPitchListsContext;
  sectionsCx: ISectionsContext;
}

interface IState {
  active: SettingsSection;
  sections: ISection[];
}

export enum SettingsSection {
  Machine = 'Machine',
  Interface = 'Interface',
  User = 'User',
  Accessories = 'Accessories',
}

const ALL_SECTIONS: ISection[] = [
  {
    label: 'common.machine',
    value: SettingsSection.Machine,
  },
  {
    label: 'settings.interface',
    value: SettingsSection.Interface,
  },
  {
    label: 'settings.user',
    value: SettingsSection.User,
  },
  {
    label: 'settings.accessories',
    value: SettingsSection.Accessories,
  },
];

// todo: deprecate this once settings is functional
export const SettingsDialogHoC = (props: IBaseProps) => {
  const authCx = useContext(AuthContext);
  const cookiesCx = useContext(CookiesContext);
  const machineCx = useContext(MachineContext);
  const listsCx = useContext(PitchListsContext);
  const sectionsCx = useContext(SectionsContext);

  return (
    <SettingsDialog
      authCx={authCx}
      cookiesCx={cookiesCx}
      machineCx={machineCx}
      listsCx={listsCx}
      sectionsCx={sectionsCx}
      {...props}
    />
  );
};

class SettingsDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      active: props.initialSection,
      sections: ALL_SECTIONS.filter(
        (s) => !this.props.sections || this.props.sections.includes(s.value)
      ),
    };

    this.renderSection = this.renderSection.bind(this);
    this.renderSidebar = this.renderSidebar.bind(this);
  }

  private renderSection() {
    switch (this.state.active) {
      case SettingsSection.Machine: {
        return (
          <MachineSection
            cookiesCx={this.props.cookiesCx}
            authCx={this.props.authCx}
            sectionsCx={this.props.sectionsCx}
            machineCx={this.props.machineCx}
            initialTab={this.props.initialTab as MachineSettingsTabKey}
            onClose={this.props.onClose}
          />
        );
      }

      case SettingsSection.Interface: {
        return (
          <InterfaceSection
            cookiesCx={this.props.cookiesCx}
            authCx={this.props.authCx}
            machineCx={this.props.machineCx}
          />
        );
      }

      case SettingsSection.User: {
        return (
          <UserSection
            authCx={this.props.authCx}
            listsCx={this.props.listsCx}
            onClose={this.props.onClose}
            initialTab={this.props.initialTab as UserSettingsTabKey}
          />
        );
      }

      case SettingsSection.Accessories: {
        return (
          <RemoteControlSection
            cookiesCx={this.props.cookiesCx}
            machineCx={this.props.machineCx}
          />
        );
      }

      default: {
        return undefined;
      }
    }
  }

  render() {
    const loading = this.props.machineCx.loading || this.props.authCx.loading;

    const DEFAULT_PROPS: IFullDialog = {
      identifier: this.props.identifier,
      title: 'common.settings',
      width: RADIX.DIALOG.WIDTH.XL,
      vFlexHeight: RADIX.DIALOG.HEIGHT.LG,
      loading: loading,
      onClose: this.props.onClose,
      content: (
        <Grid
          columns="4"
          className={RADIX.VFLEX.WRAPPER}
          gap={RADIX.FLEX.GAP.MD}
        >
          <Box>{this.renderSidebar()}</Box>
          <Flex
            gridColumn="span 3"
            overflowX="hidden"
            overflowY="auto"
            className={RADIX.VFLEX.COLUMN}
            gap={RADIX.FLEX.GAP.MD}
            pr="2"
          >
            <Box className={RADIX.VFLEX.GROW}>{this.renderSection()}</Box>
          </Flex>
        </Grid>
      ),
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog {...DEFAULT_PROPS} />
      </ErrorBoundary>
    );
  }

  private renderSidebar() {
    return (
      <CommonNavMenu orientation="vertical">
        {this.state.sections.map((s, i) => (
          <NavigationMenu.Item key={i}>
            <NavigationMenu.Trigger
              className={StringHelper.classNames([
                'NavigationMenuTrigger',
                this.state.active === s.value ? 'active' : 'inactive',
              ])}
              onClick={() => this.setState({ active: s.value })}
            >
              <Box pt="2" pb="2" pl="3" pr="3">
                <Text
                  size="2"
                  color={
                    this.state.active === s.value
                      ? RADIX.COLOR.ACCENT
                      : undefined
                  }
                >
                  {t(s.label)}
                </Text>
              </Box>
            </NavigationMenu.Trigger>
          </NavigationMenu.Item>
        ))}
      </CommonNavMenu>
    );
  }
}
