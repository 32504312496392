import { PitchUploader } from 'components/sections/pitch-uploader';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { MachineContext } from 'contexts/machine.context';
import { SectionsContext } from 'contexts/sections.context';
import { VideosContext } from 'contexts/videos/videos.context';
import { useContext } from 'react';

export const Uploader = () => {
  // todo: once uploader is a functional component, we can get rid of this stuff
  const authCx = useContext(AuthContext);
  const cookiesCx = useContext(CookiesContext);
  const machineCx = useContext(MachineContext);
  const videosCx = useContext(VideosContext);
  const sectionsCx = useContext(SectionsContext);

  return (
    <PitchUploader
      cookiesCx={cookiesCx}
      authCx={authCx}
      machineCx={machineCx}
      sectionsCx={sectionsCx}
      videosCx={videosCx}
    />
  );
};
