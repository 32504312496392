import {
  EnvelopeClosedIcon,
  EnvelopeOpenIcon,
  ExclamationTriangleIcon,
  ExitIcon,
  MixerHorizontalIcon,
} from '@radix-ui/react-icons';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonNavMenu } from 'components/common/layout/nav-menu';
import {
  SettingsDialogHoC,
  SettingsSection,
} from 'components/common/settings-dialog';
import { SidebarConnectionTrigger } from 'components/main/sidebar/footer/connection-trigger';
import { SupportMenu } from 'components/main/sidebar/footer/support-menu';
import { SidebarNavigationTrigger } from 'components/main/sidebar/nav-trigger';
import { AuthContext } from 'contexts/auth.context';
import { InboxContext } from 'contexts/inbox';
import { MachineContext, MachineDialogMode } from 'contexts/machine.context';
import { SessionEventsContext } from 'contexts/session-events.context';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { SpecialMsPosition } from 'lib_ts/interfaces/machine-msg/i-special-mstarget';
import { useContext } from 'react';

const COMPONENT_NAME = 'SidebarFooter';

const Logout = () => {
  const { logout } = useContext(AuthContext);
  const { specialMstarget } = useContext(MachineContext);

  return (
    <SidebarNavigationTrigger
      icon={<ExitIcon />}
      label="main.logout"
      onClick={() => {
        specialMstarget(SpecialMsPosition.home);
        logout();
      }}
    />
  );
};

const SidebarInbox = () => {
  const { inbox, showDialog } = useContext(InboxContext);

  if (inbox.length === 0) {
    return <></>;
  }

  return (
    <SidebarNavigationTrigger
      icon={inbox.length === 0 ? <EnvelopeClosedIcon /> : <EnvelopeOpenIcon />}
      label={t('main.inbox-x', { x: inbox.length }).toString()}
      onClick={showDialog}
    />
  );
};

const LiveView = () => {
  const { lastBallCount, setDialog } = useContext(MachineContext);

  if (lastBallCount === undefined) {
    return <></>;
  }

  if (lastBallCount === 1) {
    return <></>;
  }

  return (
    <SidebarNavigationTrigger
      icon={<ExclamationTriangleIcon />}
      color={RADIX.COLOR.WARNING}
      label="main.inspect"
      onClick={() => setDialog(MachineDialogMode.Inspect)}
    />
  );
};

const Settings = () => {
  const { machine } = useContext(MachineContext);

  if (!machine._id) {
    return <></>;
  }

  const { setSettingsDialog } = useContext(SessionEventsContext);

  return (
    <SidebarNavigationTrigger
      data-testid="SettingsButton"
      icon={<MixerHorizontalIcon />}
      label="main.settings"
      onClick={() =>
        setSettingsDialog({
          initialSection: SettingsSection.Machine,
        })
      }
    />
  );
};

export const SidebarFooter = () => {
  const { settingsDialog, setSettingsDialog } =
    useContext(SessionEventsContext);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonNavMenu orientation="vertical">
        <SidebarInbox />

        <Settings />

        <LiveView />

        <SidebarConnectionTrigger />

        <SupportMenu />

        <Logout />
      </CommonNavMenu>

      {settingsDialog && (
        <SettingsDialogHoC
          {...settingsDialog}
          identifier="SidebarFooterSettingsDialog"
          onClose={() => setSettingsDialog()}
        />
      )}
    </ErrorBoundary>
  );
};
