import { BaseRESTService } from 'services/_base-rest.service';

export class TermsService extends BaseRESTService {
  private static instance: TermsService;
  static getInstance(): TermsService {
    if (!TermsService.instance) {
      TermsService.instance = new TermsService();
    }

    return TermsService.instance;
  }

  private constructor() {
    super({
      controller: 'terms',
    });
  }

  /** gets the current user's termsDate (if any) from the database */
  async check(): Promise<string | undefined> {
    return await this.get({ uri: 'check' })
      .then((result) => result)
      .catch((reason) => {
        console.error(reason);
        return undefined;
      });
  }

  /** updates the current user's termsDate in the database */
  async accept(): Promise<string | undefined> {
    return await this.get({ uri: 'accept' });
  }
}
