import { Link } from '@radix-ui/themes';
import React, { ReactNode } from 'react';

export interface ICommonLinkProps {
  url: string;

  /** defaults to '_blank' */
  target?: string;

  /** defaults to 'noreferrer' */
  rel?: string;

  children?: ReactNode;
}

/** children will be used as the <a> HTML content, defaults to 'Click Here' */
export class CommonLink extends React.Component<ICommonLinkProps> {
  render() {
    return (
      <Link
        href={this.props.url}
        target={this.props.target ?? '_blank'}
        rel={this.props.rel ?? 'noreferrer'}
      >
        {this.props.children ?? 'Click Here'}
      </Link>
    );
  }
}
