import { NotifyHelper } from 'classes/helpers/notify.helper';
import { t } from 'i18next';
import { IServerResponse } from 'lib_ts/interfaces/common/i-server-response';
import { IPitch } from 'lib_ts/interfaces/pitches/i-pitch';
import { ISearchPitches } from 'lib_ts/interfaces/pitches/i-search-pitches';
import { BaseRESTService } from 'services/_base-rest.service';

const cleanupBeforePut = (p: Partial<IPitch>) => {
  delete p._msDictLoaded;
  delete p._msRotated;
};

export class PitchesService extends BaseRESTService {
  private static instance: PitchesService;
  static getInstance(): PitchesService {
    if (!PitchesService.instance) {
      PitchesService.instance = new PitchesService();
    }

    return PitchesService.instance;
  }

  private constructor() {
    super({
      controller: 'pitches',
    });
  }

  async getOne(id: string): Promise<IPitch | undefined> {
    return await this.get({
      uri: '',
      params: {
        pitch_id: id,
      } as any,
    });
  }

  async getListPitches(
    listID: string,
    search?: ISearchPitches
  ): Promise<IPitch[]> {
    return await this.post(
      {
        uri: 'list',
        params: {
          id: listID,
          traj: true,
          ms: true,
        } as any,
      },
      search
    );
  }

  async searchPitches(data: ISearchPitches, limit: number): Promise<IPitch[]> {
    return await this.post(
      {
        uri: 'search',
        params: {
          limit: limit,
        } as any,
      },
      data
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? t('common.request-failed-msg'),
          });
          return [];
        }

        return result.data as IPitch[];
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });
        return [];
      });
  }

  async create(data: Partial<IPitch>): Promise<IPitch | undefined> {
    return await this.post(
      {
        uri: 'one',
      },
      data
    ).then((result: IServerResponse) => {
      if (!result.success) {
        return undefined;
      }

      return result.data as IPitch;
    });
  }

  async putPitches(data: Partial<IPitch>[]): Promise<IServerResponse> {
    if (data.findIndex((p) => !p._id) !== -1) {
      throw new Error('Cannot put without id');
    }

    const payloads: Partial<IPitch>[] = data.map((p) => ({ ...p }));
    payloads.forEach((p) => cleanupBeforePut(p));

    return await this.put(
      {
        uri: 'many',
      },
      payloads
    );
  }

  /** used for bulk updating msDict of pitches (i.e. only _id and msDict attributes are necessary)
   * e.g. after refreshing ms values (i.e. needs to be allowed even in offline mode in case a pitch is missing a MS) */
  async updateMSDict(data: Partial<IPitch>[]): Promise<IPitch> {
    if (data.findIndex((p) => !p._id) !== -1) {
      throw new Error('Cannot put without id');
    }

    /** never save temp attributes */
    const payloads: Partial<IPitch>[] = data.map((d) => ({ ...d }));
    payloads.forEach((p) => cleanupBeforePut(p));

    return await this.put(
      {
        uri: 'msDict',
      },
      payloads
    );
  }

  async deletePitches(ids: string[]): Promise<IServerResponse> {
    return await this.post(
      {
        uri: 'delete',
      },
      { ids }
    );
  }
}
