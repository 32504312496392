import { ENV_PROD } from 'environments/i-prod';
import { IEnvConfig } from 'interfaces/i-env-config';
import { LanguageCode } from 'lib_ts/enums/translation';

export const ENV_PROD_KR: IEnvConfig = {
  ...ENV_PROD,
  languages: [LanguageCode.English, LanguageCode.Korean],

  enable: {
    ...ENV_PROD.enable,
    change_language: true,
  },
  region_redirect: false,
  self_url: 'https://kr.trajektsports.app',
  server_url: 'https://kr.trajektsports.app/node',
  ws_url: 'wss://kr.trajektsports.app/wss',
};
