export enum DragItem {
  AcceptNothing = 'AcceptNothing',
  CardList = 'CardList',
  DragNothing = 'DragNothing',
  MachineList = 'MachineList',
  PersonalList = 'PersonalList',
  Pitch = 'Pitch',
  Reorder = 'Reorder',
  ReferenceList = 'ReferenceList',
  SampleList = 'SampleList',
  TeamList = 'TeamList',
}

export enum DropContainer {
  CardFolder = 'CardFolder',
  MachineFolder = 'MachineFolder',
  PersonalFolder = 'PersonalFolder',
  PitchList = 'PitchList',
  ReferenceFolder = 'ReferenceFolder',
  Reorder = 'Reorder',
  SampleFolder = 'SampleFolder',
  TeamFolder = 'TeamFolder',
}
