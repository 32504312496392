import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import env from 'config';

const httpClient = axios.create({
  baseURL: env.server_url,
  withCredentials: true,
});

const DEFAULT_HEADERS: AxiosRequestHeaders = {
  'Content-Type': 'application/json',
  env: env.identifier,
};

export interface IServiceConfig {
  controller: string;
  headers?: HeadersInit;
}

interface IAxiosRequest {
  uri?: string;
  params?: AxiosRequestConfig;
  headers?: AxiosRequestHeaders;
}

export interface IGenericResponse {
  message: string;
  error?: any;
}

export class BaseRESTService {
  constructor(private config: IServiceConfig) {}

  async get(request: IAxiosRequest = {}): Promise<any> {
    try {
      const url = `${this.config.controller}/${request.uri ?? ''}`;

      const config = {
        params: request.params,
        headers: {
          ...DEFAULT_HEADERS,
          ...request.headers,
        },
      };

      return httpClient
        .get(url, config)
        .then((result) => {
          return result.data;
        })
        .catch((error) => {
          console.error(error);
          return;
        });
    } catch (e) {
      console.error(e);
      return;
    }
  }

  async delete(request: IAxiosRequest = {}): Promise<any> {
    try {
      const url = `${this.config.controller}/${request.uri ?? ''}`;

      const config = {
        params: {
          ...request.params,
        },
        headers: {
          ...DEFAULT_HEADERS,
          ...request.headers,
        },
      };

      return httpClient
        .delete(url, config)
        .then((result) => {
          return result.data;
        })
        .catch((error) => {
          console.error(error);
          return;
        });
    } catch (e) {
      console.error(e);
      return;
    }
  }

  async post(
    request: IAxiosRequest = {},
    data: any,
    onProgress?: (event: ProgressEvent) => void
  ): Promise<any> {
    try {
      const url = `${this.config.controller}/${request.uri ?? ''}`;
      const config = {
        params: request.params,
        headers: {
          ...DEFAULT_HEADERS,
          ...request.headers,
        },
        onUploadProgress: (ev: ProgressEvent) => {
          if (onProgress) {
            onProgress(ev);
          }
        },
      };

      return httpClient
        .post(url, data, config)
        .then((result) => {
          return result.data;
        })
        .catch((error) => {
          console.error(error);
          return;
        });
    } catch (e) {
      console.error(e);
      return;
    }
  }

  async put(request: IAxiosRequest = {}, data: any): Promise<any> {
    try {
      const url = `${this.config.controller}/${request.uri ?? ''}`;
      const config = {
        params: request.params,
        headers: {
          ...DEFAULT_HEADERS,
          ...request.headers,
        },
      };

      return httpClient
        .put(url, data, config)
        .then((result) => {
          return result.data;
        })
        .catch((error) => {
          console.error(error);
          return;
        });
    } catch (e) {
      console.error(e);
      return;
    }
  }

  async patch(request: IAxiosRequest = {}, data: any): Promise<any> {
    try {
      const url = `${this.config.controller}/${request.uri ?? ''}`;
      const config = {
        params: request.params,
        headers: {
          ...DEFAULT_HEADERS,
          ...request.headers,
        },
      };

      return httpClient
        .patch(url, data, config)
        .then((result) => {
          return result.data;
        })
        .catch((error) => {
          console.error(error);
          return;
        });
    } catch (e) {
      console.error(e);
      return;
    }
  }
}
