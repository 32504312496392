import { PauseIcon, PlayIcon } from '@radix-ui/react-icons';
import { Flex } from '@radix-ui/themes';
import { MachineContextHelper } from 'classes/helpers/machine-context.helper';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { CommonCallout } from 'components/common/callouts';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { MachineCameraStreamViewer } from 'components/machine/camera-stream-viewer';
import { IMachineContext } from 'contexts/machine.context';
import { addSeconds, isFuture } from 'date-fns';
import { t } from 'i18next';
import { IBaseDialog, IFullDialog } from 'interfaces/i-dialogs';
import { StaticVideoType, WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IBallStatusMsg, IReadyMsg } from 'lib_ts/interfaces/i-machine-msg';
import React from 'react';
import { WebSocketService } from 'services/web-socket.service';

const COMPONENT_NAME = 'MachineInspectionDialog';

const EJECT_BALL_RPM = 400;

const EJECT_BALL_YAW = 2;

// how many seconds into the future should the app wait before acting upon any r2f from the machine after sending the ejection mstarget
const MIN_WAIT_BEFORE_EJECTING_SEC = 2;

interface IProps extends IBaseDialog {
  // provide for admins inspecting others' machines
  machineID?: string;
  // provide for users inspecting their active machine
  machineCx?: IMachineContext;
}

interface IState {
  machineID: string;
  ejecting: boolean;
  // prevents additional drop ball triggers until a ball-status is received
  awaiting_status: boolean;
}

export class MachineInspectionDialog extends React.Component<IProps, IState> {
  private closeTimeout?: NodeJS.Timeout;
  private ignoreR2FDate = new Date();
  private cameraStream?: MachineCameraStreamViewer;

  constructor(props: IProps) {
    super(props);

    if (props.machineID && props.machineCx) {
      NotifyHelper.warning({
        message_md: `Both \`machineID\` and \`machineCx\` should not be provided; using \`machineCx\` for inspection dialog.`,
      });
    }

    this.state = {
      machineID: props.machineCx?.machine.machineID ?? props.machineID ?? '',
      ejecting: false,
      awaiting_status: false,
    };

    this.handleBallStatus = this.handleBallStatus.bind(this);
    this.handleReadyToFire = this.handleReadyToFire.bind(this);
    this.startEjectRoutine = this.startEjectRoutine.bind(this);
  }

  componentDidMount() {
    this.props.machineCx?.setSpecialMode('inspect-machine');

    WebSocketHelper.on(WsMsgType.M2U_BallStatus, this.handleBallStatus);
    WebSocketHelper.on(WsMsgType.M2U_ReadyToFire, this.handleReadyToFire);
  }

  componentWillUnmount() {
    this.props.machineCx?.setSpecialMode(undefined);

    WebSocketHelper.remove(WsMsgType.M2U_BallStatus, this.handleBallStatus);
    WebSocketHelper.remove(WsMsgType.M2U_ReadyToFire, this.handleReadyToFire);

    clearTimeout(this.closeTimeout);
  }

  private handleReadyToFire(event: CustomEvent) {
    if (!this.props.machineCx) {
      return;
    }

    if (!this.state.ejecting) {
      return;
    }

    if (isFuture(this.ignoreR2FDate)) {
      return;
    }

    const r2f: IReadyMsg = event.detail;

    if (!r2f.data) {
      return;
    }

    if (!r2f.data.w1 || !r2f.data.w2 || !r2f.data.w3) {
      return;
    }

    if (!r2f.data.px || !r2f.data.pz) {
      return;
    }

    if (!r2f.data.yaw) {
      return;
    }

    NotifyHelper.warning({
      message_md: 'Attempting to eject ball(s) from inserter, please wait...',
    });

    this.setState({ ejecting: false }, () => {
      WebSocketService.send(WsMsgType.U2S_EjectBalls, {}, COMPONENT_NAME);
    });
  }

  private handleBallStatus(event: CustomEvent) {
    if (this.state.ejecting) {
      // ignore ball status updates while ejecting
      return;
    }

    const data: IBallStatusMsg = event.detail;

    this.setState({
      awaiting_status: false,
    });

    if (data.ball_count !== 1) {
      return;
    }

    this.closeTimeout = setTimeout(() => {
      this.props.onClose();
    }, 3_000);
  }

  private async startEjectRoutine() {
    if (!this.props.machineCx) {
      return;
    }

    if (this.state.ejecting) {
      return;
    }

    const data = MachineContextHelper.getTroubleshootingMS(
      this.props.machineCx.machine,
      {
        id: 'eject-balls',
        video_uuid: StaticVideoType.screensaver,
        wheels: {
          w1: EJECT_BALL_RPM,
          w2: EJECT_BALL_RPM,
          w3: EJECT_BALL_RPM,
        },
        yaw: EJECT_BALL_YAW,
        rapid: true,
      }
    );

    this.ignoreR2FDate = addSeconds(new Date(), MIN_WAIT_BEFORE_EJECTING_SEC);

    const sendResult = await this.props.machineCx.sendTarget({
      source: COMPONENT_NAME,
      pitch: data.pitch,
      msMsg: data.msg,
      force: true,
    });

    if (!sendResult.success) {
      NotifyHelper.warning({
        message_md: t('common.request-failed-msg'),
      });
      return;
    }

    this.setState({ ejecting: true });
  }

  private renderContent() {
    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.LG}>
        <MachineCameraStreamViewer
          ref={(elem) =>
            (this.cameraStream = elem as MachineCameraStreamViewer)
          }
          machineID={this.state.machineID}
        />

        {this.state.ejecting && (
          <CommonCallout text="Ejecting balls, please wait..." />
        )}
      </Flex>
    );
  }

  render() {
    const paused = this.cameraStream?.getPaused();

    const DEFAULT_PROPS: IFullDialog = {
      identifier: COMPONENT_NAME,
      width: RADIX.DIALOG.WIDTH.LG,
      title: 'main.inspect-machine',
      content: this.renderContent(),
      buttons: [
        {
          label: 'main.drop-ball',
          color: RADIX.COLOR.WARNING,
          disabled: !this.props.machineCx,
          onClick: () => {
            if (this.state.awaiting_status) {
              // prevent spamming of drop ball
              return;
            }

            this.setState({ awaiting_status: true }, () => {
              MachineContextHelper.sendDropBall({
                machineID: this.state.machineID,
                source: COMPONENT_NAME,
              });

              NotifyHelper.info({
                message_md: 'Attempting to drop ball, please wait...',
              });
            });
          },
        },
        {
          label: 'main.eject-balls',
          color: RADIX.COLOR.WARNING,
          disabled: !this.props.machineCx,
          invisible: !this.cameraStream?.getStreamData(),
          onClick: () => this.startEjectRoutine(),
        },
        {
          icon: paused ? <PlayIcon /> : <PauseIcon />,
          label: paused ? 'Resume' : 'Pause',
          color: paused ? RADIX.COLOR.SUCCESS : RADIX.COLOR.NEUTRAL,
          invisible: !this.cameraStream?.getStreamData(),
          onClick: () => this.cameraStream?.togglePaused(),
        },
      ],
      onClose: () => this.props.onClose(),
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog {...DEFAULT_PROPS} />
      </ErrorBoundary>
    );
  }
}
