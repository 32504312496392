import { Button } from '@radix-ui/themes';
import { IntercomEvents } from 'enums/intercom.enums';
import {
  RadixBtnSize,
  RadixBtnVariant,
  RadixColor,
} from 'lib_ts/enums/radix-ui';

export const IntercomArticleButton = (props: {
  size?: RadixBtnSize;
  color?: RadixColor;
  variant?: RadixBtnVariant;
  className?: string;
  articleID: number;
  onClick?: () => void;
}) => {
  return (
    <Button
      size={props.size}
      variant={props.variant}
      color={props.color}
      className={props.className}
      onClick={() => {
        props.onClick?.();

        document.dispatchEvent(
          new CustomEvent(IntercomEvents.ShowArticle, {
            detail: { id: props.articleID },
          })
        );
      }}
    >
      View Article
    </Button>
  );
};
