/** units in feet */
export interface IStrikeZone {
  /** height of hitter in inches, without decimals, for finding strike zone closest to player's height */
  height_in: number;

  bottom_ft: number;
  top_ft: number;
  left_ft: number;
  right_ft: number;
}

/** see https://www.baseballprospectus.com/news/article/40891/prospectus-feature-the-universal-strike-zone/
 * 17 = width of plate in inches
 * 2.94 = diameter of the ball in inches
 * 12 = inches per foot
 */
const STRIKEZONE_WIDTH = (17 + 2.94) / 12;

export const DEFAULT_STRIKEZONE: IStrikeZone = {
  /** default, height is irrelevant but shouldn't match anything */
  height_in: -1,

  bottom_ft: 18 / 12,
  top_ft: 44 / 12,
  left_ft: -STRIKEZONE_WIDTH / 2,
  right_ft: STRIKEZONE_WIDTH / 2,
};

/**
 * index 0 = height in inches
 * index 1 = strike zone top in inches
 * index 2 = strike zone bottom in inches
 */
const STRINGER_TABLE: number[][] = [
  // [60, 40.3, 20.4],
  [65, 38, 17.3],
  [66, 36.4, 16.8],
  [67, 40.4, 18.6],
  [68, 38.6, 17.9],
  [69, 39.3, 18.1],
  [70, 39.7, 18.3],
  [71, 39.8, 18.3],
  [72, 40.4, 18.7],
  [73, 41, 18.9],
  [74, 41.7, 19.4],
  [75, 42.3, 19.7],
  [76, 42.7, 20],
  [77, 43.2, 20.2],
  [78, 43.4, 20.4],
  [79, 44.7, 21],
  [80, 44.1, 20.5],
  [81, 44.7, 20.8],
  [82, 47.8, 22.6],
  [83, 48.1, 22.1],
];

export const STRINGER_STRIKEZONES: IStrikeZone[] = STRINGER_TABLE.map(
  (entry) => {
    const zone: IStrikeZone = {
      height_in: entry[0],
      top_ft: entry[1] / 12,
      bottom_ft: entry[2] / 12,
      left_ft: -STRIKEZONE_WIDTH / 2,
      right_ft: STRIKEZONE_WIDTH / 2,
    };

    return zone;
  }
);
