import { RouteHelper } from 'classes/helpers/route.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { ApiKeysManagementHoC } from 'components/sections/admin-portal/api-keys';
import { ErrorTypesTableHoc } from 'components/sections/admin-portal/error-types/table';
import { MachineModelsTableHoC } from 'components/sections/admin-portal/machine-models/table';
import { MachinesTableHoC } from 'components/sections/admin-portal/machines/table';
import { ServerControlsTab } from 'components/sections/admin-portal/server-controls';
import { TeamsTableHoC } from 'components/sections/admin-portal/teams/table';
import { UsersTableHoC } from 'components/sections/admin-portal/users/table';
import { VideosTableHoC } from 'components/sections/admin-portal/videos/table';
import { MachineModelsProvider } from 'contexts/admin/machine-models.context';
import { MachinesProvider } from 'contexts/admin/machines.context';
import { TeamsProvider } from 'contexts/admin/teams.context';
import { UsersProvider } from 'contexts/admin/users.context';
import { AuthContext } from 'contexts/auth.context';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

export const AdminPortal = () => {
  const { current } = useContext(AuthContext);
  const isSuperAdmin = current.role === UserRole.admin;

  const redirect = (
    <Navigate
      to={RouteHelper.getSlug([SectionName.AdminPortal, SubSectionName.Users])}
      replace
    />
  );

  return (
    <ErrorBoundary componentName="AdminPortal">
      <TeamsProvider>
        <MachinesProvider>
          <UsersProvider>
            <MachineModelsProvider>
              <Routes>
                <Route path="" element={redirect} />

                <Route
                  path={RouteHelper.getSlug([SubSectionName.Users])}
                  element={<UsersTableHoC />}
                />

                <Route
                  path={RouteHelper.getSlug([SubSectionName.Machines])}
                  element={<MachinesTableHoC />}
                />

                <Route
                  path={RouteHelper.getSlug([SubSectionName.Teams])}
                  element={isSuperAdmin ? <TeamsTableHoC /> : redirect}
                />

                <Route
                  path={RouteHelper.getSlug([SubSectionName.MachineModels])}
                  element={isSuperAdmin ? <MachineModelsTableHoC /> : redirect}
                />

                <Route
                  path={RouteHelper.getSlug([SubSectionName.ErrorTypes])}
                  element={isSuperAdmin ? <ErrorTypesTableHoc /> : redirect}
                />

                <Route
                  path={RouteHelper.getSlug([SubSectionName.StaticVideos])}
                  element={isSuperAdmin ? <VideosTableHoC /> : redirect}
                />

                <Route
                  path={RouteHelper.getSlug([SubSectionName.ApiKeys])}
                  element={<ApiKeysManagementHoC />}
                />

                <Route
                  path={RouteHelper.getSlug([SubSectionName.ServerControls])}
                  element={isSuperAdmin ? <ServerControlsTab /> : redirect}
                />

                <Route path="*" element={redirect} />
              </Routes>
            </MachineModelsProvider>
          </UsersProvider>
        </MachinesProvider>
      </TeamsProvider>
    </ErrorBoundary>
  );
};
