import { EllipseName } from '../enums/ellipses';
import { IPlateLoc } from './pitches/i-plate-loc';

export interface IEllipseLimits {
  absMaxX: number;
  absMaxY: number;
}

export interface IEllipse {
  angle_radians: number;

  /** a, i.e. for x dimension */
  majorRadius: number;

  /** b, i.e. for y dimension */
  minorRadius: number;

  eigVector: number[];
}

export interface ICovarianceSummary {
  mean_location: IPlateLoc;
  std_location: IPlateLoc;
  covariance: number[][];

  // computed via atan2
  ellipse?: IEllipse;
}

export interface IEllipseScaling {
  name: EllipseName;

  // determines size of the ellipse
  factor: number;

  // alpha of the color used when drawing
  opacity: number;

  // whether the ellipse draws with an outline or not
  stroke?: boolean;
}

export const EMPTY_PLATE_LOC_COV: ICovarianceSummary = {
  mean_location: { plate_x: 0, plate_z: 0 },
  std_location: { plate_x: 0, plate_z: 0 },
  covariance: [
    [0, 0],
    [0, 0],
  ],
};
