import { RouteHelper } from 'classes/helpers/route.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { HitterSession } from 'components/sections/analytics/hitter-session';
import { HittingReports } from 'components/sections/analytics/hitting-reports';
import { SessionHistory } from 'components/sections/analytics/session-history';
import { ISectionsContext, SectionsContext } from 'contexts/sections.context';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const RedirectToIndex = ({
  sectionsCx,
  trigger,
}: {
  // kind of dumb but react doesn't like when you put useContext next to useEffect
  sectionsCx: ISectionsContext;
  trigger: string;
}) => {
  // Keep active section state in sync with URL for subsection UI
  useEffect(() => {
    sectionsCx.tryChangeSection({
      section: SectionName.Analytics,
      subsection: SubSectionName.HittingReports,
      trigger,
    });
  }, []);

  return (
    <Navigate
      to={RouteHelper.getSlug([
        SectionName.Analytics,
        SubSectionName.HittingReports,
      ])}
      replace
    />
  );
};

export const AnalyticsRouter = () => {
  const sectionsCx = useContext(SectionsContext);

  return (
    <ErrorBoundary componentName="AnalyticsRouter">
      <Routes>
        <Route
          path=""
          element={
            <RedirectToIndex
              sectionsCx={sectionsCx}
              trigger="Redirect from '/analytics'"
            />
          }
        />
        <Route
          path={RouteHelper.getSlug([SubSectionName.HittingReports])}
          element={<HittingReports />}
        />
        <Route
          path={RouteHelper.getSlug(
            [SubSectionName.HitterSession],
            [':sessionID', ':hitterID']
          )}
          element={<HitterSession />}
        />
        <Route
          path={RouteHelper.getSlug([SubSectionName.SessionHistory])}
          element={<SessionHistory />}
        />
        <Route
          path="*"
          element={
            <RedirectToIndex
              sectionsCx={sectionsCx}
              trigger="Redirect from '/analytics' wildcard"
            />
          }
        />
      </Routes>
    </ErrorBoundary>
  );
};
