import { Badge, Code } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { StringHelper } from 'classes/helpers/string.helper';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { CommonTableHoC } from 'components/common/table';
import { CommonTooltip } from 'components/common/tooltip';
import { AuthContext } from 'contexts/auth.context';
import { SessionEventsContext } from 'contexts/session-events.context';
import { format } from 'date-fns-tz';
import parseISO from 'date-fns/parseISO';
import { LOCAL_DATETIME_FORMAT_SHORT, LOCAL_TIMEZONE } from 'enums/env';
import { SessionDialogMode } from 'enums/session.enums';
import { ACTIONS_KEY, TABLES } from 'enums/tables';
import { t } from 'i18next';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ITableSortable } from 'interfaces/tables/sorting';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { ISessionSummary } from 'lib_ts/interfaces/i-session-summary';
import { useContext, useMemo } from 'react';
import slugify from 'slugify';
import { SessionsToolbar } from './toolbar';

const IDENTIFIER = TableIdentifier.SessionList;

const ENABLE_EDIT_SESSION = false;

const PAGE_SIZES = TABLES.PAGE_SIZES.MD;

export const SessionsTable = () => {
  const { current } = useContext(AuthContext);
  const {
    filtered,
    loading,
    getPitchStatsData,
    getShotsData,
    selectSession,
    showDialog,
  } = useContext(SessionEventsContext);

  const columns: ITableColumn[] = useMemo(
    () => [
      {
        label: 'common.actions',
        key: ACTIONS_KEY,
        actions: [
          {
            label: 'Visualize',
            suffixIcon: <SuperAdminIcon />,
            invisibleFn: () =>
              current.role !== UserRole.admin && current.mode !== 'impostor',
            disableFn: () => loading,
            onClick: (m: ISessionSummary) =>
              showDialog({
                mode: SessionDialogMode.visualize,
                session: m.session,
                key: Date.now(),
              }),
          },
          {
            label: 'common.edit',
            invisibleFn: () => !ENABLE_EDIT_SESSION,
            disableFn: () => loading,
            onClick: (m: ISessionSummary) =>
              showDialog({
                mode: SessionDialogMode.edit,
                session: m.session,
                key: Date.now(),
              }),
          },
          {
            label: 'common.export-shots',
            disableFn: () => loading,
            onClick: async (m: ISessionSummary) => {
              const data = await getShotsData(m.session);

              if (data.length === 0) {
                NotifyHelper.warning({
                  message_md: t('common.session-has-no-shots'),
                });
                return;
              }

              StringHelper.saveCsv(data, {
                prefix: slugify(
                  [
                    m.machineID,
                    m.machine_nickname,
                    format(parseISO(m.start), LOCAL_DATETIME_FORMAT_SHORT, {
                      timeZone: LOCAL_TIMEZONE,
                    }),
                  ]
                    .map((m) => m?.trim())
                    .filter((m) => m)
                    .join('-'),
                  { strict: true }
                ),
              });
            },
          },
          {
            label: 'common.export-stats',
            suffixIcon: <SuperAdminIcon />,
            invisibleFn: () => current.role !== UserRole.admin,
            disableFn: () => loading,
            onClick: async (m: ISessionSummary) => {
              const data = await getPitchStatsData({
                session: m.session,
              });

              if (data.length === 0) {
                NotifyHelper.warning({
                  message_md: t('common.session-has-no-pitch-stats'),
                });
                return;
              }

              StringHelper.saveCsv(data, {
                prefix: `session-${m.session}_stats`,
              });
            },
          },
        ],
      },
      {
        label: 'common.machine',
        key: 'machineID',
        tooltipFn: (s: ISessionSummary) =>
          s.machine_nickname ? `aka: ${s.machine_nickname}` : 'No Nickname',
        formatFn: (s: ISessionSummary) => <Badge>{s.machineID}</Badge>,
      },
      {
        label: 'common.identifier',
        key: 'session',
        sortRowsFn: (a: ISessionSummary, b: ISessionSummary, dir: number) =>
          -(a.session ?? '').localeCompare(b.session ?? '') * dir,
        formatFn: (s: ISessionSummary) => (
          <CommonTooltip
            text="Click to copy this session ID to the clipboard"
            trigger={
              <Code
                className="cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(s.session);
                  NotifyHelper.success({
                    message_md: 'Session ID copied to clipboard!',
                  });
                }}
              >
                {s.session}
              </Code>
            }
          />
        ),
      },
      {
        label: 'common.name',
        key: 'name',
        sortRowsFn: (a: ISessionSummary, b: ISessionSummary, dir: number) =>
          -(a.name ?? '').localeCompare(b.name ?? '') * dir,
      },
      {
        label: 'common.shots',
        key: 'fires',
        align: 'right',
      },
      {
        label: 'common.start-date',
        key: 'start',
        dataType: 'datetime',
      },
    ],
    [current]
  );

  /** sessions table */
  const pagination: ITablePageable = {
    identifier: IDENTIFIER,
    total: filtered.length,
    enablePagination: true,
    pageSizes: PAGE_SIZES,
  };

  const sort: ITableSortable = {
    enableSort: true,
    defaultSort: {
      key: 'start',
      dir: 1,
    },
  };

  const select: ITableSelectable = {
    enableSelect: true,
    afterChangeSelected: (model: ISessionSummary | undefined) =>
      selectSession(model),
  };

  return (
    <CommonTableHoC
      id="Sessions"
      displayColumns={columns}
      displayData={filtered}
      toolbarContent={<SessionsToolbar />}
      {...pagination}
      {...select}
      {...sort}
      vFlex
    />
  );
};
