import { DownloadIcon, PlayIcon } from '@radix-ui/react-icons';
import { Box, Button, Container, Flex } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonCallout } from 'components/common/callouts';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonDateInput } from 'components/common/form/date';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import {
  addWeeks,
  endOfToday,
  isValid,
  lightFormat,
  startOfToday,
} from 'date-fns';
import { format } from 'date-fns-tz';
import { LOCAL_TIMEZONE } from 'enums/env';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { isInteger } from 'lib_ts/classes/math.utilities';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { BallType } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import {
  IRepeatabilityFilter,
  IRepeatabilityImage,
} from 'lib_ts/interfaces/modelling/i-repeatability';
import React from 'react';
import { DEFAULT_MAX_GROUPS } from 'services/admin/machine-models.service';
import {
  AdminMachinesService,
  IRecentSession,
} from 'services/admin/machines.service';
import slugify from 'slugify';

const COMPONENT_NAME = 'RepeatabilityTab';

const sessionLabelFn = (m: IRecentSession): string => {
  const ds = lightFormat(new Date(m.max_created), 'yyyy-MM-dd');
  return `*${m._id.substring(m._id.length - 7)} @ ${ds} (${m.total} fires)`;
};

interface IProps {
  machine: IMachine;
}

interface IState {
  start: Date;
  end: Date;

  selectedSessionIDs: string[];
  recentSessions: IRecentSession[];

  filter: IRepeatabilityFilter;
  images: IRepeatabilityImage[];
  loading: boolean;
}

export class PlottingTab extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    const start = addWeeks(startOfToday(), -2);
    const end = new Date();

    const defaultFilter: IRepeatabilityFilter = {
      machineID: this.props.machine.machineID,
      // will be updated when start changes
      start_date: start.toISOString(),
      // will be updated when end changes
      end_date: end.toISOString(),
      ball_type: this.props.machine.ball_type,
      min_group_size: 3,
      min_groups: 1,
      max_groups: DEFAULT_MAX_GROUPS,
      center_groups: true,
      training: undefined,
      limit: 2000,
      sessions: [],
      pitch_list_ids: [],
      pitch_type: undefined,
      exclude_low_confidence: false,
      exclude_inactive: true,
    };

    this.state = {
      start: start,
      end: end,
      filter: defaultFilter,
      images: [],
      recentSessions: [],
      selectedSessionIDs: [],
      loading: false,
    };

    this.generate = this.generate.bind(this);
    this.getFullFilename = this.getFullFilename.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.validateFilter = this.validateFilter.bind(this);
  }

  componentDidMount(): void {
    if (this.init) {
      return;
    }

    this.init = true;

    AdminMachinesService.getInstance()
      .getRecentSessions(this.props.machine.machineID)
      .then((result) => this.setState({ recentSessions: result }));
  }

  private async generate() {
    const safeFilter = this.state.filter;

    if (this.state.selectedSessionIDs.length > 0) {
      safeFilter.sessions = ArrayHelper.unique(this.state.selectedSessionIDs);
    }

    safeFilter.pitch_list_ids = ArrayHelper.unique(
      safeFilter.pitch_list_ids.map((id) => id.trim())
    );

    if (!this.validateFilter(safeFilter)) {
      return;
    }

    this.setState({
      loading: true,
      images: [],
    });

    NotifyHelper.warning({
      message_md:
        'Fetching repeatability report, please wait as this may take awhile...',
      delay_ms: 10_000,
    });

    const results =
      await AdminMachinesService.getInstance().getRepeatabilityImages(
        this.state.filter
      );

    this.setState({
      images: results.success ? results.images : [],
      loading: false,
    });
  }

  private validateFilter(filter: IRepeatabilityFilter): boolean {
    try {
      const reasons: string[] = [];

      if (!filter.start_date || !isValid(new Date(filter.start_date))) {
        reasons.push('Start Date');
      }

      if (!filter.end_date || !isValid(new Date(filter.end_date))) {
        reasons.push('End Date');
      }

      if (!isInteger(filter.min_group_size)) {
        reasons.push('Min Group Size');
      }

      if (!isInteger(filter.min_groups)) {
        reasons.push('Min Groups');
      }

      if (!isInteger(filter.max_groups)) {
        reasons.push('Max Groups');
      }

      if (!isInteger(filter.limit)) {
        reasons.push('Record Limit');
      }

      if (reasons.length > 0) {
        NotifyHelper.warning({
          message_md: `Invalid field(s): ${reasons.join(', ')}`,
        });
        return false;
      }

      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  private getFullFilename = (filename: string, extension: string): string => {
    const filter = this.state.filter;

    const components: string[] = [filter.machineID ?? 'anonymous'];

    if (filter.start_date) {
      const sDate = new Date(filter.start_date);
      if (isValid(sDate)) {
        components.push(
          format(sDate, 'yyyy-MM-dd', { timeZone: LOCAL_TIMEZONE })
        );
      }
    }

    if (filter.end_date) {
      const eDate = new Date(filter.end_date ?? '');
      if (isValid(eDate)) {
        components.push(
          format(eDate, 'yyyy-MM-dd', { timeZone: LOCAL_TIMEZONE })
        );
      }
    }

    if (filter.ball_type) {
      components.push(filter.ball_type);
    }

    if (filter.pitch_type) {
      components.push(filter.pitch_type);
    }

    if (filter.training) {
      components.push('training');
    }

    if (filter.center_groups) {
      components.push('center');
    }

    components.push(filename);

    const slug = slugify(components.join(' '), {
      replacement: '_',
      trim: true,
    });

    return `${slug}.${extension}`;
  };

  private renderBody() {
    const saveImage = async (img: IRepeatabilityImage) => {
      const filename = this.getFullFilename(img.filename, img.extension);

      const url = `data:image/png;base64, ${img.base64}`;
      const res = await fetch(url);
      const blob = await res.blob();

      MiscHelper.saveAs(blob, filename);
    };

    const renderControls = () => (
      <CommonFormGrid columns={3}>
        {/* row 1 */}
        <CommonDateInput
          id="performance-plot-start"
          label="common.start-date"
          disabled={this.state.loading}
          defaultValue={this.state.start}
          maxDate={this.state.end}
          onChange={(date) => {
            if (!date) {
              NotifyHelper.warning({
                message_md: t('common.check-inputs-msg'),
              });
              return;
            }

            this.setState({
              start: date,
              filter: {
                ...this.state.filter,
                start_date: date.toISOString(),
              },
            });
          }}
          showTime
        />
        <CommonDateInput
          id="performance-plot-end"
          label="common.end-date"
          disabled={this.state.loading}
          defaultValue={this.state.end}
          minDate={this.state.start}
          maxDate={endOfToday()}
          onChange={(date) => {
            if (!date) {
              NotifyHelper.warning({
                message_md: t('common.check-inputs-msg'),
              });
              return;
            }

            this.setState({
              end: date,
              filter: {
                ...this.state.filter,
                end_date: date.toISOString(),
              },
            });
          }}
          showTime
        />
        <CommonSelectInput
          id="performance-plot-ball"
          name="ball_type"
          label="Ball Type"
          disabled={this.state.loading}
          options={(this.props.machine.ball_type_options ?? [BallType.MLB]).map(
            (t) => ({ label: t, value: t })
          )}
          value={this.state.filter.ball_type}
          onChange={(v) =>
            this.setState({
              filter: { ...this.state.filter, ball_type: v as BallType },
            })
          }
        />

        {/* row 2 */}
        <CommonSelectInput
          id="performance-plot-training"
          label="Training"
          name="training"
          disabled={this.state.loading}
          options={[
            {
              label: 'Yes',
              value: 'true',
            },
            {
              label: 'No',
              value: 'false',
            },
          ]}
          value={this.state.filter.training?.toString()}
          onChange={(v) =>
            this.setState({
              filter: {
                ...this.state.filter,
                training: v === undefined ? undefined : v === 'true',
              },
            })
          }
          hint_md="Filter by training or BP data only."
          optional
        />
        <CommonTextInput
          id="performance-plot-min-group"
          label="Min Group Size"
          type="number"
          name="min_group_size"
          disabled={this.state.loading}
          value={this.state.filter.min_group_size?.toString()}
          placeholder="Type an integer value"
          onNumericChange={(v) => {
            this.setState({
              filter: {
                ...this.state.filter,
                min_group_size: Math.round(v),
              },
            });
          }}
          hint_md="Minimum number of shots per target machine state."
        />
        <CommonSelectInput
          id="performance-plot-confidence"
          label="Exclude Low Confidence"
          name="confidence"
          disabled={this.state.loading}
          options={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          value={this.state.filter.exclude_low_confidence.toString()}
          onBooleanChange={(v) => {
            this.setState({
              filter: {
                ...this.state.filter,
                exclude_low_confidence: v,
              },
            });
          }}
          hint_md="Filter out samples with bad tracking device data."
        />

        {/* row 3 */}
        <CommonSearchInput
          id="sessions"
          label="Session IDs"
          options={this.state.recentSessions.map((o) => ({
            label: sessionLabelFn(o),
            value: o._id,
          }))}
          values={this.state.selectedSessionIDs}
          onChange={(ids) => {
            this.setState({
              selectedSessionIDs: ids,
            });
          }}
          hint_md="Only sessions with fires are listed, with most recent ones sorted to the top."
          multiple
          optional
          // to preserve most recent first
          skipSort
        />
        <CommonTextInput
          id="performance-plot-pitch-list-ids"
          label="Pitch List IDs"
          name="pitch_list_ids"
          disabled={this.state.loading}
          placeholder={`(${t('common.comma-delimited')})`}
          onChange={(v) => {
            this.setState({
              filter: {
                ...this.state.filter,
                pitch_list_ids: (v ?? '').replace(/\s/g, '').split(','),
              },
            });
          }}
          optional
        />

        <CommonSelectInput
          id="performance-plot-inactive"
          label="Exclude Inactive"
          name="inactive"
          disabled={this.state.loading}
          options={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          value={this.state.filter.exclude_inactive.toString()}
          onBooleanChange={(v) => {
            this.setState({
              filter: {
                ...this.state.filter,
                exclude_inactive: v,
              },
            });
          }}
          hint_md="Filter out samples with old matching hashes."
        />
      </CommonFormGrid>
    );

    const renderImages = () => (
      <Container size="4">
        <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
          {this.state.images.map((img, index) => (
            <Box key={`image-${index}`}>
              <img
                src={`data:image/png;base64, ${img.base64}`}
                style={{ width: '100%', height: 'auto' }}
                title="Click to save the graph as an image"
                alt={img.filename}
                className="cursor-pointer"
                onClick={() => saveImage(img)}
              />
            </Box>
          ))}
        </Flex>
      </Container>
    );

    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.FORM}>
        {renderControls()}

        <CommonCallout
          color={RADIX.COLOR.INFO}
          text="Will generate 5 report images: Plate Variance, Break Variance, Break Error, BS Variance, and BS Error. Click on each image to save it."
        />

        <Flex gap={RADIX.FLEX.GAP.SM} justify="end">
          <Button
            size={RADIX.BUTTON.SIZE.SM}
            color={RADIX.COLOR.SUCCESS}
            disabled={this.state.loading}
            onClick={() => this.generate()}
          >
            <PlayIcon /> Generate
          </Button>
          <Button
            size={RADIX.BUTTON.SIZE.SM}
            color={RADIX.COLOR.INFO}
            disabled={this.state.loading || !this.state.images}
            onClick={() => this.state.images.forEach((img) => saveImage(img))}
          >
            <DownloadIcon /> Save All
          </Button>
        </Flex>

        {renderImages()}
      </Flex>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        {this.renderBody()}
      </ErrorBoundary>
    );
  }
}
