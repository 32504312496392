import { IMachineContext } from 'contexts/machine.context';
import React from 'react';

interface IProps {
  machineCx: IMachineContext;

  suspendFn: () => boolean;

  // aka: fire/send/drop button
  onFire: () => void;
  onPrev: () => void;
  onNext: () => void;
}

export class RemoteKeydownListener extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  private handleKeyDown(event: KeyboardEvent) {
    if (this.props.suspendFn()) {
      /** ignore everything while suspended (e.g. if a modal is open) */
      return;
    }

    if (event.ctrlKey || event.shiftKey || event.altKey) {
      /** ignore everything with modifiers pressed */
      return;
    }

    const hotkeys = this.props.machineCx.machine.remote_hotkeys;

    if (
      event.code === hotkeys?.previous ||
      event.code === hotkeys?.next ||
      event.code === hotkeys?.fire
    ) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.repeat) {
      /** ignore spam input, after preventing default and stopping propagation */
      return;
    }

    if (hotkeys?.previous && event.code === hotkeys?.previous) {
      this.props.onPrev();
      return;
    }

    if (hotkeys?.next && event.code === hotkeys?.next) {
      this.props.onNext();
      return;
    }

    if (hotkeys?.fire && event.code === hotkeys?.fire) {
      this.props.onFire();
      return;
    }
  }

  render() {
    return <></>;
  }
}
