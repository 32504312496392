import { ENV_PROD } from 'environments/i-prod';
import { IEnvConfig } from 'interfaces/i-env-config';

export const ENV_STAGING: IEnvConfig = {
  ...ENV_PROD,
  // enable console.debug messages for staging without affecting prod
  debug_messages: true,
  region_redirect: false,
  identifier: 'staging',
  self_url: 'https://staging.trajektsports.app',
  server_url: 'https://staging.trajektsports.app/node',
  ws_url: 'wss://staging.trajektsports.app/wss',
};
