import { Box, Flex } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { Content } from 'components/main/content';
import { Maintenance } from 'components/main/landing/forms/maintenance';
import { Login } from 'components/main/landing/login';
import { MachineResponseListener } from 'components/main/listeners/machine-response';
import { NotificationListener } from 'components/main/listeners/notification';
import { QueueListenerHoC } from 'components/main/listeners/queue';
import { Sidebar } from 'components/main/sidebar';
import env from 'config';
import { AuthContext, IAuthContext } from 'contexts/auth.context';
import { CookiesContext, ICookiesContext } from 'contexts/cookies.context';
import { GlobalContext, IGlobalContext } from 'contexts/global.context';
import { HittersProvider } from 'contexts/hitters.context';
import { InboxContext } from 'contexts/inbox';
import { MachineProvider } from 'contexts/machine.context';
import { PitchListsProvider } from 'contexts/pitch-lists/lists.context';
import { MatchingShotsProvider } from 'contexts/pitch-lists/matching-shots.context';
import { PitchDesignProvider } from 'contexts/pitch-lists/pitch-design.context';
import { SectionsProvider } from 'contexts/sections.context';
import { SessionEventsProvider } from 'contexts/session-events.context';
import { VideosProvider } from 'contexts/videos/videos.context';
import { SidebarState } from 'enums/cookies.enums';
import React, { useContext } from 'react';

interface IProps {
  globalCx: IGlobalContext;
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
}

interface IState {}

const DEFAULT_STATE: IState = {};

export const MainHoC = () => {
  const props: IProps = {
    globalCx: useContext(GlobalContext),
    cookiesCx: useContext(CookiesContext),
    authCx: useContext(AuthContext),
  };

  return <MainBase {...props} />;
};

class MainBase extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;

    this.renderAuthorized = this.renderAuthorized.bind(this);
    this.renderUnauthorized = this.renderUnauthorized.bind(this);
  }

  private renderAuthorized(): React.ReactNode {
    return (
      <HittersProvider>
        <SessionEventsProvider>
          <MachineProvider>
            <VideosProvider>
              <SectionsProvider>
                <MatchingShotsProvider>
                  <PitchListsProvider>
                    <PitchDesignProvider>
                      <Flex
                        className="Main"
                        style={{
                          position: 'fixed',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      >
                        {/* sidebar */}
                        <Box
                          className={StringHelper.classNames([
                            'MainSidebar',
                            this.props.cookiesCx.app.sidebar_state ===
                            SidebarState.full
                              ? 'full'
                              : 'mini',
                          ])}
                        >
                          <Sidebar />
                        </Box>
                        {/* content */}
                        <Box className="MainContent" flexGrow="1">
                          <Content />
                        </Box>
                      </Flex>
                      <QueueListenerHoC />
                      <MachineResponseListener />
                    </PitchDesignProvider>
                  </PitchListsProvider>
                </MatchingShotsProvider>
              </SectionsProvider>
            </VideosProvider>
          </MachineProvider>
        </SessionEventsProvider>
      </HittersProvider>
    );
  }

  private renderUnauthorized() {
    return (
      <>
        <InboxContext.Consumer>
          {(inboxCx) => (
            <NotificationListener
              cookiesCx={this.props.cookiesCx}
              inboxCx={inboxCx}
            />
          )}
        </InboxContext.Consumer>
        <Login authCx={this.props.authCx} />
      </>
    );
  }

  render() {
    if (env.maintenance) {
      return <Maintenance />;
    }

    const content = this.props.authCx.current.auth
      ? this.renderAuthorized()
      : this.renderUnauthorized();

    return (
      <ErrorBoundary componentName="Main" hideIntercom>
        {content}
      </ErrorBoundary>
    );
  }
}
