import { Flex, Heading, Text } from '@radix-ui/themes';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { ContactSupportButton } from 'components/common/buttons/contact-support';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { ArcPositions } from 'components/machine/arc-positions';
import { MachineContext } from 'contexts/machine.context';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  CalibrateProc,
  ICalibrateResponseMsg,
} from 'lib_ts/interfaces/i-machine-msg';
import React from 'react';

const COMPONENT_NAME = 'RealignMachineDialog';

interface IProps {
  onClose: () => void;
}

interface IState {
  result?: ICalibrateResponseMsg;
}

export class RealignMachineDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.handleResponse = this.handleResponse.bind(this);
    this.renderComplete = this.renderComplete.bind(this);
  }

  componentDidMount(): void {
    WebSocketHelper.on(WsMsgType.M2U_CalibrationResponse, this.handleResponse);
  }

  componentWillUnmount(): void {
    WebSocketHelper.remove(
      WsMsgType.M2U_CalibrationResponse,
      this.handleResponse
    );
  }

  private handleResponse(event: CustomEvent) {
    const data: ICalibrateResponseMsg = event.detail;

    if (data.status === undefined) {
      // still in progress
      return;
    }

    this.setState({
      result: data,
    });
  }

  render() {
    return (
      <MachineContext.Consumer>
        {(machineCx) => (
          <ErrorBoundary componentName={COMPONENT_NAME}>
            <CommonDialog
              identifier="ConfirmRealignment"
              title="Realign Machine"
              width={RADIX.DIALOG.WIDTH.LG}
              content={
                this.state.result ? (
                  this.renderComplete()
                ) : (
                  <ArcPositions
                    machineCx={machineCx}
                    procs={[CalibrateProc.All]}
                    onFailure={() => {
                      const msg: ICalibrateResponseMsg = {
                        status: false,
                        can_calibrate: false,
                        calibrate_success_list: [],
                        timestamp: Date.now(),
                      };

                      this.setState({
                        result: msg,
                      });
                    }}
                  />
                )
              }
              onClose={this.props.onClose}
            />
          </ErrorBoundary>
        )}
      </MachineContext.Consumer>
    );
  }

  private renderComplete() {
    if (!this.state.result) {
      return <></>;
    }

    const message = this.state.result.status
      ? `Your realignment is complete, please try firing a test pitch before continuing your session.`
      : `Your realignment failed. ${
          this.state.result.can_calibrate
            ? 'Please try again or contact support.'
            : 'Please contact support.'
        }`;

    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.MD} align="center">
        <Heading>Result</Heading>

        <Text size={RADIX.TEXT.SIZE.MD}>{message}</Text>

        {!this.state.result.status && (
          <ContactSupportButton className="width-200px" />
        )}
      </Flex>
    );
  }
}
