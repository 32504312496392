import { Flex, Heading } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CHART_COLORS } from 'enums/charts.enums';
import { FTPS_TO_MPH, METERS_TO_INCHES } from 'lib_ts/classes/math.utilities';
import { PitchType } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IGatherSummary } from 'lib_ts/interfaces/modelling/i-gather-shot-data';
import React from 'react';
import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export type PlotMode =
  | 'std_vy'
  | 'std_wx'
  | 'std_wy'
  | 'std_wz'
  | 'std_break_x_in'
  | 'std_break_z_in';

const PRECISION_FACTOR = 100;

interface IPlotData {
  type: string;
  value?: number;
}

const getTitle = (mode: PlotMode) => {
  switch (mode) {
    default: {
      return `${mode} by Pitch Type`;
    }
  }
};

const formatData = (mode: PlotMode, groups: IGatherSummary[]): IPlotData[] => {
  return groups.map((group) => {
    const rawValue = (() => {
      switch (mode) {
        case 'std_vy': {
          const vy = group.std_shot?.actual_traj?.vy;
          if (vy === undefined) {
            return undefined;
          }
          return vy * FTPS_TO_MPH;
        }

        case 'std_wx': {
          return group.std_shot?.actual_bs?.wx;
        }

        case 'std_wy': {
          return group.std_shot?.actual_bs?.wy;
        }

        case 'std_wz': {
          return group.std_shot?.actual_bs?.wz;
        }

        case 'std_break_x_in': {
          const v = group.std_shot?.rapsodo_breaks?.PITCH_HBTrajectory;
          return v !== undefined ? v * METERS_TO_INCHES : undefined;
        }

        case 'std_break_z_in': {
          const v = group.std_shot?.rapsodo_breaks?.PITCH_VBTrajectory;
          return v !== undefined ? v * METERS_TO_INCHES : undefined;
        }

        default: {
          return undefined;
        }
      }
    })();

    const output: IPlotData = {
      type: `${group.pitch?.type ?? PitchType.None}`,
      value:
        rawValue !== undefined
          ? Math.round(rawValue * PRECISION_FACTOR) / PRECISION_FACTOR
          : undefined,
    };

    return output;
  });
};

interface IProps {
  mode: PlotMode;
  groups: IGatherSummary[];
}

interface IState {
  title: string;
  data: IPlotData[];
}

export class PlotGroupStd extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const sortedGroups = props.groups.sort((a, b) => {
      if (a.pitch?.type === PitchType.None) {
        // push none to the end
        return 1;
      }

      return (a.pitch?.type ?? '').localeCompare(b.pitch?.type ?? '');
    });

    const data = formatData(props.mode, sortedGroups).filter(
      (m) => m.value !== undefined
    );

    this.state = {
      title: getTitle(props.mode),
      data: data,
    };

    this.renderGraph = this.renderGraph.bind(this);
  }

  private renderGraph() {
    return (
      <ResponsiveContainer width="100%" height={200}>
        <ScatterChart
          data={this.state.data}
          margin={{
            top: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid />

          <XAxis
            name="Pitch"
            type="category"
            dataKey="type"
            // specifically has to be false because the default behaviour is inverted
            allowDuplicatedCategory={false}
          />

          <YAxis type="number" dataKey="value" name={this.props.mode} />

          <Tooltip />

          <Scatter
            name={this.props.mode}
            dataKey="value"
            data={this.state.data}
            fill={CHART_COLORS[0]}
          />
        </ScatterChart>
      </ResponsiveContainer>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName="PlotGroupStd">
        <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
          <Heading size={RADIX.HEADING.SIZE.MD}>{this.state.title}</Heading>
          {this.renderGraph()}
        </Flex>
      </ErrorBoundary>
    );
  }
}
