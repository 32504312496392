export enum MachineModelType {
  DirectBreaks = 'direct_breaks',
  LinearIndependent = 'linear_independent',
  LinearRansac = 'linear_ransac',
  TestModel = 'test_model',
  DevModel = 'dev_model',
}

/** to be used as a tag */
export enum ModelStatus {
  Draft = 'Draft',
  Published = 'Published',
  Unknown = 'Unknown',
}

export enum TagPrefix {
  BallType = 'BallType',
  Status = 'Status',
}

export enum MetricInterval {
  Calibration = 'calibration',
  AdHoc = '0',
  Hourly = '1h',
  Daily = '1d',
  Weekly = '1w',
  Monthly = '1m',
}
