import { Box, Button, Code, Flex, Heading } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTooltip } from 'components/common/tooltip';
import { IMachineModelsContext } from 'contexts/admin/machine-models.context';
import { IMachinesContext } from 'contexts/admin/machines.context';
import { getModelKeyFromModel } from 'lib_ts/classes/ms.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

interface IProps {
  modelID: string;
  machinesCx: IMachinesContext;
  machineModelsCx: IMachineModelsContext;

  /** callback when activated */
  onActivated?: (success: boolean) => void;
}

export class ActivateModelButton extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    const model = this.props.machineModelsCx.models.find(
      (m) => m._id === this.props.modelID
    );

    if (!model) {
      return;
    }

    return (
      <ErrorBoundary componentName="ActivateModelButton">
        <CommonTooltip
          trigger={
            <Button
              size={RADIX.BUTTON.SIZE.SM}
              color={RADIX.COLOR.SUCCESS}
              onClick={() => {
                this.props.machineModelsCx
                  .activateModel({
                    modelID: model._id,
                    machineID: model.machineID,
                  })
                  .then((success) => {
                    if (this.props.onActivated) {
                      this.props.onActivated(success);
                    }
                  })
                  .catch((error) => {
                    console.error(error);

                    if (this.props.onActivated) {
                      this.props.onActivated(false);
                    }
                  });
              }}
            >
              Activate
            </Button>
          }
          content={
            <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
              <Heading size={RADIX.HEADING.SIZE.SM}>Click to activate</Heading>
              <Box>
                Model: <Code>{model.name}</Code>
              </Box>
              <Box>
                Machine: <Code>{model.machineID}</Code>
              </Box>
              <Box>
                Key: <Code>{getModelKeyFromModel(model)}</Code>
              </Box>
            </Flex>
          }
        />
      </ErrorBoundary>
    );
  }
}
