import { Box, Flex, Heading } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonSwitchInput } from 'components/common/form/switch';
import { CommonTextInput } from 'components/common/form/text';
import { CommonTextareaInput } from 'components/common/form/textarea';
import { ITeamsContext } from 'contexts/admin/teams.context';
import { IAuthContext } from 'contexts/auth.context';
import { IFullDialog } from 'interfaces/i-dialogs';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { MlbAbbreviation } from 'lib_ts/enums/batrack.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { League } from 'lib_ts/enums/team.enums';
import { ITeamPermissions } from 'lib_ts/interfaces/i-permissions';
import { ITeam } from 'lib_ts/interfaces/i-team';
import React from 'react';

const COMPONENT_NAME = 'EditTeamDialog';

interface IProps {
  /** undefined means we're creating a new team */
  id?: string;

  authCx: IAuthContext;
  teamsCx: ITeamsContext;
  onClose: () => void;
}

interface IState {
  team: Partial<ITeam>;

  /** convert to/from string[] for save/load */
  email_domains_string: string;

  permissions: Partial<ITeamPermissions>;
}

export class EditTeamDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const found = props.teamsCx.teams.find((m) => m._id === props.id);

    if (props.id && !found) {
      NotifyHelper.error({
        message_md: `Failed to find any team with _id ${props.id}!`,
      });
    }

    const team: Partial<ITeam> = found ?? {
      name: '',
      suppress_data: false,
    };

    /** make a copy to manipulate via forms */
    this.state = {
      team: {
        ...team,
      },

      email_domains_string: (team.email_domains ?? []).join('\n'),

      permissions: team.permissions ?? {},
    };

    this.isNew = this.isNew.bind(this);
    this.renderInputs = this.renderInputs.bind(this);
    this.renderPermissionsInputs = this.renderPermissionsInputs.bind(this);
    this.updateMetadata = this.updateMetadata.bind(this);
  }

  private isNew(): boolean {
    if (this.state.team) {
      return !this.state.team._id;
    } else {
      return true;
    }
  }

  private updateMetadata(model: Partial<ITeam>) {
    this.setState({
      team: {
        ...this.state.team,
        ...model,
      },
    });
  }

  render() {
    const isSuperAdmin = this.props.authCx.current.role === UserRole.admin;

    const mergeProps: IFullDialog = {
      identifier: COMPONENT_NAME,
      width: RADIX.DIALOG.WIDTH.MD,
      title: this.isNew() ? 'Create Team' : 'Update Team',
      loading: this.props.teamsCx.loading,
      content: (
        <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
          {this.renderInputs()}
          {this.renderPermissionsInputs()}
        </Flex>
      ),
      buttons: [
        {
          label: this.isNew() ? 'Create' : 'Update',
          color: RADIX.COLOR.INFO,
          onClick: () => {
            if (!this.state.team.name) {
              NotifyHelper.error({
                message_md: 'Please enter a team name and try again.',
              });
              return;
            }

            if (
              this.props.teamsCx.teams.findIndex(
                (t) =>
                  t.name === this.state.team.name &&
                  t._id !== this.state.team._id
              ) !== -1
            ) {
              NotifyHelper.error({
                message_md: 'Please enter a unique team name and try again.',
              });
              return;
            }

            if (isSuperAdmin && !this.state.team.league) {
              NotifyHelper.error({
                message_md:
                  'Please enter a team league (or select none) and try again.',
              });
              return;
            }

            if (isSuperAdmin && !this.state.team.abbreviation) {
              NotifyHelper.error({
                message_md:
                  'Please enter a team abbreviation (or select none) and try again.',
              });
              return;
            }

            const payload: Partial<ITeam> = {
              ...this.state.team,
              permissions: this.state.permissions,
            };

            if (this.state.email_domains_string !== undefined) {
              /** empty string implies no domains, set value to an empty array */
              payload.email_domains = this.state.email_domains_string
                .split('\n')
                .filter((s) => !!s)
                .map((s) => s.trim());
            }

            if (this.isNew()) {
              this.props.teamsCx
                .create(payload)
                .then(() => this.props.onClose());
              return;
            }

            this.props.teamsCx.update(payload).then(() => this.props.onClose());
          },
        },
      ],
      onClose: () => this.props.onClose(),
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog {...mergeProps} />
      </ErrorBoundary>
    );
  }

  private renderInputs() {
    const isSuperAdmin = this.props.authCx.current.role === UserRole.admin;

    return (
      <CommonFormGrid columns={2}>
        <Box gridColumn="span 2">
          <CommonTextInput
            id="team-name"
            label="Team Name"
            labelIcon={<SuperAdminIcon />}
            placeholder="Type a unique value"
            value={this.state.team.name}
            disabled={!isSuperAdmin}
            onChange={(e) => {
              if (isSuperAdmin) {
                this.updateMetadata({ name: e });
              }
            }}
          />
        </Box>
        <CommonSelectInput
          id="team-league"
          name="league"
          label="League"
          labelIcon={<SuperAdminIcon />}
          options={Object.values(League).map((o) => ({
            label: o,
            value: o,
          }))}
          disabled={!isSuperAdmin}
          value={this.state.team.league}
          onChange={(v) => {
            if (!isSuperAdmin) {
              return;
            }

            this.updateMetadata({
              league: v as League,
              abbreviation:
                v !== League.MajorLeagueBaseball
                  ? MlbAbbreviation.None
                  : this.state.team.abbreviation,
            });
          }}
          optional
        />
        <CommonSelectInput
          id="team-abbreviation"
          name="abbreviation"
          label="Abbreviation"
          labelIcon={<SuperAdminIcon />}
          options={Object.values(MlbAbbreviation).map((o) => ({
            label: o,
            value: o,
          }))}
          disabled={
            !isSuperAdmin ||
            this.state.team.league !== League.MajorLeagueBaseball
          }
          value={this.state.team.abbreviation}
          hint_md="MLB only"
          onChange={(v) => {
            if (!isSuperAdmin) {
              return;
            }

            this.updateMetadata({ abbreviation: v as MlbAbbreviation });
          }}
          optional
        />
        <CommonSelectInput
          id="team-data-aggregation"
          name="suppress_data"
          label="Data Aggregation"
          labelIcon={<SuperAdminIcon />}
          options={[
            {
              label: 'Enabled',
              value: 'false',
            },
            {
              label: 'Disabled',
              value: 'true',
            },
          ]}
          disabled={!isSuperAdmin}
          value={this.state.team.suppress_data?.toString()}
          onChange={(v) => {
            if (!isSuperAdmin) {
              return;
            }

            this.updateMetadata({ suppress_data: v === 'true' });
          }}
          hint_md="Suppresses the team's data in aggregation (e.g. for dashboards)."
          optional
        />
        <Box gridColumn="span 2">
          <CommonTextareaInput
            id="team-email-domains"
            label="Email Domains"
            labelIcon={<SuperAdminIcon />}
            placeholder="e.g. domain.com"
            value={this.state.email_domains_string}
            disabled={!isSuperAdmin}
            rows={5}
            onChange={(value) => {
              if (isSuperAdmin) {
                this.setState({
                  email_domains_string: value ?? '',
                });
              }
            }}
            hint_md={[
              `**Please enter only one domain per line.**`,
              'For assigning newly registered users to this team automatically.',
            ].join('\n\n')}
          />
        </Box>
      </CommonFormGrid>
    );
  }

  private renderPermissionsInputs() {
    const isSuperAdmin = this.props.authCx.current.role === UserRole.admin;
    if (!isSuperAdmin) {
      return;
    }

    return (
      <>
        <Heading size={RADIX.HEADING.SIZE.SM}>Permissions</Heading>

        <CommonFormGrid columns={3}>
          <CommonSwitchInput
            id="team-quick-session"
            name="quick_session"
            label="Quick Session"
            labelIcon={<SuperAdminIcon />}
            defaultChecked={this.state.permissions.quick_session}
            onCheckedChange={(v) =>
              this.setState({
                permissions: {
                  ...this.state.permissions,
                  quick_session: v,
                },
              })
            }
          />

          <Box gridColumn="span 2">
            Team and user access must be granted for users to use Quick Session.
          </Box>

          <CommonSwitchInput
            id="team-mlb-stats"
            name="mlb_stats_api"
            label="MLB Stats API"
            labelIcon={<SuperAdminIcon />}
            defaultChecked={this.state.permissions.mlb_stats_api}
            onCheckedChange={(v) =>
              this.setState({
                permissions: {
                  ...this.state.permissions,
                  mlb_stats_api: v,
                },
              })
            }
          />
          <Box gridColumn="span 2">
            Team access must be granted for users to use features related to the
            MLB Stats API (e.g. browse pitches).
          </Box>
        </CommonFormGrid>
      </>
    );
  }
}
