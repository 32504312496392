import { Box, Button, Heading } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { MISC_URLS } from 'classes/helpers/url.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { SectionBox } from 'components/sections/feature-demo/section-box';
import { RADIX, RadixColor } from 'lib_ts/enums/radix-ui';

const TOAST_COLORS: RadixColor[] = [
  RADIX.COLOR.INFO,
  RADIX.COLOR.WARNING,
  RADIX.COLOR.DANGER,
  RADIX.COLOR.SUCCESS,
];

const notifyFnByColor = (c: RadixColor) => {
  switch (c) {
    case RADIX.COLOR.INFO: {
      return NotifyHelper.info;
    }

    case RADIX.COLOR.WARNING: {
      return NotifyHelper.warning;
    }

    case RADIX.COLOR.DANGER: {
      return NotifyHelper.error;
    }

    case RADIX.COLOR.SUCCESS: {
      return NotifyHelper.success;
    }

    default: {
      return NotifyHelper.primary;
    }
  }
};

export const ToastsDemo = () => (
  <ErrorBoundary componentName="ToastsDemo">
    <SectionBox>
      <Heading size={RADIX.HEADING.SIZE.MD}>Toasts</Heading>

      <CommonFormGrid columns={TOAST_COLORS.length}>
        {
          /** sticky */
          TOAST_COLORS.map((color, index) => (
            <Box key={index}>
              <Button
                className="btn-block"
                color={color}
                onClick={() =>
                  notifyFnByColor(color)({
                    message_md: `Sticky ${color.toUpperCase()} notification message. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
                    delay_ms: 0,
                    inbox: true,
                  })
                }
              >
                Sticky {color.toUpperCase()}
              </Button>
            </Box>
          ))
        }
        {
          /** with buttons */
          TOAST_COLORS.map((color, index) => (
            <Box key={index}>
              <Button
                className="btn-block"
                color={color}
                onClick={() =>
                  notifyFnByColor(color)({
                    message_md: `Notification message with ${color.toUpperCase()} button that does not dismiss the toast.`,
                    buttons: [
                      {
                        label: 'Console Info',
                        color: color,
                        onClick: () =>
                          console.info(
                            `FeatureDemo: you clicked the ${color} button!`
                          ),
                      },
                    ],
                  })
                }
              >
                w/ Button
              </Button>
            </Box>
          ))
        }
        {
          /** with buttons -- auto dismiss */
          TOAST_COLORS.map((color, index) => (
            <Box key={index}>
              <Button
                className="btn-block"
                color={color}
                onClick={() =>
                  notifyFnByColor(color)({
                    message_md: `Notification message with ${color.toUpperCase()} button that also dismisses the toast.`,
                    inbox: true,
                    delay_ms: 0,
                    buttons: [
                      {
                        label: '1st Action',
                        color: color,
                        onClick: () =>
                          console.info(
                            `FeatureDemo: you clicked the 1st ${color} button!`
                          ),
                        dismissAfterClick: true,
                      },
                      {
                        label: '2nd Action',
                        color: color,
                        onClick: () =>
                          console.info(
                            `FeatureDemo: you clicked the 2nd ${color} button!`
                          ),
                        dismissAfterClick: true,
                      },
                    ],
                  })
                }
              >
                w/ Button (Dismiss)
              </Button>
            </Box>
          ))
        }
        {
          /** with links as buttons */
          TOAST_COLORS.map((color, index) => (
            <Box key={index}>
              <Button
                className="btn-block"
                color={color}
                onClick={() =>
                  notifyFnByColor(color)({
                    message_md: `Notification message with ${color.toUpperCase()} [link](${
                      MISC_URLS.TOAST_DEMO
                    }) as button.`,
                    buttons: [
                      {
                        label: 'Open Link',
                        color: color,
                        onClick: () => window.open(MISC_URLS.TOAST_DEMO),
                      },
                    ],
                  })
                }
              >
                w/ Link Button
              </Button>
            </Box>
          ))
        }
        {TOAST_COLORS.map((color, index) => (
          <Box key={index}>
            <Button
              className="btn-block"
              color={color}
              onClick={() =>
                notifyFnByColor(color)({
                  message_md: `Temporary ${color.toUpperCase()} notification message. *Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.*`,
                  delay_ms: 3_000,
                })
              }
            >
              3s {color.toUpperCase()}
            </Button>
          </Box>
        ))}
      </CommonFormGrid>
    </SectionBox>
  </ErrorBoundary>
);
