import { CopyIcon } from '@radix-ui/react-icons';
import { Box, Flex, Heading, IconButton } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CopyPitchesDialogHoC } from 'components/common/dialogs/copy-pitches';
import { ErrorBoundary } from 'components/common/error-boundary';
import { PlateView } from 'components/common/plate-view';
import { CommonVideoPreview } from 'components/common/video-preview';
import { IAimingContext } from 'contexts/aiming.context';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { IHittersContext } from 'contexts/hitters.context';
import { IMachineContext } from 'contexts/machine.context';
import { PitchListContext } from 'contexts/pitch-lists/list.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { IVideosContext } from 'contexts/videos/videos.context';
import { t } from 'i18next';
import {
  GAME_STATUS_DISABLE_ACTION_MSG,
  GameStatus,
} from 'lib_ts/enums/mlb.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IVideoPlayback } from 'lib_ts/interfaces/i-video';
import React from 'react';

const COMPONENT_NAME = 'PitchListSidebar';

interface IProps {
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  videosCx: IVideosContext;
  aimingCx: IAimingContext;
  hittersCx?: IHittersContext;

  // while training, plate view should not be provided any matching context to prevent re-renders as shots come in
  training?: boolean;

  onMatchesChanged: (newPitch: boolean) => void;
  onVideoChanged: (video_id: string | undefined) => void;
}

interface IState {
  dialogSaveAs?: number;
  video_playback?: IVideoPlayback;
}

export class PitchListSidebar extends React.Component<IProps, IState> {
  // store this outside of state to avoid infinite redraws
  private last_video_id?: string;

  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.renderDialog = this.renderDialog.bind(this);
    this.updateVideo = this.updateVideo.bind(this);
  }

  componentDidMount(): void {
    this.updateVideo(this.props.aimingCx.pitch?.video_id);
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (prevProps.aimingCx.pitch?._id !== this.props.aimingCx.pitch?._id) {
      // when the pitch changed, update video to match the latest
      this.updateVideo(this.props.aimingCx.pitch?.video_id);
    }
  }

  private async updateVideo(id?: string) {
    this.last_video_id = id;

    if (!id) {
      this.setState({
        video_playback: undefined,
      });
      return;
    }

    const playback = await this.props.videosCx.getCachedPlayback(id);

    if (id !== this.last_video_id) {
      // handles when user changes pitches rapidly and the playback can't load in time
      return;
    }

    this.setState({
      video_playback: playback,
    });
  }

  private renderDialog() {
    if (!this.state.dialogSaveAs) {
      return;
    }

    const aimed = this.props.aimingCx.getAimed({
      training: false,
      usingShots: [],
    });

    if (!aimed) {
      return;
    }

    return (
      <PitchListContext.Consumer>
        {(listCx) => (
          <CopyPitchesDialogHoC
            key={this.state.dialogSaveAs}
            identifier="PitchListSidebarSavePitchDialog"
            listCx={listCx}
            description={t('pl.complete-form-to-save-pitch-to-list').toString()}
            pitches={[aimed.pitch]}
            onCreated={() => this.setState({ dialogSaveAs: undefined })}
            onClose={() => this.setState({ dialogSaveAs: undefined })}
          />
        )}
      </PitchListContext.Consumer>
    );
  }

  render() {
    const aimed = this.props.aimingCx.getAimed({
      training: false,
      usingShots: [],
    });

    return (
      <AuthContext.Consumer>
        {(authCx) => (
          <ErrorBoundary componentName={COMPONENT_NAME}>
            <Flex
              data-identifier="PLSidebar"
              direction="column"
              gap={RADIX.FLEX.GAP.MD}
            >
              <Flex gap={RADIX.FLEX.GAP.SM} justify="between">
                <Box
                  // allows the pitch name to truncate without pushing save button out of view
                  minWidth="0"
                >
                  <Heading
                    size={RADIX.HEADING.SIZE.MD}
                    className="cursor-help"
                    title={t('pd.batters-pov').toString()}
                    truncate
                  >
                    {t('pl.location-adjustment')}
                  </Heading>
                  <Heading
                    size={RADIX.HEADING.SIZE.SM}
                    color={RADIX.COLOR.SECONDARY}
                    truncate
                  >
                    {aimed?.pitch.name ?? t('common.unnamed-pitch')}
                  </Heading>
                </Box>
                <Box>
                  <IconButton
                    title={t('common.save-as').toString()}
                    color={RADIX.COLOR.NEUTRAL}
                    variant={RADIX.BUTTON.VARIANT.BORDERLESS}
                    onClick={() => {
                      if (authCx.gameStatus === GameStatus.InProgress) {
                        NotifyHelper.warning({
                          message_md: GAME_STATUS_DISABLE_ACTION_MSG,
                        });
                        return;
                      }

                      this.setState({ dialogSaveAs: Date.now() });
                    }}
                  >
                    <CopyIcon />
                  </IconButton>
                </Box>
              </Flex>

              <CookiesContext.Consumer>
                {(cookiesCx) => (
                  <Flex justify="center">
                    <Box>
                      <PlateView
                        cookiesCx={cookiesCx}
                        authCx={authCx}
                        machineCx={this.props.machineCx}
                        matchingCx={this.props.matchingCx}
                        drawShots={!this.props.training}
                        pitch={this.props.aimingCx.pitch}
                        hitter={this.props.hittersCx?.active}
                        onUpdate={(location) =>
                          this.props.aimingCx.setPlate(location)
                        }
                        onMatchesChanged={this.props.onMatchesChanged}
                        border
                      />
                    </Box>
                  </Flex>
                )}
              </CookiesContext.Consumer>

              {aimed && (
                <CommonVideoPreview
                  previewPx={aimed.ms.px}
                  previewPz={aimed.ms.pz}
                  playback={this.state.video_playback}
                  selectConfig={{
                    px: aimed.pitch.bs.px,
                    video_id: aimed.pitch.video_id,
                    onChange: (video_id) => {
                      // will clear the video when X is clicked
                      this.updateVideo(video_id);

                      // parent needs to know (e.g. to update the pitch record in db)
                      this.props.onVideoChanged(video_id);
                    },
                  }}
                />
              )}
            </Flex>

            {this.renderDialog()}
          </ErrorBoundary>
        )}
      </AuthContext.Consumer>
    );
  }
}
