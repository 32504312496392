import { CaretDownIcon, CaretUpIcon } from '@radix-ui/react-icons';
import { Box } from '@radix-ui/themes';
import { TableContext } from 'components/common/table/context';
import { useContext } from 'react';

export const CommonTableSortIcon = (props: {
  colKey: string;
  disabled?: boolean;
}) => {
  const PLACEHOLDER = <></>;

  if (props.disabled) {
    return PLACEHOLDER;
  }

  const tableCx = useContext(TableContext);

  if (props.colKey !== tableCx.sort?.key) {
    return PLACEHOLDER;
  }

  // todo: double check whether the conversion from asc/desc to 1/-1 is correct
  switch (tableCx.sort.dir) {
    case 1: {
      return (
        <Box style={{ marginTop: '2px' }}>
          <CaretUpIcon />
        </Box>
      );
    }

    case -1: {
      return (
        <Box style={{ marginTop: '2px' }}>
          <CaretDownIcon />
        </Box>
      );
    }

    default: {
      return PLACEHOLDER;
    }
  }
};
