import { IOption } from '../interfaces/common/i-option';

/** camera position, i.e. where the camera is located (left hand of hitter, right hand of hitter) when video was recorded */
export enum CamPos {
  LHH = 'LHH',
  CEN = 'CEN',
  RHH = 'RHH',
}

export const CAMERA_POSITIONS: IOption[] = [
  {
    label: 'constants.camera-lhh-box',
    value: CamPos.LHH,
  },
  {
    label: 'constants.camera-center',
    value: CamPos.CEN,
  },
  {
    label: 'constants.camera-rhh-box',
    value: CamPos.RHH,
  },
];

export enum GenerateStadium {
  Default = 'Default',
}
