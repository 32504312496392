import * as Slider from '@radix-ui/react-slider';
import { Flex, SliderProps, Text } from '@radix-ui/themes';
import { CommonInputWrapper } from 'components/common/form/wrapper';
import { IInputWrapper } from 'interfaces/forms/_base';
import { round } from 'lib_ts/classes/math.utilities';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { CommonInputLabel } from '../label';
import './index.scss';

interface IDualSliderInput extends SliderProps, IInputWrapper {
  id: string;
  label?: string;
  roundTo: number;
}

export const CommonDualSlider = (props: IDualSliderInput) => {
  const { label, roundTo, ...sliderProps } = props;

  if (!sliderProps.value) {
    return null;
  }

  return (
    <CommonInputWrapper {...props}>
      <CommonInputLabel {...props} />

      <Slider.Root className="DualSlider" {...sliderProps}>
        <Slider.Track className="DualSliderTrack">
          <Slider.Range className="DualSliderRange" />
        </Slider.Track>
        <Slider.Thumb className="DualSliderThumb" />
        <Slider.Thumb className="DualSliderThumb" />
      </Slider.Root>
      <Flex justify="between">
        <Text size={RADIX.TEXT.SIZE.SM}>
          {round(sliderProps.value[0], roundTo)}
        </Text>
        <Text size={RADIX.TEXT.SIZE.SM}>
          {round(sliderProps.value[1], roundTo)}
        </Text>
      </Flex>
    </CommonInputWrapper>
  );
};
