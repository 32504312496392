import { Box, Code } from '@radix-ui/themes';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { SectionsContext } from 'contexts/sections.context';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitchList } from 'lib_ts/interfaces/pitches';
import React from 'react';

const COMPONENT_NAME = 'DeleteFolderDialog';

interface IProps {
  lists: IPitchList[];
}

export class DeleteFolderDialog extends React.Component<IProps> {
  render() {
    /** used to trigger auto-reset to pitch design section upon success */
    const deleteIDs = this.props.lists.map((l) => l._id);

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <SectionsContext.Consumer>
          {(sectionsCx) => (
            <PitchListsContext.Consumer>
              {(listsCx) => (
                <CommonConfirmationDialog
                  identifier={COMPONENT_NAME}
                  maxWidth={RADIX.DIALOG.WIDTH.MD}
                  title="common.delete"
                  content={
                    <Box>
                      <p>
                        Are you sure you want to delete the following pitch
                        list(s)?
                      </p>

                      {this.props.lists && (
                        <ul>
                          {this.props.lists
                            .sort((a, b) => {
                              if (a.folder === b.folder) {
                                return (a.name ?? '').localeCompare(
                                  b.name ?? ''
                                );
                              }

                              return (a.folder ?? '').localeCompare(
                                b.folder ?? ''
                              );
                            })
                            .map((list, iList) => (
                              <li key={iList}>
                                <Code>{list.folder}/</Code>
                                <Code color={RADIX.COLOR.WARNING}>
                                  {list.name}
                                </Code>
                              </li>
                            ))}
                        </ul>
                      )}
                    </Box>
                  }
                  action={{
                    label: 'common.delete',
                    color: RADIX.COLOR.DANGER,
                    onClick: () => {
                      listsCx.deleteLists(deleteIDs).then((success) => {
                        if (!success) {
                          return;
                        }

                        /** change to different section before active list is set to null */
                        if (
                          listsCx.active &&
                          deleteIDs.includes(listsCx.active._id)
                        ) {
                          sectionsCx.tryGoHome();
                        }
                      });
                    },
                  }}
                />
              )}
            </PitchListsContext.Consumer>
          )}
        </SectionsContext.Consumer>
      </ErrorBoundary>
    );
  }
}
