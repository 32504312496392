import { Box, Flex } from '@radix-ui/themes';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

interface IProps {
  left?: React.ReactNode;
  right?: React.ReactNode;
  vFlex?: boolean;

  // for temporarily hiding the sidebar (and expanding the main content)
  hideSidebar?: boolean;
}

export const CommonContentWithSidebar = (props: IProps) => {
  return (
    <Flex
      data-identifier="CommonContentWithSidebar"
      className={props.vFlex ? RADIX.VFLEX.WRAPPER : undefined}
      gap={RADIX.FLEX.GAP.SIDEBAR}
    >
      <Box
        flexGrow="1"
        data-identifier="CommonContentLeft"
        overflowX="hidden"
        // this being tall doesn't affect the other column
        overflowY="auto"
      >
        {props.left}
      </Box>

      {!props.hideSidebar && (
        <Box
          className="CommonContentRight"
          data-identifier="CommonContentRight"
          overflowX="hidden"
          // this being tall doesn't affect the other column
          overflowY="auto"
        >
          {props.right}
        </Box>
      )}
    </Flex>
  );
};
