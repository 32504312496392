import { ErrorBoundary } from 'components/common/error-boundary';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { AnalyticsHeader } from 'components/sections/analytics/header';
import { SessionsTable } from 'components/sections/analytics/session-history/table';
import { AuthContext } from 'contexts/auth.context';
import { SessionEventsContext } from 'contexts/session-events.context';
import { SessionDialogMode } from 'enums/session.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext } from 'react';

export const SessionHistory = () => {
  const { current } = useContext(AuthContext);
  const { showDialog } = useContext(SessionEventsContext);

  return (
    <ErrorBoundary componentName="SessionHistory">
      <FlexTableWrapper
        gap={RADIX.FLEX.GAP.SECTION}
        header={
          <AnalyticsHeader
            actions={[
              {
                label: 'main.current-session',
                onClick: () =>
                  showDialog({
                    session: current.session,
                    mode: SessionDialogMode.edit,
                    key: Date.now(),
                  }),
              },
            ]}
          />
        }
        table={<SessionsTable />}
      />
    </ErrorBoundary>
  );
};
