import { Text } from '@radix-ui/themes';
import { IInputHint } from 'interfaces/forms/_base';
import { RADIX } from 'lib_ts/enums/radix-ui';
import ReactMarkdown from 'react-markdown';

export const CommonInputHint = (props: IInputHint) => {
  if (!props.hint_md) {
    return <></>;
  }

  return (
    <Text
      color={RADIX.COLOR.HINT}
      size={RADIX.TEXT.SIZE.HINT}
      className="CommonInputHint"
    >
      <ReactMarkdown children={props.hint_md} />
    </Text>
  );
};
