import { IconButton } from '@radix-ui/themes';
import { IButton } from 'interfaces/i-buttons';
import { RADIX } from 'lib_ts/enums/radix-ui';

export const CommonTablePaginationButton = (props: IButton) => (
  <IconButton
    size={RADIX.BUTTON.SIZE.TABLE}
    variant={props.variant ?? 'soft'}
    color={props.color ?? RADIX.COLOR.NEUTRAL}
    disabled={props.disabled}
    title={props.tooltip}
    onClick={props.onClick}
  >
    {props.icon}
  </IconButton>
);
