import { ScrollArea, Table } from '@radix-ui/themes';
import { CommonTableHoC } from 'components/common/table';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSortable } from 'interfaces/tables/sorting';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { useMemo } from 'react';

interface IProps {
  data: any[];
  simple?: boolean;
}

export const PitchUploaderCsvTable = (props: IProps) => {
  const cols = useMemo<ITableColumn[]>(() => {
    const headers = ArrayHelper.unique(
      props.data.flatMap((d) => Object.keys(d))
    );

    return headers.map((k) => {
      const col: ITableColumn = {
        label: k,
        key: k,
      };

      return col;
    });
  }, [props.data]);

  if (props.simple) {
    return (
      <ScrollArea style={{ maxHeight: '400px' }} scrollbars="both">
        <Table.Root>
          <Table.Header>
            <Table.Row>
              {cols.map((col, iCol) => (
                <Table.ColumnHeaderCell key={iCol}>
                  {col.label}
                </Table.ColumnHeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {props.data.map((data, iData) => (
              <Table.Row key={iData}>
                {cols.map((col, iCol) => (
                  <Table.Cell key={iCol}>
                    {col.formatFn?.(data) ?? (data as any)[col.key]}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      </ScrollArea>
    );
  }

  const pagination: ITablePageable = {
    identifier: TableIdentifier.PitchUploaderCsv,
    total: props.data.length,
    enablePagination: true,
  };

  const sort: ITableSortable = {
    enableSort: true,
  };

  return (
    <CommonTableHoC
      id="PitchUploaderCsv"
      displayColumns={cols}
      displayData={props.data}
      {...pagination}
      {...sort}
    />
  );
};
