import { ResetIcon } from '@radix-ui/react-icons';
import { Box, Grid } from '@radix-ui/themes';
import { CommonDetails } from 'components/common/details';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTableButton } from 'components/common/table/button';
import { CheckedContext } from 'contexts/layout/checked.context';
import { MlbBrowseContext } from 'contexts/mlb-browse.context';
import { BatSide, HomeAway, PitchHand } from 'interfaces/i-mlb-browse';
import { HitterSide } from 'lib_ts/enums/hitters.enums';
import { MLB_OUTCOME_CODES } from 'lib_ts/enums/mlb-stats-api/guid-metadata-types.enum';
import {
  PITCH_TYPE_OPTIONS,
  PitcherHand,
  PitchType,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext, useMemo, useState } from 'react';

const COMPONENT_NAME = 'MlbStatsAdditionalFilters';

export const MlbStatsAdditionalFilters = () => {
  const checkedCx = useContext(CheckedContext);
  const browseCx = useContext(MlbBrowseContext);

  const tooltip = useMemo<string | undefined>(() => {
    if (!browseCx.gameFilter.gamePk) {
      return 'Select a game first.';
    }

    if (!browseCx.pitchFilter.isHome) {
      return 'Filters results based on the team that is pitching.';
    }

    if (browseCx.pitchFilter.isHome === 'home') {
      return `Showing results for the home team's pitches.`;
    }

    if (browseCx.pitchFilter.isHome === 'away') {
      return `Showing results for the away team's pitches.`;
    }

    return undefined;
  }, [browseCx.gameFilter, browseCx.pitchFilter]);

  const teamOptions = useMemo(() => {
    const { seasonGames, gameFilter } = browseCx;
    const game = seasonGames.find((g) => g.gamePk === gameFilter.gamePk);

    return [
      {
        label: game ? `${game.home.name} (Home)` : 'Home',
        value: 'home',
      },
      {
        label: game ? `${game.away.name} (Away)` : 'Away',
        value: 'away',
      },
    ];
  }, [browseCx.seasonGames, browseCx.gameFilter]);

  const [resetAllKey, _setResetAllKey] = useState(Date.now());

  const isHomeKey = useMemo(() => Date.now(), [resetAllKey, teamOptions]);
  const pitchHandKey = useMemo(() => Date.now(), [resetAllKey]);
  const batSideKey = useMemo(() => Date.now(), [resetAllKey]);
  const pitchTypeKey = useMemo(() => Date.now(), [resetAllKey]);
  const outcomeTypeKey = useMemo(() => Date.now(), [resetAllKey]);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonDetails summary="Additional Filters" gap={RADIX.FLEX.GAP.SM}>
        <Grid pt={RADIX.FLEX.PAD.SM} columns="6" gap={RADIX.FLEX.GAP.SM}>
          <Box>
            <CommonSelectInput
              key={isHomeKey}
              id="mlb-stats-team"
              name="isHome"
              placeholder="Pitcher Team"
              title={tooltip}
              options={teamOptions}
              value={browseCx.pitchFilter.isHome}
              onChange={(v) => {
                checkedCx.resetChecked();
                browseCx.mergePitchFilter({ isHome: v as HomeAway });
              }}
              disabled={browseCx.loading || !browseCx.gameFilter.gamePk}
              optional
              skipSort
            />
          </Box>
          <Box>
            <CommonSelectInput
              key={pitchHandKey}
              id="mlb-stats-pitcher-hand"
              name="pitchHand"
              placeholder="Pitcher Hand"
              options={[
                { label: PitcherHand.RHP, value: 'R' },
                { label: PitcherHand.LHP, value: 'L' },
              ]}
              value={browseCx.pitchFilter.pitchHand}
              onChange={(v) => {
                checkedCx.resetChecked();
                browseCx.mergePitchFilter({ pitchHand: v as PitchHand });
              }}
              optional
            />
          </Box>
          <Box>
            <CommonSelectInput
              key={batSideKey}
              id="mlb-stats-batter-side"
              name="batSide"
              placeholder="Batter Side"
              options={[
                { label: HitterSide.RHH, value: 'R' },
                { label: HitterSide.LHH, value: 'L' },
              ]}
              value={browseCx.pitchFilter.batSide}
              onChange={(v) => {
                checkedCx.resetChecked();
                browseCx.mergePitchFilter({ batSide: v as BatSide });
              }}
              optional
            />
          </Box>
          <Box>
            <CommonSelectInput
              key={pitchTypeKey}
              id="mlb-stats-pitch-type"
              name="pitchType"
              placeholder="common.pitch-type"
              options={PITCH_TYPE_OPTIONS}
              value={browseCx.pitchFilter.pitchType}
              onChange={(v) => {
                checkedCx.resetChecked();
                browseCx.mergePitchFilter({ pitchType: v as PitchType });
              }}
              optional
            />
          </Box>
          <Box>
            <CommonSearchInput
              key={outcomeTypeKey}
              id="mlb-stats-outcome"
              name="outcomeType"
              placeholder="common.outcome"
              options={MLB_OUTCOME_CODES}
              values={
                browseCx.pitchFilter.outcomeType
                  ? [browseCx.pitchFilter.outcomeType]
                  : []
              }
              onChange={(v) => {
                checkedCx.resetChecked();
                browseCx.mergePitchFilter({ outcomeType: v[0] });
              }}
              optional
            />
          </Box>
          <Box>
            <CommonTableButton
              icon={<ResetIcon />}
              label="common.reset"
              className="btn-block"
              variant="soft"
              color={RADIX.COLOR.NEUTRAL}
              onClick={() => {
                checkedCx.resetChecked();

                // clear the filter values
                browseCx.mergePitchFilter({
                  isHome: undefined,
                  pitchType: undefined,
                  pitchHand: undefined,
                  batSide: undefined,
                  outcomeType: undefined,
                });

                // clear the select inputs
                _setResetAllKey(Date.now());
              }}
            />
          </Box>
        </Grid>
      </CommonDetails>
    </ErrorBoundary>
  );
};
