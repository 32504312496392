import { Flex } from '@radix-ui/themes';

interface IProps {
  children: React.ReactNode;
}

export const SettingForm = (props: IProps) => (
  <Flex direction="column" gap="4">
    {props.children}
  </Flex>
);
