import { TextArea } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { CommonInputLabel } from 'components/common/form/label';
import { CommonTextHint } from 'components/common/form/text-hint';
import { CommonInputWrapper } from 'components/common/form/wrapper';
import { t } from 'i18next';
import { ITextareaInput } from 'interfaces/forms/text';

export const CommonTextareaInput = (props: ITextareaInput) => (
  <CommonInputWrapper {...props}>
    <CommonInputLabel {...props} />

    <TextArea
      className={StringHelper.classNames([
        props.className,
        props.monospace ? 'font-mono' : undefined,
      ])}
      value={props.value}
      disabled={props.disabled}
      color={props.inputColor}
      maxLength={props.maxLength}
      name={props.name}
      onChange={(e) => {
        const v = e.target.value;
        if (props.onChange) {
          props.onChange(v);
        }
      }}
      placeholder={
        props.placeholder ? t(props.placeholder).toString() : undefined
      }
      required={!props.optional}
      rows={props.rows}
      resize="vertical"
    />

    <CommonTextHint {...props} />
  </CommonInputWrapper>
);
