import { DotsVerticalIcon } from '@radix-ui/react-icons';
import { Box, Flex, IconButton, Text } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonMenu } from 'components/common/menu';
import { RootFolder } from 'components/main/sidebar/pitch-lists/root-folder';
import { SectionsContext } from 'contexts/sections.context';
import { DropContainer } from 'enums/dnd.enums';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import { ISidebarFolder } from 'interfaces/i-sidebar';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext } from 'react';

export const FullMenu = (props: {
  folders: ISidebarFolder[];
  mainActions: IMenuAction[];
  getFolderActions: (config: {
    folder: ISidebarFolder;
    container: DropContainer;
  }) => IMenuAction[];
}) => {
  const { tryChangeSection } = useContext(SectionsContext);

  return (
    <ErrorBoundary componentName="FullMenu">
      <Flex direction="column" gap={RADIX.FLEX.GAP.SM} p="2">
        <Flex gap={RADIX.FLEX.GAP.SM} justify="between">
          <Box flexGrow="1">
            <Text
              className="cursor-pointer"
              onClick={() =>
                tryChangeSection({
                  trigger: 'sidebar pitch lists menu',
                  section: SectionName.Pitches,
                  subsection: SubSectionName.Lists,
                })
              }
              as="div"
            >
              {t('main.pitch-lists').toUpperCase()}
            </Text>
          </Box>

          <Box>
            <CommonMenu
              title="Manage Pitch Lists"
              trigger={
                <IconButton variant="ghost" color={RADIX.COLOR.NEUTRAL}>
                  <DotsVerticalIcon />
                </IconButton>
              }
              actions={props.mainActions}
            />
          </Box>
        </Flex>

        {props.folders.map((folder, iFolder) => (
          <RootFolder
            key={iFolder}
            folder={folder}
            getFolderActions={props.getFolderActions}
          />
        ))}
      </Flex>
    </ErrorBoundary>
  );
};
