import { PlateH, PlateOutcome, PlateV } from '../enums/plate.enums';
import { IPlateSummary } from '../interfaces/i-plate-config';
import { DEFAULT_STRIKEZONE, IStrikeZone } from '../interfaces/i-strike-zone';
import { IPitch, IPlateLoc } from '../interfaces/pitches';
import { TrajHelper } from './trajectory.helper';

export class PlateHelper {
  static getPitchSummary = (pitch: IPitch): IPlateSummary => {
    const safePlate =
      pitch.plate_loc_backup ?? TrajHelper.getPlateLoc(pitch.traj);
    return this.getPlateSummary(safePlate);
  };

  static getPlateSummary = (plate: IPlateLoc): IPlateSummary => {
    return this.getSummary({
      hitterPresent: false,
      strikeZone: DEFAULT_STRIKEZONE,
      side_ft: plate.plate_x ?? 0,
      height_ft: plate.plate_z ?? 0,
    });
  };

  private static getSort(
    x: {
      cell: PlateH;
      isOutsideLeft?: boolean;
    },
    z: {
      cell: PlateV;
      isOutsideTop?: boolean;
    }
  ) {
    return 10 * this.getSortZ(z) + this.getSortX(x);
  }

  //left => right
  private static getSortX = (config: {
    cell: PlateH;
    isOutsideLeft?: boolean;
  }) => {
    switch (config.cell) {
      case PlateH.L:
        return 4;
      case PlateH.C:
        return 3;
      case PlateH.R:
        return 2;
      default:
        // outside to the left
        if (config.isOutsideLeft) {
          return 5;
        }

        // outside to the right
        return 1;
    }
  };

  //top => bottom
  private static getSortZ = (config: {
    cell: PlateV;
    isOutsideTop?: boolean;
  }) => {
    switch (config.cell) {
      case PlateV.T:
        return 4;
      case PlateV.M:
        return 3;
      case PlateV.B:
        return 2;
      default:
        // outside to the top
        if (config.isOutsideTop) {
          return 5;
        }

        // outside to the bottom
        return 1;
    }
  };

  static getSummary = (config: {
    hitterPresent: boolean;
    strikeZone: IStrikeZone;
    side_ft: number;
    height_ft: number;
  }): IPlateSummary => {
    if (config.hitterPresent) {
      const MAX_DIFF_WITH_HITTER_FT = 1;

      const extremeX =
        Math.max(
          config.strikeZone.left_ft - config.side_ft,
          config.side_ft - config.strikeZone.right_ft
        ) > MAX_DIFF_WITH_HITTER_FT;

      const extremeY =
        Math.max(
          config.strikeZone.bottom_ft - config.height_ft,
          config.height_ft - config.strikeZone.top_ft
        ) > MAX_DIFF_WITH_HITTER_FT;

      if (extremeX || extremeY) {
        const noDataOutput: IPlateSummary = {
          outcome: PlateOutcome.NoData,
          vertical: PlateV.Unknown,
          horizontal: PlateH.Unknown,
          grid: 'empty',
          grid_x: -1,
          grid_z: -1,
          sort: -1,
        };

        return noDataOutput;
      }
    }

    const outsideV =
      config.height_ft < config.strikeZone.bottom_ft ||
      config.strikeZone.top_ft < config.height_ft;
    const outsideH =
      config.side_ft < config.strikeZone.left_ft ||
      config.strikeZone.right_ft < config.side_ft;

    if (outsideH || outsideV) {
      /**
       * BA_TL   BA_TR
       * BA_BL   BA_BR
       */

      /** T / B */
      const zCell = (() => {
        const half =
          Math.abs(config.strikeZone.top_ft - config.strikeZone.bottom_ft) / 2;
        return config.height_ft > config.strikeZone.bottom_ft + half
          ? PlateV.T
          : PlateV.B;
      })();

      /** R / L */
      const xCell = (() => {
        const half =
          Math.abs(config.strikeZone.right_ft - config.strikeZone.left_ft) / 2;
        return config.side_ft > config.strikeZone.left_ft + half
          ? PlateH.R
          : PlateH.L;
      })();

      const ballOutput: IPlateSummary = {
        outcome: PlateOutcome.Ball,
        vertical: zCell,
        horizontal: xCell,
        grid: `${PlateOutcome.Ball}_${zCell}${xCell}`,
        grid_x: -1,
        grid_z: -1,
        sort: this.getSort(
          {
            cell: outsideH ? PlateH.Unknown : xCell,
            isOutsideLeft: xCell === PlateH.L,
          },
          {
            cell: outsideV ? PlateV.Unknown : zCell,
            isOutsideTop: zCell === PlateV.T,
          }
        ),
      };

      return ballOutput;
    }

    /** B / M / T */
    const zDef = (() => {
      const totalHeight = Math.abs(
        config.strikeZone.top_ft - config.strikeZone.bottom_ft
      );
      const third = totalHeight / 3;
      const limits: { pos: PlateV; min: number }[] = [
        { pos: PlateV.T, min: config.strikeZone.bottom_ft + third * 2 },
        { pos: PlateV.M, min: config.strikeZone.bottom_ft + third },
        { pos: PlateV.B, min: config.strikeZone.bottom_ft },
      ];

      const cell = limits.find((d) => d.min <= config.height_ft);
      return {
        cell: cell ? cell.pos : PlateV.Unknown,
        // percentage from bottom to top
        grid_z: (config.height_ft - config.strikeZone.bottom_ft) / totalHeight,
      };
    })();

    /** L / C / R */
    const xDef = (() => {
      const totalWidth = Math.abs(
        config.strikeZone.right_ft - config.strikeZone.left_ft
      );
      const third = totalWidth / 3;
      const limits: { pos: PlateH; min: number }[] = [
        { pos: PlateH.R, min: config.strikeZone.left_ft + 2 * third },
        { pos: PlateH.C, min: config.strikeZone.left_ft + third },
        { pos: PlateH.L, min: config.strikeZone.left_ft },
      ];

      const cell = limits.find((d) => d.min <= config.side_ft);
      return {
        cell: cell ? cell.pos : PlateH.Unknown,
        // percentage from left to right
        grid_x: (config.side_ft - config.strikeZone.left_ft) / totalWidth,
      };
    })();

    const strikeOutput: IPlateSummary = {
      outcome: PlateOutcome.Strike,
      grid: `${PlateOutcome.Strike}_${zDef.cell}${xDef.cell}`,

      vertical: zDef.cell,
      grid_z: zDef.grid_z,

      horizontal: xDef.cell,
      grid_x: xDef.grid_x,
      sort: this.getSort(
        {
          cell: xDef.cell,
        },
        {
          cell: zDef.cell,
        }
      ),
    };

    return strikeOutput;
  };
}
