import { Box, Text } from '@radix-ui/themes';
import {
  DragHandle,
  DropHandle,
  IDropHandle,
} from 'components/common/drag-drop';
import { ErrorBoundary } from 'components/common/error-boundary';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { SectionsContext } from 'contexts/sections.context';
import { DragItem, DropContainer } from 'enums/dnd.enums';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { ISidebarPitchList } from 'interfaces/i-sidebar';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitchList } from 'lib_ts/interfaces/pitches';
import { useContext } from 'react';

const ENABLE_DRAGGING = false;

interface IProps {
  file: ISidebarPitchList;
  accept: DragItem;
}

export const PitchList = (props: IProps) => {
  const { active: aList, loading, updateList } = useContext(PitchListsContext);
  const { active: aSection, tryChangeSection } = useContext(SectionsContext);

  const safePath = [props.file.pathEnd, props.file.name]
    .filter((s) => !!s)
    .join('/');

  const active =
    aSection.section === SectionName.Pitches &&
    aSection.subsection === SubSectionName.List &&
    aSection.fragments?.[0] === props.file.object._id;

  const content = (
    <Box
      title={safePath}
      pt={RADIX.BOX.PAD.XS}
      pb={RADIX.BOX.PAD.XS}
      pl={RADIX.BOX.PAD.MD}
      pr={RADIX.BOX.PAD.SM}
      data-testid="SidebarPitchList"
      className={loading ? 'cursor-not-allowed' : 'cursor-pointer'}
      onClick={() => {
        if (loading) {
          return;
        }

        if (
          !aList ||
          aList._id !== props.file.object._id ||
          aSection.section !== SectionName.Pitches ||
          aSection.subsection !== SubSectionName.List ||
          aSection.fragments?.[0] !== props.file.object._id
        ) {
          /** move user to pitch list (invisible) section */
          tryChangeSection({
            trigger: 'sidebar, open list',
            section: SectionName.Pitches,
            subsection: SubSectionName.List,
            fragments: [props.file.object._id],
          });
        }
      }}
    >
      <DropHandle
        value={props.file}
        container={DropContainer.PitchList}
        accept={DragItem.Pitch}
      >
        <Text
          color={active ? RADIX.COLOR.ACCENT : undefined}
          // as div + overflow hidden allows truncate to take effect
          as="div"
          truncate
        >
          {props.file.name}
        </Text>
      </DropHandle>
    </Box>
  );

  if (!ENABLE_DRAGGING) {
    return (
      <ErrorBoundary componentName="PitchListItem">{content}</ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary componentName="PitchListItem">
      <DragHandle
        value={props.file.object}
        type={props.accept}
        endFn={(item, monitor) => {
          if (!item) {
            return;
          }

          const target = monitor.getDropResult<IDropHandle>();
          if (!target) {
            return;
          }

          const list: IPitchList = item.value;
          const targetFolder: string = target.value;

          if (typeof targetFolder !== 'string') {
            return;
          }

          if (list.folder === targetFolder) {
            return;
          }

          updateList({
            payload: {
              _id: list._id,
              folder: targetFolder,
            },
            successMsg: `Pitch list "${list.name}" moved to "${targetFolder}" folder!`,
          });
        }}
      >
        {content}
      </DragHandle>
    </ErrorBoundary>
  );
};
