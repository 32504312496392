import { CommonFormGrid } from 'components/common/form/grid';
import { HittersContext } from 'contexts/hitters.context';
import { CheckedContext } from 'contexts/layout/checked.context';
import { useContext, useMemo } from 'react';

export const HitterToolbar = () => {
  const { getInput } = useContext(HittersContext);
  const { resetChecked } = useContext(CheckedContext);

  const sideInput = useMemo(
    () => getInput('side', () => resetChecked()),
    [getInput, resetChecked]
  );

  const levelInput = useMemo(
    () => getInput('level', () => resetChecked()),
    [getInput, resetChecked]
  );

  return (
    <CommonFormGrid columns={4}>
      {sideInput}
      {levelInput}
    </CommonFormGrid>
  );
};
