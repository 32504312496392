export const HELP_URLS = {
  LANDING: '',

  PITCH_UPLOADER: 'articles/8284509-upload-pitches',

  // when user data (e.g. pitch lists) need to be reassigned to other users/machines/teams
  PITCH_REASSIGN: 'articles/9096329-reassign-pitch-lists',

  RAPSODO_HOW_TO: 'articles/8313059-rapsodo-setup',

  /** troubleshooting Rapsodo error messages */
  RAPSODO_ERRORS: 'articles/8321528-rapsodo-errors',

  TRACKMAN_ERRORS: 'articles/9883089-trackman-errors',

  SETTINGS: 'articles/9043471-use-settings-modal',

  SETTINGS_ACCESSORIES: 'articles/8813360-setting-up-and-using-your-foot-pedal',

  SETTINGS_CONTINUOUS_TRAINING: 'articles/9043427-how-to-continuous-training',

  USER_MANUAL: 'articles/9923231-trajekt-user-manual',
};

export const MISC_URLS = {
  /** what gets opened by demo toasts with links */
  TOAST_DEMO: 'https://random.org',
};

export const INTERCOM_ARTICLE_IDS = {
  MACHINE_DISCONNECTED: 8321311,
};
