import { ChevronUpIcon } from '@radix-ui/react-icons';
import { Box, Flex, Text } from '@radix-ui/themes';
import { SidebarHelper } from 'classes/helpers/sidebar.helper';
import { DropHandle } from 'components/common/drag-drop';
import { ErrorBoundary } from 'components/common/error-boundary';
import { Folder } from 'components/main/sidebar/pitch-lists/folder';
import { MachineContext } from 'contexts/machine.context';
import { DropContainer } from 'enums/dnd.enums';
import { IMenuAction } from 'interfaces/i-menus';
import { ISidebarFolder } from 'interfaces/i-sidebar';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext } from 'react';

export const RootFolder = (props: {
  folder: ISidebarFolder;
  getFolderActions: (config: {
    folder: ISidebarFolder;
    container: DropContainer;
  }) => IMenuAction[];
}) => {
  const { machine } = useContext(MachineContext);
  const def = SidebarHelper.getDragDropDef(props.folder);

  return (
    <ErrorBoundary componentName="RootFolder">
      <details className="SidebarPLFolderDetails" open>
        <summary>
          <Flex gap={RADIX.FLEX.GAP.SM} justify="between">
            <Box>
              <DropHandle
                id={def.id}
                container={def.container}
                value={props.folder.pathDisplay}
                accept={def.accept}
              >
                <Text color={RADIX.COLOR.NEUTRAL} truncate>
                  {SidebarHelper.getFolderName(props.folder, machine.machineID)}
                </Text>
              </DropHandle>
            </Box>
            <Flex gap={RADIX.FLEX.GAP.XS} className="SidebarPLFolderActions">
              <ChevronUpIcon className="SidebarPLFolderChevron" />
            </Flex>
          </Flex>
        </summary>

        <Folder
          container={def.container}
          accept={def.accept}
          folder={props.folder}
          getFolderActions={props.getFolderActions}
        />
      </details>
    </ErrorBoundary>
  );
};
