import { ArrowUpIcon, ResetIcon } from '@radix-ui/react-icons';
import { Box } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { RestartMode } from 'lib_ts/enums/admin.enums';
import { FirmwareBranch } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { AdminMachinesService } from 'services/admin/machines.service';

const COMPONENT_NAME = 'UpgradeFirmwareDialog';

interface IProps {
  machineIDs: string[];
  onClose: (success: boolean) => void;
}

export const UpgradeFirmwareDialog = (props: IProps) => {
  const isSingle = props.machineIDs.length === 1;

  const branchOptions = Object.values(FirmwareBranch).map((m) => {
    const o: IOption = {
      label: m.toUpperCase(),
      value: m,
    };

    return o;
  });

  const [branch, setBranch] = useState(FirmwareBranch.CURRENT);

  const hint = useMemo(() => {
    switch (branch) {
      case FirmwareBranch.CURRENT: {
        return 'Machine(s) will not checkout another branch before upgrading.';
      }

      default: {
        return `Machine(s) will checkout the \`${branch.toUpperCase()}\` branch before upgrading.`;
      }
    }
  }, [branch]);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonDialog
        identifier={COMPONENT_NAME}
        title="Upgrade Firmware"
        width={RADIX.DIALOG.WIDTH.MD}
        content={
          <CommonFormGrid columns={1}>
            <Box>
              <ReactMarkdown
                children={[
                  'Each machine will check for any firmware updates and apply them if found.',
                  'Please ensure each machine is not in use before proceeding.',
                  'Each machine will need to be rebooted/restarted after the updates complete.',
                  'Any active or waiting users will be disconnected.',
                  `The following ${
                    isSingle ? 'machine' : 'machines'
                  } will be upgraded:`,
                  props.machineIDs
                    .map((machineID) => ` - ${machineID}`)
                    .join('\n'),
                ].join('\n\n')}
              />
            </Box>
            <CommonSelectInput
              id="firmware-branch"
              name="branch"
              label="Branch"
              options={branchOptions}
              value={branch}
              onChange={(v) => setBranch(v as FirmwareBranch)}
              hint_md={hint}
            />
          </CommonFormGrid>
        }
        buttons={[
          {
            icon: <ResetIcon />,
            label: 'common.restart',
            color: RADIX.COLOR.WARNING,
            tooltip: 'This will restart the Arc process on the machine(s).',
            onClick: () => {
              AdminMachinesService.getInstance().restart(
                RestartMode.arc,
                props.machineIDs
              );
            },
          },
          {
            icon: <ResetIcon />,
            label: 'common.restart-os',
            color: RADIX.COLOR.DANGER,
            tooltip:
              'This will restart the operating system on the machine(s).',
            onClick: () => {
              AdminMachinesService.getInstance().restart(
                RestartMode.os,
                props.machineIDs
              );
            },
          },
          {
            label: 'Upgrade',
            color: RADIX.COLOR.SUCCESS,
            icon: <ArrowUpIcon />,
            onClick: () => {
              AdminMachinesService.getInstance()
                .upgradeFirmware({
                  machineIDs: props.machineIDs,
                  branch: branch,
                })
                .then(() => {
                  NotifyHelper.info({
                    message_md: `Starting firmware upgrade for ${props.machineIDs.join(
                      ', '
                    )}...`,
                  });
                })
                .catch((error) => {
                  console.error(error);
                  NotifyHelper.info({
                    message_md: `Failed to perform firmware upgrade for ${props.machineIDs.join(
                      ', '
                    )}, please check console.`,
                  });
                });
            },
          },
        ]}
        onClose={() => props.onClose(false)}
      />
    </ErrorBoundary>
  );
};
