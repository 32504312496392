import { NotifyHelper } from 'classes/helpers/notify.helper';
import { MAX_SHOTS_USED } from 'classes/plate-canvas';
import { t } from 'i18next';
import { IServerResponse } from 'lib_ts/interfaces/common/i-server-response';
import {
  IAggregateMachineShotsDict,
  IAggregateMachineShotsRequest,
  IArchiveMachineShotsRequest,
  IMachineShot,
  IMatchingShotsDict,
  IMatchingShotsRequest,
} from 'lib_ts/interfaces/training/i-machine-shot';
import { BaseRESTService } from 'services/_base-rest.service';

const MATCHING_SHOTS_LIMIT = MAX_SHOTS_USED + 1;

/** example service for pulling data from server
 * note the singleton definition so we only ever have 1 instance
 */
export class ShotsService extends BaseRESTService {
  private static instance: ShotsService;
  static getInstance(): ShotsService {
    if (!ShotsService.instance) {
      ShotsService.instance = new ShotsService();
    }

    return ShotsService.instance;
  }

  private constructor() {
    super({
      controller: 'shot',
    });
  }

  async updateShot(
    shotID: string,
    payload: Partial<IMachineShot>
  ): Promise<IMachineShot | undefined> {
    return await this.put(
      {
        uri: '',
        params: {
          shot_id: shotID,
        } as any,
      },
      payload
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? t('common.request-failed-msg'),
          });
          return undefined;
        }

        return result.data as IMachineShot;
      })
      .catch((e) => {
        console.error(e);
        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });
        return undefined;
      });
  }

  async archiveMachine(machineID: string): Promise<boolean> {
    return await this.get({ uri: `archive-machine/${machineID}` })
      .then((result) => !!result && result.success)
      .catch((e) => {
        console.error(e);
        return false;
      });
  }

  async archiveShot(shot: IMachineShot): Promise<boolean> {
    return await this.post(
      {
        uri: 'archive-shot',
      },
      shot
    )
      .then((result) => !!result && result.success)
      .catch((e) => {
        console.error(e);
        return false;
      });
  }

  async archiveShots(config: IArchiveMachineShotsRequest): Promise<boolean> {
    return await this.post(
      {
        uri: 'archive-shots',
      },
      config
    )
      .then((result) => !!result && result.success)
      .catch((e) => {
        console.error(e);
        return false;
      });
  }

  async archiveList(id: string): Promise<boolean> {
    return await this.get({
      uri: `archive-list/${id}`,
    })
      .then((result) => !!result && result.success)
      .catch((e) => {
        console.error(e);
        return false;
      });
  }

  /** uses matching_hash where possible, falling back to ms attributes otherwise */
  async getMatches(
    config: IMatchingShotsRequest,
    limit?: number
  ): Promise<IMatchingShotsDict> {
    return await this.post(
      {
        uri: 'matches',
        params: {
          limit: limit ?? MATCHING_SHOTS_LIMIT,
        } as any,
      },
      config
    );
  }

  /** uses matching_hash */
  async getAggregateMatches(
    payload: IAggregateMachineShotsRequest
  ): Promise<IAggregateMachineShotsDict> {
    return await this.post(
      {
        uri: `matches/aggregate`,
      },
      payload
    );
  }
}
