import { SegmentedControl } from '@radix-ui/themes';
import { SectionsContext } from 'contexts/sections.context';
import { t } from 'i18next';
import { ISubSection } from 'interfaces/i-section';

export const SubSectionSelector = (props: { subsections: ISubSection[] }) => {
  return (
    <SectionsContext.Consumer>
      {(sectionsCx) => (
        <SegmentedControl.Root value={sectionsCx.active.subsection}>
          {props.subsections
            .filter((s) => !s.invisible)
            .map((s, i) => (
              <SegmentedControl.Item
                key={`subsection-${i}`}
                value={s.value}
                className="cursor-pointer"
                onClick={() => {
                  sectionsCx.tryChangeSection({
                    section: sectionsCx.active.section,
                    subsection: s.value,
                    trigger: 'SubSectionSelector',
                  });
                }}
              >
                {t(s.label)}
              </SegmentedControl.Item>
            ))}
        </SegmentedControl.Root>
      )}
    </SectionsContext.Consumer>
  );
};
