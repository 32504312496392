import { Box, Flex } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { GameStatusBanner } from 'components/main/game-status-banner';
import { SectionRouterHoC } from 'components/main/section-router';
import { RADIX } from 'lib_ts/enums/radix-ui';

const COMPONENT_NAME = 'Content';

export const Content = () => {
  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Flex
        className={RADIX.VFLEX.WRAPPER}
        justify="center"
        p={RADIX.FLEX.PAD.MAIN}
        // the sidebar's padding already accounts for this side
        pl="0"
      >
        <Flex
          flexGrow="1"
          maxWidth="1700px"
          direction="column"
          className={RADIX.VFLEX.WRAPPER}
          gap={RADIX.FLEX.GAP.MD}
        >
          <GameStatusBanner />

          <Box
            className="MainContentWrapper"
            flexGrow="1"
            overflowY="auto"
            overflowX="hidden"
            pr="2"
          >
            <SectionRouterHoC />
          </Box>
        </Flex>
      </Flex>
    </ErrorBoundary>
  );
};
