export const NPB_TEAMS = [
  'Chiba Lotte Marines',
  'Chunichi Dragons',
  'Fukuoka SoftBank Hawks',
  'Hanshin Tigers',
  'Hiroshima Toyo Carp',
  'Hokkaido Nippon-Ham Fighters',
  'ORIX Buffaloes',
  'Saitama Seibu Lions',
  'Tohoku Rakuten Golden Eagles',
  'Tokyo Yakult Swallows',
  'YOKOHAMA DeNA BAYSTARS',
  'Yomiuri Giants',
];
