import { UpdateIcon, UploadIcon } from '@radix-ui/react-icons';
import { Badge, Code, Grid } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFileUploader } from 'components/common/file-uploader';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonTableHoC } from 'components/common/table';
import { CommonTableButton } from 'components/common/table/button';
import { AdminTabNav } from 'components/sections/admin-portal/tab-nav';
import { GlobalContext } from 'contexts/global.context';
import {
  DirtyForm,
  ISectionsContext,
  SectionsContext,
} from 'contexts/sections.context';
import { IVideosContext, VideosContext } from 'contexts/videos/videos.context';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { SubSectionName } from 'enums/route.enums';
import { CrudAction, TABLES } from 'enums/tables';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ITableSortable } from 'interfaces/tables/sorting';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IVideo } from 'lib_ts/interfaces/i-video';
import React, { useContext } from 'react';

const IDENTIFIER = TableIdentifier.AdminVideoList;

const PAGE_SIZES = TABLES.PAGE_SIZES.MD;

interface IProps {
  videosCx: IVideosContext;
  sectionsCx: ISectionsContext;
}

interface IState extends Partial<ITablePageable> {
  videoProgress: number;
  videoProgressLabel: string;
}

export const VideosTableHoC = () => {
  const videosCx = useContext(VideosContext);
  const sectionsCx = useContext(SectionsContext);

  return <VideosTable videosCx={videosCx} sectionsCx={sectionsCx} />;
};

class VideosTable extends React.Component<IProps, IState> {
  private init = false;

  private fileInput?: CommonFileUploader;

  private readonly BASE_COLUMNS: ITableColumn[] = [
    {
      label: 'Created',
      key: '_created',
      formatFn: (m: IVideo) => {
        const created = parseISO(m._created);
        return format(created, LOCAL_DATETIME_FORMAT, {
          timeZone: LOCAL_TIMEZONE,
        });
      },
    },
    {
      label: 'Filename',
      key: 'VideoFileName',
      formatFn: (m: IVideo) => <Code>{m.VideoFileName}</Code>,
    },
    {
      label: 'Title',
      key: 'VideoTitle',
    },
    {
      label: 'Type',
      key: 'static_type',
      formatFn: (m: IVideo) =>
        m.static_type ? <Badge>{m.static_type}</Badge> : '--',
    },
  ];

  constructor(props: IProps) {
    super(props);

    this.state = {
      videoProgress: 0,
      videoProgressLabel: '',
    };

    this.handleVideoProgress = this.handleVideoProgress.bind(this);
    this.onFilesChange = this.onFilesChange.bind(this);
    this.renderToolbar = this.renderToolbar.bind(this);
  }

  componentDidMount(): void {
    if (this.init) {
      return;
    }

    this.init = true;
  }

  private handleVideoProgress(ev: ProgressEvent) {
    if (ev.total === 0) {
      return;
    }

    const percent = (100 * ev.loaded) / ev.total;

    this.setState({
      videoProgress: percent,
      videoProgressLabel:
        ev.loaded < ev.total
          ? `${percent.toFixed(0)}%`
          : 'Upload complete, processing...',
    });
  }

  /** only triggered by uploader if at least 1 selected file is valid (based on format + size) */
  private async onFilesChange(files: File[]): Promise<void> {
    /** prevent/warn users before leaving if uploads are still processing */
    this.props.sectionsCx.markDirtyForm(DirtyForm.AdminVideos);
    return this.props.videosCx
      .uploadVideos(true, files, this.handleVideoProgress)
      .then(() => {
        /** hide the upload bar after a pause */
        setTimeout(() => {
          this.setState({
            videoProgress: 0,
            videoProgressLabel: '',
          });
        }, 2_000);
      })
      .finally(() =>
        this.props.sectionsCx.clearDirtyForm(DirtyForm.AdminVideos)
      );
  }

  render() {
    const data = this.props.videosCx.staticVideos;

    const pagination: ITablePageable = {
      identifier: IDENTIFIER,
      total: data.length,
      enablePagination: true,
      pageSizes: PAGE_SIZES,
    };

    const sort: ITableSortable = {
      enableSort: true,
      defaultSort: {
        key: '_created',
        dir: 1,
      },
    };

    const select: ITableSelectable = {
      enableSelect: true,
      afterChangeSelected: (m: IVideo | undefined) => {
        if (!m) {
          return;
        }

        this.props.videosCx.openCrudDialog({
          action: CrudAction.Update,
          models: [m],
        });
      },
    };

    return (
      <ErrorBoundary componentName="ErrorTypesTable">
        <FlexTableWrapper
          gap={RADIX.FLEX.GAP.SECTION}
          header={<AdminTabNav active={SubSectionName.StaticVideos} />}
          table={
            <GlobalContext.Consumer>
              {(globalCx) => (
                <CommonTableHoC
                  id="AdminErrorTypes"
                  toolbarContent={this.renderToolbar()}
                  displayColumns={this.BASE_COLUMNS}
                  displayData={data}
                  enableListener={globalCx.dialogs.length === 0}
                  {...pagination}
                  {...select}
                  {...sort}
                  vFlex
                />
              )}
            </GlobalContext.Consumer>
          }
        />

        <CommonFileUploader
          ref={(elem) => (this.fileInput = elem as CommonFileUploader)}
          id="video-uploader"
          acceptedTypes={['video/mp4']}
          progress={this.state.videoProgress}
          progressLabel={this.state.videoProgressLabel}
          notifyMode="aggregate"
          onChange={(files) => this.onFilesChange(files)}
          multiple
          hidden
        />
      </ErrorBoundary>
    );
  }

  private renderToolbar() {
    return (
      <Grid columns="4" gap={RADIX.FLEX.GAP.SM}>
        <CommonTableButton
          label="common.refresh"
          color={RADIX.COLOR.NEUTRAL}
          icon={<UpdateIcon />}
          onClick={this.props.videosCx.refresh}
        />

        <CommonTableButton
          label="Upload"
          icon={<UploadIcon />}
          onClick={() => {
            if (this.fileInput) {
              this.fileInput.handleClick();
            } else {
              console.warn('no file input element');
            }
          }}
        />
      </Grid>
    );
  }
}
