import { NotifyHelper } from 'classes/helpers/notify.helper';
import { MachineContext } from 'contexts/machine.context';
import { SectionsContext } from 'contexts/sections.context';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { IBallChar } from 'lib_ts/interfaces/i-ball-char';
import { IPitch } from 'lib_ts/interfaces/pitches/i-pitch';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { PitchesService } from 'services/pitches.service';

export interface IPitchDesignContext {
  loading: boolean;

  lastUpdated?: number;

  reference?: IPitch;

  // only used by game data subsection
  readonly setReference: (pitch: IPitch) => void;

  // reference becomes the default pitch for the machine
  readonly resetReference: () => void;

  // for forms and editors
  ball?: IBallChar;
  readonly setBall: (ball: IBallChar) => void;
}

const DEFAULT: IPitchDesignContext = {
  loading: false,

  setReference: () => console.debug('not init'),
  resetReference: () => console.debug('not init'),

  setBall: () => console.debug('not init'),
};

export const PitchDesignContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const PitchDesignProvider: FC<IProps> = (props) => {
  const sectionsCx = useContext(SectionsContext);
  const machineCx = useContext(MachineContext);

  const [_loading, _setLoading] = useState(DEFAULT.loading);

  const [_lastUpdated, _setLastUpdated] = useState(DEFAULT.lastUpdated);

  // note: reference py may differ from machine plate distance if the pitch was originally created for a different machine
  const [_reference, _setReference] = useState(machineCx.getDefaultPitch());

  // ball py should always match machine plate distance
  const [_ball, _setBall] = useState(DEFAULT.ball);

  const state: IPitchDesignContext = {
    loading: _loading,

    lastUpdated: _lastUpdated,

    reference: _reference,
    setReference: (pitch) => {
      if (machineCx.activeModel) {
        if (
          !machineCx.activeModel.supports_spins &&
          pitch.priority === BuildPriority.Spins
        ) {
          NotifyHelper.warning({
            message_md: `The pitch definition prioritizes spins but your machine's active model does not support spins.`,
          });
        }

        if (
          !machineCx.activeModel.supports_breaks &&
          pitch.priority === BuildPriority.Breaks
        ) {
          NotifyHelper.warning({
            message_md: `The pitch definition prioritizes breaks but your machine's active model does not support breaks.`,
          });
        }
      }

      _setReference(pitch);
    },

    resetReference: () => {
      _setReference(machineCx.getDefaultPitch());
    },

    ball: _ball,
    setBall: (ball) => {
      _setBall(ball);
    },
  };

  // listen to section changes to automatically get reference pitch
  useEffect(() => {
    if (sectionsCx.active.section !== SectionName.Pitches) {
      return;
    }

    if (sectionsCx.active.subsection !== SubSectionName.Design) {
      return;
    }

    if (
      !sectionsCx.active.fragments ||
      sectionsCx.active.fragments.length === 0
    ) {
      return;
    }

    // attempt to load the pitch by ID
    _setLoading(true);

    PitchesService.getInstance()
      .getOne(sectionsCx.active.fragments[0])
      .then((result) => {
        if (!result) {
          NotifyHelper.error({
            message_md: t('common.request-failed-msg'),
          });
          return;
        }

        _setReference(result);
      })
      .finally(() => _setLoading(false));
  }, [
    sectionsCx.active.section,
    sectionsCx.active.subsection,
    sectionsCx.active.fragments,
  ]);

  // for form inputs to automatically remount
  useEffect(() => {
    if (!_reference) {
      return;
    }

    _setBall(BallHelper.getCharsFromPitch(_reference));
    _setLastUpdated(Date.now());
  }, [_reference]);

  // keep ball py in sync with plate distance
  useEffect(() => {
    if (!_ball) {
      return;
    }

    _setBall({
      ..._ball,
      py: machineCx.machine.plate_distance,
    });
  }, [machineCx.machine.plate_distance]);

  return (
    <PitchDesignContext.Provider value={state}>
      {props.children}
    </PitchDesignContext.Provider>
  );
};
