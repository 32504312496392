import {
  CheckCircledIcon,
  CrossCircledIcon,
  DotsHorizontalIcon,
} from '@radix-ui/react-icons';
import { Box } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { MSDebugDialogHoC } from 'components/machine/dialogs/ms-debug';
import { SidebarNavigationTrigger } from 'components/main/sidebar/nav-trigger';
import { AimingProvider } from 'contexts/aiming.context';
import { AuthContext } from 'contexts/auth.context';
import { MachineContext, MachineDialogMode } from 'contexts/machine.context';
import { t } from 'i18next';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import { SpecialMsPosition } from 'lib_ts/interfaces/machine-msg/i-special-mstarget';
import { useContext, useState } from 'react';
import { WebSocketService } from 'services/web-socket.service';

const COMPONENT_NAME = 'SidebarConnectionButton';

const getLabel = (machine: IMachine): string => {
  return `${machine.machineID}${
    machine.nickname ? ` (${machine.nickname})` : ''
  }`;
};

const ConnectionDisconnected = () => {
  const { reconnectWS } = useContext(AuthContext);
  const { connected, machine, setDialog } = useContext(MachineContext);

  return (
    <ErrorBoundary componentName="ConnectionDisconnected">
      <SidebarNavigationTrigger
        data-testid="ConnectionButton"
        data-status="disconnected"
        data-machineid={machine.machineID}
        icon={<CrossCircledIcon />}
        title={t('common.not-connected-to-x', {
          x: getLabel(machine),
        }).toString()}
        label="common.disconnected"
        color={RADIX.COLOR.DANGER}
        onClick={async () => {
          if (WebSocketService.isConnected()) {
            // app WS is working, open dialog to troubleshoot machine
            setDialog(MachineDialogMode.Disconnected);
            return;
          }

          // app WS is not working, attempt reconnection first
          NotifyHelper.warning({
            message_md: t('common.attempting-to-reconnect-message'),
          });

          reconnectWS();

          // wait 1s to allow for WS to reconnect and update machine context
          setTimeout(() => {
            if (!WebSocketService.isConnected()) {
              NotifyHelper.error({
                message_md: t('common.reconnection-failed-message'),
              });
              return;
            }

            // app WS should be working, open dialog to troubleshoot machine
            if (!connected) {
              setDialog(MachineDialogMode.Disconnected);
            }
          }, 1000);
        }}
      />
    </ErrorBoundary>
  );
};

const ConnectionBusy = () => {
  const { machine, setDialog } = useContext(MachineContext);

  return (
    <ErrorBoundary componentName="ConnectionBusy">
      <SidebarNavigationTrigger
        data-testid="ConnectionButton"
        data-status="busy"
        data-machineid={machine.machineID}
        icon={<DotsHorizontalIcon />}
        label={`${getLabel(machine)} (${t('common.busy')})`}
        color={RADIX.COLOR.WARNING}
        onClick={() => setDialog(MachineDialogMode.RequestControl)}
      />
    </ErrorBoundary>
  );
};

const ConnectionActive = () => {
  const { current } = useContext(AuthContext);
  const { machine, setDialog, specialMstarget, restartArc } =
    useContext(MachineContext);

  const [dialogDebug, setDialogDebug] = useState<number | undefined>();
  const [dialogRestart, setDialogRestart] = useState<number | undefined>();

  return (
    <ErrorBoundary componentName="ConnectionActive">
      <SidebarNavigationTrigger
        data-testid="ConnectionButton"
        data-status="connected"
        data-machineid={machine.machineID}
        icon={<CheckCircledIcon />}
        label={getLabel(machine)}
        color={RADIX.COLOR.SUCCESS}
        actions={[
          {
            label: 'common.view-status',
            onClick: () => setDialog(MachineDialogMode.R2F),
          },
          {
            label: 'common.reset-position',
            onClick: () => specialMstarget(SpecialMsPosition.home),
          },
          {
            label: 'common.restart',
            color: RADIX.COLOR.WARNING,
            onClick: () => setDialogRestart(Date.now()),
          },
          {
            group: '_2',
            label: 'common.health-check',
            invisible: current.role !== UserRole.admin,
            suffixIcon: <SuperAdminIcon />,
            onClick: () => setDialog(MachineDialogMode.HealthCheck),
          },
          {
            group: '_2',
            label: 'common.debug',
            invisible: current.role !== UserRole.admin,
            suffixIcon: <SuperAdminIcon />,
            onClick: () => setDialogDebug(Date.now()),
          },
        ]}
      />

      {dialogDebug && (
        <AimingProvider>
          <MSDebugDialogHoC
            key={dialogDebug}
            identifier="SidebarFooterMsDebugDialog"
            onClose={() => setDialogDebug(undefined)}
          />
        </AimingProvider>
      )}

      {dialogRestart && (
        <CommonConfirmationDialog
          key={dialogRestart}
          identifier="SidebarMenuConfirmRestart"
          title="common.restart"
          content={<Box>{t('common.machine-not-in-use-msg')}</Box>}
          action={{
            onClick: () => restartArc(COMPONENT_NAME),
          }}
        />
      )}
    </ErrorBoundary>
  );
};

export const SidebarConnectionTrigger = () => {
  const { connected, busy } = useContext(MachineContext);

  if (!connected) {
    return <ConnectionDisconnected />;
  }

  if (busy) {
    return <ConnectionBusy />;
  }

  return <ConnectionActive />;
};
