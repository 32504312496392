import { ReactNode } from 'react';

// wrap clicked elements in this to prevent click events from propagating further
export const ClickCatcher = (props: { children: ReactNode }) => (
  <span
    onClick={(e) => e.stopPropagation()}
    onContextMenu={(e) => e.stopPropagation()}
  >
    {props.children}
  </span>
);
