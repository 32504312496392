import { Box, Button, Flex, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { HELP_URLS } from 'classes/helpers/url.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonProgress } from 'components/common/progress';
import { LandingFormWrapper } from 'components/main/landing/forms/wrapper';
import { IAuthContext } from 'contexts/auth.context';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

const LOADING_MS = 1_000;
const PROGRESS_INTERVAL_MS = 50;

enum Step {
  Loading,
  Login,
  Error,
}

interface IProps {
  authCx: IAuthContext;
}

interface IState {
  progress: number;
  step: Step;
}

export class LoginFormAuth0 extends React.Component<IProps, IState> {
  private progressInterval: any;

  constructor(props: IProps) {
    super(props);

    this.state = {
      progress: 0,
      step: Step.Loading,
    };

    this.renderContent = this.renderContent.bind(this);
  }

  componentDidMount(): void {
    clearInterval(this.progressInterval);
    this.progressInterval = setInterval(() => {
      if (this.state.progress === 100 && !this.props.authCx.loading) {
        clearInterval(this.progressInterval);

        setTimeout(() => {
          this.setState({
            step: this.props.authCx.error ? Step.Error : Step.Login,
          });
        }, LOADING_MS / 2);
        return;
      }

      const next = Math.min(
        100,
        this.state.progress + LOADING_MS / PROGRESS_INTERVAL_MS
      );
      if (next === this.state.progress) {
        return;
      }

      this.setState({ progress: next });
    }, PROGRESS_INTERVAL_MS);
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (!prevProps.authCx.error && this.props.authCx.error) {
      const error = this.props.authCx.error;

      console.error({
        event: 'LoginFormAuth0: detected an error',
        error,
      });

      if (error.type === 'verification') {
        NotifyHelper.error({
          message_md: error.message,
          delay_ms: 0,
          buttons: [
            {
              label: 'Troubleshoot',
              onClick: () =>
                window.open(t('common.intercom-url') + HELP_URLS.LANDING),
            },
          ],
        });
      }

      this.setState({
        step: Step.Error,
      });
    }
  }

  componentWillUnmount(): void {
    clearInterval(this.progressInterval);
  }

  private renderContent() {
    if (this.props.authCx.loading || this.state.step === Step.Loading) {
      return (
        <CommonProgress color={RADIX.COLOR.INFO} value={this.state.progress} />
      );
    }

    if (this.state.step === Step.Error) {
      return (
        <Box>
          <Button
            color={RADIX.COLOR.DANGER}
            className="btn-block"
            onClick={() => this.props.authCx.logoutAuth0()}
          >
            Logout
          </Button>
        </Box>
      );
    }

    if (this.state.step === Step.Login) {
      return (
        <CommonFormGrid columns={2}>
          <Box>
            <Button
              color={RADIX.COLOR.SUCCESS}
              className="btn-block"
              onClick={() => this.props.authCx.loginAuth0()}
            >
              Login
            </Button>
          </Box>
          <Box>
            <Button
              className="btn-block"
              onClick={() => this.props.authCx.signupAuth0()}
            >
              Sign Up
            </Button>
          </Box>
        </CommonFormGrid>
      );
    }
  }

  render() {
    const header = (() => {
      if (this.props.authCx.loading) {
        return 'Intializing Auth0 client...';
      }

      if (this.props.authCx.error) {
        return 'There was a problem with your request.';
      }

      return 'Please use Auth0 to authenticate your session.';
    })();

    return (
      <ErrorBoundary componentName="LoginFormAuth0" hideIntercom>
        <LandingFormWrapper header={header}>
          <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
            {this.props.authCx.error && (
              <Box>
                <Text align="center">
                  {this.props.authCx.error.message} (
                  {(this.props.authCx.error.type ?? 'unknown').toUpperCase()}
                  ).
                </Text>
                <Text align="center">Please logout and try again.</Text>
              </Box>
            )}

            {this.renderContent()}
          </Flex>
        </LandingFormWrapper>
      </ErrorBoundary>
    );
  }
}
