import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Box, Text } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { ClickCatcher } from 'components/common/layout/click-catcher';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { RADIX, RadixSide } from 'lib_ts/enums/radix-ui';
import { ReactNode, useMemo, useState } from 'react';

export const SEPARATOR_LABEL = '---SEPARATOR---';

// groups and separates menu actions
export const setupMenuActions = (
  actions: IMenuAction[],
  skipSort?: boolean
) => {
  const groupDict = ArrayHelper.groupBy(
    // prevents groups from being created where no actions would be shown
    actions.filter((a) => !a.invisible),
    'group'
  );

  return Object.keys(groupDict).flatMap((key) => {
    const output: IMenuAction[] = [];

    // insert an entry for the group header (if key is not empty or a separator key starting with _)
    if (key && groupDict[key].length > 0) {
      // print a separator before the group starts
      output.push({
        label: SEPARATOR_LABEL,
        group: key,
        disabled: true,
        onClick: () => {
          // do nothing
        },
      });

      if (!key.startsWith('_')) {
        // this is a regular group name, print it as a disabled option
        output.push({
          label: key,
          group: key,
          disabled: true,
          className: 'MenuGroupLabel',
          onClick: () => {
            // do nothing
          },
        });
      }
    }

    const values = groupDict[key];

    if (!skipSort) {
      // sort options within a group alphabetically
      values.sort((a, b) => a.label.localeCompare(b.label));
    }

    output.push(...values);

    return output;
  });
};

interface IProps {
  trigger: ReactNode;
  actions: IMenuAction[];
  side?: RadixSide;
  sideOffset?: number;

  /** header while using dialog mode */
  title: string;

  skipSort?: boolean;
}

export const CommonMenu = (props: IProps) => {
  const [open, setOpen] = useState(false);

  const actions = useMemo(
    () => setupMenuActions(props.actions, props.skipSort),
    [props.actions, props.skipSort]
  );

  if (actions.length === 0) {
    // to stop typescript from freaking out
    return <>{props.trigger}</>;
  }

  return (
    <ErrorBoundary componentName="CommonMenu">
      <ClickCatcher>
        <DropdownMenu.Root open={open} onOpenChange={(open) => setOpen(open)}>
          <DropdownMenu.Trigger asChild>{props.trigger}</DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content
              className="MenuContent scroll-hover"
              side={props.side ?? 'right'}
              sideOffset={props.sideOffset ?? 4}
            >
              {actions.map((a, i) =>
                a.label === SEPARATOR_LABEL ? (
                  <DropdownMenu.Separator
                    key={i}
                    data-group={a.group}
                    className="MenuSeparator"
                    // avoid starting a menu with a separator
                    hidden={i === 0}
                  />
                ) : (
                  <DropdownMenu.Item
                    key={i}
                    className={StringHelper.classNames([
                      'MenuItem',
                      a.className,
                    ])}
                    data-group={a.group}
                    title={a.tooltip ? t(a.tooltip).toString() : undefined}
                    disabled={a.disabled}
                    onClick={a.onClick}
                  >
                    {a.prefixIcon && (
                      <Box
                        className="valign-center"
                        style={{
                          marginRight: '8px',
                          paddingTop: '2px',
                        }}
                      >
                        <Text
                          color={a.disabled ? RADIX.COLOR.SECONDARY : a.color}
                        >
                          {a.prefixIcon}
                        </Text>
                      </Box>
                    )}

                    <Box className="valign-center">
                      <Text
                        color={a.disabled ? RADIX.COLOR.SECONDARY : a.color}
                        title={t(a.label).toString()}
                        truncate
                      >
                        {t(a.label)}
                      </Text>
                    </Box>

                    {a.suffixIcon && (
                      <Box
                        className="valign-center"
                        style={{
                          marginLeft: '8px',
                          paddingTop: '2px',
                        }}
                      >
                        {a.suffixIcon}
                      </Box>
                    )}
                  </DropdownMenu.Item>
                )
              )}
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </ClickCatcher>
    </ErrorBoundary>
  );
};
