import { ErrorBoundary } from 'components/common/error-boundary';
import { lightFormat, parseISO } from 'date-fns';
import { MODEL_COLORS } from 'enums/charts.enums';
import { MachineMetricHelper } from 'lib_ts/classes/metric.helper';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { IRealMachineMetric } from 'lib_ts/interfaces/modelling/i-real-machine-metric';
import React from 'react';
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

interface IDatum {
  // Epoch time
  date: number;
  datestring: string;
  value: number;
}

const CustomTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  if (!active) {
    return null;
  }

  if (!payload) {
    return null;
  }

  if (payload.length === 0) {
    return null;
  }

  const data: IDatum = payload[0].payload;

  if (!data) {
    return null;
  }

  return (
    <div className="custom-tooltip">
      <h6>{data.datestring}</h6>
      <div>Rating: {data.value.toFixed(2)}%</div>
    </div>
  );
};

interface IProps {
  metrics: IRealMachineMetric[];
}

interface IState {}

export class ModelPerformancePlot extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  render() {
    const data = this.props.metrics
      .filter((m) => m.model_performance)
      .map((m) => {
        if (!m.model_performance) {
          throw new Error(
            `Unexpected model_performance value: ${m.model_performance}`
          );
        }

        const date = parseISO(m.dataFilter.end_date ?? m._created);

        const datum: IDatum = {
          date: date.valueOf(),
          datestring: lightFormat(date, 'yyyy-MM-dd'),
          value:
            Math.round(
              MachineMetricHelper.overallModelPercent(m.model_performance) *
                10_000
            ) / 100,
        };
        return datum;
      });

    return (
      <ErrorBoundary componentName="ModelPerformancePlot">
        <ResponsiveContainer width="100%" height={400}>
          <ScatterChart
            margin={{
              top: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis
              dataKey="date"
              type="number"
              name="Date"
              scale="time"
              domain={[
                MiscHelper.getMin(data.map((d) => d.date)),
                MiscHelper.getMax(data.map((d) => d.date)),
              ]}
              tickFormatter={(date) => lightFormat(new Date(date), 'MM-dd')}
            />

            <YAxis
              dataKey="value"
              type="number"
              name="Rating"
              unit="%"
              domain={[0, 100]}
            />

            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              content={<CustomTooltip />}
              animationDuration={0}
            />

            <Legend />

            <Scatter
              name="Model Performance Over Time"
              data={data.filter((d) => d.value > 0)}
              fill={MODEL_COLORS[0]}
              animationDuration={0}
              line
            />
          </ScatterChart>
        </ResponsiveContainer>
      </ErrorBoundary>
    );
  }
}
