import { Box } from '@radix-ui/themes';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { HittersContext } from 'contexts/hitters.context';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IHitter } from 'lib_ts/interfaces/i-hitter';
import React from 'react';

const COMPONENT_NAME = 'DeleteHittersDialog';

interface IProps {
  hitters: IHitter[];
}

export class DeleteHittersDialog extends React.Component<IProps> {
  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <HittersContext.Consumer>
          {(hittersCx) => (
            <CommonConfirmationDialog
              identifier={COMPONENT_NAME}
              title={t('common.delete-x', {
                x: t(
                  this.props.hitters.length === 1
                    ? 'hitters.hitter'
                    : 'hitters.hitters'
                ),
              }).toString()}
              content={
                <Box>
                  <p>
                    {t('common.confirm-remove-n-x', {
                      n: this.props.hitters.length,
                      x: t(
                        this.props.hitters.length === 1
                          ? 'hitters.hitter'
                          : 'hitters.hitters'
                      ).toLowerCase(),
                    })}
                  </p>
                  <ul>
                    {this.props.hitters.map((v) => (
                      <li key={`del-hitter-${v._id}`}>{v.name}</li>
                    ))}
                  </ul>
                </Box>
              }
              action={{
                label: 'common.delete',
                color: RADIX.COLOR.DANGER,
                invisible: this.props.hitters.length === 0,
                onClick: () =>
                  hittersCx.delete(this.props.hitters.map((v) => v._id)),
              }}
            />
          )}
        </HittersContext.Consumer>
      </ErrorBoundary>
    );
  }
}
