import { NotifyHelper } from 'classes/helpers/notify.helper';
import { IServerResponse } from 'lib_ts/interfaces/common/i-server-response';
import { IVideo } from 'lib_ts/interfaces/i-video';
import { IMlbGame } from 'lib_ts/interfaces/mlb-stats-api/i-game';
import { IGenerateVideoRequest } from 'lib_ts/interfaces/mlb-stats-api/i-generate-video-request';
import { IMlbPitchExt } from 'lib_ts/interfaces/mlb-stats-api/i-pitch';
import { IMlbPlayer } from 'lib_ts/interfaces/mlb-stats-api/i-player';
import { MlbStatsSkeletalData } from 'lib_ts/interfaces/mlb-stats-api/raw-results/game-guids-biomechanics';
import { MlbStatsGameGuidVideo } from 'lib_ts/interfaces/mlb-stats-api/raw-results/game-guids-video';
import { BaseRESTService } from 'services/_base-rest.service';

interface ISeasonTally {
  season: number;
  total: number;
}

export class MlbStatsService extends BaseRESTService {
  private static instance: MlbStatsService;
  static getInstance(): MlbStatsService {
    if (!MlbStatsService.instance) {
      MlbStatsService.instance = new MlbStatsService();
    }

    return MlbStatsService.instance;
  }

  private constructor() {
    super({
      controller: 'mlb-stats',
    });
  }

  async countGamesPerSeason(): Promise<ISeasonTally[]> {
    return await this.get({
      uri: 'seasons/count',
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'Failed to count games per season.',
          });
          return [];
        }

        return result.data as ISeasonTally[];
      })
      .catch((e) => {
        NotifyHelper.error({
          message_md: 'Failed to count games per season.',
        });
        console.error(e);
        return [];
      });
  }

  async getPlayersForGame(gamePk: number | string): Promise<IMlbPlayer[]> {
    return await this.get({
      uri: 'game/players',
      params: {
        gamePk: gamePk,
      } as any,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'Failed to get players for game.',
          });
          return [];
        }

        return result.data as IMlbPlayer[];
      })
      .catch((e) => {
        NotifyHelper.error({
          message_md: 'Failed to get players for game.',
        });
        console.error(e);
        return [];
      });
  }

  async getPitches(config: {
    season: number | string;
    gamePk?: number | string;
    pitcherPk?: number | string;
    batterPk?: number | string;
  }): Promise<IMlbPitchExt[]> {
    return await this.get({
      uri: 'pitches',
      params: {
        season: config.season,
        gamePk: config.gamePk ?? -1,
        pitcherPk: config.pitcherPk ?? -1,
        batterPk: config.batterPk ?? -1,
      } as any,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'Failed to get pitches for game.',
          });
          return [];
        }

        return result.data as IMlbPitchExt[];
      })
      .catch((e) => {
        NotifyHelper.error({
          message_md: 'Failed to get pitches for game.',
        });
        console.error(e);
        return [];
      });
  }

  async getGamePlayVideos(
    gamePk: number | string,
    guid: string
  ): Promise<MlbStatsGameGuidVideo | undefined> {
    return await this.get({
      uri: 'game/play/videos',
      params: {
        gamePk: gamePk,
        guid: guid,
      } as any,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'Failed to get broadcast data.',
          });
          return;
        }

        return result.data as MlbStatsGameGuidVideo;
      })
      .catch((e) => {
        NotifyHelper.error({
          message_md: 'Failed to get broadcast data.',
        });
        console.error(e);
        return undefined;
      });
  }

  async getGamePlaySkeletalData(
    gamePk: number | string,
    guid: string
  ): Promise<MlbStatsSkeletalData | undefined> {
    return await this.get({
      uri: 'game/play/skeletal',
      params: {
        gamePk: gamePk,
        guid: guid,
      } as any,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'Failed to get skeletal data.',
          });
          return undefined;
        }

        return result.data as MlbStatsSkeletalData;
      })
      .catch((e) => {
        NotifyHelper.error({
          message_md: 'Failed to get skeletal data.',
        });
        console.error(e);
        return undefined;
      });
  }

  async makeSkeletalVideo(
    gamePk: number | string,
    guid: string,
    options: IGenerateVideoRequest
  ): Promise<IVideo | undefined> {
    return await this.post(
      {
        uri: 'game/play/make-skeletal-video',
        params: {
          gamePk: gamePk,
          guid: guid,
        } as any,
      },
      options
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'Failed to generate video.',
            delay_ms: 0,
          });
          return;
        }

        return result.data as IVideo;
      })
      .catch((e) => {
        NotifyHelper.error({
          message_md: 'Failed to generate video.',
        });

        console.error(e);
        return undefined;
      });
  }

  async getGamesForSeason(season: number | string): Promise<IMlbGame[]> {
    return await this.get({
      uri: `season/${season}/games`,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'Failed to get games for season.',
          });
          return [];
        }

        return result.data as IMlbGame[];
      })
      .catch((e) => {
        NotifyHelper.error({
          message_md: 'Failed to get games for season.',
        });
        console.error(e);
        return [];
      });
  }

  // sorts them by name
  async getPlayersForSeason(season: number | string): Promise<IMlbPlayer[]> {
    return await this.get({
      uri: `season/${season}/players`,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'Failed to get pitchers for season.',
          });
          return [];
        }

        return (result.data as IMlbPlayer[]).sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      })
      .catch((e) => {
        NotifyHelper.error({
          message_md: 'Failed to get pitchers for season.',
        });
        console.error(e);
        return [];
      });
  }
}
