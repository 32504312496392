import { ErrorLevel } from '../../enums/errors.enums';
import { IMongoBase } from '../mongo/_base';

// fields that will configure the toast to be rendered for the user
export interface IErrorNotification {
  delay_ms: number;

  show_restart: boolean;

  intercom_article_id: number;
}

export interface IErrorType extends IMongoBase, Partial<IErrorNotification> {
  errorID: string;

  type: string;
  category: string;

  level: ErrorLevel;
  resolution?: string[];
  patch?: string[];

  // shown to users
  user_message: string;
  user_message_korean?: string;
  user_message_japanese?: string;

  dev_message?: string;
  // customer support instructions
  cs_message?: string;

  /** when true, server should not forward the error to the user (i.e. no toast will be shown) */
  internal: boolean;

  /** when true, server should make a slack post with the error message + details */
  slack: boolean;
}

// used for pre-filling exports where not everything is provided
export const DEFAULT_ERROR_TYPE: Partial<IErrorType> = {
  errorID: '',
  type: '',
  category: '',
  level: ErrorLevel.warning,
  resolution: [],
  patch: [],
  user_message: '',
  user_message_korean: '',
  user_message_japanese: '',
  dev_message: '',
  cs_message: '',
  internal: false,
  slack: true,
  show_restart: false,
};
