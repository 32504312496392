export interface IBuildMetadata {
  updated: string;
  major: number;
  minor: number;
  build: number;
  patch: number;
  suffix: string;
}

/** format metadata components into string like '1.2.3.4-SUFFIX' */
export const buildVersion = (metadata: IBuildMetadata) => {
  const parts: string[] = [
    [metadata.major, metadata.minor, metadata.build, metadata.patch].join('.'),
    metadata.suffix,
  ].filter((s) => !!s);

  return parts.join('-');
};

export interface IServerUptime {
  /** date string, when server was spun up */
  since: string | Date;

  /** date string, build metadata updated value */
  updated: string | Date;

  /** same format as version from build metadata */
  version: string;
}
