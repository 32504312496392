import { Box, Flex, Text } from '@radix-ui/themes';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ReactNode } from 'react';

// children expected to be something text-like
export const VideoPlaceholder = (props: { children: ReactNode }) => {
  return (
    <Flex
      style={{
        aspectRatio: '1.6',
        backgroundColor: 'var(--gray-9)',
      }}
      align="center"
      justify="center"
    >
      <Box pb="8">
        <Text size="6" color={RADIX.COLOR.SECONDARY}>
          {props.children}
        </Text>
      </Box>
    </Flex>
  );
};
