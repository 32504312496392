import { Box, Button, Flex, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { RouteHelper } from 'classes/helpers/route.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonTextInput } from 'components/common/form/text';
import { CommonProgress } from 'components/common/progress';
import { LandingFormWrapper } from 'components/main/landing/forms/wrapper';
import { IAuthContext } from 'contexts/auth.context';
import { LoginMethod } from 'enums/env';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IResetPasswordPayload } from 'lib_ts/interfaces/common/i-login';
import React from 'react';
import { Navigate } from 'react-router-dom';

const PASSWORD_REQUIREMENTS = {
  MIN_LENGTH: 8,
  CONTAINS_NUMBER: true,
  CONTAINS_SYMBOL: true,
};

const invalidPasswordReasons = (value: string): string[] => {
  const output: string[] = [];

  if (value.match(/\s/)) {
    output.push('not contain any whitespace');
  }

  if (value.length < PASSWORD_REQUIREMENTS.MIN_LENGTH) {
    output.push(
      `contain at least ${PASSWORD_REQUIREMENTS.MIN_LENGTH} characters`
    );
  }

  if (PASSWORD_REQUIREMENTS.CONTAINS_NUMBER && !value.match(/\d/)) {
    output.push('contain at least one number');
  }

  if (PASSWORD_REQUIREMENTS.CONTAINS_SYMBOL && !value.match(/[^a-zA-Z\d\s]/)) {
    output.push(
      'contain at least one special symbol, for example `!@#$%^&*_+-=`'
    );
  }

  return output;
};

interface IProps {
  authCx: IAuthContext;
}

interface IState {
  payload: IResetPasswordPayload;

  /** compared vs state.payload.password for equivalence before allowing submission */
  confirmPassword: string;

  redirect: boolean;
}

export class ResetPasswordForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const query = new URLSearchParams(window.location.search);

    this.state = {
      payload: {
        email: query.get('email')?.replace(' ', '+') ?? '',
        code: query.get('code') ?? '',
        password: '',
      },
      confirmPassword: '',
      redirect: false,
    };

    this.onKeyUp = this.onKeyUp.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.submit = this.submit.bind(this);
    this.goToLogin = this.goToLogin.bind(this);
  }

  private goToLogin() {
    this.setState({ redirect: true });
  }

  private onKeyUp(event: any) {
    if (event.code === 'Enter') {
      this.submit();
    }
  }

  private submit() {
    if (!this.state.payload.password) {
      NotifyHelper.warning({
        message_md: 'Please enter a password and try again.',
      });
      return;
    }

    const reasons = invalidPasswordReasons(this.state.payload.password);
    if (reasons.length > 0) {
      NotifyHelper.warning({
        message_md:
          'Your password must:\n' + reasons.map((m) => ` - ${m}`).join('\n'),
        delay_ms: 0,
        buttons: [
          {
            label: 'common.dismiss',
            onClick: () => {
              // nothing
            },
            dismissAfterClick: true,
          },
        ],
      });
      return;
    }

    if (this.state.confirmPassword !== this.state.payload.password) {
      NotifyHelper.warning({
        message_md: 'Please confirm your password and try again.',
      });
      return;
    }

    this.props.authCx.resetPassword(this.state.payload).then((success) => {
      if (success) {
        NotifyHelper.success({
          message_md:
            'Your password has been reset. You will be redirected to the legacy login page shortly...',
          delay_ms: 0,
          buttons: [
            {
              label: 'Return to login',
              onClick: this.goToLogin,
            },
          ],
        });

        setTimeout(() => {
          this.goToLogin();
        }, 3_000);
      }
    });
  }

  private renderContent() {
    if (!this.state.payload.email || !this.state.payload.code) {
      return (
        <Flex justify="center">
          <Text>Could not parse email and/or code values.</Text>
        </Flex>
      );
    }

    if (this.props.authCx.loading) {
      return <CommonProgress value={80} color={RADIX.COLOR.INFO} />;
    }

    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
        <Box>
          <CommonTextInput
            id="reset-password-email"
            value={this.state.payload.email}
            type="email"
            placeholder="user@trajektsports.com"
            onKeyUp={this.onKeyUp}
            autoComplete="username"
            disabled
            onChange={(v) => {
              this.setState({
                payload: {
                  ...this.state.payload,
                  email: v ?? '',
                },
              });
            }}
          />
        </Box>
        <Box>
          <CommonTextInput
            id="reset-password-password"
            controlId="password"
            value={this.state.payload.password}
            type="password"
            placeholder="New password"
            onKeyUp={this.onKeyUp}
            autoComplete="current-password"
            disabled={this.props.authCx.loading}
            onChange={(v) => {
              this.setState({
                payload: {
                  ...this.state.payload,
                  password: v ?? '',
                },
              });
            }}
          />
        </Box>

        <Box>
          <CommonTextInput
            id="reset-password-confirm"
            controlId="confirm-password"
            value={this.state.confirmPassword}
            type="password"
            placeholder="Confirm new password"
            onKeyUp={this.onKeyUp}
            disabled={this.props.authCx.loading}
            onChange={(v) => {
              this.setState({
                confirmPassword: v ?? '',
              });
            }}
          />
        </Box>

        <CommonFormGrid columns={2}>
          <Box>
            <Button
              color={RADIX.COLOR.INFO}
              className="btn-block"
              disabled={this.props.authCx.loading}
              onClick={this.submit}
            >
              Reset password
            </Button>
          </Box>
          <Box>
            <Button className="btn-block" onClick={this.goToLogin}>
              Return to login
            </Button>
          </Box>
        </CommonFormGrid>
      </Flex>
    );
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={RouteHelper.getSlug([LoginMethod.Legacy])} />;
    }

    const header = this.props.authCx.loading
      ? 'Please wait...'
      : !this.state.payload.email || !this.state.payload.code
      ? 'Invalid reset password URL.'
      : 'Please reset your password for legacy login.';

    return (
      <ErrorBoundary componentName="ResetPasswordForm" hideIntercom>
        <LandingFormWrapper header={header}>
          <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
            {this.renderContent()}
          </Flex>
        </LandingFormWrapper>
      </ErrorBoundary>
    );
  }
}
