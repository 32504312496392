import { CommonSearchInput } from 'components/common/form/search';
import { VideosContext } from 'contexts/videos/videos.context';
import { DELIVERY_TYPES } from 'lib_ts/enums/pitches.enums';
import { useContext } from 'react';

interface IProps {
  onChange?: () => void;
}

export const VideosFilterDelivery = (props: IProps) => {
  const { filter, setFilter } = useContext(VideosContext);

  return (
    <CommonSearchInput
      id="video-delivery"
      placeholder="videos.pitch-delivery-type"
      options={DELIVERY_TYPES}
      values={filter.delivery}
      onChange={(v) => {
        setFilter({ delivery: v });
        props.onChange?.();
      }}
      multiple
      optional
    />
  );
};
