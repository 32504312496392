import { CaretDownIcon } from '@radix-ui/react-icons';
import { Button } from '@radix-ui/themes';
import { CommonMenu } from 'components/common/menu';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import { useMemo } from 'react';

const ActionButton = (props: IMenuAction) => (
  <Button
    title={props.tooltip}
    disabled={props.disabled}
    className={props.className}
    color={props.color}
    variant={props.variant}
    onClick={props.onClick}
  >
    {props.label && t(props.label)}
  </Button>
);

export const SectionHeaderActions = (props: { actions: IMenuAction[] }) => {
  const visible = useMemo(
    () => props.actions.filter((a) => !a.invisible),
    [props.actions]
  );

  if (visible.length === 1) {
    return <ActionButton {...visible[0]} />;
  }

  return (
    <CommonMenu
      title={t('common.actions')}
      side="bottom"
      trigger={
        <Button>
          {t('common.actions')} <CaretDownIcon />
        </Button>
      }
      actions={visible}
      skipSort
    />
  );
};
