import { Box, Flex, Text } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSearchInput } from 'components/common/form/search';
import { SettingButton } from 'components/common/settings-dialog/button';
import { SettingForm } from 'components/common/settings-dialog/form';
import { SettingRow } from 'components/common/settings-dialog/row';
import { IAuthContext } from 'contexts/auth.context';
import { IMachineContext } from 'contexts/machine.context';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';
import { MachinesService } from 'services/machines.service';
import { WebSocketService } from 'services/web-socket.service';

const COMPONENT_NAME = 'MachineSwitchTab';

interface IProps {
  authCx: IAuthContext;
  machineCx: IMachineContext;
  onClose: () => void;
}

interface IState {
  machineOptionsKey: number;
  /** machineIDs to list as options for changing machine */
  machineOptions: string[];

  /** update user machineID to this before triggering authCx token refresh */
  switchMachineID?: string;
}

export class MachineSwitchTab extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    this.state = {
      machineOptionsKey: Date.now(),
      machineOptions: [],
    };

    this.switchMachine = this.switchMachine.bind(this);
  }

  componentDidMount() {
    if (this.init) {
      return;
    }

    this.init = true;

    MachinesService.getInstance()
      .getTeamMachines()
      .then((machines) => {
        const sortedMachineIDs = machines
          .map((m) => m.machineID)
          .sort((a, b) => a.localeCompare(b));

        this.setState({
          machineOptionsKey: Date.now(),
          machineOptions: sortedMachineIDs,
        });
      });
  }

  private async switchMachine() {
    if (!this.state.switchMachineID) {
      return;
    }

    const user = await this.props.authCx.updateUser({
      machineID: this.state.switchMachineID,
    });

    if (!user) {
      return;
    }

    /** close current websocket connection first */
    const wsClient = WebSocketService.getInstance();
    if (wsClient) {
      await wsClient.close();
    }

    /** fetching the new token will provide a new session id since machine has changed */
    const success = await this.props.authCx.refreshToken();
    if (success) {
      this.props.onClose();
    }
  }

  render() {
    const loading = this.props.authCx.loading || this.props.machineCx.loading;

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <SettingForm>
          <SettingRow
            header="settings.switch-machine"
            description={<Text>{t('settings.switch-machine-msg')}</Text>}
            input={
              <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
                <CommonSearchInput
                  key={this.state.machineOptionsKey}
                  id="switch-machineID"
                  name="switchMachineID"
                  disabled={loading}
                  values={
                    this.state.switchMachineID
                      ? [this.state.switchMachineID]
                      : []
                  }
                  options={this.state.machineOptions.map((m) => {
                    return {
                      label: m,
                      value: m,
                      disabled: m === this.props.machineCx.machine.machineID,
                    };
                  })}
                  onChange={(v) => this.setState({ switchMachineID: v[0] })}
                  optional
                />
                <Box>
                  <SettingButton
                    label="settings.switch-machine"
                    disabled={loading}
                    onClick={this.switchMachine}
                  />
                </Box>
              </Flex>
            }
          />
        </SettingForm>
      </ErrorBoundary>
    );
  }
}
