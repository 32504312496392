import { Box } from '@radix-ui/themes';
import { DragItem, DropContainer } from 'enums/dnd.enums';
import { FC, ReactNode } from 'react';
import { useDrop } from 'react-dnd';

export interface IDropValue {
  /** value to be used upon drop event */
  value: any;
  container: any;
}

export interface IDropHandle extends IDropValue {
  /** for differentiating between dropping the same kind of item on different targets */
  container: DropContainer;
}

interface IProps extends IDropHandle {
  id?: string;
  className?: string;

  accept: DragItem;
  children: ReactNode;
}

export const DropHandle: FC<IProps> = (props) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: props.accept,
    drop: (): IDropHandle => ({
      value: props.value,
      container: props.container,
    }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const style = (() => {
    if (isOver && canDrop) {
      /** will drop into this target */
      return {
        color: 'rgba(150,255,150,1)', //brighter green
        fontWeight: 700,
      };
    }

    if (canDrop) {
      /** can drop into this target */
      return {
        color: 'rgba(100,255,100,0.75)', //duller green
      };
    }

    return {
      /** default */
    };
  })();

  return (
    <Box
      ref={drop}
      id={props.id}
      className={`DropHandle ${props.className ?? ''}`}
      style={style}
    >
      {props.children}
    </Box>
  );
};
