export interface ISnapshotCookie {
  // appended to whenever a new snapshot is created
  past_snapshot_ids: string[];

  pitch_id?: string;
}

export const DEFAULT_SNAPSHOT_COOKIE: ISnapshotCookie = {
  past_snapshot_ids: [],
};
