import { IStrikeZone } from 'lib_ts/interfaces/i-strike-zone';
import { IPlateLoc } from 'lib_ts/interfaces/pitches';
import { IShotConfidence } from 'lib_ts/interfaces/training/i-machine-shot';

export class TrainingHelper {
  static isPlateInZone(config: {
    loc: IPlateLoc;
    zone: IStrikeZone;
    buffer: number;
  }): boolean {
    if (config.loc.plate_x > config.zone.right_ft + config.buffer) {
      // outside right
      return false;
    }

    if (config.loc.plate_x < config.zone.left_ft - config.buffer) {
      // outside left
      return false;
    }

    if (config.loc.plate_z > config.zone.top_ft + config.buffer) {
      // outside above
      return false;
    }

    if (config.loc.plate_z < config.zone.bottom_ft - config.buffer) {
      // outside below
      return false;
    }

    return true;
  }

  static isConfidenceSufficient(config: {
    confidence: IShotConfidence;
    minValue: number;
  }): boolean {
    if (config.confidence.PITCH_SpinConfidence < config.minValue) {
      return false;
    }

    if (config.confidence.PITCH_SpeedConfidence < config.minValue) {
      return false;
    }

    if (
      config.confidence.PITCH_StrikePositionHeightConfidence < config.minValue
    ) {
      return false;
    }

    if (
      config.confidence.PITCH_StrikePositionSideConfidence < config.minValue
    ) {
      return false;
    }

    return true;
  }
}
