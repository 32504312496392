import { MS_LIMITS } from '../enums/machine.enums';
import { IMachine } from '../interfaces/i-machine';
import { IMachineState } from '../interfaces/i-machine-state';
import {
  IBallDetailsError,
  IBallState,
  IPitch,
  ITrajectory,
} from '../interfaces/pitches';
import { IWheelAngle } from '../interfaces/pitches/i-base';
import { ArrayHelper } from './array.helper';
import { BallHelper } from './ball.helper';
import { MiscHelper } from './misc.helper';

const FIXED_DECIMALS = 1;
const CHECK_PLATE_DISTANCE = false;
const AUTO_FIX_BS_RELEASE = true;

type MSHashMode = 'full' | 'matching';

const FULL_PRECISION = 100_000;

const DEG2RAD = Math.PI / 180;
const RAD2DEG = 180 / Math.PI;

export class MachineHelper {
  static clipAlphas(input: IMachineState): IMachineState {
    // ensure that we don't mutate the original value
    const ms: IMachineState = {
      ...input,
    };

    if (this.allSafeAngles(ms)) {
      // no changes necessary
      return ms;
    }

    const minW = Math.min(ms.w1, ms.w2, ms.w3);

    const vt = ms.w1 * Math.sin(ms.a1 * DEG2RAD);
    const limitA = vt < 0 ? MS_LIMITS.ALPHAS.MIN : MS_LIMITS.ALPHAS.MAX;

    const safe_vt = minW * Math.sin(limitA * DEG2RAD);

    ms.a1 = Math.asin(safe_vt / ms.w1) * RAD2DEG;
    ms.a2 = Math.asin(safe_vt / ms.w2) * RAD2DEG;
    ms.a3 = Math.asin(safe_vt / ms.w3) * RAD2DEG;

    // update both hashes since alphas changed
    ms.matching_hash = MachineHelper.getMSHash('matching', ms);
    ms.full_hash = MachineHelper.getMSHash('full', ms);

    return ms;
  }

  static needsMSDictHash(pitch: IPitch): boolean {
    if (!pitch.msDict) {
      return false;
    }

    const iHashless = Object.values(pitch.msDict).findIndex((entry) => {
      if (!ArrayHelper.isArray(entry)) {
        const ms = entry as IMachineState;
        return ms && (!ms.full_hash || !ms.matching_hash);
      }

      const noHash = (entry as IMachineState[]).filter(
        (ms) => !ms.full_hash || !ms.matching_hash
      );
      return noHash.length > 0;
    });

    return iHashless !== -1;
  }

  static getMSHash(mode: MSHashMode, ms: IMachineState): string {
    switch (mode) {
      case 'matching': {
        const value = JSON.stringify({
          // +/- 10
          w1: Math.round(ms.w1 * 0.1),
          w2: Math.round(ms.w2 * 0.1),
          w3: Math.round(ms.w3 * 0.1),

          // +/- 0.1
          a1: Math.round(ms.a1 * 10),
          a2: Math.round(ms.a2 * 10),
          a3: Math.round(ms.a3 * 10),

          // +/- 0.1
          qw: Math.round(ms.qw * 10),
          qx: Math.round(ms.qx * 10),
          qy: Math.round(ms.qy * 10),
          qz: Math.round(ms.qz * 10),
        });

        return MiscHelper.hashify(value) ?? 'failed-matching-hash';
      }

      case 'full':
      default: {
        const value = JSON.stringify({
          ball_type: ms.ball_type,
          w1: Math.round(ms.w1 * FULL_PRECISION),
          w2: Math.round(ms.w2 * FULL_PRECISION),
          w3: Math.round(ms.w3 * FULL_PRECISION),
          a1: Math.round(ms.a1 * FULL_PRECISION),
          a2: Math.round(ms.a2 * FULL_PRECISION),
          a3: Math.round(ms.a3 * FULL_PRECISION),
          qw: Math.round(ms.qw * FULL_PRECISION),
          qx: Math.round(ms.qx * FULL_PRECISION),
          qy: Math.round(ms.qy * FULL_PRECISION),
          qz: Math.round(ms.qz * FULL_PRECISION),

          // additional fields
          px: Math.round(ms.px * FULL_PRECISION),
          py: Math.round(ms.py * FULL_PRECISION),
          pz: Math.round(ms.pz * FULL_PRECISION),
          tilt: Math.round(ms.tilt * FULL_PRECISION),
          yaw: Math.round(ms.yaw * FULL_PRECISION),
        });
        return MiscHelper.hashify(value) ?? 'failed-full-hash';
      }
    }
  }

  // assumption is everything is already aimed before this is triggered within sendTarget
  static getSendTargetErrors(config: {
    bs: IBallState;
    traj: ITrajectory;
    ms: IMachineState;
    plate_distance: number;
  }): IBallDetailsError[] {
    const FN_NAME = 'MachineHelper.getSendTargetErrors';

    const MAX_ERROR_DIGITS = 1;
    const MAX_ERROR_FT = Math.pow(10, -MAX_ERROR_DIGITS);

    const output: IBallDetailsError[] = [];

    const matchingPx = [config.bs.px, config.traj.px, config.ms.px].every(
      (v) => v === config.bs.px
    );

    if (!matchingPx) {
      output.push({
        msg: `Mismatched horizontal position value(s).`,
        fix: {
          description: 'Please check pitch details and try again.',
        },
      });
    }

    const matchingPz = [config.bs.pz, config.traj.pz, config.ms.pz].every(
      (v) => v === config.bs.pz
    );

    if (!matchingPz) {
      output.push({
        msg: `Mismatched vertical position value(s).`,
        fix: {
          description: 'Please check pitch details and try again.',
        },
      });
    }

    const matchingPy = [config.bs.py, config.traj.py, config.ms.py].every(
      (v) => Math.abs(v - config.plate_distance) <= MAX_ERROR_FT
    );

    if (!matchingPy) {
      output.push({
        msg: `Mismatch between pitch distance and machine plate distance.`,
        fix: {
          description: 'Please check machine settings and try again.',
        },
      });
    }

    /** if distance is fine, apply final adjustments to mstarget before sending */
    if (!matchingPx || !matchingPz || !matchingPy) {
      console.warn({
        event: `${FN_NAME}: detected incorrect px, pz, and/or py`,

        plate_distance: config.plate_distance,

        bs: {
          px: config.bs.px,
          pz: config.bs.pz,
          py: config.bs.py,
        },

        traj: {
          px: config.traj.px,
          pz: config.traj.pz,
          py: config.traj.py,
        },

        ms: {
          px: config.ms.px,
          pz: config.ms.pz,
          py: config.ms.py,
        },

        ms_sub_bs: {
          px: config.ms.px - config.bs.px,
          pz: config.ms.pz - config.bs.pz,
          py: config.ms.py - config.bs.py,
        },

        ms_sub_traj: {
          px: config.ms.px - config.traj.px,
          pz: config.ms.pz - config.traj.pz,
          py: config.ms.py - config.traj.py,
        },
      });
    }

    output.push(
      ...this.checkBallDetails({
        ms: config.ms,
        plate_distance: config.plate_distance,
      })
    );

    return output;
  }

  static allSafeAngles(angles: IWheelAngle): boolean {
    return [angles.a1, angles.a2, angles.a3].every(
      (a) => a >= MS_LIMITS.ALPHAS.MIN && a <= MS_LIMITS.ALPHAS.MAX
    );
  }

  static checkBallDetails(config: {
    ms: IMachineState;
    plate_distance: number;
  }): IBallDetailsError[] {
    const output: IBallDetailsError[] = [];

    try {
      checkRelease();
      checkGyro();
      checkYaw();
      checkQuaternion();
    } catch (e) {
      console.error(e);
    }

    return output;

    function checkYaw() {
      if (config.ms.yaw > MS_LIMITS.YAW.MAX) {
        output.push({
          msg: 'Machine angle limit reached. Please move the plate location towards 3B or move the release position towards 1B.',
          fix: {
            description:
              'Move the plate location towards 3B or move the release position towards 1B.',
          },
        });
      } else if (config.ms.yaw < MS_LIMITS.YAW.MIN) {
        output.push({
          msg: 'Machine angle limit reached. Please move the plate location towards 1B or move the release position towards 3B.',
          fix: {
            description:
              'Move the plate location towards 1B or move the release position towards 3B.',
          },
        });
      }
    }

    function checkGyro() {
      if (!MachineHelper.allSafeAngles(config.ms)) {
        // shouldn't trigger anymore
        output.push({
          msg: 'There is too much gyro spin.',
          fix: {
            // reloading the pitches should trigger the server to rebuild with acceptable alphas
            description: 'Please reload the pitch and try again.',
          },
        });
      }

      /** tilt */
      if (config.ms.tilt > MS_LIMITS.TILT.MAX) {
        output.push({
          msg: 'Tilt angle too high. Please lower the plate location or raise the release position.',
          fix: {
            description:
              'Lower the plate location or raise the release position.',
          },
        });
      } else if (config.ms.tilt < MS_LIMITS.TILT.MIN) {
        output.push({
          msg: 'Tilt angle too low. Please raise the plate location or lower the release position.',
          fix: {
            description:
              'Raise the plate location or lower the release position.',
          },
        });
      }
    }

    function checkRelease() {
      /** plate distance */
      if (CHECK_PLATE_DISTANCE) {
        if (config.ms.py < MS_LIMITS.PITCH_PLATE_DISTANCE.MIN) {
          const TARGET = MS_LIMITS.PITCH_PLATE_DISTANCE.MIN;
          output.push({
            msg: `The distance to the plate must be at least ${TARGET.toFixed(
              FIXED_DECIMALS
            )}ft.`,
            fix: {
              description: `Increase distance to the plate from ${config.ms.py.toFixed(
                FIXED_DECIMALS
              )}ft to ${TARGET.toFixed(FIXED_DECIMALS)}ft.`,
              autoFixFn: (m) => ({ ...m, py: TARGET }),
            },
          });
        } else if (config.ms.py > MS_LIMITS.PITCH_PLATE_DISTANCE.MAX) {
          const TARGET = MS_LIMITS.PITCH_PLATE_DISTANCE.MAX;
          output.push({
            msg: `The distance to the plate must be at most ${TARGET.toFixed(
              FIXED_DECIMALS
            )}ft.`,
            fix: {
              description: `Decrease distance to the plate from ${config.ms.py.toFixed(
                FIXED_DECIMALS
              )}ft to ${TARGET.toFixed(FIXED_DECIMALS)}ft.`,
              autoFixFn: (m) => ({ ...m, py: TARGET }),
            },
          });
        }
      }

      /** release side */
      if (config.ms.px < MS_LIMITS.POSITION.X.MIN) {
        const TARGET = MS_LIMITS.POSITION.X.MIN;
        output.push({
          msg: `The release X (side to side) position must be at least ${TARGET.toFixed(
            FIXED_DECIMALS
          )}ft.`,
          fix: {
            description: `Increase release X from ${config.ms.px.toFixed(
              FIXED_DECIMALS
            )}ft to ${TARGET.toFixed(FIXED_DECIMALS)}ft.`,
            autoFixFn: AUTO_FIX_BS_RELEASE
              ? (m) => ({
                  ...m,
                  px: TARGET,
                  py: config.plate_distance,
                })
              : undefined,
            autoFixMsFn: (m) => ({ ...m, px: TARGET }),
          },
        });
      } else if (config.ms.px > MS_LIMITS.POSITION.X.MAX) {
        const TARGET = MS_LIMITS.POSITION.X.MAX;
        output.push({
          msg: `The release X (side to side) position must be at most ${TARGET.toFixed(
            FIXED_DECIMALS
          )}ft.`,
          fix: {
            description: `Decrease release X from ${config.ms.px.toFixed(
              FIXED_DECIMALS
            )}ft to ${TARGET.toFixed(FIXED_DECIMALS)}ft.`,
            autoFixFn: AUTO_FIX_BS_RELEASE
              ? (m) => ({
                  ...m,
                  px: TARGET,
                  py: config.plate_distance,
                })
              : undefined,
            autoFixMsFn: (m) => ({ ...m, px: TARGET }),
          },
        });
      }

      /** release height */
      if (config.ms.pz < MS_LIMITS.POSITION.Z.MIN) {
        const TARGET = MS_LIMITS.POSITION.Z.MIN;
        output.push({
          msg: `The release Z (height) position must be at least ${TARGET.toFixed(
            FIXED_DECIMALS
          )}ft.`,
          fix: {
            description: `Increase release Z from ${config.ms.pz.toFixed(
              FIXED_DECIMALS
            )}ft to ${TARGET.toFixed(FIXED_DECIMALS)}ft.`,
            autoFixFn: AUTO_FIX_BS_RELEASE
              ? (m) => ({
                  ...m,
                  pz: TARGET,
                  py: config.plate_distance,
                })
              : undefined,
            autoFixMsFn: (m) => ({ ...m, pz: TARGET }),
          },
        });
      } else if (config.ms.pz > MS_LIMITS.POSITION.Z.MAX) {
        const TARGET = MS_LIMITS.POSITION.Z.MAX;
        output.push({
          msg: `The release Z (height) position must be at most ${TARGET.toFixed(
            FIXED_DECIMALS
          )}ft.`,
          fix: {
            description: `Decrease release Z from ${config.ms.pz.toFixed(
              FIXED_DECIMALS
            )}ft to ${TARGET.toFixed(FIXED_DECIMALS)}ft.`,
            autoFixFn: AUTO_FIX_BS_RELEASE
              ? (m) => ({
                  ...m,
                  pz: TARGET,
                  py: config.plate_distance,
                })
              : undefined,
            autoFixMsFn: (m) => ({ ...m, pz: TARGET }),
          },
        });
      }
    }

    function checkQuaternion() {
      const magnitude = BallHelper.getOrientationNorm(config.ms);
      const diff = Math.abs(magnitude - 1);
      if (diff > 0.00001) {
        output.push({
          msg: `Invalid quaternion magnitude: ${magnitude} (should be ~1).`,
          fix: {
            description:
              'Please check seam latitude, seam longitude, and spin values.',
          },
        });
      }
    }
  }

  static getErrors(
    m: Partial<IMachine>,
    ignoreSuperAdminChecks = false
  ): string[] {
    const errors: string[] = [];

    MachineHelper.checkCompleteness(m, errors);
    MachineHelper.checkValidity(m as IMachine, errors);

    if (!ignoreSuperAdminChecks) {
      MachineHelper.checkSuperAdmin(m, errors);
    }

    return errors;
  }

  private static checkCompleteness(m: Partial<IMachine>, errors: string[]) {
    try {
      if (!m._parent_id) {
        errors.push('Parent is not defined');
      }

      if (!(m.machineID ?? '').trim()) {
        errors.push('MachineID is not defined');
      }

      if (!(m.rapsodo_serial ?? '').trim()) {
        errors.push('Rapsodo Serial is not defined');
      }

      if (!m.ball_type) {
        errors.push('Ball type is not defined');
      }

      if (m.training_threshold === undefined || isNaN(m.training_threshold)) {
        errors.push('Training threshold is not defined');
      }

      if (m.plate_distance === undefined || isNaN(m.plate_distance)) {
        errors.push('Plate distance is not defined');
      }
    } catch (e) {
      errors.push(
        e instanceof Error
          ? e.message
          : 'Unknown error while checking machine completeness'
      );
    }
  }

  /** the following can only be corrected by super admins */
  private static checkSuperAdmin(m: Partial<IMachine>, errors: string[]) {
    try {
      if (!m.ball_type_options || m.ball_type_options.length === 0) {
        errors.push('No ball types enabled');
      }

      const assignedKeys = Object.keys(m.model_ids ?? {}).filter(
        (k) => !!(m.model_ids ?? {})[k]
      );
      const unassignedBallTypes = (m.ball_type_options ?? []).filter(
        (bt) => assignedKeys.filter((k) => k.includes(bt)).length === 0
      );

      unassignedBallTypes.forEach((bt) =>
        errors.push(`No model assigned for ${bt}`)
      );
    } catch (e) {
      errors.push(
        e instanceof Error
          ? e.message
          : 'Unknown error while checking super admin details'
      );
    }
  }

  private static checkValidity(m: IMachine, errors: string[]) {
    try {
      if (m.training_threshold <= 0) {
        errors.push('Training threshold must be positive');
      }

      if (m.plate_distance < MS_LIMITS.PITCH_PLATE_DISTANCE.MIN) {
        errors.push(
          `Plate distance (${m.plate_distance}') is less than the min value (${MS_LIMITS.PITCH_PLATE_DISTANCE.MIN}')`
        );
      }

      if (m.plate_distance > MS_LIMITS.PITCH_PLATE_DISTANCE.MAX) {
        errors.push(
          `Plate distance (${m.plate_distance}') is greater than the max value (${MS_LIMITS.PITCH_PLATE_DISTANCE.MAX}')`
        );
      }
    } catch (e) {
      errors.push(
        e instanceof Error
          ? e.message
          : 'Unknown error while checking machine validity'
      );
    }
  }
}
