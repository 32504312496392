import { Box, Flex, Separator, Text } from '@radix-ui/themes';
import { CommonDialog } from 'components/common/dialogs';
import {
  DragHandle,
  DropHandle,
  IDropHandle,
} from 'components/common/drag-drop';
import { ErrorBoundary } from 'components/common/error-boundary';
import { DragItem, DropContainer } from 'enums/dnd.enums';
import {
  DEFAULT_ACCEPT_BTN,
  IBaseDialog,
  IFullDialog,
} from 'interfaces/i-dialogs';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import React from 'react';
import './index.scss';

const COMPONENT_NAME = 'CommonReorderDialog';

interface IProps extends IBaseDialog {
  items: IOption[];

  // provided function should also close the dialog if successful
  onReorder: (items: IOption[]) => void;
}

interface IState {
  orderedItems: IOption[];
}

export class CommonReorderDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      orderedItems: [...this.props.items],
    };

    this.renderContent = this.renderContent.bind(this);
  }

  render() {
    const mergeProps: IFullDialog = {
      identifier: this.props.identifier,
      width: RADIX.DIALOG.WIDTH.MD,
      title: 'common.reorder-items',
      content: this.renderContent(),
      buttons: [
        {
          ...DEFAULT_ACCEPT_BTN,
          onClick: () => {
            this.props.onReorder(this.state.orderedItems);
          },
        },
      ],
      onClose: this.props.onClose,
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog {...mergeProps} />
      </ErrorBoundary>
    );
  }

  private renderContent() {
    const total = this.state.orderedItems.length;

    return (
      <Flex
        className="ReorderList"
        direction="column"
        gap={RADIX.FLEX.GAP.SM}
        overflowY="auto"
        overflowX="hidden"
        maxHeight={RADIX.DIALOG.HEIGHT.LG}
      >
        {this.state.orderedItems.map((item, i) => (
          <React.Fragment key={`g-${i}`}>
            <Box key={`drop-${i}`}>
              <DropHandle
                value={i}
                container={DropContainer.Reorder}
                accept={DragItem.Reorder}
              >
                <Box className="ReorderLineWrapper" pt="2" pb="2">
                  <Separator className="ReorderLine" size="4" />
                </Box>
              </DropHandle>
            </Box>
            <Box key={`drag-${i}`}>
              <DragHandle
                value={i}
                type={DragItem.Reorder}
                endFn={(item, monitor) => {
                  // todo: lodash can probably do this better
                  const target = monitor.getDropResult<IDropHandle>();
                  if (!item) {
                    return;
                  }

                  if (!target) {
                    return;
                  }

                  const itemIndex = parseInt(item.value);
                  const targetIndex = parseInt(target.value);

                  const items = [...this.state.orderedItems];

                  /** only rearrange if indices don't match */
                  if (itemIndex === targetIndex) {
                    return;
                  }

                  /** remove item from list */
                  const removed = items.splice(itemIndex, 1)[0];

                  /** re-insert item, account for whether removed item would've shifted target or not */
                  items.splice(
                    itemIndex < targetIndex ? targetIndex - 1 : targetIndex,
                    0,
                    removed
                  );

                  /** update ordered items */
                  this.setState({ orderedItems: items });
                }}
              >
                <Flex gap={RADIX.FLEX.GAP.SM} justify="between">
                  <Box flexGrow="1">
                    <Text truncate>{item.label}</Text>
                  </Box>
                  <Box>
                    {i + 1} / {total}
                  </Box>
                </Flex>
              </DragHandle>
            </Box>
          </React.Fragment>
        ))}

        <Box pb="2">
          <DropHandle
            value={total}
            container={DropContainer.Reorder}
            accept={DragItem.Reorder}
          >
            <Separator size="4" />
          </DropHandle>
        </Box>
      </Flex>
    );
  }
}
