import { Box, Heading } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextareaInput } from 'components/common/form/textarea';
import { IMachinesContext } from 'contexts/admin/machines.context';
import { ITeamsContext } from 'contexts/admin/teams.context';
import { IUsersContext } from 'contexts/admin/users.context';
import { IAuthContext } from 'contexts/auth.context';
import { DEFAULT_ACCEPT_BTN, IBaseDialog } from 'interfaces/i-dialogs';
import { safeNumber } from 'lib_ts/classes/math.utilities';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { RADIX, RadixColor } from 'lib_ts/enums/radix-ui';
import {
  IAnnouncement,
  IWSUserFilter,
} from 'lib_ts/interfaces/common/i-announcement';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import React from 'react';
import { AdminUsersService } from 'services/admin/users.service';

const MAX_LENGTH = 2000;

interface IProps extends IBaseDialog {
  authCx: IAuthContext;
  teamsCx: ITeamsContext;
  machinesCx: IMachinesContext;
  usersCx: IUsersContext;

  defaultModel?: Partial<IAnnouncement>;
}

interface IState {
  loading: boolean;
  model: IAnnouncement;
  roleOptions: IOption[];
  teamOptions: IOption[];
}

/** for admins to send announcements to other users */
export class AnnouncementDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      loading: false,
      model: {
        filter: {
          // prevent team admins from announcing to anyone but their own team
          team_id: props.authCx.current.teamID,
          ...props.defaultModel?.filter,
        },
        message: props.defaultModel?.message ?? '',
      },
      roleOptions: [
        {
          label: 'Admin',
          value: UserRole.admin,
          disabled: props.authCx.current.role !== UserRole.admin,
        },
        {
          label: 'Team Admin',
          value: UserRole.team_admin,
        },
        {
          label: 'Basic User',
          value: UserRole.basic,
        },
      ].filter((o) => !o.disabled),

      teamOptions: this.props.teamsCx.teams
        .map((t) => ({
          label: t.name,
          value: t._id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    };

    this.renderForm = this.renderForm.bind(this);
    this.updateModel = this.updateModel.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
  }

  private updateModel(value: Partial<IAnnouncement>) {
    this.setState({
      model: {
        ...this.state.model,
        ...value,
      },
    });
  }

  private updateFilter(value: Partial<IWSUserFilter>) {
    this.setState({
      model: {
        ...this.state.model,
        filter: {
          ...this.state.model.filter,
          ...value,
        },
      },
    });
  }

  render() {
    return (
      <ErrorBoundary componentName="AnnouncementDialog">
        <CommonDialog
          identifier={this.props.identifier}
          title="Create Announcement"
          description="Use the form to send an announcement to users."
          width={RADIX.DIALOG.WIDTH.LG}
          content={this.renderForm()}
          buttons={[
            {
              ...DEFAULT_ACCEPT_BTN,
              onClick: () => {
                if (!this.state.model.message) {
                  NotifyHelper.warning({
                    message_md:
                      'Please provide a non-empty message and try again.',
                  });
                  return;
                }

                AdminUsersService.getInstance()
                  .postAnnouncement(this.state.model)
                  .then((result) => {
                    if (!result.success) {
                      NotifyHelper.warning({
                        message_md:
                          result.error ?? 'Unknown error sending announcement.',
                      });
                      return;
                    }

                    NotifyHelper.success({
                      message_md:
                        typeof result.data === 'string'
                          ? result.data
                          : 'Successfully sent announcement.',
                    });

                    this.props.onClose();
                  })
                  .catch((reason) => {
                    console.error(reason);
                    NotifyHelper.error({
                      message_md: 'Unknown error sending announcement.',
                    });
                  });
              },
            },
          ]}
          loading={this.state.loading}
          onClose={this.props.onClose}
        />
      </ErrorBoundary>
    );
  }

  private renderForm() {
    return (
      <CommonFormGrid columns={2}>
        <Box gridColumn="span 2">
          <Heading size={RADIX.HEADING.SIZE.SM}>Filters</Heading>
        </Box>

        <Box>
          <CommonSearchInput
            id="announcement-teamID"
            name="team_id"
            label="common.team"
            disabled={this.props.authCx.current.role !== UserRole.admin}
            options={this.state.teamOptions}
            values={
              this.state.model.filter.team_id
                ? [this.state.model.filter.team_id]
                : []
            }
            onChange={(v) => this.updateFilter({ team_id: v[0] })}
            optional
          />
        </Box>
        <Box>
          <CommonSearchInput
            id="announcement-machineID"
            label="common.machine"
            options={this.props.machinesCx.machines.map((m) => ({
              label: `${m.machineID} (${m.nickname ?? 'no nickname'})`,
              value: m.machineID,
            }))}
            values={
              this.state.model.filter.machineID
                ? [this.state.model.filter.machineID]
                : []
            }
            onChange={(machineIDs) => {
              this.updateFilter({
                machineID: machineIDs[0],
              });
            }}
          />
        </Box>

        <Box>
          <CommonSelectInput
            id="announcement-role"
            name="role"
            label="Role"
            options={this.state.roleOptions}
            value={this.state.model.filter.role}
            onChange={(v) => this.updateFilter({ role: v as UserRole })}
            optional
          />
        </Box>
        <Box>
          <CommonSelectInput
            id="announcement-email"
            name="email"
            label="User"
            options={this.props.usersCx.options.emails}
            optional
            value={this.state.model.filter.email}
            onChange={(v) => this.updateFilter({ email: v })}
          />
        </Box>

        <Box gridColumn="span 2">
          <Heading size={RADIX.HEADING.SIZE.SM}>Details</Heading>
        </Box>

        <Box>
          <CommonSelectInput
            id="announcement-level"
            name="level"
            label="Level"
            options={[
              { label: 'Neutral', value: RADIX.COLOR.NEUTRAL },
              { label: 'Success', value: RADIX.COLOR.SUCCESS },
              { label: 'Warning', value: RADIX.COLOR.WARNING },
              { label: 'Danger', value: RADIX.COLOR.DANGER },
            ]}
            value={this.state.model.color}
            onChange={(v) => this.updateModel({ color: v as RadixColor })}
            optional
          />
        </Box>
        <Box>
          <CommonSelectInput
            id="announcement-delay"
            name="delay_ms"
            label="Duration"
            options={[
              {
                label: 'Temporary (5s)',
                value: '5000',
              },
              {
                label: 'Permanent',
                value: '0',
              },
            ]}
            value={this.state.model.delay_ms?.toString()}
            onChange={(v) => this.updateModel({ delay_ms: safeNumber(v) })}
            optional
          />
        </Box>

        <Box gridColumn="span 2">
          <CommonTextareaInput
            id="announcement-message"
            value={this.state.model.message}
            disabled={this.state.loading}
            name="message"
            maxLength={MAX_LENGTH}
            onChange={(v) => this.updateModel({ message: v })}
            hint_md="Markdown input is also accepted"
            rows={5}
          />
        </Box>
      </CommonFormGrid>
    );
  }
}
